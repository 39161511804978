import { BdtBaseModel } from "./bdt-base.model";
import { BdtCategoriaModel } from "./bdt-categoria.model";

export class BdtTipoMovilModel extends BdtBaseModel {
    Id: number;
    Codigo: string;
    Acronimo: string;
    Nombre: string;
    Descripcion: string;
    Categoria_id: number;
    Categoria: BdtCategoriaModel;
}