<div #modal id="myModal" class="modal">
    <div class="modal-header">
        <div class="modal-options">
            <i class="modal-icon" nz-icon nzType="rotate-left" nzTheme="outline" (click)="onRotate(1)"></i>
            <i class="modal-icon" nz-icon nzType="rotate-right" nzTheme="outline" (click)="onRotate(0)"></i>
            <!-- <i class="modal-icon" nz-icon nzType="save" nzTheme="outline"></i> -->
            <span class="close" (click)="closeModal()">&times;</span>
        </div>

    </div>

    <div class="modal-content" (click)="closeModal()">
        <div class="img-container">
            <canvas #imageCanvas class="canvas-modal" id="myCanvas">

                <!-- <img class="modal-img" src="assets/images/movisat.png" id="img01"> -->
            </canvas>
        </div>

    </div>


</div>