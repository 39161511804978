<jqxWindow #form [theme]="theme" [position]="getFormPos(form)" [zIndex]="90" [width]="550" [height]="400"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Acciones')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden; ">
        <div style="float:left; margin-top: 4px; width: 100%; height: 90%;">
            <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'calc(120% - 85px)'" [source]="dataAdapter"
                [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="true"
                [columns]="columns" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
                [altrows]="true" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
                [columnsheight]="20" [enabletooltips]="true" [editable]="false" (onRowclick)="onRowclick($event)"
                (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-top: 6px;">
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"'
                [value]="translate('Crear')" (onClick)="onCrearAccion($event)">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
                [value]="translate('Editar')" (onClick)="onEditarAccion($event)">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"'
                [value]="translate('Borrar')" (onClick)="onBorrarAccion($event)">
            </jqxButton>
        </div>
    </div>
</jqxWindow>

<div hidden="viewForm">
    <jqxWindow #formAccion [theme]="theme" [position]="getFormPos(formAccion)" [zIndex]="90" [width]="250"
        [height]="165" [autoOpen]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
        (onClose)="onCloseForm()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Acciones')}}
        </div>
        <div class="form" style="float:left; overflow-x: hidden; ">
            <div style="float:left; margin-top: 4px; width: 100%; display: flex; flex-direction: column;">
                <div style="margin-top: 5px; float:left; clear:both;">
                    {{translate('Nombre')}}
                </div>
                <jqxInput [width]="150" [height]="25" [(ngModel)]="accion.Nombre">
                </jqxInput>
                <div style="margin-top: 5px; float:left; clear:both;">
                    {{translate('Observaciones')}}
                </div>
                <jqxInput [width]="150" [height]="25" [(ngModel)]="accion.Observaciones">
                </jqxInput>
            </div>
            <div style="float: left; margin-top: 6px;">
                <div style="position:absolute; bottom:5px; left:5px; margin-top: 15px; clear: both;">
                    <jqxButton style="float: left; margin-left: 1px" [width]="100" [height]="26" [imgPosition]="'left'"
                        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                        [imgSrc]="'/assets/images/mas.png'" [value]="translate('Guardar')"
                        (onClick)="onSaveAccion($event)"></jqxButton>
                </div>
            </div>
        </div>
    </jqxWindow>
</div>