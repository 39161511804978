import { MapMarker } from "movisat-maps/lib/models/map.marker";
import { BdtEquipamientoModel } from "../../bdt/models/bdt-equipamiento.model";
import { PuModel } from "../../pu/models/pu.model";
import { DumpsterAnalysisModel } from "./dumpster-analysis.model";
import { TagModel } from "./tag.model";
import { CerraduraModel } from "../../cerraduras/models/cerradura.model";
import { ElemImageModel } from "./elem-image-model";
import { AlarmModel } from "../../alarms/models/alarm.model";
import { ElemInstalacionModel } from "./instalacion/elem-instalacion.model";
import { VolumetricoModel } from "../../volumetricos/models/volumetrico.model";
import { ElementoEstadoModel } from "./elem-estado.model";
import { ElemCoordenadasModel } from "./elem-coordenadas.model";

export class ElementoModel {
  Guid: string = '';
  Id: number = 0;
  Empresa: number = 0;
  Nombre: string = '';
  Lat: number = 0;
  Lng: number = 0;
  IdEquipamiento: number = 0;
  FechaCreacion: Date;
  FechaModificacion: Date;
  FechaBaja: Date;
  ImeiVolum: string;
  UltFechaVolum: Date;
  UltLecturaVolum: number;
  UltPctjeVolum: number;
  UltBateriaVolum: number;
  UltTempVolum: number;
  IdConfigVolum: number;
  IdEcoSAT: number;
  IdVolumetrico: string;
  RequiereVolum: boolean;
  EstadoInstalVolum: number; // 0=No instalado, 1=Instalado pendiente lectura, 2=Instalado correctamente
  IdPU: number;
  Estado: number; // 1=Ubicado, 2=En tránsito
  Calle: string;
  CodigoPostal: string;
  Provincia: string;
  ComunidadAutonoma: string;
  Municipio: string;
  Pais: string;
  NumeroCalle: string;
  IdCircuito: string;
  Tag: TagModel = new TagModel();

  // Nuevos campos modificación vista
  Poblacion: string;
  Observaciones: string;
  FechaInstalacionVolum: Date;
  FechaInstalacionEcoLock: Date;
  AlturaSensor: number;
  AlturaMuerta: number;
  GradosSensor: number;
  SimVolum: number;
  ImeiEcoLock: string;
  SimEcoLock: number;
  IdEcoLock: string;
  RequiereTag: boolean;
  EstadoInstalacionTag: number;

  RequiereEcolock: boolean;
  RequiereIdentificacionEcolock: boolean;
  IdentificacionEcolock: number;
  EstadoInstalacionEcolock: number;
  TipoDispositivoEcolock: number;

  ObservacionesInstalacionTag: string;
  ObservacionesInstalacionEcolock: string;
  ObservacionesInstalacionVolum: string;

  // Agregados
  Equipamiento: BdtEquipamientoModel;
  PU: PuModel = new PuModel();
  Marker: MapMarker;
  selec: string; // Campo usado para filtrar búsquedas
  distancia: string; // Para buscar cercanos
  dumpsterAnalysis?: DumpsterAnalysisModel; // Datos de IA
  Infogeo: string; // Dirección + número + provincia
  estadoTexto: string;
  estadoInstallTexto: string;
  cerradura: CerraduraModel;
  Volumetrico: VolumetricoModel;
  Imagenes: ElemImageModel[] = [];
  Alarma: any;
  Frecuencia: any;
  EstadoElemento: ElementoEstadoModel;

  instalacion: ElemInstalacionModel = new ElemInstalacionModel();
  Coordenadas: ElemCoordenadasModel[] = [];

  constructor() { }
}
