import { TicketModel } from "src/app/services/sso/models/ticket.model";

export class LocalStorage {

    public static setItem(ticket: TicketModel, key: string, data: string): void {
        localStorage.setItem(ticket.Usuario.Id + '-' + ticket.Empresa.Id + '-' + ticket.Aplicacion.Id + '-' + key, data);
    }

    public static getItem(ticket: TicketModel, key: string): string {
        return localStorage.getItem(ticket.Usuario.Id + '-' + ticket.Empresa.Id + '-' + ticket.Aplicacion.Id + '-' + key);
    }
}
