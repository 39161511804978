<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [position]="getFormPos(form)" [width]="465" [height]="190" [zIndex]="100" [autoOpen]="true"
    [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()"
    (onOpen)="onOpen()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Edicion_puntos_ubicacion')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="margin-top: 5px; clear: both;">
            {{translate('Equipamiento')}}
        </div>
        <div style="float:left; clear:both;">
            <jqxDropDownButton #cbEquip style="float:left" [width]="455" [height]="25">
                <jqxGrid #grid [theme]="environment.tema" [width]="451" [autoheight]="true" [source]="dataAdapter"
                    [columnsresize]="true" [selectionmode]="'singlerow'" [columns]="columns" [sortable]="true"
                    [altrows]="true" [showrowlines]="false" [rowsheight]="20" [columnsheight]="20"
                    [enabletooltips]="true" [localization]="langGrid" (onRowclick)="onRowClick($event)">
                </jqxGrid>
            </jqxDropDownButton>
        </div>
        <div style="margin-top: 5px; float:left; clear:both;">
            {{translate('Nombre')}}
        </div>
        <div style="float:left; clear:both;">
            <jqxInput #ebNombre [(ngModel)]="puEdit.Nombre" [width]="455" [height]="25" [disabled]="!canEdit">
            </jqxInput>
        </div>
        <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both;">
            <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardar($event)"
                [value]="translate('Guardar')" [disabled]="!canEdit">
            </jqxButton>
            <jqxButton *ngIf="puEdit.Id==0;else elem_existe_tmpl" style="float:left; margin-left: 4px;" [width]='100'
                [height]='26' [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                [imgSrc]='"/assets/images/posicion.png"' [value]="translate('Posicionar')" (onClick)="onPosicionar()"
                [hidden]="!canEdit">
            </jqxButton>
            <ng-template #elem_existe_tmpl>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/localizar.png"' [value]="translate('Centrar')" (onClick)="onPosicionar()"
                    [hidden]="!canEdit">
                </jqxButton>
            </ng-template>

            <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
              [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
              [value]="translate('Salir')">
            </jqxButton>
        </div>
    </div>
</jqxWindow>
