<jqxWindow #formIncidence [position]="getFormPos(formIncidence)" [theme]="environment.tema" [width]="564" [height]="307"
    [zIndex]="90" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
    (onClose)="onClose()">

    <div class="formHeader">
        <!-- Nueva Incidencia -->
    </div>
    <div #newIncidenceContainer class="form">
        <div class="form-container">
            <div class="column">
                <div class="field-container">
                    <p class="text-name">{{translate('Tipo')}}</p>
                    <jqxDropDownList #listTipos [theme]="environment.tema" [width]="200" [height]="25"
                        [source]="adapterTipos" [displayMember]="'Nombre'" [valueMember]="'Id'"
                        [autoDropDownHeight]="true" [selectedIndex]="0" (onChange)="onChange(0,$event)">
                    </jqxDropDownList>
                </div>
                <div class="field-container">
                    <p class="text-name">{{translate('Modelo')}}</p>
                    <jqxDropDownList #listModelos [theme]="environment.tema" [width]="200" [height]="25"
                        [source]="adapterModelos" [displayMember]="'Nombre'" [valueMember]="'Id'"
                        [autoDropDownHeight]="true" [disabled]="false" [selectedIndex]="0"
                        (onChange)="onChange(1,$event)">
                    </jqxDropDownList>
                </div>
                <div class="field-container">
                    <p class="text-name">{{translate('Motivo')}}</p>
                    <jqxDropDownList #listMotivos [theme]="environment.tema" [width]="200" [height]="25"
                        [source]="adapterMotivos" [displayMember]="'Nombre'" [valueMember]="'Id'"
                        [autoDropDownHeight]="true" [disabled]="false" [selectedIndex]="0"
                        (onChange)="onChange(2,$event)">
                    </jqxDropDownList>
                </div>
                <!-- <div class="field-container" [hidden]="hiddenState">
                    <p class="text-name">{{translate('Estado')}}</p>
                    <jqxDropDownList #listEstados [width]="200" [height]="25" [source]="adapterEstados" [selectedIndex]="0"
                        [displayMember]="'Nombre'" [valueMember]="'Id'" [autoDropDownHeight]="true" [disabled]="true"
                        (onChange)="onChange(3,$event)" [placeHolder]="translate('Selecciona_estado')+'...'">
                    </jqxDropDownList>
                </div> -->

            </div>
            <div class="column-right">
                <div class="field-container" style="height: 50%;">
                    <p class="text-name">{{translate('Observaciones')}}</p>
                    <jqxTextArea #textArea [width]="300" [height]="45" [minLength]="1" (onChange)="input($event)">
                    </jqxTextArea>
                </div>
                <div class="field-container" style="height: 50%;">
                    <div class="text-button">
                        <p class="text-name">{{translate('Imagenes')}}</p>
                    </div>
                    <div class="images-container" draggable="true">

                        <div *ngFor="let image of imagenes;let i = index" [attr.data-index]="i" class="container-image">

                            <img [id]="image.Nombre" class="image" (click)="onClickImage(i)"
                                [src]="'data:image/jpeg;base64,' + image.Imagen">


                            <div class="info-container">

                                <div class="button-container">
                                    <jqxButton [width]="26" [height]="26" (onClick)="onClickBorrar(i)"
                                        [textImageRelation]='"imageBeforeText"' [textPosition]='"center"'
                                        [imgSrc]='"/assets/images/borrar.png"'>
                                    </jqxButton>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="upload-container">
                        <div class="file-container">
                            <div>
                                <input #filesInput type='file' id="getFile" style="display:none"
                                    (change)="uploadFiles(filesInput)" multiple>
                            </div>
                        </div>
                        <label #label class="text-files">
                            {{labelInput}}
                        </label>
                        <!-- <label #label class="text-files" [hidden]="labelHidden">
                            {{labelInput}}
                        </label> -->
                    </div>

                </div>

            </div>

        </div>
        <div class="row-buttons">
            <div class="field-container">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
                    (onClick)="onClickGuardar()">
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' (onClick)="onClickPosicionar()"
                    [imgSrc]='"/assets/images/posicion.png"' [value]="translate('Posicionar')">
                </jqxButton>
                <jqxButton #button style="margin-left: 45px;" [width]='150' [height]='26'
                    (onClick)="filesInput.click()">
                    {{textButton}}
                </jqxButton>
            </div>
        </div>
    </div>

</jqxWindow>