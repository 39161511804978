import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';

import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';

import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';

@NgModule({
  declarations: [
    MapToolbarComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MaterialModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
  ],
  exports : [MapToolbarComponent]
})
export class MapToolbarModule { }
