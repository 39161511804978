import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DireccionModel } from '../ciudadanos/models/direccion.model';
import { SsoService } from '../sso/sso.service';

@Injectable({
  providedIn: 'root'
})
export class DireccionService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private ssoService: SsoService, private http: HttpClient) { }

  async saveDireccion(direccion: DireccionModel): Promise<DireccionModel> {
    let response: DireccionModel = new DireccionModel();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    try{
      response = await this.http.post<DireccionModel>(
        this.urlApi + '/api/ciudadano/direccion/add',
        JSON.stringify(direccion),
        httpOptions
      ).toPromise();
    }catch(e){
      response = null;
    }
    return response;
  }

  async deleteDireccion(direccion: DireccionModel) {
    let response = false;

    try {
      await this.http.delete(
        this.urlApi + '/api/ciudadano/direccion/del?id=' + direccion.id
      ).toPromise().then(
        res => {
          response = true;
        }, error => {
          response = false;
        }
      );
    } catch (e) {
      response = false;
    }
    return response;
  }
}
