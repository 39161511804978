<jqxWindow #calendar [theme]="theme" [position]="getFormPos(calendar)" [width]="910" [zIndex]="1" [height]="480"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Calendarios')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <jqxScheduler #schedulerReference [theme]="theme" [width]="'100%'" [height]="'100%'" [views]="views" [view]="2"
            [showLegend]="true" [date]="date" [localization]="langGrid" [appointmentDataFields]="appointmentDataFields"
            [source]="dataAdapter" [resources]="resources" [editDialog]="false" (onViewChange)="ViewChange($event)">
        </jqxScheduler>
    </div>
</jqxWindow>