<jqxRibbon style="overflow: hidden" [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
[position]="'top'">
  <ul style="width: 100%; text-align: left">
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Volumetrico") }}
    </li>
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Tag") }}
    </li>
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Ecolock") }}
    </li>
  </ul>
  <div>
    <div class="row tab" style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div id="fieldInstalacionVolumetrico">
        <fieldset>
          <legend>{{ translate('Volumetrico') }}</legend>
          <div style="margin-top: 3%;">
            <span style="width: 50%;">
              <div style="float: left;">
                <jqxCheckBox [checked]="elemEdit ? elemEdit?.RequiereVolum : false" [disabled]="!editVolumetrico">
                  {{translate('Requiere_instalacion')}}
                </jqxCheckBox>
              </div>
            </span>
          </div>

          <div style="margin-top: 10%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>

            <jqxDropDownList #dropEstadoInstalacionVolum [ngModel]="elemEdit?.EstadoInstalVolum" (focusout)="dropEstadoInstalacionVolum.close()"
              [width]="'100%'" [dropDownHeight]="sourceEstadoInstalacionVolum.length * 30" [source]="sourceEstadoInstalacionVolum" [disabled]="!editVolumetrico">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10px;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Tipo_dispositivo") }}
            </div>
            <jqxDropDownButton #dropTipoDispositivoVolum style="float: left" [width]="'100%'" (onClose)="onCloseDropTipoDispositivoVolum($event)"
            (onOpen)="onOpenDropTipoDispositivoVolum($event)" [disabled]="!editVolumetrico" (focusout)="dropTipoDispositivoVolum.close()">
              <div [hidden]="gridDropTipoDispositivoVolum" style="width: 100%;">
                <jqxGrid #gridDropDownTipoDispositivoVolum [theme]="environment.tema" [width]="dropTipoDispositivoVolum?.scrollWidth"
                  [height]="100" [source]="dataAdapterTipoDispositivoVolum" [columnsresize]="true" [localization]="langGrid"
                  [selectionmode]="'singlerow'" [columns]="columnsTipoDispositivoVolum" [sortable]="true" [altrows]="true"
                  [showrowlines]="true" [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true"
                  [localization]="langGrid" (onRowclick)="onRowClickTipoDispositivoVolum($event)">
                </jqxGrid>
              </div>
            </jqxDropDownButton>
          </div>

          <div style="margin-top: 10%;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="elemEdit?.ObservacionesInstalacionVolum" (ngModelChange)="elemEdit.ObservacionesInstalacionVolum = $event" [width]="'100%'" [height]="130">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <div class="row" style="height: 98%;">
            <div class="container imagenPrincipal">
              <div class="row" style="height: 350px;">
                <!-- <div *ngIf="selectedImg != null" style="width: 350px; height: 350px;">
                  <img #imgPrincipal src="data:image/png;base64,{{selectedImg?.imagen}}"
                    style="width: 90%; height: 90%;">
                  <h3 style="float: right;">{{ formatDate(selectedImg.fecha, true) }}</h3>
                </div> -->
              </div>

              <div class="row" style="margin-top: 5px;">
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/upload.png"' [imgPosition]="'center'">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/borrar.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
              </div>
            </div>

            <div class="container sliderImages">
              <!-- <div *ngFor="let img of elemEdit?.instalacion?.detalle; let i = index"
                style="margin: 4% 0%; align-items: center; background-color: white; padding: 6px; width: 75px; height: 80px;">
                <div class="min-image" style="width: 100%; height: 100%;">
                  <img class="min-image"
                    style="margin-left: 10px; padding: auto; align-items: center; width: 40px; height: 40px;"
                    src="data:image/png;base64,{{img.imagen}}" (click)="setImgPrincipal(img)">
                  <p class="min-image">{{ formatDate(img.fecha, true) }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasVolum [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'" [source]="dataAdapterIncidenciasVolum"
            [columns]="columnsIncidenciasVolum" [showdefaultloadelement]="false" [localization]="langGrid" [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <div class="row" style="height: 98%;">
            <div class="container imagenPrincipal">
              <div class="row" style="height: 350px;">
                <!-- <div *ngIf="selectedImg != null" style="width: 350px; height: 350px;">
                  <img #imgPrincipal src="data:image/png;base64,{{selectedImg?.imagen}}"
                    style="width: 90%; height: 90%;">
                  <h3 style="float: right;">{{ formatDate(selectedImg.fecha, true) }}</h3>
                </div> -->
              </div>

              <div class="row" style="margin-top: 5px;">
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/upload.png"' [imgPosition]="'center'">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/borrar.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
              </div>

            </div>

            <div class="container sliderImages">
              <!-- <div *ngFor="let img of elemEdit.Imagenes; let i = index"
                style="margin: 4% 0%; align-items: center; background-color: white; padding: 6px; width: 75px; height: 80px;">
                <div class="min-image" style="width: 100%; height: 100%;">
                  <img class="min-image"
                    style="margin-left: 10px; padding: auto; align-items: center; width: 40px; height: 40px;"
                    src="data:image/png;base64,{{img.imagen}}" (click)="setImgPrincipal(img)">
                  <p class="min-image">{{ formatDate(img.fecha, true) }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row tab" style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div id="fieldInstalacionTag">
        <fieldset>
          <legend>{{ translate('Tag') }}</legend>
          <div style="margin-top: 3%;">
            <span style="width: 50%;">
              <div style="float: left;">
                <jqxCheckBox [checked]="elemEdit?.instalacion ? elemEdit?.instalacion?.requiereInstalacionTag : false" [disabled]="!editTag">
                  {{translate('Requiere_instalacion')}}
                </jqxCheckBox>
              </div>
            </span>
          </div>

          <div style="margin-top: 10%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>

            <jqxDropDownList #dropEstadoInstalacionTag [ngModel]="elemEdit?.EstadoInstalacionTag" (focusout)="dropEstadoInstalacionTag.close()"
              [width]="'100%'" [dropDownHeight]="sourceEstadoInstalacionTag.length * 30" [source]="sourceEstadoInstalacionTag" [disabled]="!editTag">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10px;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Tipo_dispositivo") }}
            </div>
            <jqxDropDownButton #dropTipoDispositivoTag style="float: left" [width]="'100%'" (onClose)="onCloseDropTipoDispositivoTag($event)"
            (onOpen)="onOpenDropTipoDispositivoTag($event)" [disabled]="!editTag" (focusout)="dropTipoDispositivoTag.close()">
              <div [hidden]="gridDropTipoDispositivoTag" style="width: 100%;">
                <jqxGrid #gridDropDownTipoDispositivoTag [theme]="environment.tema" [width]="dropTipoDispositivoTag?.scrollWidth"
                  [height]="100" [source]="dataAdapterTipoDispositivoTag" [columnsresize]="true" [localization]="langGrid"
                  [selectionmode]="'singlerow'" [columns]="columnsTipoDispositivoTag" [sortable]="true" [altrows]="true"
                  [showrowlines]="true" [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true"
                  [localization]="langGrid" (onRowclick)="onRowClickTipoDispositivoTag($event)">
                </jqxGrid>
              </div>
            </jqxDropDownButton>
          </div>

          <div style="margin-top: 10%;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="elemEdit?.ObservacionesInstalacionTag" (ngModelChange)="elemEdit.ObservacionesInstalacionTag" [width]="'100%'" [height]="130">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <div class="row" style="height: 98%;">
            <div class="container imagenPrincipal">
              <div class="row" style="height: 350px;">
                <!-- <div *ngIf="selectedImg != null" style="width: 350px; height: 350px;">
                  <img #imgPrincipal src="data:image/png;base64,{{selectedImg?.imagen}}"
                    style="width: 90%; height: 90%;">
                  <h3 style="float: right;">{{ formatDate(selectedImg.fecha, true) }}</h3>
                </div> -->
              </div>

              <div class="row" style="margin-top: 5px;">
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/upload.png"' [imgPosition]="'center'">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/borrar.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
              </div>
            </div>

            <div class="container sliderImages">
              <!-- <div *ngFor="let img of elemEdit.Imagenes; let i = index"
                style="margin: 4% 0%; align-items: center; background-color: white; padding: 6px; width: 75px; height: 80px;">
                <div class="min-image" style="width: 100%; height: 100%;">
                  <img class="min-image"
                    style="margin-left: 10px; padding: auto; align-items: center; width: 40px; height: 40px;"
                    src="data:image/png;base64,{{img.imagen}}" (click)="setImgPrincipal(img)">
                  <p class="min-image">{{ formatDate(img.fecha, true) }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasTag [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'" [source]="dataAdapterIncidenciasTag"
            [columns]="columnsIncidenciasTag" [showdefaultloadelement]="false" [localization]="langGrid" [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <div class="row" style="height: 98%;">
            <div class="container imagenPrincipal">
              <div class="row" style="height: 350px;">
                <!-- <div *ngIf="selectedImg != null" style="width: 350px; height: 350px;">
                  <img #imgPrincipal src="data:image/png;base64,{{selectedImg?.imagen}}"
                    style="width: 90%; height: 90%;">
                  <h3 style="float: right;">{{ formatDate(selectedImg.fecha, true) }}</h3>
                </div> -->
              </div>

              <div class="row" style="margin-top: 5px;">
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/upload.png"' [imgPosition]="'center'">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/borrar.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
              </div>

            </div>

            <div class="container sliderImages">
              <!-- <div *ngFor="let img of elemEdit.Imagenes; let i = index"
                style="margin: 4% 0%; align-items: center; background-color: white; padding: 6px; width: 75px; height: 80px;">
                <div class="min-image" style="width: 100%; height: 100%;">
                  <img class="min-image"
                    style="margin-left: 10px; padding: auto; align-items: center; width: 40px; height: 40px;"
                    src="data:image/png;base64,{{img.imagen}}" (click)="setImgPrincipal(img)">
                  <p class="min-image">{{ formatDate(img.fecha, true) }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row tab" style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div id="fieldInstalacionEcoLock">
        <fieldset>
          <legend>{{ translate('Ecolock') }}</legend>
          <div style="margin-top: 3%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Requiere_instalacion") }}:
            </div>
            <div style="margin-top: 5px; margin-left: 10px;">
              <jqxCheckBox [checked]="elemEdit?.instalacion ? elemEdit?.instalacion?.requiereInstalacionCerradura : false" [disabled]="!editEcoLock">
                {{translate('Ecolock')}}
              </jqxCheckBox>
            </div>
            <div style="margin-top: 5px; margin-left: 10px;">
              <jqxCheckBox [checked]="elemEdit?.instalacion ? elemEdit?.instalacion?.requiereInstalacionIdentificador : false" [disabled]="!editEcoLock">
                {{translate('Identificacion')}}
              </jqxCheckBox>
            </div>
          </div>

          <div style="margin-top: 10px;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>
            <jqxDropDownList #dropEstadoInstalacionEcolock [ngModel]="elemEdit?.EstadoInstalacionEcolock" (focusout)="dropEstadoInstalacionEcolock.close()"
              [width]="'100%'" [dropDownHeight]="sourceEstadoInstalacion.length * 30" [source]="sourceEstadoInstalacion" [disabled]="!editEcoLock">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10px;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Tipo_dispositivo") }}
            </div>
            <!-- TODO: cambiar DropDownHeight -->
            <jqxDropDownList #dropTipoDispositivoEcolock [ngModel]="elemEdit?.TipoDispositivoEcolock" (focusout)="dropTipoDispositivoEcolock.close()"
              [width]="'100%'" [dropDownHeight]="30" [source]="sourceTipoDispositivoEcolock" [disabled]="!editEcoLock">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10px;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="elemEdit?.Observaciones" (ngModelChange)="elemEdit.Observaciones = $event" [width]="'100%'" [height]="100">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <div class="row" style="height: 98%;">
            <div class="container imagenPrincipal">
              <div class="row" style="height: 330px;">
                <!-- <div *ngIf="selectedImg != null" style="width: 350px; height: 350px;">
                  <img #imgPrincipal src="data:image/png;base64,{{selectedImg?.imagen}}"
                    style="width: 90%; height: 90%;">
                  <h3 style="float: right;">{{ formatDate(selectedImg.fecha, true) }}</h3>
                </div> -->
              </div>

              <div class="row" style="margin-top: 5px;">
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/upload.png"' [imgPosition]="'center'">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/borrar.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
              </div>
            </div>

            <div class="container sliderImages">
              <!-- <div *ngFor="let img of elemEdit.Imagenes; let i = index"
                style="margin: 4% 0%; align-items: center; background-color: white; padding: 6px; width: 75px; height: 80px;">
                <div class="min-image" style="width: 100%; height: 100%;">
                  <img class="min-image"
                    style="margin-left: 10px; padding: auto; align-items: center; width: 40px; height: 40px;"
                    src="data:image/png;base64,{{img.imagen}}" (click)="setImgPrincipal(img)">
                  <p class="min-image">{{ formatDate(img.fecha, true) }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasEcolock [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'" [source]="dataAdapterIncidenciasEcolock"
            [columns]="columnsIncidenciasEcolock" [showdefaultloadelement]="false" [localization]="langGrid" [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <div class="row" style="height: 98%;">
            <div class="container imagenPrincipal">
              <div class="row" style="height: 350px;">
                <!-- <div *ngIf="selectedImg != null" style="width: 350px; height: 350px;">
                  <img #imgPrincipal src="data:image/png;base64,{{selectedImg?.imagen}}"
                    style="width: 90%; height: 90%;">
                  <h3 style="float: right;">{{ formatDate(selectedImg.fecha, true) }}</h3>
                </div> -->
              </div>

              <div class="row" style="margin-top: 5px;">
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/upload.png"' [imgPosition]="'center'">
                </jqxButton>
                <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                  [imgSrc]='"/assets/images/borrar.png"' [imgPosition]="'center'"
                  [disabled]="selectedImg == null">
                </jqxButton>
              </div>

            </div>

            <div class="container sliderImages">
              <!-- <div *ngFor="let img of elemEdit.Imagenes; let i = index"
                style="margin: 4% 0%; align-items: center; background-color: white; padding: 6px; width: 75px; height: 80px;">
                <div class="min-image" style="width: 100%; height: 100%;">
                  <img class="min-image"
                    style="margin-left: 10px; padding: auto; align-items: center; width: 40px; height: 40px;"
                    src="data:image/png;base64,{{img.imagen}}" (click)="setImgPrincipal(img)">
                  <p class="min-image">{{ formatDate(img.fecha, true) }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</jqxRibbon>
