import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { ConfigService } from 'src/app/services/config/config.service';
import { SensorsService } from 'src/app/services/sensors/sensors.service';

import { SensorModel } from 'src/app/services/sensors/models/sensor.model';
import { Utils } from 'src/app/utils/utils';
import { MovilModel } from 'src/app/services/resources/models/movil.model';

class Config {
  public tiempoParada = 5; // Cinco minutos
  public criterio = 'X_VELOCIDAD';
  public colorFijo = '4cff33';
  public colorVel1 = '4cff33';
  public colorVel2 = 'cb9500';
  public colorVel3 = 'ff333c';
  public colorLu = '33f6ff';
  public colorMa = '4cff33';
  public colorMi = 'ff33f9';
  public colorJu = 'cb9500';
  public colorVi = 'bb33ff';
  public colorSa = '3342ff';
  public colorDo = 'ff333c';
  public sensor = 16; // Ignición
  public colorSensor1 = '4cff33';
  public colorSensor0 = 'ff333c';
  public colorSensorX = '000000';
}

@Component({
  selector: 'app-sensor-filter',
  templateUrl: './sensor-filter.component.html',
  styleUrls: ['./sensor-filter.component.css']
})

export class SensorFilterComponent implements OnInit, AfterViewInit {
  @ViewChild('form', { static: false }) form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('dtParada', { static: false }) dtParada: jqxDateTimeInputComponent;
  @ViewChild('cbCriterio', { static: false }) cbCriterio: jqxDropDownListComponent;
  @ViewChild('cbColorFijo', { static: false }) cbColorFijo: jqxDropDownButtonComponent;
  @ViewChild('cbColorVel1', { static: false }) cbColorVel1: jqxDropDownButtonComponent;
  @ViewChild('cbColorVel2', { static: false }) cbColorVel2: jqxDropDownButtonComponent;
  @ViewChild('cbColorVel3', { static: false }) cbColorVel3: jqxDropDownButtonComponent;
  @ViewChild('cbColorLu', { static: false }) cbColorLu: jqxDropDownButtonComponent;
  @ViewChild('cbColorMa', { static: false }) cbColorMa: jqxDropDownButtonComponent;
  @ViewChild('cbColorMi', { static: false }) cbColorMi: jqxDropDownButtonComponent;
  @ViewChild('cbColorJu', { static: false }) cbColorJu: jqxDropDownButtonComponent;
  @ViewChild('cbColorVi', { static: false }) cbColorVi: jqxDropDownButtonComponent;
  @ViewChild('cbColorSa', { static: false }) cbColorSa: jqxDropDownButtonComponent;
  @ViewChild('cbColorDo', { static: false }) cbColorDo: jqxDropDownButtonComponent;
  @ViewChild('cbSensor', { static: false }) cbSensor: jqxDropDownListComponent;
  @ViewChild('cbColorSensor1', { static: false }) cbColorSensor1: jqxDropDownButtonComponent;
  @ViewChild('cbColorSensor0', { static: false }) cbColorSensor0: jqxDropDownButtonComponent;
  @ViewChild('cbColorSensorX', { static: false }) cbColorSensorX: jqxDropDownButtonComponent;

  public componentRef: any = null;
  private sensores: SensorModel[] = [];
  public canEdit = true;
  public environment = environment;
  public config: Config = new Config();
  public textVel1 = this.translate('Hasta') + ' 60';
  public textVel2 = this.translate('Hasta') + ' 100';
  public textVel3 = this.translate('Mas');
  public tiempoParada = "00:05:00";
  private movil: MovilModel;

  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);
  public columns: any = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60, hidden: true },
    { text: '', columntype: 'image', datafield: 'imagen', width: 16, cellsrenderer: this.imagerenderer },
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 300,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + ' </div>';
        }
        return renderstring;
      }
    }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private configService: ConfigService,
    private sensorService: SensorsService) {
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
  }

  async ngAfterViewInit(): Promise<void> {
    this.form.setTitle(AppComponent.translate('Configuracion'))
    await this.getConfig();
    this.updateContent();
  }

  async getConfig(): Promise<void> {
    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    this.loader.open();
    const res = await this.configService.getUsuEmpApp('config-recorridos', null);
    if (res) {
      this.config = JSON.parse(res);
      const hh = Math.trunc(this.config.tiempoParada / 60);
      const mm = Math.trunc(this.config.tiempoParada - hh * 60);
      const ss = Math.trunc((this.config.tiempoParada - (hh * 60) - mm) * 60 + 0.5);
      this.tiempoParada = hh.toString().padStart(2, '0') + ':';
      this.tiempoParada += mm.toString().padStart(2, '0') + ':';
      this.tiempoParada += ss.toString().padStart(2, '0');
    }
    this.loader.close();
  }

  getPos(): any {
    const footer = document.getElementById('headerLayout').clientHeight;
    return {
      x: 0,
      y: document.getElementById('headerLayout').clientHeight
    };
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, movil: MovilModel) {
    this.componentRef = componentRef;
    this.movil = movil;
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  imagerenderer(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="' + value + '"/>';
  }

  // Cierro el formulario y destruyo el componente
  onClose() {
    this.cbColorVel1.close();
    this.cbColorVel2.close();
    this.cbColorVel3.close();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  async onBindingComplete() {
    const rows = this.grid.getrows();
    if (rows) {
      let select: any = await this.configService.getUsuEmpApp('sensores-filter', null);
      if (select) {
        select = JSON.parse(select);
      } else {
        select = [];
      }
      rows.forEach(row => {
        if (select.find(s => s.id === row.id) !== undefined) {
          this.grid.selectrow(row.boundindex);
        }
      });
    }

    Utils.renderSizeGrid(this.grid, 500)
  }

  // Rellena la lista de sensores
  async updateContent() {
    this.sensores = await this.sensorService.getSensorsByMovil(this.movil.Codigo);
    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'imagen', type: 'image', map: 'Icono' },
        { name: 'nombre', type: 'string', map: 'Nombre' }
      ],
      localdata: this.sensores,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    // Relleno el combo de sensores
    if (this.sensores) {
      let i = 0;
      this.sensores.forEach(sensor => {
        if (sensor.Id < 9 || sensor.Id === 16) {
          this.cbSensor.addItem({ label: sensor.Nombre, value: sensor.Id });
          if (sensor.Id === this.config.sensor) {
            this.cbSensor.selectIndex(i);
          }
          i++;
        }
      });
    }
  }

  onGuardarClick() {
    this.loader.open();
    const sensores: any[] = [];
    const selection = this.grid.getselectedrowindexes();
    if (selection) {
      selection.forEach(i => {
        sensores.push({ id: this.sensores[i].Id });
      });
    }
    // Guardo la variable de configuración con los datos del filtro
    const hora: string[] = this.tiempoParada.split(':');
    this.config.tiempoParada = Number.parseInt(hora[0]) * 60 + Number.parseInt(hora[1]) + Number.parseInt(hora[2]) / 60;
    this.configService.setUsuEmpApp('sensores-filter', JSON.stringify(sensores));
    this.configService.setUsuEmpApp('config-recorridos', JSON.stringify(this.config));
    this.configService.onSendConfig();
    this.loader.close();
    this.form.close();
  }

  onClickTabSensores() {
  }

  onClickTabParadas() {
  }

  onClickTabColores() {
    if (!this.cbCriterio.getItems()) {
      this.cbCriterio.addItem({ label: this.translate('Color_fijo'), value: 'COLOR_FIJO' });
      this.cbCriterio.addItem({ label: this.translate('Por_velocidad'), value: 'X_VELOCIDAD' });
      this.cbCriterio.addItem({ label: this.translate('Por_dias_semana'), value: 'X_DIAS_SEMANA' });
      this.cbCriterio.addItem({ label: this.translate('Por_sensor'), value: 'X_SENSOR' });
    }
    switch (this.config.criterio) {
      case 'X_VELOCIDAD':
        this.cbCriterio.selectIndex(1);
        break;
      case 'X_DIAS_SEMANA':
        this.cbCriterio.selectIndex(2);
        break;
      case 'X_SENSOR':
        this.cbCriterio.selectIndex(3);
        break;
      default:
        this.cbCriterio.selectIndex(0);
        break;
    }
  }

  hexToRgb(hex: string): any {
    let r = 0; let g = 0; let b = 0;
    try {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        r = parseInt(result[1], 16);
        g = parseInt(result[2], 16);
        b = parseInt(result[3], 16);
      }
    } catch {
    }
    return {
      r: r,
      g: g,
      b: b,
      hex: hex
    }
  }

  colorFijoChange(event: any) {
    this.config.colorFijo = event.args.color.hex;
    this.cbColorFijo.setContent(this.setTextElementByColor(event.args.color, '&nbsp;'));
    this.cbColorFijo.close();
  }

  onSelecCriterio(event: any) {
    this.config.criterio = event.args.item.value;
    switch (this.config.criterio) {
      case 'X_VELOCIDAD':
        this.cbColorVel1.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorVel1), this.textVel1));
        this.cbColorVel2.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorVel2), this.textVel2));
        this.cbColorVel3.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorVel3), this.textVel3));
        break;
      case 'X_DIAS_SEMANA':
        this.cbColorLu.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorLu), this.translate('Lunes')));
        this.cbColorMa.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorMa), this.translate('Martes')));
        this.cbColorMi.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorMi), this.translate('Miercoles')));
        this.cbColorJu.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorJu), this.translate('Jueves')));
        this.cbColorVi.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorVi), this.translate('Viernes')));
        this.cbColorSa.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorSa), this.translate('Sabado')));
        this.cbColorDo.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorDo), this.translate('Domingo')));
        break;
      case 'X_SENSOR':
        this.cbColorSensor1.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorSensor1), this.translate('Activo')));
        this.cbColorSensor0.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorSensor0), this.translate('Inactivo')));
        this.cbColorSensorX.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorSensorX), this.translate('Sin_datos')));
        break;
      default:
        this.cbColorFijo.setContent(this.setTextElementByColor(this.hexToRgb(this.config.colorFijo), '&nbsp;'));
        break;
    }
  }

  onSelecSensor(event: any) {
    this.config.sensor = event.args.item.value;
  }

  closeCbColor(event: any, number) {
    switch (number) {
      case 1:
        this.cbColorVel2.close();
        this.cbColorVel3.close();
        break;
      case 2:
        this.cbColorVel1.close();
        this.cbColorVel3.close();
        break;
      case 3:
        this.cbColorVel1.close();
        this.cbColorVel2.close();
        break;
      default:
        break;
    }
  }

  colorVel1Change(event: any) {
    this.config.colorVel1 = event.args.color.hex;
    this.cbColorVel1.setContent(this.setTextElementByColor(event.args.color, this.textVel1));
  }

  colorVel2Change(event: any) {
    this.config.colorVel2 = event.args.color.hex;
    this.cbColorVel2.setContent(this.setTextElementByColor(event.args.color, this.textVel2));
  }

  colorVel3Change(event: any) {
    this.config.colorVel3 = event.args.color.hex;
    this.cbColorVel3.setContent(this.setTextElementByColor(event.args.color, this.textVel3));
  }

  colorLuChange(event: any) {
    this.config.colorLu = event.args.color.hex;
    this.cbColorLu.setContent(this.setTextElementByColor(event.args.color, this.translate('Lunes')));
    this.cbColorLu.close();
  }

  colorMaChange(event: any) {
    this.config.colorMa = event.args.color.hex;
    this.cbColorMa.setContent(this.setTextElementByColor(event.args.color, this.translate('Martes')));
    this.cbColorMa.close();
  }

  colorMiChange(event: any) {
    this.config.colorMi = event.args.color.hex;
    this.cbColorMi.setContent(this.setTextElementByColor(event.args.color, this.translate('Miercoles')));
    this.cbColorMi.close();
  }

  colorJuChange(event: any) {
    this.config.colorJu = event.args.color.hex;
    this.cbColorJu.setContent(this.setTextElementByColor(event.args.color, this.translate('Jueves')));
    this.cbColorJu.close();
  }

  colorViChange(event: any) {
    this.config.colorVi = event.args.color.hex;
    this.cbColorVi.setContent(this.setTextElementByColor(event.args.color, this.translate('Viernes')));
    this.cbColorVi.close();
  }

  colorSaChange(event: any) {
    this.config.colorSa = event.args.color.hex;
    this.cbColorSa.setContent(this.setTextElementByColor(event.args.color, this.translate('Sabado')));
    this.cbColorSa.close();
  }

  colorDoChange(event: any) {
    this.config.colorDo = event.args.color.hex;
    this.cbColorDo.setContent(this.setTextElementByColor(event.args.color, this.translate('Domingo')));
    this.cbColorDo.close();
  }

  colorSensor1Change(event: any) {
    this.config.colorSensor1 = event.args.color.hex;
    this.cbColorSensor1.setContent(this.setTextElementByColor(event.args.color, this.translate('Activo')));
    this.cbColorSensor1.close();
  }

  colorSensor0Change(event: any) {
    this.config.colorSensor0 = event.args.color.hex;
    this.cbColorSensor0.setContent(this.setTextElementByColor(event.args.color, this.translate('Inactivo')));
    this.cbColorSensor0.close();
  }

  colorSensorXChange(event: any) {
    this.config.colorSensorX = event.args.color.hex;
    this.cbColorSensorX.setContent(this.setTextElementByColor(event.args.color, this.translate('Sin_datos')));
    this.cbColorSensorX.close();
  }

  setTextElementByColor(color: any, text: string): any {
    if (color === 'transparent' || color.hex === '') {
      return '<div style="text-shadow: none; position: relative; padding-bottom: 2px; margin-top: 2px;"></div>';
    }
    let nThreshold = 105;
    let bgDelta = (color.r * 0.299) + (color.g * 0.587) + (color.b * 0.114);
    let foreColor = (255 - bgDelta < nThreshold) ? 'Black' : 'White';
    let element = '<div style="text-shadow: none; position: relative; padding-bottom: 2px; margin-top: 2px;color:' +
      foreColor + '; background: #' + color.hex + '">' + text + '</div>';
    return element;
  }
}
