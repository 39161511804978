
export class ProvCartoEmpresaModel {
    public EmpresaId: number;
    public CartografiaId: number;
    public Raster: boolean;

    constructor(empresaId: number, cartografiaId: number, raster: boolean) {
        this.EmpresaId = empresaId;
        this.CartografiaId = cartografiaId;
        this.Raster = raster;
    }
}