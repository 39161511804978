import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuModel } from './models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) { }

  // Recupera un menú según la aplicación y la empresa
  async getMenu(aplicacionId: number, empresaId: number): Promise<MenuModel> {
    try {
      return await this.http.get<MenuModel>(environment.apiSSO + '/sso/menu/aplicacion/' +
        aplicacionId + '/' + empresaId).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

}
