import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { jqxTabsModule } from 'jqwidgets-ng/jqxtabs';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { HeaderModule } from '../header/header.module';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxRibbonModule } from 'jqwidgets-ng/jqxribbon';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxCalendarModule } from 'jqwidgets-ng/jqxcalendar';
import { jqxSchedulerModule } from 'jqwidgets-ng/jqxscheduler';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';

import { CalendarComponent } from './calendar.component';
import { ViewCalendarComponent } from './view-calendar/view-calendar.component';
import { EditCalendarComponent } from './edit-calendar/edit-calendar.component';
import { AssociateLockComponent } from './associate-lock/associate-lock.component';

@NgModule({
  declarations: [
    CalendarComponent,
    EditCalendarComponent,
    ViewCalendarComponent,
    AssociateLockComponent,
  ],
  imports: [
    FormsModule,
    HeaderModule,
    CommonModule,
    jqxGridModule,
    jqxTabsModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxRibbonModule,
    jqxLoaderModule,
    jqxCalendarModule,
    jqxCheckBoxModule,
    jqxSchedulerModule,
    jqxNumberInputModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule,
  ],
  exports: [
    CalendarComponent
  ]
})
export class CalendarModule { }
