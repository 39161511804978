import { MapMarker } from "movisat-maps/lib/models/map.marker";
import { BdtClaseModel } from "../../bdt/models/bdt-clase.model";
import { BdtConjuntoVehiculoModel } from "../../bdt/models/bdt-conjunto-vehiculo.model";
import { BdtTipoMovilModel } from "../../bdt/models/bdt-tipo-movil.model";
import { SubflotaModel } from "../../flota/models/subflota.model";
import { PosicionModel } from "../../positions/models/posicion.model";
import { GLSModel } from "./gls.model";
import { PerifericoMovilModel } from "./periferico-movil.model";
import { TurnoTrabajoModel } from "./turno-trabajo.model";

export class MovilModel {
    Empresa: number;
    Codigo: number;
    Matricula: string;
    Nombre: string;
    TipoMovil: BdtTipoMovilModel = new BdtTipoMovilModel();
    ConjuntoVehiculo: BdtConjuntoVehiculoModel = new BdtConjuntoVehiculoModel();
    Conectado: number;
    Ignicion: number;
    Subflota: SubflotaModel = new SubflotaModel();
    Clase: BdtClaseModel = new BdtClaseModel();
    // Estos campos no pertenecen al modelo en BD ya que se asignan después
    // Cuando se recuperan las últimas posiciones de cada móvil
    marker: MapMarker; // Marcador asociado con la última posición
    ultimaPos: PosicionModel; // Ultima posición
    selec: string;
    distancia: string; // Para ver móviles cercanos

    // Nuevos campos
    numeroCalca: string;
    disponible: boolean = true;
    estadoVehiculo: string;
    base: string;
    domicilioFlota: string;
    descripcion: string;
    gls: GLSModel = new GLSModel();
    periferico: PerifericoMovilModel = new PerifericoMovilModel();
    turnoTrabajo: TurnoTrabajoModel[] = [];

    constructor() { }

    // Método que permite saber si ha cambiado algún dato significativo del móvil
    public static compare(movil1: MovilModel, movil2: MovilModel): boolean {
        return movil1.Nombre === movil2.Nombre &&
            movil1.Matricula === movil2.Matricula &&
            movil1.Conectado === movil2.Conectado &&
            movil1.Ignicion === movil2.Ignicion &&
            (movil1.Subflota && movil2.Subflota && movil1.Subflota.Id === movil2.Subflota.Id &&
                movil1.Subflota.Nombre === movil2.Subflota.Nombre);
    }
}
