import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';
import { ConfiguracionCerraduraService } from 'src/app/services/cerraduras/configuracion-cerradura.service';

import { CerraduraModel } from 'src/app/services/cerraduras/models/cerradura.model';
import { ConfiguracionCerraduraModel } from 'src/app/services/cerraduras/models/configuracionCerradura.model';

import { ConfiguracionCerradurasComponent } from '../configuracion-cerraduras.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-configuracion-cerradura-asignar',
  templateUrl: './configuracion-cerradura-asignar.component.html',
  styleUrls: ['./configuracion-cerradura-asignar.component.css']
})

export class ConfiguracionCerraduraAsignarComponent extends CustomForms implements OnInit {
  @ViewChild('windowForm') windowForm: jqxWindowComponent;
  @ViewChild('gridConfiguraciones') gridConfiguraciones: jqxGridComponent;
  @ViewChild('gridCerradurasAsignadas') gridCerradurasAsignadas: jqxGridComponent;
  @ViewChild('gridCerradurasLibres') gridCerradurasLibres: jqxGridComponent;

  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public configuracionSelect: ConfiguracionCerraduraModel;
  public cerradurasAsignadasCheck: number[] = [];
  public cerradurasLibresCheck: number[] = [];
  private clearSelection: boolean = false;
  columnsGridCerraduras: any;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500);
  }

  public init(componentRef: any, configuraciones: ConfiguracionCerraduraModel[], selectConfiguracion: ConfiguracionCerraduraModel) {
    this.configuracionSelect = selectConfiguracion;

    this.columnsGridCerraduras = [
      { text: 'ID', columntype: 'textbox', datafield: 'id', width: 50, hidden: true },
      {
        text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nsMovisat', width: 140,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }], //agrego el total al grid
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total') + ': ' +
              aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Ns_fabricante'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nsFabricante', width: 160, },
      { text: this.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'elemento', width: 'auto' },
      { text: this.translate('Imei'), columntype: 'textbox', filtertype: 'textbox', datafield: 'imei', width: 130 },
    ];

    this.initGridConfiguraciones();
    this.initGridCerradurasLibres();
    this.initGridCerradurasAsignadas();
    this.componentRef = componentRef;
    this.configuraciones = configuraciones;
  }

  constructor(
    private ssoService: SsoService,
    private cerraduraService: CerraduraService,
    private configuracionCerraduraService: ConfiguracionCerraduraService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit() {
    this.addCustomForm(this.windowForm);
    this.windowForm.setTitle(this.translate('Asignar_cerraduras'));
    setTimeout(() => {
      this.initGridConfiguraciones();
      this.initGridCerradurasLibres();
      this.initGridCerradurasAsignadas();
      this.getCerradurasSinConfiguracion();
    }, 100);
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
      ConfiguracionCerradurasComponent._this.form.expand();
    }
  }

  closeWindow() {
    this.windowForm.close();
  }

  asociarCerraduras() {
    this.clearSelection = true;
    this.associateCerradura().then(
      async (result: boolean) => {
        if (result === true) {
          this.cerradurasLibresCheck.forEach((element: number) => {
            this.cerradurasAsignadas.push(this.cerradurasLibres.find(x => x.id === element));
            let index = this.cerradurasLibres.findIndex(x => x.id === element);
            this.cerradurasLibres.splice(index, 1);
          });

          this.gridCerradurasLibres.clearselection();
          this.cerradurasLibresCheck = [];

          this.gridCerradurasAsignadas.updatebounddata();
          this.gridCerradurasLibres.updatebounddata();
        }
      }
    );
    this.clearSelection = false;
  }

  async associateCerradura(): Promise<boolean> {
    if (this.cerradurasLibresCheck.length > 1) {
      return await this.configuracionCerraduraService.associateCerradurasToConfiguracion(this.configuracionSelect.id, this.cerradurasLibresCheck);
    } else {
      return await this.configuracionCerraduraService.associateCerraduraToConfiguracion(this.configuracionSelect.id, this.cerradurasLibresCheck[0]);
    }
  }

  desasociarCerraduras() {
    this.clearSelection = true;
    this.disassociateCerradura().then(
      async (result: boolean) => {
        if (result === true) {
          this.cerradurasAsignadasCheck.forEach((element: number) => {
            this.cerradurasLibres.push(this.cerradurasAsignadas.find(x => x.id === element));
            let index = this.cerradurasAsignadas.findIndex(x => x.id === element);
            this.cerradurasAsignadas.splice(index, 1);
          });

          this.gridCerradurasAsignadas.clearselection();
          this.cerradurasAsignadasCheck = [];

          this.gridCerradurasAsignadas.updatebounddata();
          this.gridCerradurasLibres.updatebounddata();
        }
      }
    );
    this.clearSelection = false;
  }

  async disassociateCerradura(): Promise<boolean> {
    if (this.cerradurasAsignadasCheck.length > 1) {
      return await this.configuracionCerraduraService.disassociateCerradurasFromConfiguracion(this.cerradurasAsignadasCheck);
    } else {
      return await this.configuracionCerraduraService.disassociateCerraduraFromConfiguracion(this.cerradurasAsignadasCheck[0]);
    }
  }

  /* Obtener cerraduras sin configuracion */
  async getCerradurasSinConfiguracion() {
    this.cerradurasLibres = await this.cerraduraService.getCerradurasSinConfiguracion();

    if (!this.cerradurasLibres) {
      this.cerradurasLibres = [];
    }

    this.sourceGridCerradurasLibres.localdata = this.cerradurasLibres;
    this.gridCerradurasLibres.updatebounddata();
  }

  /* Grid configuraciones */
  configuraciones: ConfiguracionCerraduraModel[] = [];
  public columnsGridConfiguracion;
  public sourceGridConfiguracion: any;
  public adapterGridConfiguracion;

  initGridConfiguraciones() {
    this.columnsGridConfiguracion = [
      { text: 'ID', columntype: 'textbox', datafield: 'id', width: 50, hidden: true },
      {
        text: this.translate('Descripcion_breve'), columntype: 'textbox', datafield: 'nombre', width: 'auto',
        /* Muestra el total de filas en el grid */
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total') + ': ' +
              aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Servidor'), columntype: 'textbox', datafield: 'host', width: 250 },
      { text: this.translate('Puerto'), columntype: 'textbox', datafield: 'puerto', width: 100 }
    ];
    this.columnsGridConfiguracion.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    this.sourceGridConfiguracion = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'string', map: 'id' },
        { name: 'nombre', type: 'string', map: 'nombre' },
        { name: 'host', type: 'string', map: 'host' },
        { name: 'puerto', type: 'number', map: 'puerto' },
      ],
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };
    this.sourceGridConfiguracion.localdata = this.configuraciones;
    this.adapterGridConfiguracion = new jqx.dataAdapter(this.sourceGridConfiguracion);

    setTimeout(() => {
      this.gridConfiguraciones.updatebounddata('data');

      this.gridConfiguraciones.getrows().forEach((row, index) => {
        if (row.id === this.configuracionSelect.id) {
          this.gridConfiguraciones.selectrow(row.boundindex);
          this.gridConfiguraciones.getselectedrowindexes();
          this.gridConfiguraciones.scrolloffset(index * 20, 0);
        }
      });
      //this.resizeColumns(this.gridConfiguraciones);
    }, 200)
  }

  async onRowClick(event: any) {
    let configuracion: any = this.gridConfiguraciones.getrowdatabyid(event.args.rowindex);
    this.configuracionSelect = configuracion;

    this.getCerradurasPorConfiguracion();
  }

  async getCerradurasPorConfiguracion() {
    if (this.configuracionSelect) {
      this.cerradurasAsignadas = await this.cerraduraService.getCerradurasPorConfiguracion(this.configuracionSelect.id);

      this.sourceGridCerradurasAsignadas.localdata = this.cerradurasAsignadas;
      this.gridCerradurasAsignadas.updatebounddata();
    }
  }

  /* Grid cerradurasAsignadas */
  cerradurasAsignadas: CerraduraModel[] = [];
  public sourceGridCerradurasAsignadas: any;
  public adapterGridCerradurasAsignadas;

  initGridCerradurasAsignadas() {
    this.sourceGridCerradurasAsignadas = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'nsFabricante', type: 'string', map: 'nsFabricante' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'elemento', type: 'number', map: 'elemento>nombre' },
        { name: 'imei', type: 'string', map: 'imei' },
      ]
    };

    this.adapterGridCerradurasAsignadas = new jqx.dataAdapter(this.sourceGridCerradurasAsignadas);

    setTimeout(() => {
      this.getCerradurasPorConfiguracion();

      if (this.gridCerradurasAsignadas) {
        this.resizeColumns(this.gridCerradurasAsignadas);
      }
    }, 200);
  }

  onRowSelectAsignada(event: any) {
    if (event.args.row) {
      const cerraduraId = this.cerradurasAsignadas[event.args.rowindex]?.id;
      if (cerraduraId !== undefined && !this.cerradurasAsignadasCheck.includes(cerraduraId)) {
        this.cerradurasAsignadasCheck.push(cerraduraId);
      }
    } else {
      this.cerradurasAsignadasCheck = [];
      this.gridCerradurasAsignadas.selectedrowindexes().forEach((index: any) => {
        const cerraduraId = this.cerradurasAsignadas[index]?.id;
        if (cerraduraId !== undefined) {
          this.cerradurasAsignadasCheck.push(cerraduraId);
        }
      });
    }
  }


  onRowUnselectAsignada(event: any) {
    if (!this.clearSelection) {
      const cerraduraId = this.cerradurasAsignadas[event.args.rowindex]?.id;
      const indexToRemove = this.cerradurasAsignadasCheck.indexOf(cerraduraId);
      if (indexToRemove !== -1) {
        this.cerradurasAsignadasCheck.splice(indexToRemove, 1);
      }
    }
  }


  /* Grid cerradurasLibres */
  cerradurasLibres: CerraduraModel[] = [];
  public sourceGridCerradurasLibres: any;
  public adapterGridCerradurasLibres;

  initGridCerradurasLibres() {
    this.sourceGridCerradurasLibres = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'nsFabricante', type: 'string', map: 'nsFabricante' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'elemento', type: 'number', map: 'elemento>nombre' },
        { name: 'imei', type: 'string', map: 'imei' },
      ],
      sortcolumn: 'nsMovisat',
      sortdirection: 'asc'
    };

    this.adapterGridCerradurasLibres = new jqx.dataAdapter(this.sourceGridCerradurasLibres);

    if (this.gridCerradurasLibres) {
      this.resizeColumns(this.gridCerradurasLibres);
    }
  }

  onRowSelectLibre(event: any) {
    const cerradura = this.cerradurasLibres[event.args.rowindex];
    if (cerradura && cerradura.id !== undefined && !this.cerradurasLibresCheck.includes(cerradura.id)) {
      this.cerradurasLibresCheck.push(cerradura.id);
    } else {
      return
    }
  }



  onRowUnselectLibre(event: any) {
    if (!this.clearSelection) {
      const cerradura = this.cerradurasLibres[event.args.rowindex];
      if (cerradura && cerradura.id !== undefined) {
        const indexToRemove = this.cerradurasLibresCheck.indexOf(cerradura.id);
        if (indexToRemove !== -1) {
          this.cerradurasLibresCheck.splice(indexToRemove, 1);
        }
      } else {
        return
      }
    }
  }

}
