export class IdentificacionCerraduraModel {
    id: number;
    id_cerradura: number;
    idTarjeta: number;
    idElemento: number;
    fecha: Date;
    medio: number;
    tipoTag: number;
    aporte: boolean;
    tarjetaMaster: boolean;
    aperturaTapa: boolean;
    noCerroTapa: boolean;
    fechaRecibido: Date;
    empresa: number;
    nsTarjeta: number;
    nsCerradura: string;
    nombreCiudadano: string;
    nombreElemento: string;
    marcaElemento: string;
    modeloElemento: string;
    observacionesElemento: string;
    matriculaElemento: string;
    direccionElemento: string;
    municipioElemento: string;
    poblacionElemento: string;
    tipoPermiso: string;
}
