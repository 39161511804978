
// Sobrescribo la función que usa JSON para parsear fechas
Date.prototype.toISOString = function () {
    return DateUtils.formatDateTime(this, false).replace(' ', 'T');
}

export class DateUtils {

    static formatDateTime(date: Date, humanReadable: boolean): string {
        let res = '';
        if (!humanReadable) {
            res = date.getFullYear().toString().padStart(4, '0') + '-';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '-';
            res = res + date.getDate().toString().padStart(2, '0') + 'T';
        } else {
            res = date.getDate().toString().padStart(2, '0') + '/';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '/';
            res = res + date.getFullYear().toString().padStart(4, '0') + ' ';
        }
        res = res + date.getHours().toString().padStart(2, '0') + ':';
        res = res + date.getMinutes().toString().padStart(2, '0') + ':';
        res = res + date.getSeconds().toString().padStart(2, '0');
        return res;
    }

    static formatDateTypeFecha(date: Date): Date {
        //paso de strign a date
        date = new Date(date);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return new Date(`${month}/${day}/${year}`);
    }

    static formatDateTypeHours(date: Date): Date {
        //paso de strign a date
        date = new Date(date);
        // paso la fecha a formato hh:mm:ss
        let hour = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        date.setHours(hour, minutes, seconds);
        return date;
    }

    static formatDate(date: Date, humanReadable: boolean): string {
        let res = '';
        if (!humanReadable) {
            res = date.getFullYear().toString().padStart(4, '0') + '-';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '-';
            res = res + date.getDate().toString().padStart(2, '0');
        } else {
            res = date.getDate().toString().padStart(2, '0') + '/';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '/';
            res = res + date.getFullYear().toString().padStart(4, '0');
        }
        return res;
    }

    // no control de 1999
    // static formatDateTimeShort(date: Date, humanReadable: boolean): string {
    //     let res = '';
    //     if (!humanReadable) {
    //         res = (date.getFullYear() - 2000).toString().padStart(2, '0') + '-';
    //         res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '-';
    //         res = res + date.getDate().toString().padStart(2, '0') + 'T';
    //     } else {
    //         res = date.getDate().toString().padStart(2, '0') + '/';
    //         res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '/';
    //         res = res + (date.getFullYear() - 2000).toString().padStart(2, '0') + ' ';
    //     }
    //     res = res + date.getHours().toString().padStart(2, '0') + ':';
    //     res = res + date.getMinutes().toString().padStart(2, '0') + ':';
    //     res = res + date.getSeconds().toString().padStart(2, '0');
    //     return res;
    // }

    static formatDateTimeShort(date: Date, humanReadable: boolean): string {
        let res = '';
        if (!humanReadable) {
            res = date.getFullYear().toString() + '-';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '-';
            res = res + date.getDate().toString().padStart(2, '0') + 'T';
        } else {
            res = date.getDate().toString().padStart(2, '0') + '/';
            res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '/';
            res = res + date.getFullYear().toString() + ' ';
        }
        res = res + date.getHours().toString().padStart(2, '0') + ':';
        res = res + date.getMinutes().toString().padStart(2, '0') + ':';
        res = res + date.getSeconds().toString().padStart(2, '0');
        return res;
    }
    


    static formatDateTimeShortTwoLines(date: Date, humanReadable: boolean): string {
      let res = '';
      if (!humanReadable) {
          res = (date.getFullYear() - 2000).toString().padStart(2, '0') + '-';
          res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '-';
          res = res + date.getDate().toString().padStart(2, '0') + 'T';
      } else {
          res = date.getDate().toString().padStart(2, '0') + '/';
          res = res + (date.getMonth() + 1).toString().padStart(2, '0') + '/';
          res = res + (date.getFullYear() - 2000).toString().padStart(2, '0') + ' ';
      }
      res = res + '<br>';
      res = res + date.getHours().toString().padStart(2, '0') + ':';
      res = res + date.getMinutes().toString().padStart(2, '0') + ':';
      res = res + date.getSeconds().toString().padStart(2, '0');
      return res;
  }

    static formatTime(date: Date): string {
        let res = date.getHours().toString().padStart(2, '0') + ':';
        res = res + date.getMinutes().toString().padStart(2, '0') + ':';
        res = res + date.getSeconds().toString().padStart(2, '0');
        return res;
    }

    static formatDateAMDhms(date: Date): string {
        let res = '';
        res = (date.getFullYear() - 2000).toString().padStart(2, '0');
        res = res + (date.getMonth() + 1).toString().padStart(2, '0');
        res = res + date.getDate().toString().padStart(2, '0');
        res = res + date.getHours().toString().padStart(2, '0');
        res = res + date.getMinutes().toString().padStart(2, '0');
        res = res + date.getSeconds().toString().padStart(2, '0');
        return res;
    }

    static daysOfMonth(mes: number, anyo: number): number {
        switch (mes) {
            case 1: case 3: case 5: case 7: case 8: case 10: case 12:
                return 31;
            case 2:
                return (anyo % 4) === 0 ? 29 : 28;
            default:
                return 30;
        }
    }

    static numberToDaysHoursMinutesSeconds(seconds: number): string {
      let res: string = '';
      let days = Math.floor(seconds / 86400);
      seconds = seconds % 86400;
      let hours = Math.floor(seconds / 3600);
      seconds = seconds % 3600;
      let minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;

      if (days > 0 ) {
        res = days.toString() + 'd ';
      }

      res = res + (hours < 10 ? '0' + hours.toString() : hours.toString()) + ':';
      res = res + (minutes < 10 ? '0' + minutes.toString() : minutes.toString()) + ':';
      res = res + (seconds < 10 ? '0' + seconds.toString() : seconds.toString());

      return res;
    }

    static isValidDate(date: Date): boolean {
        return date && date.getTime() > new Date(2000, 1, 1).getTime();
    }

}
