<jqxWindow #form [theme]="environment.tema" [width]="120" [showCloseButton]="true" [showCollapseButton]="true"
    [zIndex]="90" [height]="90" [resizable]="false" [position]="getFormPos(form)" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden; height: 20px; border: 0px; margin: 0px; padding: 4px;">
        {{translate('Marco_cartografico')}}
    </div>
    <div style="float: left; overflow: hidden;" class="form">
        <div style="margin-top: 0px; float:left;">
            <div style="float:left; margin-top: 4px;">
                <input [(ngModel)]="visible" type="checkbox" (change)="onChangeVisible($event)" [disabled]="!canEdit" />
                {{translate('Ver')}}
            </div>
        </div>
        <div style="position:absolute; bottom:5px; left:4px; margin-top: 10px; clear: both;">
            <jqxButton style="float: left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardar($event)"
                [value]="translate('Guardar')" [disabled]="!canEdit">
            </jqxButton>
        </div>
    </div>
</jqxWindow>
