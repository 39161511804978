import { ElementoModel } from "../../elements/models/elem.model";
import { EstadoModel } from "./estado.model";
import { ImagenModel } from "./imagen.model";
import { ModeloModel } from "./modelo.model";
import { MotivoModel } from "./motivo.model";
import { TipoModel } from "./tipo.model";
import { UsuarioModel } from "../../sso/models/usuario.model";

export class IncidenciaModel {
    Lat: number = 0;
    Lng: number = 0;
    Id: number = 0;
    EmpresaId: number = 0;
    TipoId: number = 0;
    ModeloId: number = 0;
    MotivoId: number = 0;
    Fecha: string = '';
    Observaciones: string = '';
    EstadoId: number = 0;
    Guid: string = '';
    UsuarioId : number = 0;
    ElementoId : number = 0;
    Imagenes : ImagenModel[] = [];

    //agregados
    Motivo: MotivoModel = new MotivoModel();
    Estado : EstadoModel = new EstadoModel();
    Usuario : UsuarioModel = new UsuarioModel();
    Elemento : ElementoModel = new ElementoModel();
    selec: string  = 'selec'; // Campo usado para filtrar búsquedas

}