import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

import { ModeloSelectModule } from '../modelo-select/modelo-select.module';

import { TarjetasCiudadanosComponent } from './tarjetas-ciudadanos.component';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { HeaderModule } from '../header/header.module';

@NgModule({
  declarations: [
    TarjetasCiudadanosComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    HeaderModule,
    BrowserModule,
    jqxGridModule,
    jqxButtonModule,
    jqxWindowModule,
    jqxSplitterModule,
    ModeloSelectModule,
    PeriodoSelectModule,
    jqxDropDownListModule,
  ]
})
export class TarjetasCiudadanosModule { }
