import { Component, OnInit, ViewChild } from '@angular/core';

import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxEditorComponent } from 'jqwidgets-ng/jqxeditor';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.css']
})
export class NotasComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('editor') editor: jqxEditorComponent;

  public static _this: NotasComponent;

  private componentRef = null;
  public environment = environment;
  public text: string = '';
  private oldText: string = '';

  // Pongo por defecto los textos en los controles
  public lang = JqWidgets.getLocalization('es');

  constructor(
    private configService: ConfigService,
    private ssoService: SsoService,
    private modal: NzModalService) {
    super();
    NotasComponent._this = this;
  }

  ngOnInit(): void {
    this.lang = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.form);
    this.form.setTitle(this.translate('Anotaciones'));
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.form.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
    this.text = await this.configService.getEmpApp('anotaciones', '');
    this.oldText = this.text;
    this.editor.val(this.text);
  }

  // Inicialización del componente
  async init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Cuando se cierra
  onClose() {
    if (this.oldText !== this.text) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('No_ha_guardado') + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          if (this.componentRef) {
            this.componentRef.destroy();
          }
        },
        nzOnCancel: async () => {
          this.form.open();
        }
      });
    } else {
      if (this.componentRef) {
        this.componentRef.destroy();
      }
    }

    NotasComponent._this = null;
  }

  async onGuardar(event: any) {
    await this.configService.setEmpApp('anotaciones', this.text);
    this.oldText = this.text;
    MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);
    this.form.close();
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
