<jqxWindow #form [width]="356" [height]="94" [zIndex]="100" [autoOpen]="true" [isModal]="false" [showCloseButton]="true"
    [position]="getFormPos(form)" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()"
    (onOpen)="onOpen()">
    <div class="formHeader" style="overflow: hidden;">
        {{translate('Itinerarios')}}
    </div>
    <div class="form" style="float:left; overflow: hidden;">
        <div style="float: left;">
            <input id="itiIni" class="search-group" type="text" (keydown.enter)="$event.preventDefault()"
                placeholder="{{translate('Introduzca_origen')}}" autocorrect="off" autocapitalize="off" spellcheck="off"
                list="ini-lista" on-focus="onOrigenFocus($event)">
            <datalist id="ini-lista">
            </datalist>
            <input id="itiFin" class="search-group2" type="text" (keydown.enter)="$event.preventDefault()"
                placeholder="{{translate('Introduzca_destino')}}" autocorrect="off" autocapitalize="off"
                spellcheck="off" list="fin-lista" on-focus="onDestinoFocus($event)">
            <datalist id="fin-lista">
            </datalist>
        </div>
        <div style="float: left;">
            <jqxButton style="float: left; margin-left: 4px;" [width]='32' [height]='53'
                [imgSrc]='"/assets/images/refresh.png"' (onClick)="onAlternar($event)">
            </jqxButton>
        </div>
    </div>
</jqxWindow>
