import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { VolumService } from 'src/app/services/volumetricos/volum.service';

import { VolumLecturasMesModel } from 'src/app/services/volumetricos/models/volum-lecturas-mes.model';

import { ListadoVolumComponent } from '../listado-volum/listado-volum.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-resumen-volum',
  templateUrl: './resumen-volum.component.html',
  styleUrls: ['./resumen-volum.component.css']
})
export class ResumenVolumComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('grid2') grid2: jqxGridComponent;
  @ViewChild('formResumen') formResumen: jqxWindowComponent;

  private componentRef = null;
  private parentComponent = null;
  public environment = environment;
  public fechaIni: Date;
  public fechaFin: Date;
  public volumSinElemento = 0;
  public volumConElemento = 0;
  public volumTotales = 0;
  public lecturasTotales = 0;
  public lecturasEnHora = 0;
  public lecturasAtrasadas = 0;
  public lecturasFueraHora = 0;
  public enviosEnHora = 0;
  public enviosAtrasados = 0;
  public enviosFueraHora = 0;
  public totalEnvios = 0;
  public lecturasConElemento = 0;
  public lecturasSinElemento = 0;
  public lecturasErroneas = 0;
  public bateriaBaja = 0;
  public bateriaMuyBaja = 0;
  public temperaturaAlta = 0;
  public temperaturaMuyAlta = 0;
  public lecturasVolumMes: Map<string, VolumLecturasMesModel>;
  public faltas: any = [];
  public volumSinLecturas: any[] = [];
  public rowSelec = null;

  // Variables para los grid
  public dataSource;
  public dataAdapter;
  public dataSource2;
  public dataAdapter2;

  public _15_MIN;
  public _30_MIN;
  public BAT_BAJA;
  public BAT_MUY_BAJA;
  public TEMP_ALTA;
  public TEMP_MUY_ALTA;

  // Para poder usar la clase NumberUtils en el template
  public numberUtils = NumberUtils;
  public dateUtils = DateUtils;

  // Preparo las columnas del grid de lecturas que faltan en el periodo
  public columns = [
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'elemento', width: 180,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center; margin-left: 4px;">' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Volumetrico') + ' IMEI', columntype: 'textbox', filtertype: 'textbox',
      datafield: 'imei', width: 150
    },
    {
      text: this.translate('Dias_faltan_lecturas'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'dias', width: '800'
    }
  ];

  // Preparo las columnas del grid de elementos que no han enviado en el periodo
  public columns2 = [
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'elemento', width: 'auto',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center; margin-left: 4px;">' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Volumetrico') + ' IMEI', columntype: 'textbox', filtertype: 'textbox',
      datafield: 'imei', width: 150
    }
  ];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService, private volumService: VolumService) {
    super();
  }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.formResumen);
    this.formResumen.setTitle(AppComponent.translate('Resumen_volumetricos'));
    const t = setTimeout(() => {
      clearTimeout(t);
      this.rellenaFaltas();
      this.rellenaNoEnviados();
    }, 500);
  }

  // Este método es llamado por el creador del componente
  public init(params: any) {
    this.parentComponent = params.parentComponent;
    this.componentRef = params.component;
    this.fechaIni = params.fechaIni;
    this.fechaFin = params.fechaFin;
    this.volumSinElemento = params.volumSinElemento;
    this.volumConElemento = params.volumConElemento;
    this.lecturasTotales = params.lecturasTotales;
    this.lecturasEnHora = params.lecturasEnHora;
    this.lecturasAtrasadas = params.lecturasAtrasadas;
    this.lecturasFueraHora = params.lecturasFueraHora;
    this.enviosEnHora = params.enviosEnHora;
    this.enviosAtrasados = params.enviosAtrasados;
    this.enviosFueraHora = params.enviosFueraHora;
    this.lecturasSinElemento = params.lecturasSinElemento;
    this.lecturasErroneas = params.lecturasErroneas;
    this.bateriaBaja = params.bateriaBaja;
    this.bateriaMuyBaja = params.bateriaMuyBaja;
    this.temperaturaAlta = params.temperaturaAlta;
    this.temperaturaMuyAlta = params.temperaturaMuyAlta;
    this.lecturasVolumMes = params.lecturasVolumMes;
    // Calculo totales
    this.volumTotales = this.volumConElemento + this.volumSinElemento;
    this.lecturasConElemento = this.lecturasTotales - this.lecturasSinElemento;
    this.totalEnvios = this.enviosEnHora + this.enviosAtrasados + this.enviosFueraHora;
    // Valores para las baterías y temperaturas
    this._15_MIN = ListadoVolumComponent.getInstance()._15_MIN;
    this._30_MIN = ListadoVolumComponent.getInstance()._30_MIN;
    this.BAT_BAJA = ListadoVolumComponent.getInstance().BAT_BAJA;
    this.BAT_MUY_BAJA = ListadoVolumComponent.getInstance().BAT_MUY_BAJA;
    this.TEMP_ALTA = ListadoVolumComponent.getInstance().TEMP_ALTA;
    this.TEMP_MUY_ALTA = ListadoVolumComponent.getInstance().TEMP_MUY_ALTA;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    ListadoVolumComponent.getInstance().resumenVisible = false;
    if (this.parentComponent) {
      this.parentComponent.expand();
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  resizeColumns(grid: jqxGridComponent){
    Utils.renderSizeGrid(grid, 500);
  }

  // Rellena la lista con los días que faltan lecturas
  rellenaFaltas() {
    this.faltas = [];
    // Genero el array a partir de la tabla hash de los volumétricos que han enviado
    // alguna lectura en el periodo
    for (const [key, value] of this.lecturasVolumMes) {
      const config = this.volumService.volumConfig.get(value.elemento.IdConfigVolum);
      const numMediasDia = 24 * 60 / config.FrecuenciaLectura;
      let dias = '';
      for (let dia = this.fechaIni.getDate(); dia <= this.fechaFin.getDate(); dia++) {
        let numLec = value.lecturasDia.get(dia);
        if (!numLec) {
          numLec = 0;
        }
        if (numLec < numMediasDia) {
          if (dias.length < 1) {
            dias = '' + dia + '(' + (numMediasDia - numLec) + ')';
          } else {
            dias += ', ' + dia + '(' + (numMediasDia - numLec) + ')';
          }
        }
      }
      if (dias.length > 0) {
        this.faltas.push({
          elemento: value.elemento,
          imei: value.elemento.ImeiVolum,
          dias: dias
        });
      }
    }
    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'elemento', map: 'elemento>Nombre', type: 'string' },
        { name: 'imei', map: 'imei', type: 'string' },
        { name: 'dias', map: 'dias', type: 'string' }
      ],
      localdata: this.faltas,
      sortcolumn: 'elemento',
      sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);

    this.resizeColumns(this.grid);
  }

  // Rellena la lista con los volumétricos que no han enviado en el periodo
  rellenaNoEnviados() {
    this.volumSinLecturas = [];
    // Genero el array a partir de la tabla hash de elementos con volumétrico
    for (const [key, value] of this.volumService.elementos) {
      if (!this.lecturasVolumMes.get(value.ImeiVolum)) {
        this.volumSinLecturas.push({
          elemento: value
        });
      }
    }
    this.dataSource2 = {
      datatype: 'json',
      datafields: [
        { name: 'elemento', map: 'elemento>Nombre', type: 'string' },
        { name: 'imei', map: 'elemento>ImeiVolum', type: 'string' }
      ],
      localdata: this.volumSinLecturas,
      sortcolumn: 'elemento',
      sortdirection: 'asc'
    };
    this.dataAdapter2 = new jqx.dataAdapter(this.dataSource2);

    this.resizeColumns(this.grid2);
  }

  onRowClick(event: any) {
    this.rowSelec = this.faltas[event.args.rowindex];
  }

  onRowClick2(event: any) {
    this.rowSelec = this.volumSinLecturas[event.args.rowindex];
  }

  onCentrar(event: any) {
    if (this.rowSelec && this.rowSelec.elemento && this.rowSelec.elemento.Marker) {
      this.formResumen.collapse();
      const map = MainComponent.getActiveMap();
      map.setCenter(this.rowSelec.elemento.Marker.position);
      map.setZoom(18);
      this.rowSelec.elemento.Marker.animate(3000);
    }
  }

}
