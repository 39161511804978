import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { NumberUtils } from 'src/app/utils/number-utils';

import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css']
})
export class InputNumberComponent implements OnInit {
  @ViewChild('inputNumber') inputNumber: jqxInputComponent;
  @Input() height: number = 25;
  @Input() width: number = 110;
  @Input() disabled: boolean = false;
  @Input() renderizar = true;
  @Input() render: any;
  @Input() value: any = 0;
  @Input() decimal: number = 0;
  @Output() onChange = new EventEmitter();

  ejecutado: boolean = false;

  numeroFormateado: string = '0';
  ejecutadoDos: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  public rendererDecimal = (itemValue?: string, itemInput?: string, value?: number) => {
    setTimeout(() => {
      if (!this.ejecutado && this.renderizar) {
        this.numeroFormateado = NumberUtils.formatInputs(value, this.decimal);
        this.inputNumber.val(this.numeroFormateado);
        this.ejecutado = true;
      }
    }, 0);
  };

  // divido el valor por x cantidad de decimales
  public rendererDividir = (itemValue?: string, itemInput?: string, value?: number) => {
    setTimeout(() => {
      if (!this.ejecutadoDos && this.renderizar) {
        this.inputNumber.val(value);
        this.ejecutadoDos = true;
      }
    }, 0);
  };

  valueChanged(event: any) {
    setTimeout(() => {
      this.onChange.emit(event.target.value);
      if (this.renderizar) {
        this.numeroFormateado = NumberUtils.formatInputs(event.target.value, this.decimal);
        this.inputNumber.val(this.numeroFormateado);
      }
    }, 0);
  }
}
