import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

import { ModeloSelectModule } from '../modelo-select/modelo-select.module';

import { ElementosSelectComponent } from './elementos-select.component';

@NgModule({
  declarations: [
    ElementosSelectComponent
  ],
  imports: [
    CommonModule,
    jqxGridModule,
    jqxSplitterModule,
    ModeloSelectModule,
    jqxDropDownListModule,
  ],
  exports: [
    ElementosSelectComponent
  ]
})
export class ElementosSelectModule { }
