import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HeaderModule } from "../header/header.module";

import { jqxGridModule } from "jqwidgets-ng/jqxgrid";
import { jqxInputModule } from "jqwidgets-ng/jqxinput";
import { jqxWindowModule } from "jqwidgets-ng/jqxwindow";
import { jqxLoaderModule } from "jqwidgets-ng/jqxloader";
import { jqxRibbonModule } from "jqwidgets-ng/jqxribbon";
import { jqxButtonModule } from "jqwidgets-ng/jqxbuttons";
import { jqxCheckBoxModule } from "jqwidgets-ng/jqxcheckbox";
import { jqxRadioButtonModule } from "jqwidgets-ng/jqxradiobutton";
import { jqxDateTimeInputModule } from "jqwidgets-ng/jqxdatetimeinput";
import { jqxDropDownButtonModule } from "jqwidgets-ng/jqxdropdownbutton";
import { jqxRadioButtonGroupModule } from "jqwidgets-ng/jqxradiobuttongroup";

import { InputNumberModule } from "../input-number/input-number.module";
import { LabelOverLenghtModule } from "../label-over-lenght/label-over-lenght.module";

import { ConfiguracionCerradurasComponent } from "./configuracion-cerraduras.component";
import { ConfiguracionCerradurasEditComponent } from "./configuracion-cerraduras-edit/configuracion-cerraduras-edit.component";
import { ConfiguracionCerraduraAsignarComponent } from './configuracion-cerradura-asignar/configuracion-cerradura-asignar.component';

@NgModule({
  declarations: [
    ConfiguracionCerradurasComponent,
    ConfiguracionCerradurasEditComponent,
    ConfiguracionCerraduraAsignarComponent
  ],
  imports: [
    FormsModule,
    HeaderModule,
    HeaderModule,
    CommonModule,
    jqxGridModule,
    jqxInputModule,
    jqxLoaderModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxRibbonModule,
    InputNumberModule,
    jqxCheckBoxModule,
    jqxRadioButtonModule,
    LabelOverLenghtModule,
    jqxDateTimeInputModule,
    jqxDropDownButtonModule,
    jqxRadioButtonGroupModule,
  ],
  exports: [
    ConfiguracionCerradurasComponent
  ]
})
export class ConfiguracionCerraduraModule { }
