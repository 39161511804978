import { Component, OnInit, ViewChild } from '@angular/core';

import { Config } from '../track-config';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { ConfigService } from 'src/app/services/config/config.service';


@Component({
  selector: 'app-leyenda-moviles',
  templateUrl: './leyenda-moviles.component.html',
  styleUrls: ['./leyenda-moviles.component.css']
})
export class LeyendaMovilesComponent  implements OnInit {
  //defecto
  private componentRef = null;
  public theme = environment.tema;
  public config: Config = new Config();

  @ViewChild('infoMoviles') infoMoviles: jqxWindowComponent;

  constructor(private configService: ConfigService,) { }
  ngOnInit(): void {

  }
  /**Inicializa el componente
  */
  init(componentRef: any) {
    this.componentRef = componentRef;
  }
  //Iniciamos el componente
  async ngAfterViewInit(): Promise<void> {
    this.positionLeyenda();
    await this.getConfiguration();
    this.selectConfigMoviles();

  }
  //Obtenemos la configuracion actualizada
  async getConfiguration(): Promise<void> {
    const res = await this.configService.getUsuEmpApp('config-recorridos', null);
    if (res) {
      this.config = JSON.parse(res);
    }
  }

  //Segun el criterio del selector elegido, pinta los div de un color u otro
  selectConfigMoviles() {
    switch (this.config.criterio) {
      case 'X_VELOCIDAD':
        this.infoMoviles.width(120);
        this.infoMoviles.height(90);
        setTimeout(() => {
          document.getElementById('r60').style.background = '#' + this.config.colorVel1;
          document.getElementById('r100').style.background = '#' + this.config.colorVel2;
          document.getElementById('rmas100').style.background = '#' + this.config.colorVel3;
        }, 500);
        break;
      case 'X_DIAS_SEMANA':
        this.infoMoviles.width(120);
        this.infoMoviles.height(165);
        setTimeout(() => {
          document.getElementById('rL').style.background = '#' + this.config.colorLu;
          document.getElementById('rM').style.background = '#' + this.config.colorMa;
          document.getElementById('rMi').style.background = '#' + this.config.colorMi;
          document.getElementById('rJ').style.background = '#' + this.config.colorJu;
          document.getElementById('rV').style.background = '#' + this.config.colorVi;
          document.getElementById('rS').style.background = '#' + this.config.colorSa;
          document.getElementById('rD').style.background = '#' + this.config.colorDo;
        }, 500);
        break;

      case 'X_SENSOR':
        this.infoMoviles.width(120);
        this.infoMoviles.height(90);
        setTimeout(() => {
          document.getElementById('sA').style.background = '#' + this.config.colorSensor0;
          document.getElementById('sI').style.background = '#' + this.config.colorSensor1;
          document.getElementById('sST').style.background = '#' + this.config.colorSensorX;
        }, 500);
        break;
      default:
        break;
    }
  }
  //posiciono la ventana a la derecha
  positionLeyenda() {
    this.infoMoviles.setTitle(AppComponent.translate('Leyenda'));
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.infoMoviles.position({
      x: mapContainer[0].right - 230,
      y: mapContainer[0].top + 60
    });
  }

  //destruyo el componente y el componente windows
  onClose() {
    if (this.componentRef) {
      this.infoMoviles.close();
      this.infoMoviles.destroy();
      this.componentRef.destroy();
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
