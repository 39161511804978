<jqxWindow #formWindow [theme]="environment.tema" [position]="getFormPos(formWindow)" [width]="500" [height]="430" [zIndex]="999"
[autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{ translate("Jobs") }}
  </div>
  <div class="form" style="float: left; overflow-x: hidden;">
    <div class="row" style="height: 100%; overflow-y: auto; overflow-x: hidden;">
      <span style="width: 100%;">
        <div class="rowC" style="height: calc(100% - 60px); display: flex;">
          <fieldset>
            <div class="row">
              <span style="width: 100%; clear: both;">
                <div style="margin-top: 5px">
                  {{ translate('Nombre') }}
                </div>
                <jqxInput [ngModel]="tarea.nombre" [width]="'100%'" [disabled]="true">
                </jqxInput>
              </span>
            </div>

            <div class="row">
              <span style="width: 100%; clear: both;">
                <div style="margin-top: 5px">
                  Trigger
                </div>
                <jqxInput [ngModel]="tarea.nombreTrigger" [width]="'100%'" [disabled]="true">
                </jqxInput>
              </span>
            </div>

            <div class="row">
              <span style="width: 100%; clear: both;">
                <div style="margin-top: 5px">
                  {{ translate('Grupo') }}
                </div>
                <jqxInput [ngModel]="tarea.grupo" [width]="'100%'" [disabled]="true">
                </jqxInput>
              </span>
            </div>

            <div class="row">
              <span style="width: 30%; clear: both;">
                <div style="margin-top: 5px">
                  {{ translate('Segundos') }}
                </div>
                <jqxInput [(ngModel)]="tarea.segundos" [width]="'100%'">
                </jqxInput>
              </span>

              <span style="width: 70%; clear: both;">
                <div style="margin-top: 5px">
                  {{ translate('Expresion_cron') }}
                </div>
                <jqxInput [(ngModel)]="tarea.cronExpresion" [width]="'100%'">
                </jqxInput>
              </span>
            </div>

            <div class="row">
              <span style="width: 100%; clear: both;">
                <div style="margin-top: 5px">
                  {{ translate('Activa') }}
                </div>

                <div class="row">
                  <span style="width: 50%;">
                    <jqxRadioButton (onChange)="setActivado($event)"
                    [width]="250" [height]="25" [checked]="tarea.activado">
                    <span>Si</span>
                    </jqxRadioButton>
                  </span>

                  <span style="width: 50%;">
                    <jqxRadioButton [width]="250" [height]="25" [checked]="!tarea.activado">
                    <span>No</span>
                    </jqxRadioButton>
                  </span>
                </div>
              </span>
            </div>

            <div style="width: 99%; height: 25%; margin-bottom: 5px;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Descripcion") }}
              </div>
              <jqxTextArea [(ngModel)]="tarea.descripcion" [width]="'100%'" [height]="'100%'">
              </jqxTextArea>
            </div>
          </fieldset>
        </div>
      </span>
    </div>


    <div style="position: absolute; bottom: 5px; left: 0px; margin-top: 23px;
      clear: both;">
      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
        (onClick)="onGuardar()" [value]="translate('Guardar')">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('CANCELAR')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
