export class AlarmaCerraduraModel {
    id: number;
    idCerradura: number;
    fecha: string;
    bateria: number; //  1=Batería baja, 2=Batería crítica y 3=Cambiar batería.
    voltaje: number;
    temperaturaElevado: boolean;
    temperatura: number;
    puertaAbierta: boolean;
    puertaViolentada: boolean;
    vandalismo: boolean;
    vaciado: boolean;
    diagnostico: boolean;
    diagnosticoInfo: number;
    diagnosticoInfoDescripcion: string[];
    idTarjeta: number;
    fechaRecibido: Date;
    empresa: number;
    nsTarjeta: string;
    nsCerradura: string;
    nombreElemento: string;
    idElemento: number;
    nombreCiudadano: Date;
    idEnvio: boolean;
    bateriaBaja: boolean;
    bateriaPorcentaje: boolean;
    marcaElemento: string;
    modeloElemento: string;
    observacionesElemento: string;
    matriculaElemento: string;
    direccionElemento: string;
    municipioElemento: string;
    poblacionElemento: string;
    alarma: string;
}
