<jqxWindow #infoMoviles [width]="220" [height]="150" [theme]="theme" [showCloseButton]="false"
    [showCollapseButton]="false" [resizable]="false" >
    <div class="" style="overflow-x: hidden; height: 20px; border: 0px; margin: 0px; padding: 4px;">
        {{translate('Leyenda')}}
    </div>
    <div class="container">
        <div *ngIf='config.criterio === "X_VELOCIDAD"'>
            <div class="col">
                <div class="color" id="r60"></div>
                <div class="text">Hasta 60</div>
            </div>
            <div class="col">
                <div class="color" id="r100"></div>
                <div class="text">Hasta 100</div>
            </div>
            <div class="col">
                <div class="color" id="rmas100"></div>
                <div class="text">Mas de 100</div>
            </div>
        </div>
        <div *ngIf='config.criterio === "X_DIAS_SEMANA"'>
            <div class="col">
                <div class="color" id="rL"></div>
                <div class="text">Lunes</div>
            </div>
            <div class="col">
                <div class="color" id="rM"></div>
                <div class="text">Martes</div>
            </div>
            <div class="col">
                <div class="color" id="rMi"></div>
                <div class="text">Miercoles</div>
            </div>
            <div class="col">
                <div class="color" id="rJ"></div>
                <div class="text">Jueves</div>
            </div>
            <div class="col">
                <div class="color" id="rV"></div>
                <div class="text">Viernes</div>
            </div>
            <div class="col">
                <div class="color" id="rS"></div>
                <div class="text">Sabado</div>
            </div>
            <div class="col">
                <div class="color" id="rD"></div>
                <div class="text">Domingo</div>
            </div>
        </div>
        <div *ngIf='config.criterio === "X_SENSOR"'>
            <div class="col">
                <div class="color" id="sA"></div>
                <div class="text">Activo</div>
            </div>
            <div class="col">
                <div class="color" id="sI"></div>
                <div class="text">Inactivo</div>
            </div>
            <div class="col">
                <div class="color" id="sST"></div>
                <div class="text">Sin datos</div>
            </div>
        </div>
    </div>
</jqxWindow>