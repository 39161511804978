import { TipoModel } from "./tipo.model";

export class ModeloModel {
    Id: number = 0;
    EmpresaId: number = 0;
    TipoId: number = 0;
    Nombre: string = '';
    Observaciones: string = '';

    //agregados
    Tipo : TipoModel = new TipoModel();
}