import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { environment } from "src/environments/environment";



export class Utils {
    static clone<T>(a: T): T {
        return JSON.parse(JSON.stringify(a));
    }


    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // ajusta el tamaño de las columnas del grid
    static renderSizeGrid(grid: jqxGridComponent, timeout = 100, exclude = []) {
        const t = setTimeout(() => {
            if (grid == null) return;
            grid.attrColumns.forEach((column: any) => {
                try {
                    if (!exclude.includes(column.datafield)) {
                        grid.autoresizecolumn(column.datafield, column.columntype);
                    }
                } catch (e) {
                }
            })
        }, timeout);
    }

    // quita los elementos duplicados del array y lo devuelve sin undefined
    static onChangeArray(list: any[]): any[] {
        list = list.filter((elem, index, self) =>
            index == self.findIndex((t) => t?.Id == elem?.Id) && elem !== undefined && elem !== null
        );
        return list;
    }

    static tooltiprenderer(element, tooltipMessage?) {
        if (element && element.context) { // Check to ensure element and element.context are defined
            const jqElement = element.context.parentElement;
            const options = {
                content: tooltipMessage ? tooltipMessage : element.text(), // Corrected the conditional statement
                theme: environment.tema,
                width: 'auto',
                height: 'auto',
                position: 'mouse',
                autoHide: true,
            };
            jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
        }
    }







    static renderRowGridText(): Promise<boolean> {
        return new Promise((resolve) => {
            setTimeout(() => {
                document.querySelectorAll('.jqx-grid-cell-left-align').forEach(element => {
                    // Verify if the element is an HTMLElement to access the 'style' property
                    if (element instanceof HTMLElement && element.textContent.length > 10) {
                        element.addEventListener('mouseenter', () => {
                            // Apply styles when the mouse enters the element
                            element.style.position = 'fixed';
                            element.style.backgroundColor = 'grey';
                            element.style.color = 'white'
                        });

                        element.addEventListener('mouseleave', () => {
                            // Revert styles when the mouse leaves the element
                            element.style.position = '';
                            element.style.backgroundColor = '';
                            element.style.color = '';
                        });
                    }
                });
                resolve(false);
            }, 200);
        });
    }





}
