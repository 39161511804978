import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';

import { AccionesComponent } from './acciones/acciones.component';
import { InformesComponent } from './informes/informes.component';

@NgModule({
  declarations: [
    AccionesComponent,
    InformesComponent,

  ],
  imports: [
    FormsModule,
    CommonModule,
    jqxGridModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
    PeriodoSelectModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule
  ]
})
export class AuditoriaModule { }
