import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxRadioButtonModule } from 'jqwidgets-ng/jqxradiobutton';

import { AdministradorComponent } from './administrador.component';
import { EditTareaComponent } from './tareas/edit-tarea/edit-tarea.component';
import { ListadoTareasComponent } from './tareas/listado-tareas/listado-tareas.component';
import { HeaderModule } from '../header/header.module';
import { jqxTextAreaModule } from 'jqwidgets-ng/jqxtextarea';

@NgModule({
  declarations: [
    EditTareaComponent,
    AdministradorComponent,
    ListadoTareasComponent,
  ],
  imports: [
    FormsModule,
    HeaderModule,
    CommonModule,
    BrowserModule,
    jqxGridModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxTextAreaModule,
    jqxRadioButtonModule
  ]
})
export class AdministradorModule { }
