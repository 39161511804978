import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { VersionModel } from './models/version.model';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(
    private http: HttpClient,
    private ssoService: SsoService
  ) { }

  async getVersionJobs(): Promise<VersionModel> {
    let response: VersionModel;

    try {
      await this.http.get<VersionModel>(
        this.urlApi + '/api/servicio/version?id=4'
      ).toPromise().then(
        res => {
          response = res;
        }, error => {
          response = null;
        }
      );
    } catch (e) {
      response = null;
    }

    return response;
  }
}
