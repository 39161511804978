import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { MainComponent } from './main.component';
import { environment } from 'src/environments/environment';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';

import { jqxMenuModule } from 'jqwidgets-ng/jqxmenu';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxRibbonModule } from 'jqwidgets-ng/jqxribbon';
import { jqxPopoverModule } from 'jqwidgets-ng/jqxpopover';
import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';

import { IaModule } from 'movisat-ia';
import { MapModule } from 'movisat-maps';
import { PuModule } from '../pu/pu.module';
import { KmlModule } from '../kml/kml.module';
import { FlotaModule } from '../flota/flota.module';
import { ZonesModule } from '../zones/zones.module';
import { NotasModule } from '../notas/notas.module';
import { TracksModule } from '../tracks/tracks.module';
import { AlarmsModule } from '../alarms/alarms.module';
import { ReportsModule } from '../reports/reports.module';
import { ElementsModule } from '../elements/elements.module';
import { GraphicsModule } from '../graphics/graphics.module';
import { CalendarModule } from '../calendar/calendar.module';
import { AuditoriaModule } from '../auditoria/auditoria.module';
import { ResourcesModule } from '..//resources/resources.module';
import { MapsToolsModule } from '../maps-tools/maps-tools.module';
import { IncidencesModule } from '../incidences/incidences.module';
import { CiudadanosModule } from '../ciudadanos/ciudadanos.module';
import { MapToolbarModule } from '../map-toolbar/map-toolbar.module';
import { CuadriculasModule } from '../cuadriculas/cuadriculas.module';
import { AdministradorModule } from '../administrador/administrador.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TarjetasCiudadanosModule } from '../tarjetas-ciudadanos/tarjetas-ciudadanos.module';
import { ConfiguracionCerraduraModule } from '../configuracion-cerraduras/configuracion-cerraduras.module';

import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { AppbarComponent } from './appbar/appbar.component';

@NgModule({
  declarations: [
    MainComponent,
    MenuComponent,
    AppbarComponent,
    FooterComponent
  ],
  imports: [
    PuModule,
    KmlModule,
    FlotaModule,
    FormsModule,
    ZonesModule,
    NotasModule,
    AlarmsModule,
    TracksModule,
    CommonModule,
    RouterModule,
    ReportsModule,
    BrowserModule,
    jqxMenuModule,
    ElementsModule,
    GraphicsModule,
    CalendarModule,
    AuditoriaModule,
    MapsToolsModule,
    ResourcesModule,
    jqxRibbonModule,
    jqxLoaderModule,
    jqxLoaderModule,
    jqxPopoverModule,
    IncidencesModule,
    MapToolbarModule,
    CiudadanosModule,
    jqxSplitterModule,
    CuadriculasModule,
    AdministradorModule,
    ToastContainerModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    TarjetasCiudadanosModule,
    ConfiguracionCerraduraModule,
    MapModule.forRoot({
      app: MainComponent,
      googleApiKey: environment.googleApiKey,
      hereApiKey: environment.hereApiKey
    }),
    IaModule.forRoot({
      appInstance: MainComponent,
      tema: environment.tema
    }),
  ]
})
export class MainModule { }
