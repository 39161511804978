import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainComponent } from 'src/app/components/main/main.component';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.body && request.body.indexOf('grant_type') > -1) {
            return next.handle(request);
        }
        // Las llamadas a la Api de HERE no se interceptan
        if (request.url.indexOf('hereapi.com') > -1) {
            return next.handle(request);
        }
        let tokenTAG = '';
        if (request.url.indexOf(environment.apiSSO) > -1) {
            tokenTAG = 'tokenSSO';
        } else {
            if (request.url.indexOf(MainComponent.getInstance().ssoTicket.UrlApi) > -1) {
                tokenTAG = 'tokenApi';
            } else {
                if (request.url.indexOf(environment.urlApiBdt) > -1) {
                    tokenTAG = 'tokenBdt';
                } else {
                    if (request.url.indexOf(MainComponent.getInstance().ssoTicket.UrlApiIA) > -1 && MainComponent.getInstance().ssoTicket.UrlApiIA.length > 0 ) {
                        tokenTAG = 'tokenIA';
                    } else {
                        if(request.url.indexOf(environment.carto.baseUrl) > -1){
                            tokenTAG = 'tokenCarto';
                        }else{
                            return next.handle(request);
                        }

                    }
                }
            }
        }
        const newRequest = request.clone({
            setHeaders: {
                Authorization: `Bearer ${sessionStorage.getItem(tokenTAG)}`
            }
        });
        return next.handle(newRequest);
    }
}
