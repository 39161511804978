import { MapLatLng } from "movisat-maps";

export class CasaModel {
    posicion: MapLatLng;
    zoom: number;

    constructor(posicion: MapLatLng, zoom: number) {
        this.posicion = posicion;
        this.zoom = zoom;
    }
}