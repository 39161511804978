import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MovilModel } from '../resources/models/movil.model';
import { SsoService } from '../sso/sso.service';
import { AlarmModel } from './models/alarm.model';
import { ListadoAlarmasModel } from './models/listado-alarmas.model';
import { MotivoAlarma } from './models/motivo-alarma';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { Accion } from '../auditoria/models/accion.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { MainComponent } from 'src/app/components/main/main.component';
import { environment } from 'src/environments/environment';
import { UsuarioModel } from '../sso/models/usuario.model';
import { element } from 'protractor';
enum Motivos {
  ENTRADA = 1, // Estaba fuera y ha entrado
  DENTRO = 2,  // Está dentro pero no sabemos si antes estaba fuera
  SALIDA = 3,  // Estaba dentro y ha salido
  FUERA = 4,   // Está fuera pero no sabemos si antes estaba dentro
  NO_ENTRADA = 5, // Ha llegado la hora de entrada y está fuera
  NO_SALIDA = 6   // Ha llegado la hora de salida y está dentro
}


@Injectable({
  providedIn: 'root'
})




export class AlarmsService {
  REST_API = environment.apiSSO;
  private motivosAlarma = new Map<number, MotivoAlarma>();
  usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) {
    this.crearMapMotivos();
  }


  // Recupera todas las alarmas
  async getAlarmas(): Promise<AlarmModel[]> {
    let result: AlarmModel[] = null;
    try {
      result = await this.http.get<AlarmModel[]>(this.ssoService.getTicket().UrlApi + '/api/alarmasgeo/' +
        this.ssoService.getTicket().Empresa.IdGestion).toPromise();
      result.forEach(alarm => {
        alarm.HoraEntrada = new Date(alarm.HoraEntrada);
        alarm.HoraSalida = new Date(alarm.HoraSalida);
      });

      // obtengo el numero de alarmas asociadas
      for (let element of result) {
        const alarmasMoviles = await this.getAlarmaMoviles(element.Id);
        element.numeroAlarmaAsig = alarmasMoviles.length;
      }

    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // Almacena una alarma 
  async saveAlarma(alarma: AlarmModel): Promise<AlarmModel> {
    let response = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      // Quito el objeto zona que da problemas para serializar
      delete alarma.Zona.Geometria;
      alarma.EmpresaId = this.ssoService.getTicket().Empresa.IdGestion;
      response = await this.http.post<AlarmModel>(this.ssoService.getTicket().UrlApi + '/api/alarmasgeo/crear',
        JSON.stringify(alarma), httpOptions).toPromise();
      response.HoraEntrada = new Date(response.HoraEntrada);
      response.HoraSalida = new Date(response.HoraSalida);

      if (alarma.Id && response) {
        this.auditoria.AccionId = Accion.EDITAR_ALARMA;
      } else if (!alarma.Id && response) {
        this.auditoria.AccionId = Accion.CREAR_ALARMA
      }
      this.auditoria.Info = this.msgChangeResponse(response);
      this.auditoriaService.addAuditoria(this.auditoria);

    } catch (e) {
      console.log(e);
    }
    return response;
  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.Id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response.Nombre;
  }


  // Borra una alarma
  async deleteAlarma(alarmaId: number): Promise<void> {
    try {
      await this.http.delete<void>(this.ssoService.getTicket().UrlApi + '/api/alarmasgeo/borrar/' + alarmaId).toPromise();
      this.auditoria.AccionId = Accion.ELIMINAR_ALARMA
      this.auditoria.Info = 'ID:' + alarmaId;
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      console.log(e);
    }

  }

  // Recupera todas los móviles asigndos a una alarma
  async getAlarmaMoviles(alarmaId: number): Promise<number[]> {
    let result: number[] = null;
    try {
      result = await this.http.get<number[]>(this.ssoService.getTicket().UrlApi + '/api/alarmasgeo/moviles/' +
        alarmaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  async saveMovilesAlarma(alarmaId: number, movilesId: number[]): Promise<boolean> {
    let result: any;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = {
      AlarmaId: alarmaId,
      MovilesId: movilesId
    }
    result = await this.http.post<boolean>(this.ssoService.getTicket().UrlApi + '/api/alarmasgeo/asignarmoviles',
      JSON.stringify(body), httpOptions).toPromise();

    if (result) {
      this.auditoria.AccionId = Accion.ASIGNAR_MOVILES_ALARMA;
      this.auditoriaService.addAuditoria(this.auditoria);
    }
    return result;
  }



  //traer datos filtrados de alarmas
  async getListadoAlarmas(movilesIds: number[], moviles: MovilModel[], fechaIni: Date, fechaFin: Date) {
    let listadoAlarmas: ListadoAlarmasModel[] = [];
    //header
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = {
      empresa: this.ssoService.getTicket().Empresa.IdGestion,
      moviles: movilesIds,
      desde: fechaIni,
      hasta: fechaFin
    }
    try {
      listadoAlarmas = await this.http.post<ListadoAlarmasModel[]>(this.ssoService.getTicket().UrlApi + '/api/alarmasgeoh',
        JSON.stringify(body), httpOptions).toPromise();
      //asignar movil
      listadoAlarmas.forEach(listaAlarma => {
        listaAlarma.Movil = moviles.filter(movil => listaAlarma.MovilId == movil.Codigo)[0];
        //asignar motivo
        listaAlarma.MotivoAlarma = this.motivosAlarma.get(listaAlarma.Motivo);
      });
    } catch (e) {
      console.log(e);
    }
    return listadoAlarmas;
  }

  //crear map de motivos
  crearMapMotivos() {
    const entrada: MotivoAlarma = { Id: Motivos.ENTRADA, Nombre: 'ENTRADA', Descripcion: 'Estaba fuera y ha entrado', TraduccionId: 'motivo_alarma_entrada' };
    this.motivosAlarma.set(entrada.Id, entrada);
    const dentro: MotivoAlarma = { Id: Motivos.DENTRO, Nombre: 'DENTRO', Descripcion: 'Está dentro pero no sabemos si antes estaba fuera', TraduccionId: 'motivo_alarma_dentro' };
    this.motivosAlarma.set(dentro.Id, dentro);
    const salida: MotivoAlarma = { Id: Motivos.SALIDA, Nombre: 'SALIDA', Descripcion: 'Estaba dentro y ha salido', TraduccionId: 'motivo_alarma_salida' };
    this.motivosAlarma.set(salida.Id, salida);
    const fuera: MotivoAlarma = { Id: Motivos.FUERA, Nombre: 'FUERA', Descripcion: 'Está fuera pero no sabemos si antes estaba dentro', TraduccionId: 'motivo_alarma_fuera' };
    this.motivosAlarma.set(fuera.Id, fuera);
    const no_entrada: MotivoAlarma = { Id: Motivos.NO_ENTRADA, Nombre: 'NO_ENTRADA', Descripcion: 'Ha llegado la hora de entrada y está fuera', TraduccionId: 'motivo_alarma_no_entrada' };
    this.motivosAlarma.set(no_entrada.Id, no_entrada);
    const no_salida: MotivoAlarma = { Id: Motivos.NO_SALIDA, Nombre: 'NO_SALIDA', Descripcion: 'Ha llegado la hora de salida y está dentro', TraduccionId: 'motivo_alarma_no_salida' };
    this.motivosAlarma.set(no_salida.Id, no_salida);
  }

  //get usuarios
  async getUsers(): Promise<UsuarioModel[]> {
    let result: UsuarioModel[] = null;
    try {
      result = await this.http
        .get<UsuarioModel[]>(`${this.REST_API}/sso/usuarios`)
        .toPromise();
    } catch (e) {
      console.log(e);
      window.alert(e.error);
    }
    return result;
  }

}
