import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxChartModule } from 'jqwidgets-ng/jqxchart';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxRibbonModule } from 'jqwidgets-ng/jqxribbon';
import { jqxSliderModule } from 'jqwidgets-ng/jqxslider';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxTextAreaModule } from 'jqwidgets-ng/jqxtextarea';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { InputNumberModule } from '../input-number/input-number.module';
import { jqxDropDownButtonModule } from 'jqwidgets-ng/jqxdropdownbutton';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { LabelOverLenghtModule } from '../label-over-lenght/label-over-lenght.module';

import { ElementsComponent } from './elements.component';
import { ElementsEditComponent } from './elements-edit/elements-edit.component';
import { ElementsCercaComponent } from './elements-cerca/elements-cerca.component';
import { ElementsDetailComponent } from './elements-detail/elements-detail.component';
import { ElementsSelectComponent } from './elements-select/elements-select.component';
import { ElementsCatalogComponent } from './elements-catalog/elements-catalog.component';
import { ElementsEditGestionComponent } from './elements-edit/elements-edit-gestion/elements-edit-gestion.component';
import { ElementsEditDetalleComponent } from './elements-edit/elements-edit-detalle/elements-edit-detalle.component';
import { ElementsEditInformacionComponent } from './elements-edit/elements-edit-informacion/elements-edit-informacion.component';
import { ElementsEditPerifericosComponent } from './elements-edit/elements-edit-perifericos/elements-edit-perifericos.component';
import { ElementsEditInstalacionesComponent } from './elements-edit/elements-edit-instalaciones/elements-edit-instalaciones.component';
import { ElementsEditHistoricoCoordenadasComponent } from './elements-edit/elements-edit-historico-coordenadas/elements-edit-historico-coordenadas.component';
import { HeaderModule } from '../header/header.module';


@NgModule({
  declarations: [
    ElementsComponent,
    ElementsEditComponent,
    ElementsCercaComponent,
    ElementsDetailComponent,
    ElementsSelectComponent,
    ElementsCatalogComponent,
    ElementsEditGestionComponent,
    ElementsEditDetalleComponent,
    ElementsEditInformacionComponent,
    ElementsEditPerifericosComponent,
    ElementsEditInstalacionesComponent,
    ElementsEditHistoricoCoordenadasComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    HeaderModule,
    jqxDateTimeInputModule,
    BrowserModule,
    jqxGridModule,
    jqxInputModule,
    jqxChartModule,
    jqxSliderModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxRibbonModule,
    jqxCheckBoxModule,
    jqxTextAreaModule,
    InputNumberModule,
    PeriodoSelectModule,
    jqxNumberInputModule,
    LabelOverLenghtModule,
    jqxDropDownListModule,
    jqxDropDownButtonModule,
  ],
  exports: [
    ElementsComponent
  ]
})
export class ElementsModule { }
