import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownButtonModule } from 'jqwidgets-ng/jqxdropdownbutton';

import { ModeloSelectComponent } from './modelo-select.component';

@NgModule({
  declarations: [
    ModeloSelectComponent
  ],
  imports: [
    CommonModule,
    jqxGridModule,
    jqxDropDownButtonModule,
  ],
  exports: [
    ModeloSelectComponent
  ]
})
export class ModeloSelectModule { }
