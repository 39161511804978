import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxRibbonModule } from 'jqwidgets-ng/jqxribbon';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxColorPickerModule } from 'jqwidgets-ng/jqxcolorpicker';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxDropDownButtonModule } from 'jqwidgets-ng/jqxdropdownbutton';

import { SensorFilterComponent } from './sensor-filter/sensor-filter.component';

@NgModule({
  declarations: [
    SensorFilterComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    jqxGridModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxRibbonModule,
    jqxListBoxModule,
    jqxCheckBoxModule,
    jqxColorPickerModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule,
    jqxDropDownButtonModule
  ]
})
export class SensorModule { }
