import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { DateAuditoriaModel } from 'src/app/services/auditoria/models/date.auditoria.model';

import { PeriodoSelectComponent } from '../../periodo-select/periodo-select.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.css']
})
export class InformesComponent extends CustomForms implements OnInit {
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;

  public static _this: InformesComponent;

  private componentRef = null;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');

  dataAdapter: any;
  auditorias: AuditoriaModel[] = [];
  auditoria: AuditoriaModel = new AuditoriaModel(null, null);
  dataSource: any;
  dateFin = new Date();
  dateInicio = new Date();
  dateAuditoria: DateAuditoriaModel = new DateAuditoriaModel();

  constructor(private auditoriaService: AuditoriaService, private ssoService: SsoService) {
    super();
    InformesComponent._this = this;
  }
  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }
  /**Inicializa el componente
  */

  ngAfterViewInit() {
    this.form.setTitle(AppComponent.translate('Informes'));
    this.addCustomForm(this.form);
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  //Obtenemos los datos del grid
  async getAuditoria(date: DateAuditoriaModel) {
    this.auditorias = await this.auditoriaService.getAuditoria(date);
    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'Fecha', type: 'date', map: 'Fecha' },
        { name: 'UsuarioNombre', type: 'string', map: 'Usuario' },
        { name: 'AccionNombre', type: 'string', map: 'Accion>Nombre' },
        { name: 'Info', type: 'string', map: 'Info' }
      ],
      localdata: this.auditorias
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);

    Utils.renderSizeGrid(this.myGrid, 500);
  }

  columns = [
    {
      text: this.translate('Fecha'), datafield: 'Fecha', type: 'date', width: '150', cellsrenderer: this.renderRow, aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: this.translate('USUARIO'), datafield: 'UsuarioNombre', type: 'string', width: '180',
    },
    { text: this.translate('Accion'), datafield: 'AccionNombre', type: 'string', width: '150', editable: false },
    { text: this.translate('Informacion'), datafield: 'Info', type: 'string', autowidth: true, editable: false }
  ];

  //Renderizamos las fechas
  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      /*
        Cuando se hace new Date le esta añadiendo a la fecha GMT +1
        por tanto se debe de corregir seteando los valores a UTC
      */
      let dateUTC = new Date(value);
      let date = new Date(value);
      dateUTC.setFullYear(date.getUTCFullYear());
      dateUTC.setMonth(date.getUTCMonth());
      dateUTC.setDate(date.getUTCDate());
      dateUTC.setHours(date.getUTCHours());
      return '<div style="margin-left: 3px; margin-top: 2px">' + DateUtils.formatDateTimeShort(dateUTC, true) + '</div>';
    }
  }

  // Si las fechas son correctas, obtenemos los datos del grid
  onSearch(event) {
    this.dateInicio = this.periodoSelect.getFechaIni();
    this.dateFin = this.periodoSelect.getFechaFin();
    this.dateAuditoria.Desde = this.dateInicio;
    this.dateAuditoria.Hasta = this.dateFin;
    this.getAuditoria(this.dateAuditoria);
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    InformesComponent._this = null;
  }
  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
