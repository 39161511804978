import { Component, OnInit, ViewChild } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';
import * as xlsx from 'xlsx';

import { GeoUtils } from 'src/app/utils/geo-utils';
import { MapBounds, MapComponent, MapLatLng, MapMarker, MapPolygon } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { CuadriculaService } from 'src/app/services/cuadricula/cuadricula.service';

import { CuadriculaModel } from 'src/app/services/cuadricula/models/cuadricula.model';
import { MarcoGeograficoModel } from 'src/app/services/geographics/marco-geografico.model';
import { DateUtils } from 'src/app/utils/date-utils';
import { HeaderComponent } from '../header/header.component';
import { NumberUtils } from 'src/app/utils/number-utils';


@Component({
  selector: 'app-cuadriculas',
  templateUrl: './cuadriculas.component.html',
  styleUrls: ['./cuadriculas.component.css']
})
export class CuadriculasComponent extends CustomForms implements OnInit {
  public static _this: CuadriculasComponent;

  private componentRef = null;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');
  private subscriptionOnMapClick = null;
  private subscriptionOnMarkerMove = null;

  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('formCuadricula') formCuadricula: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;
  // grid
  dataAdapter: any;
  dataSource: any;
  row: number;
  public visible = false;
  // mostrar formulario
  viewForm: boolean = false;
  // instancia de la clase cuadricula
  cuadricula: CuadriculaModel = new CuadriculaModel();
  cuadriculas: CuadriculaModel[] = [];
  modificando: boolean = false;
  // Medidas por defecto del marco en metros
  private marcoAncho = 10000;
  private marcoAlto = 10000;

  // map
  private map: MapComponent;
  private markerSw: MapMarker;
  private markerNw: MapMarker;
  private markerNe: MapMarker;
  private markerSe: MapMarker;
  private polygon: MapPolygon;
  private polygon2: MapPolygon;
  private polygonPoints: MapLatLng[] = [];
  public marcoCartoPolygon: MapPolygon;
  polygonSquare: MapPolygon[] = [];
  private marco: MarcoGeograficoModel;
  columns: any[] = [];

  constructor(private modal: NzModalService, private cuadriculaService: CuadriculaService, private ssoService: SsoService) {
    super();
    CuadriculasComponent._this = this;
  }
  // grid


  /**Inicializa el componente
*/
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    setTimeout(() => {
      this.getCuadriculas();
    }, 1000);
  }

  ngAfterViewInit() {
    this.form.setTitle(AppComponent.translate('Cuadriculas'));
    this.formCuadricula.setTitle(AppComponent.translate('Cuadriculas'));
    this.map = MainComponent.getActiveMap();
    this.addCustomForm(this.form);
    this.addCustomForm(this.formCuadricula);
    //this.renderSizeGrid();
  }

  //Carga de datos
  async getCuadriculas() {

    this.columns = [

      {
        text: '',
        width: 100,
        columntype: 'text',
        sortable: false,
        editable: false,
        groupable: false,
        menu: false,
        rendered: (columnHeaderElement) => {
          const buttonContainer1 = document.createElement('div');
          buttonContainer1.style.width = '100%';
          buttonContainer1.style.display = 'flex';
          buttonContainer1.style.justifyContent = 'center';

          buttonContainer1.id = `buttonContainerColumn_jqxButton`;
          columnHeaderElement[0].appendChild(buttonContainer1);
          const btnCrear = document.createElement('div');
          btnCrear.innerHTML =
            `<button class="button" style="height: 20px; width: 100%; padding: 0; margin: 0; cursor: pointer !important ; title="` +
            AppComponent.translate('Crear') + `"> <i style="font-size: 18px;" class="fa-solid fa-plus"></i></button>`;
          btnCrear.id = `buttonCrear_jqxButton`;
          buttonContainer1.appendChild(btnCrear);
          btnCrear.style.width = '50%'
          btnCrear.addEventListener('click', async (event: any) => {
            this.onCrearCuadricula();
          });
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
      },
      {
        text: this.translate('Nombre'), datafield: 'Nombre', width: 'auto', editable: false, type: 'string',
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }], //agrego el total al grid
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      {
        text: this.translate('Alto'), datafield: 'Alto', width: '25%', filtertype: 'number', editable: false,
      },
      {
        text: this.translate('Ancho'), datafield: 'Ancho', width: '25%', filtertype: 'number', editable: false,
      },
    ];

    let isFirstIteration = true;
    this.columns.forEach(column => {
      if (!isFirstIteration) {
        column.rendered = (element) => { Utils.tooltiprenderer(element) };
      } else {
        isFirstIteration = false;
      }
    });

    this.cuadriculas = await this.cuadriculaService.getCuadriculas();
    if (this.cuadriculas) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'string', map: 'id' },
          { name: 'Nombre', type: 'string', map: 'nombre' },
          { name: 'Alto', type: 'number', map: 'alto' },
          { name: 'Ancho', type: 'number', map: 'ancho' },
        ],
        localdata: this.cuadriculas,
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    }

    //this.renderSizeGrid();
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    let rowdata: any;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.myGrid.getrowdata(row);
    }

    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'flex-start';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
      <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEdit.id = `buttonEdit`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', async (event: any) => {
      this.cuadricula = this.cuadriculas.find(elem => elem.id == rowdata.Id);
      this.onEditarCuadricula();
    });

    // onVer
    const btnVer = document.createElement('div');
    btnVer.innerHTML = `
      <button class="button ver" style="height: 23px; width: 30px; cursor: pointer;" title="`+ AppComponent.translate('Ver') + `">
        <i class="fa-solid fa-eye"></i>
      </button>
    `;
    btnVer.id = `buttonVer`;
    btnContainer.appendChild(btnVer);

    // Crear el botón de Ocultar y agregarlo al contenedor, pero inicialmente estará oculto
    const btnOcultar = document.createElement('div');
    btnOcultar.innerHTML = `
      <button class="button ocultar" style="height: 23px; width: 30px; cursor: pointer; display: none;" title="`+ AppComponent.translate('Ocultar') + `">
        <i class="fa-solid fa-eye-slash"></i>
      </button>
    `;
    btnOcultar.id = `buttonOcultar`;
    btnContainer.appendChild(btnOcultar);

    // Agregar evento de click al botón Ver
    btnVer.addEventListener('click', async (event) => {
      this.cuadricula = this.cuadriculas.find(elem => elem.id == rowdata.Id);
      this.onVerCuadricula();

      // Ocultar el botón Ver y mostrar el botón Ocultar
      btnVer.querySelector('button').style.display = 'none';
      btnOcultar.querySelector('button').style.display = 'inline-block';
    });

    // Agregar evento de click al botón Ocultar para revertir los cambios
    btnOcultar.addEventListener('click', (event) => {
      // Realizar las acciones necesarias para "ocultar" lo que se mostró con el botón Ver
      this.removePolygonSquare();
      this.removeMarkerAtMarco();


      // Mostrar el botón Ver y ocultar el botón Ocultar nuevamente
      btnVer.querySelector('button').style.display = 'inline-block';
      btnOcultar.querySelector('button').style.display = 'none';
    });


    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
        <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="`+ AppComponent.translate('Borrar') + `">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;
    btnDelete.id = `buttonDelete`;
    btnContainer.appendChild(btnDelete);

    btnDelete.addEventListener('click', async (event: any) => {
      this.cuadricula = this.cuadriculas.find(elem => elem.id == rowdata.Id);
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_elemento') + ': ' + this.cuadricula.nombre + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          await this.cuadriculaService.delCuadricula(this.cuadricula.id);
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          this.cuadriculas.splice(this.cuadriculas.indexOf(this.cuadricula), 1);
          this.myGrid.updatebounddata();
          this.row = null;
        }
      });
    });
    htmlElement.appendChild(btnContainer);
  }

  // obtener fila seleccionada
  onRowclick(event: any): void {
    this.row = event.args.row.bounddata.Id;
  }

  onRowdoubleclick(event: any) {
    this.onEditarCuadricula();
  }

  //Crud form
  onCrearCuadricula() {
    this.viewForm = true;
    this.modificando = false;
    this.cuadricula = new CuadriculaModel();
    this.marco = null;
    this.subscribeMapEvents();
    this.formCuadricula.open(); //Abre el formulario
    this.form.collapse();
  }

  async onSaveCuadricula(event) {
    // Comprobamos que los datos del formulario son correctos
    if (this.marco === undefined || !this.controlForm()) {
      MainComponent.getInstance().showError('ATENCION', 'Pinte_cuadricula_mapa', 2000);
      return;
    } else {
      if (this.cuadricula.id) {
        // busco la cuadricula en el array de cuadriculas
        let elem = this.cuadriculas.find(elem => elem.id == this.cuadricula.id);
        this.cuadriculas.splice(this.cuadriculas.indexOf(elem), 1);
        this.cuadricula.marco = this.marco.marco;
        this.cuadricula = await this.cuadriculaService.addCuadricula(this.cuadricula)
        // Si el id de la acción es null, es que estamos creando
      } else {
        this.cuadricula = await this.saveCuadricula();
      }
      if (this.cuadricula) {
        MainComponent.getInstance().showInfo(
          'ATENCION',
          'Registro_almacenado',
          2000
        );
        this.cuadriculas.push(this.cuadricula);
        this.myGrid.updatebounddata();
        this.formCuadricula.close();
        this.form.expand();
      } else {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Fallo_almacenar_info',
          2000
        );
      }
    }
  }

  onEditarCuadricula() {
    this.viewForm = true;
    this.modificando = true;
    // si no hay ninguna acción seleccionada, salgo
    if (!this.row) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    // busco la acción en el array de acciones
    let positionCenter = this.positionSquareCenter();
    this.subscribeMapEvents();
    this.onCreateMarco(this, positionCenter);
    // Abro el formulario
    this.formCuadricula.open(); //Abre el formulario
    this.form.collapse();
    // Encuadro el mapa en el marco
    this.map.fitTo(this.marco.marco);
  }

  // Ver cuadrícula
  onVerCuadricula() {
    if (!this.row) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    else {
      this.modificando = true;
      let positionCenter = this.positionSquareCenter();
      this.onCreateMarco(this, positionCenter);
      this.removeMarkerAtMarco();
      this.map.setCenter(positionCenter);
      this.map.fitTo(this.marco.marco);
    }
  }




  // Cuando se modifica el alto de la cuadrícula se modifica el marco controlando que no sea menor de 50
  changeHigh(event) {
    let back = true;
    if (event.code === 'Backspace') {
      back = false;
    }
    if (event && this.marco && back) {
      let altoInput = event.target.value;
      if (Number.isNaN(parseInt(altoInput))) {
        MainComponent.getInstance().showWarning('ATENCION', 'Solo_numeros', 2000);
        return;
      }
      this.cuadricula.alto = parseInt(altoInput);
      if (this.cuadricula.alto > 50) {
        let position = this.positionFrameCenter();
        // modificamos el alto del marco
        this.onCreateMarco(this, position);
      } else {
        this.controlForm();
        return;
      }
    }
  }

  // Cuando se modifica el ancho de la cuadrícula se modifica el marco controlando que no sea menor de 50
  changeBroad(event) {
    let back = true;
    if (event.code === 'Backspace') {
      back = false;
    }
    if (event && this.marco && back) {
      let anchoInput = event.target.value;
      // Si no es un número, salgo
      if (Number.isNaN(parseInt(anchoInput))) {
        MainComponent.getInstance().showWarning('ATENCION', 'Solo_numeros', 2000);
        return;
      }
      this.cuadricula.ancho = parseInt(anchoInput);
      if (this.cuadricula.ancho > 50) {
        let position = this.positionFrameCenter();
        this.onCreateMarco(this, position);
      } else {
        this.controlForm();
        return;
      }
    }
  }

  // Busca la cuadrícula en el array de cuadrículas
  onFindCuadricula(): CuadriculaModel {
    let cuadricula = this.cuadriculas.find(x => x.id == this.row);
    if (cuadricula) {
      this.cuadricula = cuadricula;
      return cuadricula;
    }
  }

  renderSizeGrid() {
    Utils.renderSizeGrid(this.myGrid, 500)
  }

  // Obtenemos el centro del marco si la cuadricula no está creada
  positionFrameCenter(): MapLatLng {
    if (this.marco) {
      let position: MapLatLng = new MapLatLng(this.marco.marco.swCorner.lat + ((this.marco.marco.neCorner.lat - this.marco.marco.swCorner.lat) / 2),
        this.marco.marco.swCorner.lng + ((this.marco.marco.neCorner.lng - this.marco.marco.swCorner.lng) / 2));
      return position
    }
  }
  // Obtenemos el centro de la cuadrícula si el marco ya está creado
  positionSquareCenter(): MapLatLng {
    if (this.cuadricula) {
      let positionCenter: MapLatLng = new MapLatLng(this.cuadricula.marco.swCorner.lat + ((this.cuadricula.marco.neCorner.lat - this.cuadricula.marco.swCorner.lat) / 2),
        this.cuadricula.marco.swCorner.lng + ((this.cuadricula.marco.neCorner.lng - this.cuadricula.marco.swCorner.lng) / 2));
      return positionCenter
    }
  }

  //Control de los datos del formulario
  controlForm(): boolean {
    if (!this.cuadricula.nombre) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return false;
    } else if (this.cuadricula.alto <= 50 || this.cuadricula.alto == undefined || this.cuadricula.ancho == undefined || this.cuadricula.ancho <= 50) {
      MainComponent.getInstance().showError('ATENCION', 'Minimo_cuadricula', 2000);
      return false;
    }
    return true;
  }

  // Configura el marco de 10 km²
  configLatLngMarco(position: MapLatLng): MapBounds {
    if (this.marco) {
      this.marcoAncho = GeoUtils.getDistance(this.marco.marco.swCorner.lat, this.marco.marco.swCorner.lng,
        this.marco.marco.swCorner.lat, this.marco.marco.neCorner.lng);
      this.marcoAlto = GeoUtils.getDistance(this.marco.marco.swCorner.lat, this.marco.marco.swCorner.lng,
        this.marco.marco.neCorner.lat, this.marco.marco.swCorner.lng);
    }
    return new MapBounds(GeoUtils.getNewLatLng(position, -(this.marcoAncho / 2), -(this.marcoAlto / 2)),
      GeoUtils.getNewLatLng(position, this.marcoAncho / 2, this.marcoAlto / 2));
  }

  // Elimina los marcadores del marco
  removeMarkerAtMarco() {
    if (this.markerSw) {
      this.map.removeMarker(this.markerSw);
      this.markerSw = null;
    }

    if (this.markerNe) {
      this.map.removeMarker(this.markerNe);
      this.markerNe = null;
    }

    if (this.markerNw) {
      this.map.removeMarker(this.markerNw);
      this.markerNw = null;
    }

    if (this.markerSe) {
      this.map.removeMarker(this.markerSe);
      this.markerSe = null;
    }
  }

  // Elimina los polígonos de la cuadrícula
  removePolygonSquare() {
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.polygon = null;
      this.polygonSquare.forEach(polygon => this.map.removePolygon(polygon));
      this.polygonSquare = [];
      this.polygonPoints = [];
    }

    if (this.polygon2) {
      this.map.removePolygon(this.polygon2);
      this.polygon2 = null;
    }
  }

  // Añade los marcadores en cada esquina del marco de 10 km
  addMarkersAtMarco(position: MapBounds, polygon: MapPolygon) {
    this.removeMarkerAtMarco();
    this.removePolygonSquare();
    let lat1 = position.neCorner.lat;
    let lat2 = position.neCorner.lat;
    let lng1 = position.swCorner.lng;
    let lng2 = position.swCorner.lng;

    if (polygon) {
      this.map.removePolygon(polygon);
      polygon = null;
    }

    this.polygon2 = this.map.addPolygon({
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 4,
      fillColor: '#000000',
      fillOpacity: 0
    });

    this.marcoCartoPolygon.points.forEach(point => {
      this.map.addPolygonPoint(this.polygon2, {
        dataModel: this,
        content: '',
        position: point.point
      });
      if (point.point.lat < lat1) {
        lat1 = point.point.lat;
      }
      if (point.point.lng < lng1) {
        lng1 = point.point.lng;
      }
      if (point.point.lat > lat2) {
        lat2 = point.point.lat;
      }
      if (point.point.lng > lng2) {
        lng2 = point.point.lng;
      }
    });

    // Crear los marcadores en cada esquina del marco de 10 km
    this.markerSw = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat1, lng1),
      icon: '/assets/images/angulo-sw.png',
      zIndex: 999,
      drag: true,
      visible: true
    });

    this.markerNw = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat2, lng1),
      icon: '/assets/images/angulo-nw.png',
      zIndex: 999,
      drag: true,
      visible: true
    });

    this.markerNe = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat2, lng2),
      icon: '/assets/images/angulo-ne.png',
      zIndex: 999,
      drag: true,
      visible: true
    });

    this.markerSe = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat1, lng2),
      icon: '/assets/images/angulo-se.png',
      zIndex: 999,
      drag: true,
      visible: true
    });
    this.getSquareBounds(this.markerSw.position, this.markerNe.position);
  }

  // Crea el marco
  async onCreateMarco(_this: CuadriculasComponent, position: MapLatLng) {
    if (!_this.controlForm()) {
      _this.formCuadricula.expand();
      return;
    } else {
      // Si la cuadricula ya existe, modificamos el marco
      if (_this.modificando) {
        _this.marco = new MarcoGeograficoModel(this.cuadricula.marco);
      } else {
        if (!_this.modificando) {
          const bound = _this.configLatLngMarco(position);
          _this.marco = new MarcoGeograficoModel(bound);
        }
      }

      if (_this.marcoCartoPolygon) {
        _this.map.removePolygon(_this.marcoCartoPolygon);
        _this.marcoCartoPolygon = null;
      }

      _this.marcoCartoPolygon = _this.map.addPolygon({
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 4,
        fillColor: '#000000',
        fillOpacity: 0
      });
      _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
        dataModel: _this,
        position: _this.marco.marco.swCorner
      });
      _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
        dataModel: _this,
        position: new MapLatLng(_this.marco.marco.neCorner.lat, _this.marco.marco.swCorner.lng)
      });
      _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
        dataModel: _this,
        position: _this.marco.marco.neCorner
      });
      _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
        dataModel: _this,
        position: new MapLatLng(_this.marco.marco.swCorner.lat, _this.marco.marco.neCorner.lng)
      });
      _this.addMarkersAtMarco(_this.marco.marco, _this.marcoCartoPolygon);
      _this.formCuadricula.expand();
    }
  }

  async saveCuadricula(): Promise<CuadriculaModel> {
    let cuadricula: any = {
      Nombre: this.cuadricula.nombre,
      Ancho: this.cuadricula.ancho,
      Alto: this.cuadricula.alto,
      Marco: this.marco.marco,
      Empresa: this.ssoService.getTicket().Empresa.IdGestion
    };
    if (!this.cuadricula.id) {
      this.cuadricula = await this.cuadriculaService.addCuadricula(cuadricula);
    }
    return this.cuadricula;
  }

  getSquareBounds(markerSw: MapLatLng, markerNe: MapLatLng): void {
    // Cojo las esquinas del marco
    let swCorner = this.modificando ? markerSw : this.marco.marco.swCorner;
    const neCorner = this.modificando ? markerNe : this.marco.marco.neCorner;
    // Calculo las medidas del marco
    const marcoAncho = GeoUtils.getDistance(swCorner.lat, swCorner.lng, swCorner.lat,
      swCorner.lng + (neCorner.lng - swCorner.lng));
    const marcoAlto = GeoUtils.getDistance(swCorner.lat, swCorner.lng, swCorner.lat +
      (neCorner.lat - swCorner.lat), swCorner.lng);
    // Calculo el número filas que cogen dentro del marco
    const numColumnas = Math.floor(marcoAncho / this.cuadricula.ancho);
    // Calculo el número columnas que cogen dentro del marco
    const numFilas = Math.floor(marcoAlto / this.cuadricula.alto);
    if (numFilas > 0 && numColumnas > 0) {
      // Recorro filas de abajo a arriba
      for (let fila = 0; fila < numFilas; fila++) {
        // Recorro columnas de izquierda a derecha
        let p1 = swCorner; // Pongo el puntero en la primera columna de la fila
        for (let columna = 0; columna < numColumnas; columna++) {
          const p2 = GeoUtils.getNewLatLng(p1, 0, this.cuadricula.alto);
          const p3 = GeoUtils.getNewLatLng(p1, this.cuadricula.ancho, this.cuadricula.alto);
          const p4 = GeoUtils.getNewLatLng(p1, this.cuadricula.ancho, 0);
          this.polygonPoints = [p1, p2, p3, p4];
          // Creao un polígono para cada cuadrícula y lo agrego al mapa
          this.polygon = this.map.addPolygon({
            dataModel: this,
            strokeColor: '#ff0000',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#0000ff',
            fillOpacity: 0.1,
            zIndex: 100,
          });
          this.polygonPoints.forEach(punto => {
            this.map.addPolygonPoint(this.polygon, {
              position: punto
            });
          });
          this.polygonSquare.push(this.polygon);
          // Adelanto el puntero una columna
          p1 = p4;
        }
        // Subo el puntero una fila
        swCorner = GeoUtils.getNewLatLng(swCorner, 0, this.cuadricula.alto);
      }
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Tamaño_cuadricula', 2000);
    }
  }

  // Modifica el marco al mover los marcadores
  modificarMarco(_this: CuadriculasComponent, marker: MapMarker) {
    if (marker === _this.markerSw) {
      _this.markerNw.setPosition(new MapLatLng(_this.markerNw.position.lat, marker.position.lng));
      _this.markerSe.setPosition(new MapLatLng(marker.position.lat, _this.markerSe.position.lng));
    }
    if (marker === _this.markerNw) {
      _this.markerSw.setPosition(new MapLatLng(_this.markerSw.position.lat, marker.position.lng));
      _this.markerNe.setPosition(new MapLatLng(marker.position.lat, _this.markerNe.position.lng));
    }
    if (marker === _this.markerNe) {
      _this.markerNw.setPosition(new MapLatLng(marker.position.lat, _this.markerNw.position.lng));
      _this.markerSe.setPosition(new MapLatLng(_this.markerSe.position.lat, marker.position.lng));
    }
    if (marker === _this.markerSe) {
      _this.markerSw.setPosition(new MapLatLng(marker.position.lat, _this.markerSw.position.lng));
      _this.markerNe.setPosition(new MapLatLng(_this.markerNe.position.lat, marker.position.lng));
    }

    if (_this.marcoCartoPolygon) {
      _this.map.removePolygon(_this.marcoCartoPolygon);
      _this.marcoCartoPolygon = null;
      _this.removePolygonSquare();
    }

    _this.marcoCartoPolygon = _this.map.addPolygon({
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 4,
      fillColor: '#000000',
      fillOpacity: 0
    });
    _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
      dataModel: _this,
      content: '',
      position: _this.markerSw?.position
    });
    _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
      dataModel: _this,
      content: '',
      position: new MapLatLng(_this.markerNe?.position.lat, _this.markerSw?.position.lng)
    });
    _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
      dataModel: _this,
      content: '',
      position: _this.markerNe?.position
    });
    _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
      dataModel: _this,
      content: '',
      position: new MapLatLng(_this.markerSw?.position.lat, _this.markerNe?.position.lng)
    });

    _this.getSquareBounds(_this.markerSw?.position, _this.markerNe?.position);
    _this.marco = new MarcoGeograficoModel(new MapBounds(_this.markerSw?.position, _this.markerNe?.position));

  }

  // Suscripción a los eventos del mapa
  subscribeMapEvents() {
    // si no hay suscripción, la creo
    if (!this.subscriptionOnMapClick) {
      this.formCuadricula.collapse();
      this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onCreateMarco);
    }
    if (!this.subscriptionOnMarkerMove) {
      this.formCuadricula.collapse();
      this.subscriptionOnMarkerMove = this.map.subscribeOnMarkerMove(this, this.modificarMarco);
    }
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    CuadriculasComponent._this = null;
  }

  ngOnDestroy() {
    if (this.marcoCartoPolygon) {
      this.map.removePolygon(this.marcoCartoPolygon);
      this.marcoCartoPolygon = null;
    }
    if (this.polygon2) {
      this.map.removePolygon(this.polygon2);
      this.polygon2 = null;
    }
    this.removePolygonSquare();
    this.removeMarkerAtMarco();
  }

  onCloseForm() {
    this.form.expand();
    this.viewForm = false;

    this.removePolygonSquare();
    this.removeMarkerAtMarco();
    this.unsubscribeMapEvents();
  }

  onCancelBtn() {
    this.removePolygonSquare();
    this.removeMarkerAtMarco();
    this.unsubscribeMapEvents();
    this.formCuadricula.close();
  }

  // Desuscripción a los eventos del mapa
  unsubscribeMapEvents() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
  }
  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }


  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
  }

  eventResetFilter() {
    this.myGrid.clearfilters();
  }

  onBuscar() {
    let filtervalue = '';
    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.cuadriculas.forEach(elem => {
      if (
        (elem?.nombre + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (NumberUtils.format(elem?.alto, 0) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (NumberUtils.format(elem?.ancho, 0) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        elem['selec'] = 'selec';
      } else {
        elem['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.myGrid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.myGrid.addfilter('selec', filtergroup);
    }
    this.myGrid.applyfilters();
    this.myGrid.updatebounddata('data');
  }

  onExportar() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.myGrid.exportdata('json');
      let datos = JSON.parse(json);
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Zonas') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.myGrid.hidecolumn('Botones');
      let gridContent = this.myGrid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.myGrid.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

}
