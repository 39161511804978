import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { VolumLectruraModel } from 'src/app/services/volumetricos/models/volum-lectura.model';

@Component({
  selector: 'app-lec-volum-graph',
  templateUrl: './lec-volum-graph.component.html',
  styleUrls: ['./lec-volum-graph.component.css']
})
export class LecVolumGraphComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;

  private componentRef = null;
  private parentComponent = null;
  public environment = environment;
  public lecturas: VolumLectruraModel[] = [];
  public fechaIni: Date;
  public fechaFin: Date;
  public elemento: ElementoModel;

  // Variables para generar la gráfica
  public dataSource: any;
  public dataAdapter: any;

  public padding: any = { left: 5, top: 5, right: 30, bottom: 5 };
  public titlePadding: any = { left: 30, top: 5, right: 0, bottom: 10 };
  public xAxis: any
  public valueAxis: any;
  public seriesGroups: any;

  //  Esto es para que los textos en las gráficas
  public lang = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService) {
    super();
  }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.lang = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Grafico_medidas'));
    const t = setTimeout(() => {
      clearTimeout(t);
      this.generaGrafica();
    }, 1000);
  }

  // Este método es llamado por el creador del componente
  public init(params: any) {
    this.parentComponent = params.parentComponent;
    this.componentRef = params.component;
    this.fechaIni = params.fechaIni;
    this.fechaFin = params.fechaFin;
    this.elemento = params.elemento;
    this.lecturas = params.lecturas;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.parentComponent) {
      this.parentComponent.expand();
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  generaGrafica() {
    this.xAxis =
    {
      dataField: 'fechaLectura',
      minValue: this.fechaIni,
      maxValue: this.fechaFin,
      type: 'date',
      baseUnit: 'day',
      labels:
      {
        formatFunction: (value: any): any => {
          return value.getDate() + '-' + this.lang.months.namesAbbr[value.getMonth()];
        }
      },
      rangeSelector: {
        size: 80,
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        minValue: this.fechaIni,
        backgroundColor: 'white',
        dataField: 'porcentaje',
        baseUnit: 'month',
        gridLines: { visible: false },
        serieType: 'area',
        labels: {
          formatFunction: (value: any): any => {
            return this.lang.months.names[value.getMonth()];
          }
        }
      }
    }
    this.valueAxis = {
      title: { text: this.translate('Lecturas') },
      labels: { horizontalAlignment: 'right' }
    }
    this.seriesGroups = [{
      type: 'line',
      toolTipFormatFunction: (value: any, itemIndex: any, serie: any, group: any,
        categoryValue: any, categoryAxis: any) => {
        return '<div style="text-align: left">' +
          DateUtils.formatDateTime(categoryValue, true) + '<br>' +
          this.translate('Nivel') + ': <b>' + this.dataAdapter.records[itemIndex].porcentaje + '%</b> ' +
          this.dataAdapter.records[itemIndex].lectura + 'cm.' +
          '</div>';
      },
      series: [
        { dataField: 'porcentaje', displayText: this.translate('Lectura'), lineWidth: 1, lineWidthSelected: 1 }
      ]
    }];
    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'elemento', map: 'Elemento>Nombre', type: 'string' },
        { name: 'horaInicio', map: 'Config>HoraInicio', type: 'date' },
        { name: 'frecuencia', map: 'Config>FrecuenciaLectura', type: 'int' },
        { name: 'fechaLectura', map: 'FechaLectura', type: 'date' },
        { name: 'fechaRecepcion', map: 'Fecha', type: 'date' },
        { name: 'lectura', map: 'Lectura', type: 'float' },
        { name: 'porcentaje', map: 'Porcentaje', type: 'float' },
        { name: 'bateria', map: 'Bateria', type: 'float' },
        { name: 'temperatura', map: 'Temperatura', type: 'float' },
        { name: 'imei', map: 'Imei', type: 'string' }
      ],
      localdata: this.lecturas,
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);
  }

}
