import { CatastroModel } from "./catastro.model";
import { DireccionModel } from "./direccion.model";
import { TarjetaModel } from "./tarjeta.model";

export class CiudadanoModel {
  id: number = 0;
  estadoValidacion: number = 14;
  validadoTexto: string = '';
  creador: string;

  nombre: string = '';
  apellidos: string = '';
  nif: string = '';

  telefono: number;
  correoElectronico: string = '';

  direccion: string;
  numero: string;
  planta: string;
  puerta: string;
  codigoPostal: string;
  poblacion: string;
  municipio: string;
  provincia: string;
  comunidadAutonoma: string;

  numeroCatastro: string;

  empresa: number;
  idZona: number = null;

  catastro: CatastroModel;
  tarjetas: TarjetaModel[] = [];
  direcciones: DireccionModel[] = [];

  fechaCreacion: Date = new Date();

  direccionCatastral: string;

  constructor() { }
}
