import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import * as xlsx from 'xlsx';
import { MapLatLng } from 'movisat-maps';
import { Utils } from 'src/app/utils/utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ElementsService } from 'src/app/services/elements/elements.service';
import { IncidencesService } from 'src/app/services/incidences/incidences.service';

import { UsuarioModel } from 'src/app/services/sso/models/usuario.model';
import { TipoModel } from 'src/app/services/incidences/models/tipo.model';
import { EstadoModel } from 'src/app/services/incidences/models/estado.model';
import { ModeloModel } from 'src/app/services/incidences/models/modelo.model';
import { MotivoModel } from 'src/app/services/incidences/models/motivo.model';
import { IncidenciaModel } from 'src/app/services/incidences/models/incidencia.model';

import { EditStateComponent } from '../edit-state/edit-state.component';
import { NewIncidenceComponent } from '../new-incidence/new-incidence.component';
import { StatusHistoryComponent } from '../status-history/status-history.component';
import { PeriodoSelectComponent, PeriodoSelectTipo } from '../../periodo-select/periodo-select.component';
import { HeaderComponent } from '../../header/header.component';

@Component({
  selector: 'app-grid-incidences',
  templateUrl: './grid-incidences.component.html',
  styleUrls: ['./grid-incidences.component.css']
})
export class GridIncidencesComponent extends CustomForms implements OnInit {
  @ViewChild('windowIncidences') windowIncidences: jqxWindowComponent;
  @ViewChild('incidencesGrid') incidencesGrid: jqxGridComponent;
  @ViewChild('loader') loaderComponent: jqxLoaderComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridIncidencesContainer', { read: ViewContainerRef }) gridIncidencesContainer;

  public static _this: GridIncidencesComponent;

  private componentRef = null;
  public environment = environment;
  private static instance: GridIncidencesComponent;
  private searchText = '';
  private static toolbarContainer = null;
  private static searchControl = null;
  //variables públicas del grid
  columns: any[] = [];
  dataSource: any;
  dataAdapter: any;
  //modelos
  tipos: TipoModel[] = [];
  modelos: ModeloModel[] = [];
  motivos: MotivoModel[] = [];
  estados: EstadoModel[] = [];
  incidences: IncidenciaModel[] = [];
  //variables
  fechaIni: Date = new Date();
  fechaFin: Date = new Date();
  mostrarEdit = false;
  selectedEstados = [];
  selectedIncidence;
  searchControl;
  map;
  markerIncidence: any;
  sourceEstados: { datatype: string; datafields: { name: string; }[]; id: string; localdata: EstadoModel[]; };
  adapterEstados: any;
  users: UsuarioModel[];
  isCheckedAll: boolean = false;
  actualizarEstados: boolean;
  isOpen: boolean;
  isPartialSelection: boolean;
  binarySelection;
  mapHeight: number;
  mapWidth: number;
  constructor(private incidencesService: IncidencesService,
    private ssoService: SsoService,
    private elementsService: ElementsService,
    private modal: NzModalService) {
    super();
    GridIncidencesComponent.instance = this;
    GridIncidencesComponent._this = this;
  }

  public static getInstance(): GridIncidencesComponent {
    return GridIncidencesComponent.instance;
  }

  ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    //trae los datos del endpoint de incidencias

    //inicializa el grid
    setTimeout(() => {
      this.initGrid();

    }, 1000);

    //recupera el mapa principal
    this.map = MainComponent.getInstance().getMap();
  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500);
  }

  // textos del grid en el idioma correspondiente
  langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

  async getData() {
    //traer usuarios
    this.users = await this.ssoService.getUsuariosByEmpresaId();
    //añadir opción de todos
    let estadoAll = new EstadoModel();
    estadoAll.Nombre = 'Todos';
    estadoAll.Id = -1;
    this.estados.push(estadoAll);
    //trae stados
    this.estados.push(...await this.incidencesService.getEstados());
    //inicaliza dropdownlist
    this.initDropdownlist();
    //actualiza los datos del dropdownlist
    this.sourceEstados.localdata = this.estados;
    //trae tipos
    this.tipos = await this.incidencesService.getTipos();
    //guardar modelos
    await Promise.all(this.tipos.map(async (tipo) => {
      this.modelos.push(...await this.incidencesService.getModelos(tipo.Id));
    }));
    //asignar tipos a modelos
    this.modelos.forEach(modelo => {
      modelo.Tipo = this.tipos.find(tipo => tipo.Id === modelo.TipoId);
    });
    //guardar motivos
    await Promise.all(this.modelos.map(async (modelo) => {
      this.motivos.push(...await this.incidencesService.getMotivos(modelo.Id));
    }));
    //asignar modelos a motivos
    this.motivos.forEach(motivo => {
      motivo.Modelo = this.modelos.find(modelo => modelo.Id === motivo.ModeloId);
    });
  }


  // Refresh grid
  async refreshGrid() {
    //se limpia la selección de filas, si la hay
    this.incidencesGrid.clearselection();
    //traer incidencias
    this.incidences = await this.incidencesService.getIncidencesByDate(this.fechaIni, this.fechaFin);
    //asignar datos a incidencias
    this.incidences.forEach(incidence => {
      incidence.Motivo = this.motivos.find(motivo => motivo.Id == incidence.MotivoId);
      incidence.Estado = this.estados.find(estado => estado.Id == incidence.EstadoId);
      // incidence.Usuario = await this.ssoService.getUsuarioById(incidence.UsuarioId);
      incidence.Usuario = this.users.find(usuario => usuario.Id == incidence.UsuarioId);
      incidence.Elemento = this.elementsService.elementos.get(incidence.ElementoId);
    });
    let filterEstados = [];
    //filtrar las incidencias teniendo en cuenta la selección de estados
    this.incidences.forEach(incidence => {
      this.selectedEstados.forEach(state => {
        if (incidence.Estado.Id == state['originalItem'].Id) {
          incidence.selec = 'selec';
          filterEstados.push(incidence);
        }
      })
    })
    this.incidences = filterEstados;

    if(this.dataSource) {
      this.dataSource.localdata = this.incidences;
    }

    if (this.searchText == '') {
      this.incidencesGrid.updatebounddata('data');
      this.incidencesGrid.sortby('Fecha', 'asc');

      this.resizeColumns(this.incidencesGrid);
    } else {
      this.initSearchControl();
    }
  }
  //evento que se lanza cuando se ha cargado el dropdownlist
  onLoadList() {
    setTimeout(() => {
      //se hace check en los estados por defecto
      this.selectItems();
    }, 50);
  }

  ngAfterViewInit() {
    this.getData();

    this.binarySelection = new Array(5).fill(0);
    this.incidencesGrid.autoshowloadelement(false);
    //this.incidencesGrid.showdefaultloadelement(false);
    //hace visible la ventana del gridIncidence component
    this.hideWindow(false);
    //añade título a la ventana
    this.windowIncidences.setTitle(this.translate('Incidencias'));
    //se extraen las fechas del selector de periodo
    setTimeout(() => {
      this.addCustomForm(this.windowIncidences);
      //se actualiza el grid en el periodo actual
      this.header.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
      this.header.periodoSelect.setDiaActual(new Date());
      this.header.periodoSelect.resetForm();
      this.fechaIni = new Date(this.header.periodoSelect.getFechaIni());
      this.fechaFin = new Date(this.header.periodoSelect.getFechaFin());
      this.refreshGrid();
    }, 500);
  }
  //seleccionar estados por defecto
  selectItems() {
    if (this.header.listEstados) {
      let items = this.header.listEstados.getItems();
      if (items) {
        items.forEach((item, i) => {
          if (item['originalItem'].Nombre == 'En proceso' || item['originalItem'].Nombre == 'Abierta') {
            this.header.listEstados.checkIndex(i)
          } else {
            this.header.listEstados.uncheckIndex(i);
          }
        })
      }
    }
  }

  eventFilter() {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();
    this.header.searchInput['nativeElement'].value = '';
    this.refreshGrid();
  }

  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.desde.setHours(0, 0, 0);
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.hasta.setHours(23, 59, 59);
    PeriodoSelectComponent._this.dateForm.get('desde').setValue(new Date());
    PeriodoSelectComponent._this.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';

    //extrae las fechas del selector de periodo
    this.fechaIni = new Date(this.header.periodoSelect.getFechaIni());
    this.fechaFin = new Date(this.header.periodoSelect.getFechaFin());

    //restablece el listado de estados
    this.selectItems();

    //actualiza grid
    this.refreshGrid();
  }

  onClickCrear() {
    if (this.markerIncidence) this.map.removeMarker(this.markerIncidence);
    this.hideWindow(true);
    const component = GridIncidencesComponent.getInstance().gridIncidencesContainer.createComponent(NewIncidenceComponent);
    component.instance.init(component, this.tipos, this.modelos, this.motivos, this.estados, '', new IncidenciaModel, this.translate('Crear_incidencia'));
    component.instance.cerrar.subscribe(() => {
      this.hideWindow(false);
      this.refreshGrid();
    });
  }

  onClickCambiar() {
    if (this.incidencesGrid.selectedrowindex() > -1) {
      this.hideWindow(true);
      const component = GridIncidencesComponent.getInstance().gridIncidencesContainer.createComponent(EditStateComponent);
      component.instance.init(component, this.estados, this.selectedIncidence);
      component.instance.cerrar.subscribe(() => {
        this.hideWindow(false);
        this.refreshGrid();
      });
    } else {
      MainComponent.showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.incidencesGrid.getdisplayrows()));
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Incidencias') + '.xlsx');
  }
  async onClickBorrar() {
    if (this.incidencesGrid.selectedrowindex() > -1) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_incidencia') + '?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          await this.incidencesService.deleteIncidence(this.selectedIncidence.Guid);
          MainComponent.showSuccess('ATENCION', 'Registro_borrado', 2000);
          this.refreshGrid();
        }
      });

    } else {
      MainComponent.showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onClickVer() {
    if (this.incidencesGrid.selectedrowindex() > -1) {
      this.windowIncidences.collapse();
      this.addMarkerIncidence();
    } else {
      MainComponent.showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onClickEditar() {
    if (this.incidencesGrid.selectedrowindex() > -1) {
      this.hideWindow(true);
      const component = GridIncidencesComponent.getInstance().gridIncidencesContainer.createComponent(NewIncidenceComponent);
      component.instance.init(component, this.tipos, this.modelos, this.motivos, this.estados, this.selectedIncidence.Guid, this.selectedIncidence, this.translate('Editar_incidencia'));
      component.instance.cerrar.subscribe(() => {
        this.hideWindow(false);
        this.refreshGrid();
      });
    } else {
      MainComponent.showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }
  //cuando se pulsa en el enlace
  onClickHref() {
    this.hideWindow(true);
    const component = GridIncidencesComponent.getInstance().gridIncidencesContainer.createComponent(StatusHistoryComponent);
    component.instance.init(component, this.estados, this.users);
    component.instance.cerrar.subscribe(() => {
      this.hideWindow(false);
    });
  }
  //end eventos botones

  //ocultar o mostrar ventana
  hideWindow(hidden: boolean) {
    if (hidden) {
      if (this.markerIncidence) this.map.removeMarker(this.markerIncidence);
      //se mueve la ventana del grid a una zona no visible
      // this.windowIncidences.position({ x: -2000, y: 0 });
      this.windowIncidences.collapse();
      this.windowIncidences.disable();
    } else {
      // this.windowIncidences.position(this.getFormPos());
      this.windowIncidences.expand();
      this.windowIncidences.enable();
    }
  }

  //acaba la carga de datos en el grid
  onBindingComplete() {
    this.loaderComponent.close();
  }

  //obtener fila seleccionada
  onRowSelect(event) {
    this.selectedIncidence = this.incidences.find(incidence => incidence.Id == event.args.row.Id);
  }

  onRowdoubleclick(event: any) {
    this.selectedIncidence = this.incidences.find(incidence => incidence.Id == event.args.row.Id);
    this.onClickEditar();
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  //inicializar dropdownlist
  initDropdownlist() {
    this.header.listEstados.source = this.adapterEstados;

    this.sourceEstados = {
      datatype: 'json',
      datafields: [
        { name: 'Nombre' },
        { name: 'Id' }
      ],
      id: 'Id',
      localdata: []
    };

    this.adapterEstados = new jqx.dataAdapter(this.sourceEstados);
  }


  initGrid() {
    this.columns = [
      {
        text: 'Id', datafield: 'Id', align: 'center', cellsalign: 'center',
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return currentValue ? aggregatedValue + 1 : aggregatedValue;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: center;">' + aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Fecha'), datafield: 'Fecha', align: 'center', cellsalign: 'center', cellsformat: 'dd/MM/yy HH:mm:ss' },
      { text: this.translate('Tipo'), datafield: 'Tipo', cellsalign: 'left', align: 'left' },
      { text: this.translate('Modelo'), datafield: 'Modelo', cellsalign: 'left', align: 'left' },
      { text: this.translate('Motivo'), datafield: 'Motivo', cellsalign: 'left', align: 'left' },
      { text: this.translate('Elemento'), datafield: 'Elemento', cellsalign: 'left', align: 'left' },
      { text: this.translate('Estado'), datafield: 'Estado', cellsalign: 'left', align: 'left' },
      { text: this.translate('USUARIO'), datafield: 'UsuarioEmail', cellsalign: 'left', align: 'left' },
      { text: this.translate('Observaciones'), datafield: 'Observaciones', cellsalign: 'left', align: 'left', },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];

    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'Id', map: 'Id', type: 'number' },
        { name: 'Fecha', map: 'Fecha', type: 'date' },
        { name: 'Tipo', map: 'Motivo>Modelo>Tipo>Nombre', type: 'string' },
        { name: 'Modelo', map: 'Motivo>Modelo>Nombre', type: 'string' },
        { name: 'Motivo', map: 'Motivo>Nombre', type: 'string' },
        { name: 'Estado', map: 'Estado>Nombre', type: 'string' },
        { name: 'Elemento', map: 'Elemento>Nombre', type: 'string' },
        { name: 'Observaciones', map: 'Observaciones', type: 'string' },
        { name: 'UsuarioEmail', map: 'Usuario>Email', type: 'string' },
        { name: 'Guid', map: 'Guid', type: 'string' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: [],
      sortcolumn: 'Fecha',
      sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    this.resizeColumns(this.incidencesGrid);
  }

  // Inicializa el control de búsqueda de la cabecera
  initSearchControl(): void {
    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      this.searchText = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      this.searchText = '';
    }
    // Marco los registros que cumplen la condición de búsqueda y pongo el campo
    // oculto a "selec"
    this.incidences.forEach(incidence => {
      if ((incidence.Id && incidence.Id.toString().toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Usuario && incidence.Usuario.Email && incidence.Usuario.Email.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Motivo.Modelo.Tipo.Nombre && incidence.Motivo.Modelo.Tipo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Motivo.Modelo.Nombre && incidence.Motivo.Modelo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Motivo.Nombre && incidence.Motivo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Estado.Nombre && incidence.Estado.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Usuario && incidence.Usuario.Nombre.toUpperCase().indexOf(this.searchText) > -1)
      ) {
        incidence.selec = 'selec';
      } else {
        incidence.selec = '';
      }
    });
    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.incidencesGrid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.incidencesGrid.addfilter('selec', filtergroup);
    }
    this.incidencesGrid.applyfilters();
    this.incidencesGrid.updatebounddata('data');

    this.resizeColumns(this.incidencesGrid);
  }

  //evento que se lanza al cambiar los checks del dropdownlist de estados
  onChangeEstados() {
    let selecteds = this.header.listEstados.getCheckedItems();
    this.selectedEstados = [];
    //no se almacena la selección de todos, ya que este es un estado auxiliar del front
    selecteds.forEach(option => {
      if (option.value != -1) {
        this.selectedEstados.push(option);
      }
    });
  }

  //cuando se hace check en alguna opción
  onCheckChange(event) {
    if (event.args.value != -1) {
      if (!this.isCheckedAll) {
        this.saveOptions();
      } else {
        if (!event.args.checked) {
          this.isPartialSelection = true;
          this.header.listEstados.uncheckIndex(0);
          this.saveOptions();
          this.isPartialSelection = false;
        } else {
          this.saveOptions();
        }
      }
    }
    //si se hace check en todos
    if (event.args.value == -1 && !this.isPartialSelection) {
      if (event.args.checked) {
        this.isCheckedAll = true;
        this.header.listEstados.checkAll();
        this.saveOptions();
      } if (!event.args.checked) {
        this.isCheckedAll = false;
        this.header.listEstados.uncheckAll();
        this.saveOptions();
      }
    }
  }

  saveOptions() {
    this.selectedEstados = [];
    let selecteds = this.header.listEstados.getCheckedItems();
    selecteds.forEach(option => {
      if (option.value != -1) {
        this.selectedEstados.push(option)
      }
    })

    if (this.selectedEstados.length == (this.header.listEstados.getItems().length - 1)) {
      this.header.listEstados.checkAll();
    }
  }

  onCloseCombo() {
    if (this.isCheckedAll) {
    }
  }
  // Cierro el formulario y destruyo el componente
  public onClose() {
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    GridIncidencesComponent._this = null;
  }
  // Cuando se destruye el componente
  ngOnDestroy() {
    if (this.markerIncidence) this.map.removeMarker(this.markerIncidence);
    try {
      GridIncidencesComponent.toolbarContainer.remove();
      GridIncidencesComponent.searchControl.remove();
    } catch (error) {

    }
  }

  //añadir marcador
  addMarkerIncidence() {
    if (this.markerIncidence) {
      this.map.removeMarker(this.markerIncidence);
    }
    this.markerIncidence = this.map.addMarker({
      dataModel: this.selectedIncidence,
      title: this.addMarkerContent(this.selectedIncidence),
      content: this.addMarkerContent(this.selectedIncidence),
      position: new MapLatLng(this.selectedIncidence.Lat, this.selectedIncidence.Lng),
      icon: '/assets/images/warning.png',
      zIndex: 999,
      drag: false,
      visible: true
    });
    this.map.setCenter(new MapLatLng(this.selectedIncidence.Lat, this.selectedIncidence.Lng));
    this.map.setZoom(14);

  }

  //añadir contenido al marcador
  addMarkerContent(incidence: IncidenciaModel) {
    let result = this.translate('Tipo') + ': ' + incidence.Motivo.Modelo.Tipo.Nombre + '<br>' +
      this.translate('Modelo') + ': ' + incidence.Motivo.Modelo.Nombre + '<br>' +
      this.translate('Motivo') + ': ' + incidence.Motivo.Nombre + '<br>' +
      this.translate('Estado') + ': ' + incidence.Estado.Nombre + '<br>' +
      this.translate('USUARIO') + ': ' + incidence.Usuario.Email + '<br>' +
      this.translate('Fecha') + ': ' + DateUtils.formatDateTime(new Date(incidence.Fecha), true) + '<br>' +
      this.translate('Observaciones') + ': ' + incidence.Observaciones + '<br>';

    return result;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }




}
