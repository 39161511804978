import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { MapCircle, MapLatLng } from 'movisat-maps';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from 'src/app/components/main/main.component';
import { BdtService, BDT_RECURSOS } from '../bdt/bdt.service';
import { ConfigService } from '../config/config.service';
import { SsoService } from '../sso/sso.service';
import { EntradaModel } from './models/zona-entrada.model';
import { ZonaModel } from './models/zona.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { Accion } from '../auditoria/models/accion.model';

export enum TiposMaster {
  ZONAADMIN = 1,
  MANUAL = 2,
  ELEMENTO = 3,
  EDIFICIOS = 4,
}
@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  public zonas = new Map<number, ZonaModel>();
  private zonasVisibles: ZonaModel[] = [];
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  private auditoriaFigura: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  // Evento para avisar que se ha cambiado el filtro de zonas visibles
  @Output() changeFilterEmiter: EventEmitter<void> = new EventEmitter();
  @Output() zonaEmit = new EventEmitter<ZonaModel>();

  constructor(private ssoService: SsoService,
    private http: HttpClient,
    private bdtService: BdtService,
    private configService: ConfigService,
    private auditoriaService: AuditoriaService) { }

  onSendZona(zona: ZonaModel) {
    this.zonaEmit.emit(zona);
  }
  getZona() {
    return this.zonaEmit;
  }

  async getDireccion(lat: number, lng: number): Promise<any> {
    let object: any = null;
    try {
      object = await this.http.get<any>(this.ssoService.getTicket().UrlApi + '/api/maps?mapType='+(this.ssoService.getTicket().Empresa.CartoId+1)+'&lat=' + lat + '&lng=' + lng).toPromise();
    } catch (error) {
      console.log(error);
    }
    return object;
  }

  //Crud entradas
  //crear entrada
  async addEntrada(entrada: EntradaModel): Promise<EntradaModel> {
    let response = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post<EntradaModel>(this.ssoService.getTicket().UrlApi + '/api/zonas/entradas/crear',
        JSON.stringify(entrada), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
      window.alert(e.error.message);
    }
    if (entrada.Id && response) {
      this.auditoria.AccionId = Accion.EDITAR_ENTRADA;
    } else if (response && !entrada.Id) {
      this.auditoria.AccionId = Accion.CREAR_ENTRADA;
    }
    this.msgChangeResponse(response);
    this.auditoriaService.addAuditoria(this.auditoria);
    return response;
  }

  //Borrar entrada
  async deleteEntrada(id: number) {
    try {
      await this.http.delete<void>(this.ssoService.getTicket().UrlApi + '/api/zonas/entradas/borrar/' + id).toPromise();
      this.auditoria.AccionId = Accion.ELIMINAR_ENTRADA;
      this.auditoria.Info = 'ID: ' + id;

      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      console.log(e);
    }
  }

  //Obtener entradas a partir del id de zona
  async getEntradas(id: number) {

    let result: EntradaModel[] = null;
    try {
      result = await this.http.get<EntradaModel[]>(this.ssoService.getTicket().UrlApi + '/api/zonas/entradas/' + id).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // Recupera todas las zonas
  async getZonas(): Promise<ZonaModel[]> {
    let result: ZonaModel[] = null;
    try {
      await this.bdtService.getRecursos(BDT_RECURSOS.Edificios);
      result = await this.http.get<ZonaModel[]>(this.ssoService.getTicket().UrlApi + '/api/zonas/' +
        this.ssoService.getTicket().Empresa.IdGestion).toPromise();
      if (result) {
        result.forEach(zone => {
          zone.TipoGeoNombre = zone.TipoGeo === 0 ? AppComponent.translate('Circulo') : AppComponent.translate('Poligono');
          //si es un edifio/instalacion, se asignan los nombres
          if (zone.Tipo == TiposMaster.EDIFICIOS && zone.TipoBDT != 0) {
            const recurso = BdtService.recursos.get(zone.TipoBDT);
            zone.TipoNombre = recurso ? recurso.Nombre : '';
          } else {
            switch (zone.Tipo) {
              case TiposMaster.ZONAADMIN:
                zone.TipoNombre = MainComponent.getInstance().translate('Zonas_administrativas');
                break;
              case TiposMaster.MANUAL:
                zone.TipoNombre = MainComponent.getInstance().translate('Manual');
                break;
              case TiposMaster.ELEMENTO:
                zone.TipoNombre = MainComponent.getInstance().translate('Asociada_elemento');
                break;
              case TiposMaster.EDIFICIOS:
                zone.TipoNombre = MainComponent.getInstance().translate('Edificios_instalaciones');
            }
          }
        });
      }
    } catch (e) {
    }
    return result;
  }

  // Almacena una zona
  async saveZona(zona: ZonaModel): Promise<ZonaModel> {
    let response: ZonaModel;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      zona.EmpresaId = this.ssoService.getTicket().Empresa.IdGestion;
      response = await this.http.post<ZonaModel>(this.ssoService.getTicket().UrlApi + '/api/zonas/crear',
        JSON.stringify(zona), httpOptions).toPromise();
      if (response) {
        response.TipoGeoNombre = response.TipoGeo === 0 ? AppComponent.translate('Circulo') : AppComponent.translate('Poligono');
        if (response.Tipo == TiposMaster.EDIFICIOS && response.TipoBDT != 0) {
          response.TipoNombre = BdtService.recursos.get(response.TipoBDT).Nombre;
        } else {
          switch (response.Tipo) {
            case TiposMaster.ZONAADMIN:
              response.TipoNombre = MainComponent.getInstance().translate('Zonas_administrativas');
              break;
            case TiposMaster.MANUAL:
              response.TipoNombre = MainComponent.getInstance().translate('Manual');
              break;
            case TiposMaster.ELEMENTO:
              response.TipoNombre = MainComponent.getInstance().translate('Asociada_elemento');
              break;
            case TiposMaster.EDIFICIOS:
              response.TipoNombre = MainComponent.getInstance().translate('Edificios_instalaciones');
          }
        }
        this.showZone(response);
      }
    } catch (e) {
      console.log(e);
    }
    if (zona.Id !== 0 && response) {
      this.auditoria.AccionId = Accion.EDITAR_ZONA;
      this.auditoriaFigura.AccionId = Accion.EDITAR_FIGURA_GEOMETRICA;

    } else if (response && !zona.Id) {
      this.auditoria.AccionId = Accion.CREAR_ZONA;
      this.auditoriaFigura.AccionId = Accion.CREAR_FIGURA_GEOMETRICA;
    }
    this.auditoria.Info = this.msgChangeResponse(response);
    this.msgChnageFigura(response);
    this.auditoriaService.addAuditoria(this.auditoria);
    this.auditoriaService.addAuditoria(this.auditoriaFigura);
    return response;
  }

  // Borra una zona
  async deleteZona(zonaId: number): Promise<void> {
    try {
      await this.http.delete<void>(this.ssoService.getTicket().UrlApi + '/api/zonas/borrar/' + zonaId).toPromise();
      this.hideZone(zonaId);
      this.auditoria.AccionId = Accion.ELIMINAR_ZONA;
      this.auditoria.Info = 'ID:' + zonaId;
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      console.log(e);
    }
  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.Id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response.Nombre;
  }

  msgChnageFigura(response): string {
    return this.auditoriaFigura.Info = '<div style="margin-left: 3px; margin-top: 2px"> ID:' + response.Id + MainComponent.getInstance().translate('Nombre') + response.TipoGeoNombre + '</div>';
  }


  // Aplico el filtro de zonas visibles en el mapa
  setFilterVisible() {
    this.changeFilterEmiter.emit();
  }

  // Recupera y muestra todas las zonas visibles en el mapa
  async loadZones() {
    // Recupero los filtros
    let select: any = await this.configService.getUsuEmpApp('zones-filter', null);
    if (select) {
      select = JSON.parse(select);
    } else {
      select = [];
    }
    const zonas = await this.getZonas();
    this.zonasVisibles.forEach(zona => {
      if (zona.circle) {
        zona.map.removeCircle(zona.circle)
      }
      if (zona.polygons) {
        zona.polygons.forEach(polygon => {
          zona.map.removePolygon(polygon);
        });
      }
    });
    this.zonasVisibles = [];
    if (zonas) {
      zonas.forEach(zona => {
        this.zonas.set(zona.Id, zona);
        if (select.find(s => s.id === zona.Id) !== undefined) {
          switch (zona.TipoGeo) {
            case 0: // Círculo
              zona.circle = this.newCircle(zona, select);
              this.zonasVisibles.push({ ...zona });
              break;
            case 1: // Polígono
              if (zona.Geometria) {
                zona.map = MainComponent.getActiveMap();
                zona.polygons = zona.map.addPolygonsFromGeoJson(zona.Geometria, false, {
                  dataModel: zona,
                  content: this.setContent(select, zona),
                  strokeColor: '#ff0000',
                  strokeOpacity: 0.3,
                  strokeWeight: 1,
                  fillColor: '#0000ff',
                  fillOpacity: 0.1,
                  editable: false
                });
              }
              this.zonasVisibles.push({ ...zona });
              break;
          }
        }
      });
    }
  }

  newCircle(zona: ZonaModel, select?): MapCircle {

    zona.map = MainComponent.getActiveMap();
    zona.circle = zona.map.addCircle({
      dataModel: zona,
      content: this.setContent(select, zona),
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 1,
      fillColor: '#0000ff',
      fillOpacity: 0.1,
      position: new MapLatLng(zona.Lat, zona.Lng),
      radius: zona.Radio,
      draggable: false,
      editable: false
    });
    return zona.circle;
  }

  showZone(zona: ZonaModel, select?: any) {
    this.hideZone(zona.Id);
    switch (zona.TipoGeo) {
      case 0: // Círculo
        zona.circle = this.newCircle(zona, select);
        this.zonasVisibles.push({ ...zona });
        break;
      case 1: // Polígono
        if (zona.Geometria) {
          zona.map = MainComponent.getActiveMap();
          zona.polygons = zona.map.addPolygonsFromGeoJson(zona.Geometria, false, {
            dataModel: zona,
            content: this.setContent(select, zona),
            strokeColor: '#ff0000',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#0000ff',
            fillOpacity: 0.1,
            editable: false
          });
          this.zonasVisibles.push({ ...zona });
        }
        break;
    }
  }

  setContent(select, zona): string {
    if (select) {
      return select.find(s => s.TextoVisible == true && s.id == zona.Id) ? zona.Nombre : ''
    } else {
      return '';
    }
  }

  hideZone(id: number) {
    for (let i = 0; i < this.zonasVisibles.length; i++) {
      if (this.zonasVisibles[i].Id === id) {
        if (this.zonasVisibles[i].circle) {
          this.zonasVisibles[i].map.removeCircle(this.zonasVisibles[i].circle)
        }
        if (this.zonasVisibles[i].polygons) {
          this.zonasVisibles[i].polygons.forEach(polygon => {
            this.zonasVisibles[i].map.removePolygon(polygon);
          });
        }
        this.zonasVisibles.splice(i, 1);
        break;
      }
    }
  }


}
