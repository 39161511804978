import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';

import { SsoService } from 'src/app/services/sso/sso.service';
import { AlarmsService } from 'src/app/services/alarms/alarms.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { SubflotaModel } from 'src/app/services/flota/models/subflota.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { ListadoAlarmasModel } from 'src/app/services/alarms/models/listado-alarmas.model';

import { PeriodoSelectComponent, PeriodoSelectTipo } from '../../../periodo-select/periodo-select.component';
import { Utils } from 'src/app/utils/utils';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
  selector: 'app-listado-alarmas',
  templateUrl: './listado-alarmas.component.html',
  styleUrls: ['./listado-alarmas.component.css']
})
export class ListadoAlarmasComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;
  @ViewChild('mainSplitter') mainSplitter: jqxSplitterComponent;
  @ViewChild('header') header: HeaderComponent;

  public static _this: ListadoAlarmasComponent;

  private componentRef = null;
  public environment = environment;

  // Variables para filtrado
  public fechaIni: Date;
  public fechaFin: Date;

  // Variables para generar el listado
  private informe: ListadoAlarmasModel[] = [];
  private movilesSelect: MovilModel[] = [];
  public movilesInforme: MovilModel[] = [];
  public subflotas: SubflotaModel[] = [];
  public dataSource: any = [];
  public dataAdapter = new jqx.dataAdapter(this.dataSource);
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  // Preparo las columnas del grid
  public columns: any[] = [];
  public columngroups = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  mapHeight: number;
  mapWidth: number;
  showLoader = true;

  constructor(
    private ssoService: SsoService,
    private alarmsService: AlarmsService, private auditoriaService: AuditoriaService) {
    super();
    ListadoAlarmasComponent._this = this;
  }





  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'horaEntrada':
      case 'horaSalida':
        if (value.getDate() !== new Date('0001-01-01T00:00:00').getDate()) {
          value = value ? DateUtils.formatTime(value) : '';
        } else {
          value = '';
        }
        //return `<div style="text-align:${columnproperties.cellsalign}"><span style="text-align:${columnproperties.cellsalign}" title="${value}">${value}</span> </div>`;
        return `<div style="margin-right: 4px; margin-top: 5px;  text-align: right;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
      case 'entrada':
      case 'salida':
        value = value ? 'X' : '';
        // return `<div style="text-align:${columnproperties.cellsalign}"><span style="text-align:${columnproperties.cellsalign}" title="${AppComponent.translate(value == 'X' ? 'SI' : 'NO')}">${value}</span> </div>`;
        return `<div style="margin-right: 4px; margin-top: 5px;  text-align: right;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
      case 'radio':
      case 'area':

        return `<div style="margin-right: 4px; margin-top: 5px;  text-align: right;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${NumberUtils.format(value, 0)}</div>`;
        value
      case 'motivo':
        //return `<span style="text-align:${columnproperties.cellsalign}" title="${AppComponent.translate(value)}">${AppComponent.translate(value)}</span>`;
        return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${AppComponent.translate(value)}</div>`;
    }
  }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Listado_alarmas_geo'));
    this.header.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();

    this.initListado();


    if (this.grid.getrows().length == 0) {
      this.grid.showemptyrow(false);
    }

  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    ListadoAlarmasComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Metodo para cerrar el splitter cuando le damos clic al boton aceptar
  generateEventSplitter() {
    this.mainSplitter.collapse();
  }

  rendexTextGeneric = (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {

    switch (columnfield) {
      case 'fecha':
        value = new Date(value);
        const formattedDate = DateUtils.formatDateTime(value, true);
        return `<div style="margin-right: 4px; margin-top: 5px;  text-align: right;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${formattedDate}</div>`;


      default:
        return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
    }

  }


  initListado() {
    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'movil', map: 'Movil>Nombre', type: 'string' },
        { name: 'motivo', map: 'MotivoAlarma>TraduccionId', type: 'string' },
        { name: 'fecha', type: 'date', map: 'Fecha' },
        { name: 'nombreAlarma', map: 'Alarma>Nombre', type: 'string' },
        { name: 'zona', type: 'string', map: 'Alarma>Zona>Nombre' },
        { name: 'radio', type: 'number', map: 'Alarma>Zona>Radio' },
        { name: 'area', type: 'number', map: 'Alarma>Zona>Area' },
        { name: 'dias', type: 'string', map: 'Alarma>Dias' },
        { name: 'entrada', type: 'string', map: 'Alarma>Entrada' },
        { name: 'horaEntrada', type: 'date', map: 'Alarma>HoraEntrada' },
        { name: 'salida', type: 'string', map: 'Alarma>Salida' },
        { name: 'horaSalida', type: 'date', map: 'Alarma>HoraSalida' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.informe
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);

    this.columngroups = [
      { text: AppComponent.translate('Alarmas_geograficas'), align: 'center', name: 'alarmGroup' },
      { text: AppComponent.translate('Zona'), align: 'center', name: 'zoneGroup' }
    ];
    this.columns = [
      {
        text: AppComponent.translate('Movil'), columntype: 'textbox', filtertype: 'textbox', datafield: 'movil', width: 180, cellsrenderer: this.rendexTextGeneric,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              NumberUtils.format(aggregates["Total"], 0) + '</div>';
          }
          return renderstring;
        },
      },
      { text: AppComponent.translate('Motivo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'motivo', width: 185, cellsrenderer: this.renderRow },
      { text: AppComponent.translate('Fecha'), columntype: 'date', filtertype: 'date', datafield: 'fecha', cellsformat: 'dd/MM/yy HH:mm:ss', width: 150, cellsrenderer: this.rendexTextGeneric },
      { text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', width: 140, datafield: 'nombreAlarma', columngroup: 'alarmGroup', cellsrenderer: this.rendexTextGeneric },
      { text: AppComponent.translate('Zona'), columntype: 'textbox', filtertype: 'textbox', datafield: 'zona', width: 140, columngroup: 'alarmGroup', cellsrenderer: this.rendexTextGeneric },
      { text: AppComponent.translate('Radio'), columntype: 'textbox', filtertype: 'textbox', datafield: 'radio', width: 55, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow, columngroup: 'alarmGroup' },
      { text: AppComponent.translate('Superficie'), columntype: 'textbox', filtertype: 'textbox', datafield: 'area', width: 80, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow, columngroup: 'alarmGroup' },
      { text: AppComponent.translate('Dias'), columntype: 'textbox', filtertype: 'textbox', datafield: 'dias', width: 70, align: 'center', cellsalign: 'center', columngroup: 'alarmGroup', cellsrenderer: this.rendexTextGeneric },
      { text: AppComponent.translate('E'), columntype: 'textbox', filtertype: 'textbox', datafield: 'entrada', width: 5, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow, columngroup: 'alarmGroup' },
      { text: AppComponent.translate('Hora_entrada'), columntype: 'textbox', filtertype: 'textbox', datafield: 'horaEntrada', width: 85, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow, columngroup: 'alarmGroup' },
      { text: AppComponent.translate('S'), columntype: 'textbox', filtertype: 'textbox', datafield: 'salida', width: 5, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow, columngroup: 'alarmGroup' },
      { text: AppComponent.translate('Hora_salida'), columntype: 'textbox', filtertype: 'textbox', datafield: 'horaSalida', width: 85, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow, columngroup: 'alarmGroup' },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];
    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    this.showLoader = false;
  }

  // Genera el informe con los datos obtenidos
  generaInforme() {
    if (this.informe !== undefined && this.informe !== null) {
      this.dataSource.localdata = this.informe;
    }
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.informe.forEach(inf => {
      if (
        inf?.Movil?.Nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        inf?.Movil?.Nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        inf?.MotivoAlarma?.Descripcion?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        inf?.Alarma?.Nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        inf?.Alarma?.Zona?.Nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (inf?.Alarma?.Zona?.Radio + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (inf?.Alarma?.Zona?.Area + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        inf['selec'] = 'selec';
      } else {
        inf['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    this.grid.applyfilters();
    this.grid.updatebounddata('data');
    this.grid.sortby('fecha', 'desc');

  }

  async onAceptar() {
    this.informe = [];
    this.header.searchInput['nativeElement'].value = '';
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaIni.setHours(0, 0, 0)
    this.fechaFin = this.header.periodoSelect.getFechaFin();
    this.fechaFin.setHours(23, 59, 59)
    const moviles: number[] = [];
    if (this.movilesSelect.length > 0) {
      this.movilesSelect.forEach(mov => {
        moviles.push(mov.Codigo);
      });
    } else {
      MainComponent.showWarning('ATENCION', 'Seleccione_movil', 2000);
      return;
    }
    // Recupero el listado de alarmas
    this.informe = await this.alarmsService.getListadoAlarmas(moviles, this.movilesSelect, this.fechaIni, this.fechaFin);
    if (this.grid.getrows().length == 0) {
      this.grid.showemptyrow(true);
    }
    this.generaInforme();
    this.generateEventSplitter();
    this.onBuscar();
    Utils.renderSizeGrid(this.grid, 500);
    this.grid.sortby('fecha', 'desc');
    this.showLoader = false
  }

  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.resetForm();
    this.header.searchInput['nativeElement'].value = '';
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    //actualiza grid
    this.onAceptar();
  }

  movilesSelected(mov: MovilModel[]) {
    this.movilesSelect = mov;
  }

  onExportar() {
    const json = this.grid.exportdata('json');
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(JSON.parse(json));
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + AppComponent.translate('Listado_alarmas_geo') + '.xlsx');
    this.auditoria.AccionId = Accion.EXPORTAR_INFORME_ALARMAS;
    this.auditoriaService.addAuditoria(this.auditoria);
  }

}
