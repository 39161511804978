import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Output, OnDestroy, Input, SimpleChanges } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { SsoService } from 'src/app/services/sso/sso.service';
import { FlotaService } from '../../services/flota/flota.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { SubflotaModel } from 'src/app/services/flota/models/subflota.model';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-moviles-select',
  templateUrl: './moviles-select.component.html',
  styleUrls: ['./moviles-select.component.css']
})
export class MovilesSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('grid') grid: jqxGridComponent;

  @Input() filterText = '';

  @Output() passArray = new EventEmitter;

  private select: MovilModel[] = [];

  public environment = environment;

  // Para la cabecera del grid
  private static toolbarContainer = null;
  private static searchControl = null;

  // Variables para generar el listado
  private moviles: MovilModel[] = []
  public subflotas: SubflotaModel[] = [];
  public movilesSubf: MovilModel[] = [];
  public dataSource;
  public dataAdapter;

  returnMoviles(): MovilModel[] {
    return this.moviles;
  }

  // Preparo las columnas del grid
  public columns = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
    { text: '', columntype: 'image', datafield: 'imagen', width: 25, cellsrenderer: this.imagerenderer },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 'auto',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private ssoService: SsoService,
    private resourcesService: ResourcesService,
    private flotaService: FlotaService
  ) { }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.getMoviles();
  }

  ngAfterViewInit(): void {
    this.getSubflotas();
  }

  // Detecta cambios en variables de entrada
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'filterText':
            this.filterMoviles();
            break;
          default:
            break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (MovilesSelectComponent.toolbarContainer) {
      MovilesSelectComponent.toolbarContainer.remove();
    }
  }

  expanded(event) {
  }

  Collapsed(event) {
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Obtiene los moviles
  private async getMoviles() {
    this.moviles = await this.resourcesService.getMoviles();
    if (this.moviles) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number', map: 'Codigo' },
          { name: 'imagen', type: 'image', map: 'ConjuntoVehiculo>Icono' },
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'selec', map: 'selec', type: 'string' }
        ],
        localdata: this.moviles,
        sortcolumn: 'nombre',
        sortdirection: 'asc'
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    } else {
      this.moviles = [];
    }
  }

  imagerenderer(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return value ? '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' + value + '"/>' :
      '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/car.png" />';
  }

  // Cada select/unselect recorre las columnas y seleccionados y los añade a un array
  onSelect() {
    this.select = [];
    const selec = this.grid.getselectedrowindexes();
    if(selec){
      selec.forEach(element => {
        this.select.push(this.moviles[element]);
      });
    }
    this.passArray.emit(this.select);
  }

  getSelection(): MovilModel[] {
    return this.select;
  }

  // Inicializa los controles de la cabecera del grid
  filterMoviles(): void {
    if(this.grid){
      this.moviles.forEach(mov => {
        if(
          mov?.Nombre?.toUpperCase().indexOf(this.filterText.toUpperCase()) > -1
        ){
          mov['selec'] = 'selec';
        }else{
          mov['selec'] = '';
        }
      });

      // Compruebo si ya he creado el filtro "selec" anteriormente
      const filters = this.grid.getfilterinformation();
      if (filters.find(s => s.datafield === 'selec') === undefined) {
        const filtergroup = new jqx.filter();
        filtergroup.operator = 'and';
        filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
        this.grid.addfilter('selec', filtergroup);
      }
      this.grid.applyfilters();
      this.grid.updatebounddata('data');
    }
  }

  // Rellena la lista de subflotas
  async getSubflotas() {
    const item = {
      id: 'todo',
      html: '<div style="height: 20px; float: left;">' +
        '<span style="float: left; font-size: 13px;">' + AppComponent.translate('Todas') + '</span></div>'
    };
    const item2 = {
      id: 0,
      html: '<div style="height: 20px; float: left;">' +
        '<span style="float: left; font-size: 13px;">' + AppComponent.translate('Resto_flota') + '</span></div>'
    };
    this.header.selectSubflotas.addItem(item);
    this.header.selectSubflotas.selectIndex(0);
    this.subflotas = await this.flotaService.getSubflotas();
    if (this.subflotas) {
      this.subflotas.forEach(subflota => {
        const item = {
          id: subflota.Id,
          html: '<div style="height: 20px; float: left;">' +
            '<span style="float: left; font-size: 13px;">' + subflota.Nombre + '</span></div>'
        };
        this.header.selectSubflotas.addItem(item);
      });
    }
    this.header.selectSubflotas.addItem(item2);
  }

  // Obtiene listado de subflotas
  async onChangeSubflotas(event: any) {
    let subflotaId = event.args.item.value['id'];
    this.grid.clearselection();
    let subAdapter = new jqx.dataAdapter(this.dataSource, {
      beforeLoadComplete: (records: any[]): void => { //Si el movil no coincide con el Id de la flota, se quita del array
        for (let i = 0; i < records.length; i++) {
          this.moviles.forEach(movil => {
            if (records[i]) {
              if (movil.Subflota.Id !== subflotaId && records[i]['id'] == movil['Codigo']) {
                records.splice(i, 1)
                i--
              }
            }
          })
        }
        records.sort((a, b) => (a.Nombre < b.Nombre) ? -1 : 1)
      }
    });
    // Si marca todas, no aplica ningún filtro
    if (subflotaId === 'todo') {
      subAdapter = new jqx.dataAdapter(this.dataSource)
    }
    this.grid.setOptions({ source: subAdapter });
  }
}
