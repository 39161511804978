import { Component, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';

import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';

import { Subscription } from 'rxjs';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxTextAreaComponent } from 'jqwidgets-ng/jqxtextarea';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { IncidencesService } from 'src/app/services/incidences/incidences.service';

import { TipoModel } from 'src/app/services/incidences/models/tipo.model';
import { ModeloModel } from 'src/app/services/incidences/models/modelo.model';
import { MotivoModel } from 'src/app/services/incidences/models/motivo.model';
import { EstadoModel } from 'src/app/services/incidences/models/estado.model';
import { ImagenModel } from 'src/app/services/incidences/models/imagen.model';
import { IncidenciaModel } from 'src/app/services/incidences/models/incidencia.model';
import { CambioEstadoModel } from 'src/app/services/incidences/models/cambioEstado.model';

@Component({
  selector: 'app-new-incidence',
  templateUrl: './new-incidence.component.html',
  styleUrls: ['./new-incidence.component.css']
})

export class NewIncidenceComponent extends CustomForms implements OnInit {
  @ViewChild('buttonContainer', { read: ViewContainerRef }) buttonContainer;
  @ViewChild('formIncidence') formIncidence: jqxWindowComponent;
  @ViewChild('textArea') textArea: jqxTextAreaComponent;
  @ViewChild('newIncidenceContainer', { read: ViewContainerRef }) newIncidenceContainer;
  @ViewChild('listTipos') listTipos: jqxDropDownListComponent;
  @ViewChild('listModelos') listModelos: jqxDropDownListComponent;
  @ViewChild('listMotivos') listMotivos: jqxDropDownListComponent;
  @ViewChild('listEstados') listEstados: jqxDropDownListComponent;
  @ViewChild('modal', { read: ViewContainerRef }) modal;
  @Output()
  cerrar = new EventEmitter<boolean>();

  public subscription = new Subscription;
  subscriptionOnMapClick: Subscription;
  subscriptionOnMarkerClick: Subscription;
  public environment = environment;
  private componentRef = null;
  private static instance: NewIncidenceComponent;
  private map: MapComponent;
  //input
  tipos: TipoModel[] = [];
  modelos: ModeloModel[] = [];
  motivos: MotivoModel[] = [];
  imagenes: ImagenModel[] = [];
  title: string = '';
  incidenceGuid: string = '';
  //dropdown data
  sourceTipos: any;
  adapterTipos: any;
  sourceModelos: any;
  adapterModelos: any;
  sourceMotivos: any;
  adapterMotivos: any;
  sourceEstados: any;
  adapterEstados: any;
  //models
  incidence: IncidenciaModel = new IncidenciaModel();
  estados: EstadoModel[];
  base64Images: Object[] = [];
  base64ImagesTemp: Object[] = [];
  //
  labelInput: string = this.translate('Ningun_archivo_seleccionado');
  labelHidden = true;
  sourceTextArea = [];
  value = '';
  hiddenState = true;
  markerIncidence: MapMarker;
  paramsIncidence = { TipoId: null, ModeloId: null, MotivoId: null, EstadoId: null, Lat: null, Lng: null, Observaciones: null, Imagenes: null };
  //upload
  image;
  textButton = this.translate('Seleccionar_archivos');
  contentTooltip = '';
  filesList: FileList;
  base64List: Object[] = [];
  openModal = false;
  indexSelected;
  changeState: boolean = false;

  constructor(private incidencesServices: IncidencesService) {
    super();
    NewIncidenceComponent.instance = this;
  }

  public static getInstance(): NewIncidenceComponent {
    return NewIncidenceComponent.instance;
  }

  ngOnInit(): void {
    //get map
    this.map = MainComponent.getInstance().getMap();
    //por ahora no se puede seleccionar un estado, por defecto es Abierta
    this.estados = this.estados.filter(estado => estado.Id == 1);
    //recibe la imagen del modal de edicion
    this.incidencesServices.outputImg.subscribe(img => {
      this.imagenes[this.indexSelected].Imagen = this.getBase64StringFromDataURL(img);
    })
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, tipos: TipoModel[], modelos: ModeloModel[],
    motivos: MotivoModel[], estados: EstadoModel[], guid: string, incidence: IncidenciaModel, titleWindow: string) {
    this.title = titleWindow;
    this.incidenceGuid = guid;
    this.componentRef = componentRef;
    this.tipos = tipos;
    this.incidence = incidence ? incidence : new IncidenciaModel();
    this.modelos = modelos;
    this.motivos = motivos;
    this.estados = estados;
  }

  ngAfterViewInit() {
    this.addCustomForm(this.formIncidence);
    this.formIncidence.title(this.title);
    setTimeout(() => {
      this.initDrops();
      this.loadIncidence();
      if (this.incidence.Id == 0) {
        this.incidence.EstadoId = this.estados.find(estado => estado.Nombre == 'Abierta') ? this.estados.find(estado => estado.Nombre == 'Abierta').Id : null;
      }
    }, 1000);

  }

  initDrops() {
    this.sourceTipos = {
      datatype: 'json',
      datafields: [
        { name: 'Nombre' },
        { name: 'Id' }
      ],
      id: 'Id',
      localdata: this.tipos
    };
    this.adapterTipos = new jqx.dataAdapter(this.sourceTipos);

    this.sourceModelos = {
      datatype: 'json',
      datafields: [
        { name: 'Nombre' },
        { name: 'Id' }
      ],
      id: 'Id',
      localdata: []
    };
    this.adapterModelos = new jqx.dataAdapter(this.sourceModelos);

    this.sourceMotivos = {
      datatype: 'json',
      datafields: [
        { name: 'Nombre' },
        { name: 'Id' }
      ],
      id: 'Id',
      localdata: []
    };
    this.adapterMotivos = new jqx.dataAdapter(this.sourceMotivos);

    this.sourceEstados = {
      datatype: 'json',
      datafields: [
        { name: 'Nombre' },
        { name: 'Id' }
      ],
      id: 'Id',
      localdata: this.estados
    };
    this.adapterEstados = new jqx.dataAdapter(this.sourceEstados);
  }



  //evento que se activa cuando cambia algun dropdown
  onChange(idDrop, event) {
    switch (idDrop) {
      case 0:
        //al seleccionar un tipo se cargan los modelos
        this.sourceModelos.localdata = this.modelos.filter(modelos => modelos.TipoId == event.args.item.originalItem.uid);
        //refresh data dropdow
        this.adapterModelos.dataBind();
        //asigna el tipoId a la incidencia
        this.incidence.TipoId = Number(event.args.item.originalItem.uid);
        //desbloquea el dropdown de modelos
        this.listModelos.disabled(false);
        //refresh data
        this.adapterMotivos.dataBind();
        //bloquea el dropdown de motivos
        this.listMotivos.disabled(false);
        this.paramsIncidence.TipoId = event.args.item.originalItem.uid;
        break;
      case 1:
        this.adapterMotivos.dataBind();
        this.sourceMotivos.localdata = this.motivos.filter(motivo => motivo.ModeloId == event.args.item.originalItem.uid);
        this.adapterMotivos.dataBind();
        this.incidence.ModeloId = Number(event.args.item.originalItem.uid);
        this.listMotivos.disabled(false);
        this.paramsIncidence.ModeloId = event.args.item.originalItem.uid;
        break;
      case 2:
        this.incidence.MotivoId = event.args.item.originalItem.uid;
        this.paramsIncidence.MotivoId = Number(event.args.item.originalItem.uid);
        break;
      // case 3:
      //   this.paramsIncidence.EstadoId = event.args.item.originalItem.uid;
      //   this.incidence.EstadoId = Number(event.args.item.originalItem.uid);
      //   break;
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.setMousePointer('');
      this.removeMarkerIncidence();
    }
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
    }
    if (this.subscriptionOnMarkerClick) {
      this.subscriptionOnMarkerClick.unsubscribe();
    }
  }

  // Cierro el formulario y destruyo el componente
  onClose() {
    this.cerrar.emit(true);
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }

  }


  refreshLabel() {
    //añadir texto con el número de archivos seleccionados
    this.labelInput = this.imagenes.length > 1 ? this.imagenes.length + ' ' + this.translate('Archivos_seleccionados') : this.imagenes.length + ' ' + this.translate('Archivo_seleccionado');
    this.labelInput = this.imagenes.length == 0 ? this.translate('Ningun_archivo_seleccionado') : this.labelInput;
  }

  //evento boton borrar
  onClickBorrar(id) {
    //borrar elemento de array
    this.imagenes.splice(id, 1);
    this.refreshLabel();
  }

  //evento boton guardar
  async onClickGuardar() {
    let error = this.errorParams();
    if (!error) {
      this.incidence.Imagenes = this.imagenes;
      this.incidence.Lat = this.markerIncidence.position.lat;
      this.incidence.Lng = this.markerIncidence.position.lng;
      let resultIncidence = await this.incidencesServices.createIncidence(this.incidence);

      if (resultIncidence) {
        MainComponent.showSuccess('ATENCION', 'Incidencia_guardada', 2000);
        //registrar el cambio de estado
        let cambioEstado = new CambioEstadoModel();
        cambioEstado.EstadoId = resultIncidence.EstadoId;
        cambioEstado.Fecha = resultIncidence.Fecha;
        cambioEstado.IncidenciaId = resultIncidence.Id;
        // si se crea una nueva, cambiar estado
        if (this.changeState) await this.incidencesServices.cambiarEstado(cambioEstado);

        //cerrar ventana
        this.formIncidence.close();
      } else {
        MainComponent.showError('ATENCION', 'Error_crear_incidencia', 2000);
      }

    } else {
      MainComponent.showError('ATENCION', error, 2000);
    }


  }

  async createIncidence() {
    let result = await this.incidencesServices.createIncidence(this.incidence);
  }
  //evento botón posicionar
  onClickPosicionar() {
    this.startPositioning();
  }


  endPositioning() {
    this.map.setMousePointer('');
    this.removeMarkerIncidence();
    this.addMarkerIncidenceCreate();
  }
  startPositioning() {
    this.formIncidence.collapse();
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
    }
    if (this.subscriptionOnMarkerClick) {
      this.subscriptionOnMarkerClick.unsubscribe();
    }
    this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onMapClick);
    this.subscriptionOnMarkerClick = this.map.subscribeOnMarkerClick(this, this.onMarkerClick);
    // Cambio el puntero del ratón sobre el mapa
    // this.map.setMousePointer('assets/images/center.png');
  }

  input(event) {
    this.value = this.textArea.val();
    this.incidence.Observaciones = this.value;
  }
  //click en coordenada
  onMapClick(_this: NewIncidenceComponent, position: MapLatLng) {
    _this.incidence.ElementoId = 0;
    _this.incidence.Lat = position.lat;
    _this.paramsIncidence.Lat = position.lat;
    _this.incidence.Lng = position.lng;
    _this.paramsIncidence.Lng = position.lng;
    _this.subscriptionOnMapClick.unsubscribe();
    _this.subscriptionOnMarkerClick.unsubscribe();
    _this.formIncidence.expand();
    _this.endPositioning();
  }

  //click en marcador
  onMarkerClick(_this: NewIncidenceComponent, marker: MapMarker) {
    _this.incidence.ElementoId = marker.dataModel.Id;
    _this.incidence.Lat = marker.dataModel.Lat;
    _this.incidence.Lng = marker.dataModel.Lng;
    _this.paramsIncidence.Lat = marker.dataModel.Lat;
    _this.paramsIncidence.Lng = marker.dataModel.Lng;
    _this.subscriptionOnMapClick.unsubscribe();
    _this.subscriptionOnMarkerClick.unsubscribe();
    _this.formIncidence.expand();
    _this.endPositioning();
  }

  addMarkerIncidenceCreate() {
    this.markerIncidence = this.map.addMarker({
      dataModel: this.incidence,
      title: '',
      content: '',
      position: new MapLatLng(this.incidence.Lat, this.incidence.Lng),
      icon: '/assets/images/warning.png',
      zIndex: 999,
      drag: true,
      visible: true
    });
  }

  addMarkerIncidence() {
    if (this.markerIncidence) {
      this.map.removeMarker(this.markerIncidence);
    }
    this.markerIncidence = this.map.addMarker({
      dataModel: this.incidence,
      title: this.addMarkerContent(this.incidence),
      content: this.addMarkerContent(this.incidence),
      position: new MapLatLng(this.incidence.Lat, this.incidence.Lng),
      icon: '/assets/images/warning.png',
      zIndex: 999,
      drag: true,
      visible: true
    });
  }
  //añadir contenido al marcador
  addMarkerContent(incidence: IncidenciaModel) {
    let result = this.translate('Tipo') + ': ' + incidence.Motivo.Modelo.Tipo.Nombre + '<br>' +
      this.translate('Modelo') + ': ' + incidence.Motivo.Modelo.Nombre + '<br>' +
      this.translate('Motivo') + ': ' + incidence.Motivo.Nombre + '<br>' +
      this.translate('Estado') + ': ' + incidence.Estado.Nombre + '<br>' +
      this.translate('USUARIO') + ': ' + incidence.Usuario.Email + '<br>' +
      this.translate('Fecha') + ': ' + DateUtils.formatDateTime(new Date(incidence.Fecha), true) + '<br>' +
      this.translate('Observaciones') + ': ' + incidence.Observaciones + '<br>';

    return result;
  }

  removeMarkerIncidence() {
    if (this.markerIncidence) {
      this.map.removeMarker(this.markerIncidence);
    }
  }

  //checkea los parámetros de la incidencia
  errorParams() {
    let error = undefined;
    if (!this.paramsIncidence.Lat || !this.paramsIncidence.Lng) error = 'Error_posicion';
    if (!this.paramsIncidence.MotivoId) error = 'Selecciona_motivo';
    if (!this.paramsIncidence.ModeloId) error = 'Selecciona_modelo';
    if (!this.paramsIncidence.TipoId) error = 'Selecciona_tipo';
    return error;
  }

  /////////////////////////////////////editar incidencias/////////////////////

  //cargar datos de la incidencia
  async loadIncidence() {
    //si no hay incidencia seleccionada, sale de la funcion
    if (this.incidenceGuid == '') {
      this.changeState = true;
      this.hiddenState = true;
      return;
    }
    this.hiddenState = false;
    //cargar datos de la incidencia en los dropdownlists
    let incidenceImages = await this.incidencesServices.getIncidenceByGuid(this.incidence.Guid);
    this.incidence.Imagenes = incidenceImages.Imagenes;
    let indexTipo = this.listTipos.getItemByValue(this.incidence.TipoId.toString())['index'];
    this.listTipos.selectIndex(indexTipo);
    let indexModelo = this.listModelos.getItemByValue(this.incidence.ModeloId.toString())['index'];
    this.listModelos.selectIndex(indexModelo);
    // let indexEstado = this.listEstados.getItemByValue(this.incidence.EstadoId.toString())['index'];
    // this.listEstados.selectIndex(indexEstado);
    let indexMotivo = this.listMotivos.getItemByValue(this.incidence.MotivoId.toString())['index'];
    this.listMotivos.selectIndex(indexMotivo);
    //mostrar observaciones
    this.textArea.val(this.incidence.Observaciones);
    //las imagenes vienen del servidor sin nombre, se  le asigna un nombre '' para que se pueda mostrar
    this.incidence.Imagenes.forEach(imagen => {
      imagen.Nombre = '';
    });
    //mostar imagenes
    this.imagenes = this.incidence.Imagenes;
    this.paramsIncidence.Lat = this.incidence.Lat;
    this.paramsIncidence.Lng = this.incidence.Lng;
    this.map.setCenter(new MapLatLng(this.incidence.Lat, this.incidence.Lng));
    this.addMarkerIncidence();
    this.refreshLabel();
  }



  ///////////////////////upload//////////////////////////////////////////////
  uploadFiles(event) {
    this.filesList = event.files;
    this.fileToBase64(this.filesList);
  }

  //crea un string para mostrar los nombres de los archivos seleccionados
  listFilesNames(fileList: FileList) {
    let fileNames = '';
    for (let i = 0; i < fileList.length; i++) {
      fileNames += fileList[i].name + '<br>';
    }
    return fileNames;
  }

  //pasar tipo file a BASE64
  async fileToBase64(files: FileList) {

    let arr = [];
    Array.prototype.forEach.call(files, function (file) {
      arr.push(file);
    });

    await Promise.all(arr.map(async file => {
      let imagen = new ImagenModel();
      imagen.Nombre = file.name;
      imagen.Id = 0;
      let data = await this.readFile(file);
      imagen.Imagen = this.getBase64StringFromDataURL(data);
      this.imagenes.push(imagen);
    }));
    this.refreshLabel();

  }


  readFile(file) {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  getBase64StringFromDataURL(dataUrl) {
    return dataUrl.replace('data:', '').replace(/^.+,/, '');
  }

  //evento cuando se cancela la selección de archivos
  cancelSelection() {
    this.labelHidden = true;
    this.filesList = undefined;
    this.base64List = [];
  }


  onClickImage(i) {
    this.indexSelected = i;
    //servicio para abrir el modal de edición de imágenes
    this.incidencesServices.onSelectImage(this.imagenes[i].Imagen);
  }

}



