import { Component, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';

import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { VolumService } from 'src/app/services/volumetricos/volum.service';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { OperadorModel } from 'src/app/services/cerraduras/models/operador.model';
import { CerraduraModel } from 'src/app/services/cerraduras/models/cerradura.model';
import { VolumLectruraModel } from 'src/app/services/volumetricos/models/volum-lectura.model';

import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';
import { PeriodoSelectComponent } from 'src/app/components/periodo-select/periodo-select.component';
import { Utils } from 'src/app/utils/utils';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-elements-edit-perifericos',
  templateUrl: './elements-edit-perifericos.component.html',
  styleUrls: ['./elements-edit-perifericos.component.css', '../elements-edit.component.css']
})
export class ElementsEditPerifericosComponent implements OnInit {
  @ViewChild('fieldPerifericoCerradura') fieldPerifericoCerradura;
  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;

  @Input() elemEdit: ElementoModel;
  @Input() expanded: boolean = true;
  @Input() closed: boolean = false;

  ejecutado: boolean = false;

  public localization = 'es-ES';
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  constructor(
    private volumService: VolumService,
    private cerraduraService: CerraduraService,
    private modal: NzModalService,
  ) { }

  ngOnInit(): void {
    this.getCatalogoCerradurasWithoutElement();
  }

  ngAfterViewInit(): void {
    this.periodoSelect.setPeriodo(1);
    this.periodoSelect.setSemanaAnterior(new Date());

    this.addItemOperador();
    this.getCerraduraByElement(this.elemEdit);

    if(this.dropCerraduras && this.elemEdit && this.elemEdit.cerradura && this.elemEdit.cerradura.nsMovisat){
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">'+this.elemEdit.cerradura.nsMovisat+'</div>';
      this.dropCerraduras.setContent(content);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;

            if(this.dropCerraduras && this.elemEdit && this.elemEdit.cerradura && this.elemEdit.cerradura.nsMovisat){
              let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">'+this.elemEdit.cerradura.nsMovisat+'</div>';
              this.dropCerraduras.setContent(content);
            }
            break;
          }
          case 'expanded': {
            this.expanded = changes[propName].currentValue;
            if (!this.expanded) {
              this.dropCerraduras.close();
            }
            break;
          }
          case 'closed': {
            this.closed = changes[propName].currentValue;
            if (this.closed){
              this.dropCerraduras.close();
              if (this.gridDropCerraduras) {
                this.gridDropDownCerraduras.destroy();
              }
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  formatDate(fecha) {
    if(fecha) {
      return DateUtils.formatDateTimeShort(fecha, true);
    }else {
      return null;
    }
  }

  /* APARTADO GRAFICO LECTURAS */
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;
  lecturas: VolumLectruraModel[];
  public seriesGroups: any[] = null;
  xAxis: any;
  toolTipCustomFormatTemperatura = (value: any, itemIndex: any, serie: any, group: any, categoryValue: any, categoryAxis: any): string => {
    return '<DIV style="text-align:left"><b>Lectura: </b>' + DateUtils.formatDateTimeShort(categoryValue, true) + '<br />' +
      '<b>Temperatura: </b>' + value + '</DIV>';
  };

  toolTipCustomFormatLlenado = (value: any, itemIndex: any, serie: any, group: any, categoryValue: any, categoryAxis: any): string => {
    return '<DIV style="text-align:left"><b>Lectura: </b>' + DateUtils.formatDateTimeShort(categoryValue, true) + '<br />' +
      '<b>Llenado: </b>' + value + '</DIV>';
  };

  async getLecturas() {
    let dateInicio = this.periodoSelect.getFechaIni();
    let dateFin = this.periodoSelect.getFechaFin();

    this.lecturas = await this.volumService.getLecturasByVolum(this.elemEdit.ImeiVolum, dateInicio, dateFin);

    this.seriesGroups = [
      {
        type: 'stackedline',
        source: this.lecturas,
        toolTipFormatFunction: this.toolTipCustomFormatTemperatura,
        series: [
          {
            dataField: 'Temperatura', displayText: this.translate('Temperaturas') + ' (ºC)'
          }
        ]
      },
      {
        type: 'stackedline',
        source: this.lecturas,
        toolTipFormatFunction: this.toolTipCustomFormatLlenado,
        series: [
          {
            dataField: 'Porcentaje', displayText: this.translate('Llenado') + ' (%)'
          }
        ]
      }
    ]

    this.xAxis = {
      dataField: 'FechaLectura',
      formatFunction: (value: any) => {
        return DateUtils.formatDateTimeShortTwoLines(value, true);
      },
      type: 'date',
      labels: {
        angle: -55,
        rotationPoint: 'topright',
        offset: { x: 0, y: -25 }
      }
    }
  }
  /* FIN APARTADO GRAFICO LECTURAS */

  /* APARTADO CERRADURA */
  @ViewChild('dropCerraduras') dropCerraduras: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownCerraduras') gridDropDownCerraduras: jqxGridComponent;
  @ViewChild('jqxDropDownList') jqxDropDownList: jqxDropDownListComponent;

  public gridDropCerraduras: boolean;

  public cerradura: CerraduraModel = new CerraduraModel();

  cerraduras: CerraduraModel[];
  cerraduraSelect: CerraduraModel;
  columnsCerradura: any[] =
  [
    { text: 'ID', datafield: 'Id', hidden: true },
    { text: this.translate('Serie_identificador'), datafield: 'nsMovisat', width: '100%' },
  ];
  private sourceCerraduras: any = [];
  public dataAdapterCerraduras = new jqx.dataAdapter(this.sourceCerraduras);


  onCloseDropCerraduras(event) {
    if (this.gridDropDownCerraduras) {
      this.gridDropCerraduras = true;
    }
  }

  onOpenDropCerraduras(event) {
    if (this.gridDropCerraduras) {
      this.gridDropCerraduras = false;
    }
  }

  /* Obtiene la cerradura del elemento */
  async getCerraduraByElement(elemento: ElementoModel): Promise<void> {
    if (elemento && elemento.cerradura && elemento.cerradura.id > 0) {
      this.cerradura = elemento.cerradura;
    } else {
      this.cerradura = new CerraduraModel();
    }

    if (this.jqxDropDownList) {
      this.jqxDropDownList.disabled(true);
    }
  }

  /* Obtiene las cerraduras sin elemento */
  async getCatalogoCerradurasWithoutElement() {
    this.cerraduras = await this.cerraduraService.getCerradurasWithoutElement();
    if (this.cerraduras) {
      this.sourceCerraduras = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'number', map: 'id' },
          { name: 'nsMovisat', type: 'string', map: 'nsMovisat' }
        ],
        localdata: this.cerraduras
      };
      this.dataAdapterCerraduras = new jqx.dataAdapter(this.sourceCerraduras);

      try {
        Utils.renderSizeGrid(this.gridDropDownCerraduras, 500);
      } catch (error) {

      }
    }
  }

  onRowClickCerradura(event: any) {
    this.cerraduraSelect = this.cerraduras[event.args.rowindex];
    this.elemEdit.cerradura = this.cerraduraSelect;
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
      this.elemEdit.cerradura.nsMovisat + '</div>';
    this.dropCerraduras.setContent(content);
    this.dropCerraduras.close();
  }


  //Desasociar cerradura del elemento
  async disassociateCerraduraFromElemento() {
    this.modal.confirm({
      nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
      nzContent: AppComponent.translate('Quieres_desasociar_cerradura'),
      nzCentered: true,
      nzCancelText: AppComponent.translate('CANCELAR'),
      nzOkText: AppComponent.translate('SI'),
      nzOnOk: async () => {
        let asociacion: any = {
          "idCerradura": this.elemEdit.cerradura.id,
          "idElemento": this.elemEdit.Id,
          "fecha": new Date()
        };

        this.cerradura = null;
        this.elemEdit.cerradura = null;

        this.cerraduraService.disassociateCerraduraFromElement(asociacion);

        let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;"></div>';
        this.dropCerraduras.setContent(content);
      }
    });
  }
  /* FIN APARTADO CERRADURA */

  /* APARTADO OPERADORES */
  private operadores: OperadorModel[] = [];

  addItemOperador() {
    if (this.jqxDropDownList) {
      let Movistar: OperadorModel = {
        Id: 1,
        Nombre: 'Movistar',
      }
      let Vodafone: OperadorModel = {
        Id: 2,
        Nombre: 'Vodafone',
      }
      let Orange: OperadorModel = {
        Id: 3,
        Nombre: 'Orange',
      }

      this.operadores.push(Movistar, Vodafone, Orange);

      if (this.operadores.length > 0) {
        this.operadores.forEach((operador, index) => {
          this.jqxDropDownList.addItem({ label: operador.Nombre, value: operador.Id });
        });
      }

      if (this.elemEdit.cerradura) {
        let id: number = this.elemEdit.cerradura.operadorTelefonia.Id;

        if (id == 1) {
          this.jqxDropDownList.selectIndex(0);
        }
        if (id == 2) {
          this.jqxDropDownList.selectIndex(1);
        }
        if (id == 3) {
          this.jqxDropDownList.selectIndex(2);
        }
      }
    }
  }

  selectProveedores(event: any) {
    event.args.item.value = this.cerradura.operadorTelefonia
  }
  /* FIN APARTADO OPERADORES */
}
