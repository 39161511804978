import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { jqxChartModule } from 'jqwidgets-ng/jqxchart';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';

import { LecVolumGraphComponent } from './volum/lec-volum-graph/lec-volum-graph.component';

@NgModule({
  declarations: [
    LecVolumGraphComponent
  ],
  imports: [
    CommonModule,
    jqxChartModule,
    jqxWindowModule,
  ]
})
export class GraphicsModule { }
