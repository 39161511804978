import { ElemInstalacionDetalleModel } from "./elem-instalacion-detalle.model";

export class ElemInstalacionModel {
  detalle: ElemInstalacionDetalleModel[] = [];
  empresa: number;
  fechaBajar: string;
  fechaCreacion: string;
  fechaModificacion: string;
  guid: string;
  id: number;
  idElemento: number;
  idEstado: number;
  observaciones: string;
  requiereInstalacionCerradura: Boolean;
  requiereInstalacionIdentificador: Boolean;
  requiereInstalacionTag: Boolean;
  requiereInstalacionVolumetrico: Boolean;
}
