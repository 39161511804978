import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { jqxInputModule } from 'jqwidgets-ng/jqxinput';

import { InputNumberComponent } from './input-number.component';

@NgModule({
  declarations: [
    InputNumberComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    jqxInputModule
  ],
  exports: [
    InputNumberComponent
  ]

})
export class InputNumberModule { }
