import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IncidenciaModel } from './models/incidencia.model';
import { SsoService } from '../sso/sso.service';
import { TipoModel } from './models/tipo.model';
import { ModeloModel } from './models/modelo.model';
import { MotivoModel } from './models/motivo.model';
import { ElementoModel } from '../elements/models/elem.model';
import { CambioEstadoModel } from './models/cambioEstado.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { Accion } from '../auditoria/models/accion.model';
import { MainComponent } from 'src/app/components/main/main.component';

@Injectable({
  providedIn: 'root'
})
export class IncidencesService {
  public selectedImg: EventEmitter<Object> = new EventEmitter();
  public outputImg: EventEmitter<string> = new EventEmitter();
  private empresaId;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  constructor(private http: HttpClient, private ssoService: SsoService, private auditoriaService: AuditoriaService) {
    this.empresaId = this.ssoService.getTicket().Empresa.IdGestion;
    // this.empresaId = 607;
  }

  // devuelve las incidencias filtradas por fecha y por empresa
  async getIncidencesByDate(startDate: Date, endDate: Date): Promise<IncidenciaModel[]> {
    let response;

    try {

      const json = {
        empresaId: this.empresaId,
        Desde: startDate,
        Hasta: endDate
      }
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      response = await this.http.post<IncidenciaModel[]>(this.ssoService.getTicket().UrlApi + '/api/incidencias',
        JSON.stringify(json), httpOptions).toPromise();

    } catch (e) {
      console.log(e);
    }
    return response;

  }
  //traer incidencia a partir de un guid
  async getIncidenceByGuid(guid: string): Promise<IncidenciaModel> {
    let response;
    try {
      response = await this.http.get<IncidenciaModel>(this.ssoService.getTicket().UrlApi + '/api/incidencia/' + guid).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  //devuelve los tipos de incindecia
  async getTipos(): Promise<TipoModel[]> {
    let response;
    try {
      response = await this.http.get<TipoModel[]>(this.ssoService.getTicket().UrlApi + '/api/incidencias/tipos/' + this.empresaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  //devuelve los modelos de incidencia
  async getModelos(idTipo: number): Promise<ModeloModel[]> {
    let response;
    try {
      response = await this.http.get<ModeloModel[]>(this.ssoService.getTicket().UrlApi + '/api/incidencias/modelos/' + this.empresaId + '/' + idTipo + '/').toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }


  //devuelve los motivos de incidencia
  async getMotivos(idModelo: number): Promise<MotivoModel[]> {
    let response;
    try {
      response = await this.http.get<MotivoModel[]>(this.ssoService.getTicket().UrlApi + '/api/incidencias/motivos/' + this.empresaId + '/' + idModelo + '/').toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  //devuelve los estados de incidencia
  async getEstados(): Promise<any> {
    let response;
    try {
      response = await this.http.get<any>(this.ssoService.getTicket().UrlApi + '/api/incidencias/estados/' + this.empresaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  //devuelve un elemento a partir de un id
  async getElement(idElement: number): Promise<ElementoModel> {
    let response;
    try {
      response = await this.http.get<ElementoModel>(this.ssoService.getTicket().UrlApi + '/api/elemento/' + idElement).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }



  //crear o editar incidencia
  async createIncidence(incidencia: IncidenciaModel) {
    //si el id es 0, es una nueva incidencia
    if (incidencia.EmpresaId == 0) incidencia.EmpresaId = this.empresaId;
    //se añade el usuario que crea la incidencia
    if (incidencia.UsuarioId == 0) incidencia.UsuarioId = this.ssoService.getTicket().Usuario.Id;
    //se añade la fecha de creación
    let dateNow = Date.now();
    let date = new Date(dateNow);
    incidencia.Fecha = date.toJSON();
    delete incidencia.Elemento;
    delete incidencia.Motivo;
    delete incidencia.Estado;
    delete incidencia.Usuario;
    let response;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post<IncidenciaModel>(this.ssoService.getTicket().UrlApi + '/api/incidencias/crear'
        , JSON.stringify(incidencia), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    if (incidencia.Id && response) {
      this.auditoria.AccionId = Accion.EDITAR_INCIDENCIA
    } else if (response && !incidencia.Id) {
      this.auditoria.AccionId = Accion.CREAR_INCIDENCIA;
    }
    this.auditoriaService.addAuditoria(this.auditoria);
    return response;
  }

  async deleteIncidence(guid: string) {
    let response;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.delete<IncidenciaModel>(this.ssoService.getTicket().UrlApi + '/api/incidencias/borrar/' + guid, httpOptions).toPromise();
      this.auditoria.AccionId = Accion.ELIMINAR_INCDENCIA;
      this.auditoria.Info = 'GUID: ' + guid;
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  //cambiar estado de una incidencia
  async cambiarEstado(cambioEstado: CambioEstadoModel) {
    //si el id es 0, es una nueva incidencia
    if (cambioEstado.EmpresaId == 0) cambioEstado.EmpresaId = this.empresaId;
    //se añade el usuario que crea la incidencia
    if (cambioEstado.UsuarioId == 0) cambioEstado.UsuarioId = this.ssoService.getTicket().Usuario.Id;
    //se añade la fecha de creación
    let dateNow = Date.now();
    let date = new Date(dateNow);
    let response: IncidenciaModel = null;;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post<IncidenciaModel>(this.ssoService.getTicket().UrlApi + '/api/incidencias/estados/crear'
        , JSON.stringify(cambioEstado), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
      return null;
    }
    if (response && cambioEstado.EstadoNombre) {
      this.auditoria.AccionId = Accion.CAMBIO_DE_ESTADO_DE_INCIDENCIA;
      this.auditoria.Info = 'ID: ' + response.Id + ',' + cambioEstado.EstadoNombre + '-->' + response.Estado;
      this.auditoriaService.addAuditoria(this.auditoria);
    }

    return response;
  }

  //trae registro de cambios de las incidencias
  async getHistoricStates(startDate: Date, endDate: Date): Promise<CambioEstadoModel[]> {
    let response;

    try {

      const json = {
        empresaId: this.empresaId,
        Desde: startDate,
        Hasta: endDate
      }
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      response = await this.http.post<CambioEstadoModel[]>(this.ssoService.getTicket().UrlApi +
        '/api/incidencias/estados/historico',
        JSON.stringify(json), httpOptions).toPromise();

    } catch (e) {
      console.log(e);
    }
    return response;

  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.Id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response.Nombre;
  }


  //evento cuando se selecciona ampliar imagen (el modal se suscribe)
  onSelectImage(imageSrc) {
    let obj = { open: true, src: imageSrc }
    this.selectedImg.emit(obj);
  }

  //envía la imagen rotada desde el modal al componente
  onOutputImage(img) {
    this.outputImg.emit(img);
  }
}
