import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SsoService } from '../sso/sso.service';
import { BdtCatalogoEquipamientoModel } from './models/bdt-catalogo-equipamiento.model';
import { BdtEquipamientoModel } from './models/bdt-equipamiento.model';
import { BdtRecursoModel } from './models/bdt-recurso.model';

export enum BDT_RECURSOS {
   Vehiculos = 1,
   Maquinas = 3,
   Perifericos = 4,
   Gls = 5,
   Sensores = 6,
   Software = 7,
   MandosIntermedios = 8,
   Encargados = 9,
   Empleados = 10,
   Edificios = 116,
   Servicios = 141
}

export enum BDT_ELEMENTOS {
   generico = 162
}

@Injectable({
   providedIn: 'root'
})
export class BdtService {
   public static catEquipamiento = new Map<number, BdtCatalogoEquipamientoModel>();
   public static equipamiento = new Map<number, BdtEquipamientoModel>();
   public static recursos = new Map<number, BdtRecursoModel>();
   public elemGenericos = false;

   constructor(private ssoService: SsoService, private http: HttpClient) { }

   // Recupera el catálogo de elementos
   async getCatalogoEquipamiento(): Promise<BdtCatalogoEquipamientoModel[]> {
      let result: BdtCatalogoEquipamientoModel[] = [];
      try {
         // if (BdtService.catEquipamiento.size < 1) {
         result = await this.http.get<BdtCatalogoEquipamientoModel[]>(
            this.ssoService.getTicket().UrlApi + '/api/elementos/catalogo?empresaId=' +
            this.ssoService.getTicket().Empresa.IdGestion).toPromise();
         if (result) {
            result.forEach(reg => {
               reg.Equipamiento.Capacidad = reg.Equipamiento.CapacidadLitros > 0 ?
                  reg.Equipamiento.CapacidadLitros : reg.Equipamiento.CapacidadKilos;
               if (reg.Equipamiento.Elemento.TipoId === BDT_ELEMENTOS.generico) {
                  reg.Unidades = Number.MAX_VALUE;
               }
               if (reg.Equipamiento.Elemento.TipoId === BDT_ELEMENTOS.generico) {
                  this.elemGenericos = true;
               }
               reg.Equipamiento.ResiduosNombres = '';
               if (reg.Equipamiento.Residuos && reg.Equipamiento.Residuos.length > 0) {
                  reg.Equipamiento.Residuos.forEach((res, i) => {
                     reg.Equipamiento.ResiduosNombres += (i > 0 ? ' | ' + res.Nombre : res.Nombre);
                  });
               }
               BdtService.catEquipamiento.set(reg.Id, reg);
               BdtService.equipamiento.set(reg.Equipamiento.Id, reg.Equipamiento);
            });
         }
      } catch (e) {
         console.log(e);
      }
      return result;
   }

   // Recupera el catálogo de recursos (tipo=0 -> todos)
   async getRecursos(tipo: number): Promise<BdtRecursoModel[]> {
      let result: BdtRecursoModel[] = [];
      try {
         if (BdtService.recursos.size < 1) {
            const res: any = await this.http.get(this.ssoService.getTicket().UrlApi + '/api/bdt/recursos?include=all').toPromise();
            if (res) {
               res.forEach(reg => {
                  if (tipo < 1 || reg.tipo_id === tipo) {
                     const recurso = new BdtRecursoModel(reg.id, reg.codigo, reg.acronimo,
                        reg.nombre, reg.tipo_id);
                     result.push(recurso);
                     BdtService.recursos.set(recurso.Id, recurso);
                  }
               });
            }
         } else {
            return new Promise<BdtRecursoModel[]>((resolve, reject) => {
               for (const [key, value] of BdtService.recursos) {
                  result.push(value);
               }
               resolve(result);
            });
         }
      } catch (e) {
         console.log(e);
      }
      return result;
   }

   async sincronizaBDT(): Promise<void> {
      try {
         await this.http.get(this.ssoService.getTicket().UrlApi + '/api/bdt/sincronizar/all?enterprise=' +
            this.ssoService.getTicket().Empresa.IdGestion + "&include=all").toPromise();
      } catch (e) {
         console.log(e);
      }
   }

}
