import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

@Component({
  selector: 'app-elements-edit-instalaciones',
  templateUrl: './elements-edit-instalaciones.component.html',
  styleUrls: ['./elements-edit-instalaciones.component.css', '../elements-edit.component.css']
})
export class ElementsEditInstalacionesComponent implements OnInit {
  @Input() elemEdit: ElementoModel;

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  selectedImg = null;

  public editTag: boolean = true;
  public editEcoLock: boolean = true;
  public editVolumetrico: boolean = true;

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() { }

  ngOnInit(): void {
    this.initGridTipoVolumentrico();
    this.initGridIncidenciasVolum();
    this.initGridTipoTag();
    this.initGridIncidenciasTag();
    this.initGridIncidenciasEcolock();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;
            if(this.elemEdit) {
              /* this.elemEdit.Volumetrico != null ? this.editVolumetrico = false : this.editVolumetrico = true;
              this.elemEdit.Tag != null ? this.editTag = false : this.editTag = true;
              this.elemEdit.cerradura != null ? this.editEcoLock = false : this.editEcoLock = true; */
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  sourceEstadoInstalacion =
  [
    { label: AppComponent.translate('Pendiente_instalacion'), value: 0 },
    { label: AppComponent.translate('Sin_lectura'), value: 1 },
    { label: AppComponent.translate('Instalado_correctamente'), value: 2 },
    { label: AppComponent.translate('Instalado_sin_cobertura'), value: 3 },
  ];

  /* APARTADO INSTALACION VOLUMETRICO */
  /* Tipo volumetrico */
  @ViewChild('dropTipoDispositivoVolum') dropTipoDispositivoVolum: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownTipoDispositivoVolum') gridDropDownTipoDispositivoVolum: jqxGridComponent;
  gridDropTipoDispositivoVolum: boolean;

  columnsTipoDispositivoVolum;
  private sourceTipoDispositivoVolum;
  public dataAdapterTipoDispositivoVolum;

  initGridTipoVolumentrico() {
    this.columnsTipoDispositivoVolum =
    [
      { text: 'ID', datafield: 'id', hidden: true },
    ];

    this.sourceTipoDispositivoVolum = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  onOpenDropTipoDispositivoVolum(event:any){

  }

  onCloseDropTipoDispositivoVolum(event:any){

  }

  onRowClickTipoDispositivoVolum(event:any){

  }

  /* Estado instalacion volumetrico */
  @ViewChild('dropEstadoInstalacionVolum') dropEstadoInstalacionVolum: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownEstadoInstalacionVolum') gridDropDownEstadoInstalacionVolum: jqxGridComponent;

  gridDropEstadoInstalacionVolum: boolean;

  columnsEstadoInstalacionVolum;
  public dataAdapterEstadoInstalacionVolum;

  sourceEstadoInstalacionVolum =
  [
    { label: AppComponent.translate('Pendiente_instalacion'), value: 0 },
    { label: AppComponent.translate('Sin_lectura'), value: 1 },
    { label: AppComponent.translate('Instalado_correctamente'), value: 2 },
  ];

  onChangeInstalacionVolumetrico(event) {
    if (event.target.checked) {
      this.elemEdit.RequiereVolum = true;
    } else {
      this.elemEdit.RequiereVolum = false;
    }
  }


  onOpenDropEstadoInstalacionVolum(event:any){

  }

  onCloseDropEstadoInstalacionVolum(event:any){

  }

  onRowClickEstadoInstalacionVolum(event:any){

  }

  /* Imagenes instalacion volumetrico */

  /* Grid incidencias instalacion volumetrico */
  @ViewChild('gridIncidenciasVolum') gridIncidenciasVolum: jqxGridComponent;
  columnsIncidenciasVolum;
  private sourceIncidenciasVolum;
  public dataAdapterIncidenciasVolum;

  initGridIncidenciasVolum() {
    this.columnsIncidenciasVolum =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Tipo'), datafield: 'tipo', width: 100 },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Estado'), datafield: 'estado', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
    ];

    this.sourceIncidenciasVolum = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
      localdata: []
    };
  }

  /* Imagenes incidencias instalacion volumetrico */

  /* FIN APARTADO INSTALACION VOLUMETRICO */

  /* APARTADO INSTALACION TAG */
  /* Tipo tag */
  @ViewChild('dropTipoDispositivoTag') dropTipoDispositivoTag: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownTipoDispositivoTag') gridDropDownTipoDispositivoTag: jqxGridComponent;
  gridDropTipoDispositivoTag: boolean;

  columnsTipoDispositivoTag;
  private sourceTipoDispositivoTag;
  public dataAdapterTipoDispositivoTag;

  initGridTipoTag() {
    this.columnsTipoDispositivoTag =
    [
      { text: 'ID', datafield: 'id', hidden: true },
    ];

    this.sourceTipoDispositivoTag = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  onOpenDropTipoDispositivoTag(event:any){

  }

  onCloseDropTipoDispositivoTag(event:any){

  }

  onRowClickTipoDispositivoTag(event:any){

  }

  /* Estado instalacion tag */
  @ViewChild('dropEstadoInstalacionTag') dropEstadoInstalacionTag: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownEstadoInstalacionTag') gridDropDownEstadoInstalacionTag: jqxGridComponent;
  gridDropEstadoInstalacionTag: boolean;

  columnsEstadoInstalacionTag;
  public dataAdapterEstadoInstalacionTag;
  sourceEstadoInstalacionTag =
  [
    { label: AppComponent.translate('Pendiente_instalacion'), value: 0 },
    { label: AppComponent.translate('Sin_lectura'), value: 1 },
    { label: AppComponent.translate('Instalado_correctamente'), value: 2 },
  ];

  onChangeInstalacionTag(event) {
    if (event.target.checked) {
      this.elemEdit.RequiereTag = true;
    } else {
      this.elemEdit.RequiereTag = false;
    }
  }

  onOpenDropEstadoInstalacionTag(event:any){

  }

  onCloseDropEstadoInstalacionTag(event:any){

  }

  onRowClickEstadoInstalacionTag(event:any){

  }

  /* Imagenes instalacion tag */

  /* Grid incidencias instalacion tag */
  @ViewChild('gridIncidenciasTag') gridIncidenciasTag: jqxGridComponent;

  columnsIncidenciasTag;
  private sourceIncidenciasTag;
  public dataAdapterIncidenciasTag;


  initGridIncidenciasTag() {
    this.columnsIncidenciasTag =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Tipo'), datafield: 'tipo', width: 100 },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Estado'), datafield: 'estado', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
    ];

    this.sourceIncidenciasTag = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
      localdata: []
    };
  }

  /* Imagenes incidencias instalacion tag */

  /* FIN APARTADO INSTALACION TAG */

  /* APARTADO INSTALACION ECOLOCK */
  /* Instalacion ecolock */
  onChangeInstalacionEcolock(event) {
  }

  /* Instalacion identificación */
  onChangeInstalacionIdentificacion(event) {
  }

  /* Identificacion ecolock */
  @ViewChild('dropIdentificacionEcolock') dropIdentificacionEcolock: jqxDropDownListComponent;

  /* Opciones dropdown identificacion ecolock */
  sourceIdentificacionEcolock =
  [
    { label: AppComponent.translate('Tarjeta_nfc'), value: 0 },
    { label: 'Bluetooth', value: 1 },
  ];

  /* Estado instalacion ecolock */
  @ViewChild('dropEstadoInstalacionEcolock') dropEstadoInstalacionEcolock: jqxDropDownListComponent;

  /* Tipo dispositivo ecolock */
  @ViewChild('dropTipoDispositivoEcolock') dropTipoDispositivoEcolock: jqxDropDownListComponent;
  sourceTipoDispositivoEcolock = [];

  /* Grid incidencias instalacion ecolock */
  @ViewChild('gridIncidenciasEcolock') gridIncidenciasEcolock: jqxGridComponent;

  columnsIncidenciasEcolock;
  private sourceIncidenciasEcolock;
  public dataAdapterIncidenciasEcolock;

  initGridIncidenciasEcolock() {
    this.columnsIncidenciasEcolock =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
      { text: this.translate('Informacion_grafica'), datafield: 'informacionGrafica', width: 150 },
    ];

    this.sourceIncidenciasEcolock = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'descripcion', type: 'string' },
        { name: 'informacionGrafica', type: 'string' },
      ],
      localdata: []
    };
  }

  /* Imagenes incidencias instalacion ecolock */
  /* FIN APARTADO INSTALACION ECOLOCK */
}
