<div style="height: 100%;">
  <jqxGrid #gridDirecciones [theme]="environment.tema" [width]="'99%'" [height]="'100%'" [source]="adapterDirecciones" [autoshowloadelement]='false'
    [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="false" [columngroups]="columnsGroupDirecciones"
    [columns]="columnsDirecciones" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
    [altrows]="true" [showstatusbar]="true" [statusbarheight]="0" [showaggregates]="false" [rowsheight]="25" [sortmode]="'many'"
    [columnsheight]="30" [enabletooltips]="false" [editable]="false" [groupable]="true" [rowdetails]="ciudadano.direcciones.length > 0"
    [initrowdetails]="initrowdetails" [rowdetailstemplate]="rowdetailstemplate">
  </jqxGrid>
</div>

<div #formDireccion></div>
