import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { HistoricoUbicacion } from './models/historico-ubicacion';

@Injectable({
  providedIn: 'root'
})
export class HistoricoUbicacionService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private http: HttpClient,
    private ssoService: SsoService) { }


  async getHistoricoUbicacion(elemento): Promise<HistoricoUbicacion[]>{
    let response: HistoricoUbicacion[] = null;

    try{
      await this.http.get<HistoricoUbicacion[]>(this.urlApi + '/api/puntoubicacion/historico/elemento?idElemento=' + elemento.Id).toPromise().then(
        res => {
          response = res;
        }
      )
    }catch(e){

    }

    return response;
  }
}
