import { MapMarker } from "movisat-maps";
import { BdtEquipamientoModel } from "../../bdt/models/bdt-equipamiento.model";
import { DumpsterAnalysisModel } from "../../elements/models/dumpster-analysis.model";
import { ElemImageModel } from "../../elements/models/elem-image-model";
import { PuModel } from "../../pu/models/pu.model";
import { CerraduraModel } from "./cerradura.model";

export class ElementoCerraduraModel {

    guid: string = '';
    id: number = 0;
    empresa: number = 0;
    nombre: string = '';
    lat: number = 0;
    lng: number = 0;
    idEquipamiento: number = 0;
    fechaCreacion: Date;
    fechaModificacion: Date;
    fechaBaja: Date;
    imeiVolum: string;
    ultFechaVolum: Date = new Date();
    ultLecturaVolum: number;
    ultPctjeVolum: number;
    ultBateriaVolum: number;
    ultTempVolum: number;
    idConfigVolum: number;
    idEcoSAT: number;
    idVolumetrico: string;
    requiereVolum: boolean;
    estadoInstalVolum: number; // 0=No instalado, 1=Instalado pendiente lectura, 2=Instalado correctamente
    idPU: number;
    estado: number; // 1=Ubicado, 2=En tránsito
    calle: string;
    codigoPostal: string;
    provincia: string;
    comunidadAutonoma: string;
    municipio: string;
    pais: string;
    numeroCalle: string;
    idCircuito: string;
    tag: TagCerraduraModel = new TagCerraduraModel();

    // Nuevos campos modificación vista
    poblacion: string;
    observaciones: string;
    fechaInstalacionVolum: Date;
    fechaInstalacionEcoLock: Date;
    alturaSensor: number;
    alturaMuerta: number;
    gradosSensor: number;
    simVolum: number;
    imeiEcoLock: string;
    simEcoLock: number;
    idEcoLock: string;
    requiereTag: boolean;

    // Agregados
    equipamiento: BdtEquipamientoModel;
    pu: PuModel;
    marker: MapMarker;
    selec: string; // Campo usado para filtrar búsquedas
    distancia: string; // Para buscar cercanos
    dumpsterAnalysis?: DumpsterAnalysisModel; // Datos de IA
    infogeo: string; // Dirección + número + provincia
    estadoTexto: string;
    estadoInstallTexto: string;
    cerradura: CerraduraModel;
    marca: string = '';
    modelo: string = '';
    // Agregados modificacion vista
    imagenes: ElemImageModel[];
    alarma: any;
    frecuencia: any;
}


export class TagCerraduraModel {
    id: number;
    tag: string = '';
    matricula: string = '';
}