import { Component, OnInit } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { SsoService } from '../../../services/sso/sso.service';

@Component({
  selector: 'app-appbar',
  templateUrl: './appbar.component.html',
  styleUrls: ['./appbar.component.css']
})

export class AppbarComponent implements OnInit {
  public environment = environment;

  constructor(
    public ssoService: SsoService) { }

  async ngOnInit(): Promise<void> {
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
