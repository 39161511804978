import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { BdtService } from 'src/app/services/bdt/bdt.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { JqxDomService } from 'src/app/services/jqwidgets/jqx-dom.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { BdtCatalogoEquipamientoModel } from 'src/app/services/bdt/models/bdt-catalogo-equipamiento.model';

import { ElementsDetailComponent } from '../elements-detail/elements-detail.component';
import { Utils } from 'src/app/utils/utils';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';
import { InputNumberComponent } from '../../input-number/input-number.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { HeaderComponent } from '../../header/header.component';


@Component({
  selector: 'app-elements-catalog',
  templateUrl: './elements-catalog.component.html',
  styleUrls: ['./elements-catalog.component.css'],
})
export class ElementsCatalogComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('fichaCatalogo') ventanaFicha: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  /* GRID EQUIPAMIENTO */
  @ViewChild('gridDropDownEquip') gridDropDownEquip: jqxGridComponent;
  @ViewChild('cbEquip') cbEquip: jqxDropDownButtonComponent;
  @ViewChild('sTieneRuedas') sTieneRuedas: jqxDropDownListComponent;
  @ViewChild('sTienePedal') sTienePedal: jqxDropDownListComponent;
  @Input() elemEdit: BdtCatalogoEquipamientoModel;
  private catEquipSelec: BdtCatalogoEquipamientoModel;
  /* APARTADO IMAGENES */
  @ViewChild('imgPrincipal') imgPrincipal;
  @ViewChild('uploadImageWind') uploadImageWind: jqxWindowComponent;
  @ViewChild('fracciones') fracciones: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;

  inputFile: any = null;

  private imgPrincipalRotation = 0;

  public static _this: ElementsCatalogComponent;
  private componentRef = null;
  public environment = environment;

  public canEdit = true;
  catEquipamiento: BdtCatalogoEquipamientoModel[] = [];
  private catEquip = new Map<number, BdtCatalogoEquipamientoModel>();
  @Output() catEquipSelecChange = new EventEmitter;
  private select: any = [];
  private fromIAConfig = false;
  private detailComponents: any[] = [];
  mapWidth: number;
  mapHeight: number;
  showLoader: boolean = true;
  elementos: ElementoModel[] = [];
  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);
  public catEquipList: BdtCatalogoEquipamientoModel[] = [];
  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');
  columns: any[] = [];
  showFicha: boolean = false;
  columnsEquip: any[] = [];
  sourceEquip: { datatype: string; datafields: { name: string; type: string; map: string; }[]; localdata: any; sortcolumn: string; sortdirection: string; };
  dataAdapterEquip: any;
  verGridEquipamiento: boolean;
  ejecutado: boolean = false;
  dataAdapterClasesElemento: any;
  columnsClasesElemento: any[] = [];
  columnsElementos: any[] = [];
  dataAdapterElementos: any;
  imagenesEquipamiento;
  selectedImg: any;

  constructor(
    private ssoService: SsoService,
    private bdtService: BdtService,
    private configService: ConfigService,
    public elemService: ElementsService,
    private jqxDomService: JqxDomService,
  ) {
    super();
    ElementsCatalogComponent._this = this;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(
      this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2)
    );
    this.initGrid();
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    if (MainComponent.getInstance().ssoTicket.Aplicacion.Id === 14) {
      this.form.setTitle(AppComponent.translate('Catalogo_BDT'));
    } else {
      if (!this.fromIAConfig) {
        this.form.setTitle(AppComponent.translate('Catalogo_elementos_BDT'));
      } else {
        this.form.setTitle(
          AppComponent.translate('Catalogo_elementos_BDT') +
          ' - ' +
          AppComponent.translate('IA')
        );
      }
    }
    // Recupero el filtro guardado
    if (!this.fromIAConfig) {
      this.select = await this.configService.getUsuEmpApp(
        'elem-model-filter',
        null
      );
    } else {
      this.select = await this.configService.getEmp('elem-model-IA', null);
    }
    if (this.select) {
      this.select = JSON.parse(this.select);
    } else {
      this.select = [];
    }
    // Recupero el catálogo de elementos de la empresa
    this.getCatalogoEquipamiento();
    ;
    if (this.grid.getrows().length == 0) {
      this.grid.showemptyrow(true);
    }
    Utils.renderSizeGrid(this.grid);
    this.initGridEquip();
  }

  initGrid(): void {
    this.columns = [
      {
        text: 'Id',
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'id',
        width: 60,
        hidden: true,
      },

      { text: 'idElemento', datafield: 'idElemento', hidden: true },
      {
        text: 'U.Max',
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'unidadesMax',
        width: 75,
        align: 'center',
        cellsalign: 'center',
        //hidden: true,
        cellsrenderer: this.numberrenderer,
      },
      {
        text: AppComponent.translate('Unidades'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'unidades',
        width: 75,
        align: 'center',
        cellsalign: 'center',
        //hidden: true,
        aggregates: [
          {
            Total: function (aggregatedValue, currentValue: number) {
              return currentValue
                ? aggregatedValue + currentValue
                : aggregatedValue;
            },
          },
        ],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates['Total'] !== undefined) {
            renderstring =
              '<div style="text-align: center; margin-left: 4px;">' +
              aggregates['Total'] +
              ' </div>';
          }
          return renderstring;
        },
        cellsrenderer: this.numberrenderer,
      },
      {
        text: '',
        columntype: 'image',
        datafield: 'imagen',
        width: 16,
        filterable: false,
        sortable: false,
        cellsrenderer: this.imagerenderer,
      },
      {
        text: AppComponent.translate('Elemento'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'elemento',
        width: 130,
        aggregates: [
          {
            Total: function (aggregatedValue, currentValue: number) {
              return aggregatedValue + 1;
            },
          },
        ],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates['Total'] !== undefined) {
            renderstring =
              '<div style="text-align: left; margin-left: 4px;position:fixed;">' +
              AppComponent.translate('Equipamientos') +
              ': ' +
              aggregates['Total'] +
              ' </div>';
          }
          return renderstring;
        },
      },
      {
        text: AppComponent.translate('Marca'),
        columntype: 'textbox',
        filtertype: 'checkedlist',
        datafield: 'marca',
        width: 100,
        hidden: this.elemService.elemGenericos,
      },
      {
        text: AppComponent.translate('Modelo'),
        columntype: 'textbox',
        filtertype: 'checkedlist',
        datafield: 'modelo',
        width: 100,
        hidden: this.elemService.elemGenericos,
      },
      {
        text: AppComponent.translate('Tipo'),
        columntype: 'textbox',
        filtertype: 'checkedlist',
        datafield: 'tipo',
        width: 100,
        hidden: this.elemService.elemGenericos,
      },
      {
        text: AppComponent.translate('Residuo'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'residuo',
        width: 100,
        hidden: this.elemService.elemGenericos,
      },
      {
        text: AppComponent.translate('Capacidad'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'capacidad-lit',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Carga_maxima'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'cma',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Altura_libre'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'altura',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrendererDecimales
      },
      {
        text: AppComponent.translate('Ancho'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'ancho',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrendererDecimales
      },
      {
        text: AppComponent.translate('Largo'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'largo',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrendererDecimales
      },
      {
        text: AppComponent.translate('Tara'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'tara',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Coste'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'coste',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Años_vida'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'anyos',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Precio_compra'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'precio',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Año_amortizacion'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'amortizacion',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Mantenimiento_actual'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'mantenimiento',
        width: 80,
        hidden: this.elemService.elemGenericos,
        cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Radio'),
        columntype: 'textbox',
        filtertype: 'number',
        datafield: 'radio',
        width: 80,
        cellsrenderer: this.numberrenderer
      },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
      // {
      //   text: '', datafield: 'mas', columntype: 'button', width: 6,
      //   cellsrenderer: (): string => {
      //     return '+';
      //   },
      //   buttonclick: (row: number): void => {
      //     const dataRecord: any = this.grid.getrowdata(row);
      //     let i = 0;
      //     // this.myWindow.position({ x: 68, y: 368 });
      //     // this.myWindow.open();
      //   }
      // }
    ];
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, fromIAConfig: boolean) {
    this.componentRef = componentRef;
    this.fromIAConfig = fromIAConfig;
  }

  formatDate(fecha) {
    // paso la fecha strign a date
    fecha = new Date(fecha);
    return DateUtils.formatDateTimeShort(fecha, true);
  }

  initGridEquip() {
    this.columnsEquip = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
      {
        text: '', columntype: 'image', datafield: 'imagen', cellsrenderer: this.imagerenderer,
      },
      {
        text: this.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'elemento', aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }], //agrego el total al grid
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="margin-left: 4px;">Total: ' +
              aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'marca', hidden: this.elemService.elemGenericos },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'modelo', hidden: this.elemService.elemGenericos },
      { text: this.translate('Residuo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'residuo', hidden: this.elemService.elemGenericos },
    ];

    this.columnsEquip.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    this.sourceEquip = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Equipamiento>Modelo>Id' },
        { name: 'imagen', type: 'image', map: 'Equipamiento>Icono' },
        { name: 'elemento', type: 'string', map: 'Equipamiento>Elemento>Nombre' },
        { name: 'marca', type: 'string', map: 'Equipamiento>Marca>Nombre' },
        { name: 'modelo', type: 'string', map: 'Equipamiento>Modelo>Nombre' },
        { name: 'residuo', type: 'string', map: 'Equipamiento>ResiduosNombres' },
      ],
      localdata: this.catEquipList,
      sortcolumn: 'marca',
      sortdirection: 'asc',
    };

    this.dataAdapterEquip = new jqx.dataAdapter(this.sourceEquip);

    //this.getImages();
  }

  dataAdapterFracciones: any;
  columnsFracciones: any[] = [];

  onOpenSelectEquipamientos(event) {
    this.customComponent();
    this.verGridEquipamiento = true;
  }

  onCloseSelectEquipamientos(event) {
    this.verGridEquipamiento = false;
  }

  clickCerrar($event) {
    this.fracciones.close();
  }

  /* Establece la imagen principal al
    hacer click en una imagen del slider */
  setImgPrincipal(img) {
    this.selectedImg = img;
    this.rotate('0');
  }

  /* Selecciona, a traves de las flechas,
   la imagen siguiente o anterior */
  changeImage(direction: string) {
    let index;
    switch (direction) {
      case 'previous':
        index = this.imagenesEquipamiento.indexOf(this.selectedImg);
        if (index > 0) {
          this.rotate('0');
          this.selectedImg = this.imagenesEquipamiento[index - 1];
        } else {
          this.rotate('0');
          this.selectedImg = this.imagenesEquipamiento[this.imagenesEquipamiento.length - 1];
        }
        break;
      case 'next':
        index = this.imagenesEquipamiento.indexOf(this.selectedImg);
        if (index < this.imagenesEquipamiento.length - 1) {
          this.rotate('0');
          this.selectedImg = this.imagenesEquipamiento[index + 1];
        } else {
          this.rotate('0');
          this.selectedImg = this.imagenesEquipamiento[0];
        }
        break;
      default:
        break;
    }
  }

  // Rota la imagen principal
  rotate(orientation: string) {
    if (this.imgPrincipal) {
      switch (orientation) {
        case 'left':
          this.imgPrincipalRotation = this.imgPrincipalRotation - 90;
          this.imgPrincipal.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case 'right':
          this.imgPrincipalRotation = this.imgPrincipalRotation + 90;
          this.imgPrincipal.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case '180':
          this.imgPrincipalRotation = this.imgPrincipalRotation + 180;
          this.imgPrincipal.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case '0':
          this.imgPrincipalRotation = 0;
          this.imgPrincipal.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        default:
          break;
      }
    }
  }

  renderRow(row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string {
    let contentHtml = '<div style="margin: 4px; text-align: center;">' + value + '</div>';

    switch (rowdata.select) {
      case 1:
        // Caso 0%, podrías dejar la celda vacía o como está.
        break;

      case 2:
        // Caso 25%, podrías cambiar el color de fondo de la celda o añadir una barra de progreso.
        if (rowdata.frac == 2) {
          contentHtml = '<div style="margin: 4px; text-align: center; color: white; background-color: lightgreen; width: 25%;">' + value + '</div>';
        }
        break;

      case 3:
        if (rowdata.frac == 3) {
          // Caso 50%, similar al anterior pero con 50% de la celda.
          contentHtml = '<div style="margin: 4px; text-align: center; color: white; background-color: lightgreen; width: 50%;">' + value + '</div>';
        }
        break;

      case 4:
        if (rowdata.frac == 4) {
          // Caso 75%, similar al anterior pero con 75% de la celda.
          contentHtml = '<div style="margin: 4px; text-align: center;color: white; background-color: lightgreen; width: 75%;">' + value + '</div>';
        }
        break;

      case 5:

        if (rowdata.frac == 5) {
          //modifico la celda que tiene el valor 100% para que se vea en rojo
          contentHtml = '<div style="margin: 4px; text-align: center; color: white; background-color: green; width: 100%;">' + value + '</div>';
        }
        break;
    }

    return contentHtml;
  }

  openFracciones() {
    this.fracciones.open();

    if (this.elemEdit) {
      this.columnsFracciones = [
        // tiene dos columnas, fracion 1 hasta el 5 y Porcentaje
        { text: 'select', datafield: 'select', hidden: true, },
        { text: 'frac', datafield: 'frac', hidden: true, },
        { text: 'Fracción', datafield: 'fraccion', width: 100, align: 'center', cellsalign: 'left' },
        { text: 'Porcentaje', datafield: 'porcentaje', width: 100, align: 'center', cellsalign: 'right', cellsrenderer: this.renderRow },
      ];

      let sourceFracciones = {
        datatype: 'json',
        datafields: [
          { name: 'fraccion', type: 'string' },
          { name: 'porcentaje', type: 'string' },
          { name: 'select', type: 'number', datafield: 'select' },
          { name: 'frac', type: 'number', datafield: 'frac' },
        ],
        localdata: [
          { fraccion: '1º Fraccion', porcentaje: '0%', select: 1, frac: this.elemEdit.Equipamiento.NumeroFracciones },
          { fraccion: '2º Fraccion', porcentaje: '25%', select: 2, frac: this.elemEdit.Equipamiento.NumeroFracciones },
          { fraccion: '3º Fraccion', porcentaje: '50%', select: 3, frac: this.elemEdit.Equipamiento.NumeroFracciones },
          { fraccion: '4º Fraccion', porcentaje: '75%', select: 4, frac: this.elemEdit.Equipamiento.NumeroFracciones },
          { fraccion: '5º Fraccion', porcentaje: '100%', select: 5, frac: this.elemEdit.Equipamiento.NumeroFracciones },
        ],
        sortcolumn: 'fraccion',
        sortdirection: 'asc',
      };

      this.dataAdapterFracciones = new jqx.dataAdapter(sourceFracciones);
    }
  }

  // Cierra la ventana de subir imagen
  closeUploadImageWind() {
    this.uploadImageWind.close();
  }

  customComponent(component?: any) {
    setTimeout(() => {
      this.gridDropDownEquip.setOptions({
        showaggregates: true,
        sortable: true,
        altrows: true,
        filterable: true,
        rowsheight: 20,
        columnsheight: 20,
        statusbarheight: 20,
        columnsresize: true,
        showstatusbar: true,
        showcolumnlines: true,
      });
    }, 50);
  }

  loadDataGridEquip() {
    const t = setTimeout(async () => {
      clearTimeout(t);
      this.catEquipList = await this.getCatalogoEquipamientoGrid();
      if (this.elemEdit) {
        if (this.elemEdit.Id < 1) {
          // Relleno el combo de equipamientos
          this.catEquipSelec = this.catEquipList[0];
        } else {
          this.catEquipList.forEach((cat, i) => {
            if (cat.IdEquipamiento === this.elemEdit.IdEquipamiento) {
              this.catEquipSelec = cat;
            }
          });
        }
        this.catEquipSelecChange.emit(this.catEquipSelec);
        this.elemEdit.Equipamiento = this.catEquipSelec.Equipamiento;
        let content = '';
        if (this.elemService && this.elemService.elemGenericos) {
          content =
            '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
            this.catEquipSelec.Equipamiento.Elemento.Nombre +
            '</div>';
        } else {
          content =
            '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
            this.catEquipSelec.Equipamiento.Elemento.Nombre +
            (this.catEquipSelec.Equipamiento.Marca.Nombre
              ? ' | ' + this.catEquipSelec.Equipamiento.Marca.Nombre
              : '') +
            (this.catEquipSelec.Equipamiento.Modelo.Nombre
              ? ' | ' + this.catEquipSelec.Equipamiento.Modelo.Nombre
              : '') +
            '</div>';
        }
        if (this.cbEquip) {
          this.cbEquip.setContent(content);
        }
      }
      this.loadGridClases();
      this.loadGridElementos();
      this.selectTieneRuedasYPedal();
    }, 100);
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  loadGridClases() {
    //id,codigo, nombre, acronimo, descripcion
    if (this.elemEdit) {
      this.columnsClasesElemento = [

        { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
        { text: this.translate('CODIGO'), columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo', },
        { text: this.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', },
        { text: this.translate('Acronimo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'acronimo', },
        { text: this.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'descripcion', },
      ]

      this.columnsClasesElemento.forEach(column => {
        column.rendered = (element) => { Utils.tooltiprenderer(element) };
      });

      let sourceClasesElemento = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number', map: 'Id' },
          { name: 'codigo', type: 'string', map: 'Codigo' },
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'acronimo', type: 'string', map: 'Acronimo' },
          { name: 'descripcion', type: 'string', map: 'Descripcion' },
        ],
        localdata: this.elemEdit.Equipamiento?.Clases,
      };

      this.dataAdapterClasesElemento = new jqx.dataAdapter(sourceClasesElemento);

    }
  }

  clasesRender(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      let textToShow = '';
      value.forEach(element => {
        textToShow += textToShow == '' ? element.Nombre : ', ' + element.Nombre;
      });

      return (
        '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' +
        textToShow +
        '</div>');
    } else {
      return (
        '<div style="margin-left: 4px; margin-top: 5px;"></div>');
    }
  }

  loadGridElementos() {
    if (this.elemEdit) {

      let idsElemenetosByCat = this.catEquipSelec.Equipamiento.IdsElementos;
      // obtengo los elementos que pertenecen a la categoria seleccionada
      this.elementos = MainComponent.getInstance().elementsList.filter(elem => idsElemenetosByCat?.includes(elem.Id));

      this.columnsElementos = [
        {
          text: AppComponent.translate('Icono'), columntype: 'textbox', width: 20, datafield: 'icono', filterable: false, sortable: false, cellsrenderer: this.imagerenderer,
          aggregates: [{
            'Total': function (aggregatedValue, currentValue: number) {
              return aggregatedValue + 1;
            }
          }], //agrego el total al grid
          aggregatesrenderer: function (aggregates) {
            let renderstring = '';
            if (aggregates["Total"] !== undefined) {
              renderstring = '<div style="margin-left: 4px; position:fixed;">' + AppComponent.translate('Total') + ': ' +
                aggregates["Total"] + '</div>';
            }
            return renderstring;
          }
        },
        { text: AppComponent.translate('Descripcion'), columntype: 'textbox', menu: false, datafield: 'descripcion', width: 120 },
        { text: AppComponent.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', datafield: 'marca', width: 120, },
        { text: AppComponent.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'modelo', width: 150, },
        { text: AppComponent.translate('Residuo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'residuo', width: 120 },
        { text: AppComponent.translate('Clases'), columntype: 'textbox', filtertype: 'textbox', datafield: 'clases', width: 120, cellsrenderer: this.clasesRender }
      ]

      this.columnsElementos.forEach(column => {
        column.rendered = (element) => { Utils.tooltiprenderer(element) };
      });

      let sourceClasesElemento = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number', map: 'Id' },
          { name: 'icono', type: 'image', map: 'Equipamiento>Icono' },
          { name: 'descripcion', type: 'string', map: 'Nombre' },
          { name: 'marca', type: 'string', map: 'Equipamiento>Marca>Nombre' },
          { name: 'modelo', type: 'string', map: 'Equipamiento>Modelo>Nombre' },
          { name: 'residuo', type: 'string', map: 'Equipamiento>ResiduosNombres' },
          { name: 'clases', type: 'object', map: 'Equipamiento>Clases' },

        ],
        localdata: this.elementos,
      };

      this.dataAdapterElementos = new jqx.dataAdapter(sourceClasesElemento);
    }
  }

  /* Recupera el catálogo de elementos de la empresa */
  async getCatalogoEquipamientoGrid(): Promise<BdtCatalogoEquipamientoModel[]> {
    this.catEquipList = await this.bdtService.getCatalogoEquipamiento();
    if (this.catEquipList) {
      this.sourceEquip.localdata = this.catEquipList;
      this.dataAdapterEquip = new jqx.dataAdapter(this.sourceEquip);
      this.onBuscar();
    }
    return this.catEquipList;
  }

  // Cierro el formulario y destruyo el componente
  public async onClose() {
    ElementsCatalogComponent._this = null;
    // Destruyo los componentes
    this.detailComponents.forEach((cpn) => {
      cpn.destroy();
    });
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    if (this.ventanaFicha) {
      this.ventanaFicha.destroy();
      this.showFicha = false;
    }

    ElementsCatalogComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onRowdoubleclick(event: any) {
    this.form.collapse();
    this.showFicha = true;
    this.elemEdit = this.catEquipamiento.find(val => val.Equipamiento.Id == event.args.row.bounddata.id);
    this.imagenesEquipamiento = this.elemEdit.Equipamiento.Imagenes;
    this.selectedImg = this.elemEdit.Equipamiento.Imagenes[0];
    this.loadDataGridEquip();
    setTimeout(() => {
      this.addCustomForm(this.ventanaFicha);
      this.ventanaFicha.setTitle(AppComponent.translate('Ficha_catalogo_equipamiento') + " <b>" + this.elemEdit.Equipamiento.Elemento.Nombre + ' ' +
        this.elemEdit.Equipamiento.Marca.Nombre + ' ' + this.elemEdit.Equipamiento.Modelo.Nombre + "</b>");
      this.ventanaFicha.bringToFront();
    }, 200);
  }

  onBindingComplete() {
    if (this.grid) {
      const rows = this.grid.getrows();
      if (rows) {
        rows.forEach((row) => {
          if (this.select.find((s) => s.id === row.id) !== undefined) {
            this.grid.selectrow(row.boundindex);
          }
        });
      }
    }
  }

  // Recupera el catálogo de elementos de la empresa
  async getCatalogoEquipamiento() {
    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    let filterIA: any = await this.configService.getEmp('elem-model-IA', null);
    if (filterIA) {
      filterIA = JSON.parse(filterIA);
    }
    this.catEquipamiento = [];
    const response = await this.bdtService.getCatalogoEquipamiento();
    if (response) {
      response.forEach((cat) => {
        cat.Unidades2 = 0;
        MainComponent.getInstance().elementsList.forEach(elem => {
          if (elem.Equipamiento.Id === cat.Equipamiento.Id) {
            cat.Unidades2++;
          }
        });
        if (!MainComponent.getInstance().isEcoEvolution || this.fromIAConfig) {
          this.catEquip.set(cat.IdEquipamiento, cat);
          this.catEquipamiento.push(cat);
        } else {
          if (
            !filterIA ||
            filterIA.find((s) => s.id === cat.IdEquipamiento) !== undefined
          ) {
            this.catEquip.set(cat.IdEquipamiento, cat);
            this.catEquipamiento.push(cat);
          }
        }
      });
      this.source = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'int', map: 'IdEquipamiento' },
          { name: 'unidadesMax', type: 'int', map: 'Unidades' },
          { name: 'unidades', type: 'int', map: 'Unidades2' },
          { name: 'imagen', type: 'image', map: 'Equipamiento>Icono' },
          { name: 'marca', type: 'string', map: 'Equipamiento>Marca>Nombre' },
          { name: 'modelo', type: 'string', map: 'Equipamiento>Modelo>Nombre' },
          {
            name: 'elemento',
            type: 'string',
            map: 'Equipamiento>Elemento>Nombre',
          },
          { name: 'idElemento', type: 'number', map: 'idElemento' },
          {
            name: 'tipo',
            type: 'string',
            map: 'Equipamiento>Elemento>Tipo>Nombre',
          },
          {
            name: 'residuo',
            type: 'string',
            map: 'Equipamiento>ResiduosNombres',
          },
          { name: 'capacidad-lit', type: 'int', map: 'Equipamiento>Capacidad', },
          {
            name: 'cma',
            type: 'int',
            map: 'Equipamiento>CargaMaximaAutorizada',
          },
          { name: 'altura', type: 'int', map: 'Equipamiento>AlturaLibre', },
          { name: 'ancho', type: 'int', map: 'Equipamiento>Ancho', },
          { name: 'largo', type: 'int', map: 'Equipamiento>Largo', },
          { name: 'tara', type: 'int', map: 'Equipamiento>Tara', },
          { name: 'coste', type: 'int', map: 'Equipamiento>Coste', },
          { name: 'anyos', type: 'int', map: 'Equipamiento>AnyoVida', },
          { name: 'precio', type: 'int', map: 'Equipamiento>PrecioCompra', },
          {
            name: 'amortizacion',
            type: 'int',
            map: 'Equipamiento>AnyoAmortizacion',
          },
          {
            name: 'mantenimiento',
            type: 'int',
            map: 'Equipamiento>MantenimientoActual',
          },
          { name: 'radio', type: 'int', map: 'Equipamiento>AreaInfluencia' },
          { name: 'selec', map: 'selec', type: 'string' }
        ],
        localdata: this.catEquipamiento,
        sortcolumn: 'marca',
        sortdirection: 'asc',
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);
      this.showLoader = await Utils.renderRowGridText();
    }

    // const t = setTimeout(() => {
    //   clearTimeout(t);
    //   this.grid.attrColumns.forEach((column: any) => {
    //     this.grid.autoresizecolumn(column.datafield, column.columntype);
    //   });
    // }, 500);
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      value = value / 10;
      return (
        '<div style="margin-right: 4px; margin-top: 6px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 6px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  imagerenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value && value.length > 50) {
      return (
        '<img style="margin-left: 4px; margin-top: 6px;" height="16" width="16" src="data:image/jpg;base64,' +
        value +
        '"/>'
      );
    }
    return '<img style="margin-left: 4px; margin-top: 6px;" height="16" width="16" src="assets/images/elemento.png"/>';
  }

  rowdetailstemplate(index: number): any {
    return {
      rowdetails: '<div class="elem-detail" style="width: 300px;"></div>',
      rowdetailsheight: 380,
    };
  }

  initrowdetails(
    index: number,
    htmlElement: HTMLElement,
    gridElement: any,
    dataRecord: any
  ): void {
    if (htmlElement) {
      const container = document.createElement('div');
      htmlElement.appendChild(container);
      // Añado el componente de detalle y lo inicializo con el id del equipamiento
      const component: any =
        ElementsCatalogComponent._this.jqxDomService.loadComponent(
          ElementsDetailComponent,
          container
        );
      component.componentRef.instance.init(component, dataRecord.id);
      ElementsCatalogComponent._this.detailComponents.push(
        component.componentRef
      );
    }
  }

  selectTieneRuedasYPedal() {
    this.sTieneRuedas.addItem({ label: 'Si', value: 1 });
    this.sTieneRuedas.addItem({ label: 'No', value: 0 });

    this.sTienePedal.addItem({ label: 'Si', value: 1 });
    this.sTienePedal.addItem({ label: 'No', value: 0 });
    if (this.elemEdit.Equipamiento.Ruedas == null) {
      this.elemEdit.Equipamiento.Ruedas = false;
    }

    if (this.elemEdit.Equipamiento.Pedal == null) {
      this.elemEdit.Equipamiento.Pedal = false;

    }

    if (this.elemEdit.Equipamiento.Ruedas && this.elemEdit.Equipamiento.Pedal) {
      this.sTieneRuedas.selectIndex(0);
      this.sTienePedal.selectIndex(0);
    } else {
      this.sTieneRuedas.selectIndex(1);
      this.sTienePedal.selectIndex(1);
    }
  }

  onChangeTieneRuedas(event: any) {

  }

  onChangeTienePedales(event: any) {

  }

  onCloseFicha() {
    this.showFicha = false;
    this.ejecutado = false;
    this.form.expand();
  }

  onRowExpand(event: any) {
    const args = event.args;
    const details = args.details;
    const rowBoundIndex = args.rowindex;
    setTimeout(() => {
      this.grid.scrolloffset(rowBoundIndex * this.grid.rowsheight(), 0);
    }, 250);
  }

  eventFilter(){
    this.getCatalogoEquipamientoGrid();
  }

  eventResetFilter(){
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if(this.header.searchInput['nativeElement'].value.length >= 3){
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    }else {
      filtervalue = '';
    }

    this.catEquipamiento.forEach(catEqp => {
      if(
        (catEqp?.Unidades + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Unidades2 + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Marca?.Nombre + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Modelo?.Nombre + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Elemento?.Nombre + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Elemento?.Tipo?.Nombre + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.ResiduosNombres + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Capacidad + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.CargaMaximaAutorizada + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.AlturaLibre + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Ancho + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Largo + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Tara + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.Coste + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.AnyoVida + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.PrecioCompra + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.AnyoAmortizacion + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.MantenimientoActual + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (catEqp?.Equipamiento?.AreaInfluencia + '')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ){
        catEqp['selec'] = 'selec';
      }else{
        catEqp['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    this.grid.applyfilters();
    this.grid.updatebounddata('data');
  }

  closeWindow() {
    this.form.close();
  }
}
