import { ElementoModel } from "../../elements/models/elem.model";
import { VolumLectruraModel } from "./volum-lectura.model";

export class VolumLecturasMesModel {
    imei: string;
    elemento: ElementoModel;
    lecturasDia = new Map<number, number>();

    constructor(imei: string, elemento: ElementoModel) {
        this.imei = imei;
        this.elemento = elemento;
    }

    sumaLecturaDia(dia: number) {
        const lecDia = this.lecturasDia.get(dia);
        this.lecturasDia.set(dia, lecDia ? lecDia + 1 : 1);
    }
}
