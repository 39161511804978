import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { AdministradorService } from 'src/app/services/administrador/administrador.service';

import { TareaModel } from 'src/app/services/administrador/models/tarea.model';

import { ListadoTareasComponent } from '../listado-tareas/listado-tareas.component';

@Component({
  selector: 'app-edit-tarea',
  templateUrl: './edit-tarea.component.html',
  styleUrls: ['./edit-tarea.component.css']
})
export class EditTareaComponent extends CustomForms implements OnInit {
  @ViewChild('formWindow') formWindow: jqxWindowComponent;

  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public tarea: TareaModel;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private administradorService: AdministradorService,
  ) {
    super();
  }

  init(componentRef: any, tarea: TareaModel){
    this.componentRef = componentRef;
    this.tarea = tarea;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.addCustomForm(this.formWindow);

    this.formWindow.setTitle(this.translate('Jobs'));
  }

  onClose() {
    if(this.componentRef) {
      this.componentRef.destroy();
      ListadoTareasComponent._this.windowJob.expand();
    }
  }

  closeWindow(){
    this.formWindow.close();
  }

  setActivado(event: any){
    if(event.args.checked){
      this.tarea.activado = true;
    }else {
      this.tarea.activado = false;
    }
  }

  async onGuardar(){
    let tarea = await this.administradorService.updateTarea(this.tarea);
    if(tarea !== null) {
      this.tarea = tarea;

      let index = ListadoTareasComponent._this.jobsList.findIndex(job => job.id === this.tarea.id);
      ListadoTareasComponent._this.jobsList[index] = this.tarea;

      this.formWindow.close();
      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_almacenado',
        2000
      );
    }else{
      MainComponent.getInstance().showError(
        'ATENCION',
        'Fallo_almacenar_info',
        2000
      );
    }
  }
}
