import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { ConfiguracionCerraduraModel } from './models/configuracionCerradura.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { Accion } from '../auditoria/models/accion.model';
import { MainComponent } from 'src/app/components/main/main.component';
import { AuditoriaService } from '../auditoria/auditoria.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionCerraduraService {
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;

  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  msgChangeResponse(result: any): string {
    return this.auditoria.Info = 'ID: ' + result.id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + result.nombre;
  }

  async createConfiguracionCerradura(configuracionCerradura: ConfiguracionCerraduraModel) {
    let result = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let configCerradura:ConfiguracionCerraduraModel = {...configuracionCerradura};

    configCerradura.empresa = this.empresaId;
    configCerradura.bateriaBaja *= 100;
    configCerradura.bateriaCritica *= 100;
    configCerradura.temperatura *= 10;

    try{
      await this.http.post<ConfiguracionCerraduraModel>(this.urlApi + '/api/cerraduras/configuracion',
        JSON.stringify(configCerradura), httpOptions).toPromise().then(
          res => {
            result = res;

            if (configuracionCerradura.id) {
              this.auditoria.AccionId = Accion.EDITAR_CONFIGURACION_CERRADURA;
            } else {
              this.auditoria.AccionId = Accion.CREAR_CONFIGURACION_CERRADURA;
            }

            this.msgChangeResponse(result);
            this.auditoriaService.addAuditoria(this.auditoria);
          }, err => {
            result = null;
          }
        );
    }catch(e){
      result = null;
    }

    return result;
  }

  async getConfiguracionesCerradua(): Promise<ConfiguracionCerraduraModel[]>{
    let result: ConfiguracionCerraduraModel[] = [];

    try{
      await this.http.get<ConfiguracionCerraduraModel[]>(
        this.urlApi + '/api/cerraduras/configuraciones?enterprise=' + this.empresaId + '&include=all'
      ).toPromise().then(
        res => {
          result = res;

        }, error => {
          result = [];
        }
      );
    }catch(e){
      result = [];
    }

    return result;
  }


  async getConfiguracionCerradua(id: number): Promise<ConfiguracionCerraduraModel>{
    let result: ConfiguracionCerraduraModel = null;

    try{
      await this.http.get<ConfiguracionCerraduraModel>(
        this.urlApi + '/api/cerraduras/configuraciones?id='+id+'&enterprise=' + this.empresaId + '&include=all'
      ).toPromise().then(
        res => {
          result = res;
        }, error => {
          result = null;
        }
      );
    }catch(e){
      result = null;
    }

    return result;
  }

  async deleteConfiguracionCerradura(id: number): Promise<boolean>{
    let response: boolean = false;

    try{
      await this.http.delete(this.urlApi + '/api/cerraduras/configuracion?id='+id+'&enterprise=' + this.empresaId).toPromise().then(
        res => {
          this.auditoria.AccionId = Accion.ELIMINAR_CONFIGURACION_CERRADURA;
          this.auditoria.Info = 'ID: ' + id;
          this.auditoriaService.addAuditoria(this.auditoria);

          response = true;
        }, err => {
          response = false;
        }
      );
    }catch(e){
      response = false;
    }

    return response;
  }

  async associateCerraduraToConfiguracion(idConfiguracion: number, idCerradura: number): Promise<boolean>{
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let body = {
      idConfig : idConfiguracion,
      idCerradura : idCerradura
    };

    try{
      await this.http.post<any>(
        this.urlApi + '/api/cerraduras/configuracion/asociar',
        JSON.stringify(body), httpOptions
      ).toPromise().then(
        res => {
          this.auditoria.AccionId = Accion.ASOCIAR_CERRADURA_CONFIGURACION;
          this.auditoria.Info = 'ID Empresa:' + this.empresaId + ', ID Configuracion: ' + idConfiguracion + ', ID Cerradura: ' + idCerradura;
          this.auditoriaService.addAuditoria(this.auditoria);

          response = true;
        }, err => {
          response = false;
        }
      );
    }catch(e){
      response = false;
    }

    return response;
  }

  async associateCerradurasToConfiguracion(idConfiguracion: number, idsCerradura: number[]): Promise<boolean>{
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let body = {
      configuracion : idConfiguracion,
      cerraduras: idsCerradura
    };

    try{
      await this.http.post<any>(
        this.urlApi + '/api/cerraduras/configuracion/asociar/lista',
        JSON.stringify(body), httpOptions
      ).toPromise().then(
        res => {
          this.auditoria.AccionId = Accion.ASOCIAR_CERRADURA_CONFIGURACION;
          this.auditoria.Info = 'ID Empresa: ' + this.empresaId + ', ID Configuracion: ' + idConfiguracion + ', ID Cerradura: ' + idsCerradura;
          this.auditoriaService.addAuditoria(this.auditoria);

          response = true;
        }, err => {
          response = false;
        }
      );
    }catch(e){
      response = false;
    }

    return response;
  }

  async disassociateCerraduraFromConfiguracion(idCerradura: number): Promise<boolean>{
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let body = {
      idCerradura : idCerradura
    };

    try{
      await this.http.post(this.urlApi + '/api/cerraduras/configuracion/desasociar',
      JSON.stringify(body), httpOptions).toPromise().then(
        res => {
          this.auditoria.AccionId = Accion.DESASOCIAR_CERRADURA_CONFIGURACION;
          this.auditoria.Info = 'ID Empresa' + this.empresaId + ', ID Cerradura: ' + idCerradura;
          this.auditoriaService.addAuditoria(this.auditoria);

          response = true;
        }, err => {
          response = false;
        }
      );
    }catch(e){
      response = false;
    }

    return response;
  }

  async disassociateCerradurasFromConfiguracion(idsCerradura: number[]): Promise<boolean>{
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let body = {
      cerraduras : idsCerradura
    };

    try{
      await this.http.post(this.urlApi + '/api/cerraduras/configuracion/desasociar/lista',
      JSON.stringify(body), httpOptions).toPromise().then(
        res => {
          this.auditoria.AccionId = Accion.DESASOCIAR_CERRADURA_CONFIGURACION;
          this.auditoria.Info = 'ID Empresa' + this.empresaId + ', ID Cerradura: ' + idsCerradura;
          this.auditoriaService.addAuditoria(this.auditoria);

          response = true;
        }, err => {
          response = false;
        }
      );
    }catch(e){
      response = false;
    }

    return response;
  }
}
