import { MapLatLng } from "movisat-maps";

export class GeoUtils {
    // Devuelve la distancia entre dos puntos (en metros)
    public static getDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
        const rad = (x => {
            return x * Math.PI / 180;
        });
        const R = 6378.137; //Radio de la tierra en km 
        const dLat = rad(lat2 - lat1);
        const dLong = rad(lng2 - lng1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(lat1)) *
            Math.cos(rad(lat2)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c * 1000; // Paso a metros
        return d;
    }

    // Devuelve una coordenada sumando N metros 
    public static getNewLatLng(pos: MapLatLng, xMetros, yMetros): MapLatLng {
        const rad = (x => {
            return x * Math.PI / 180;
        });
        const R = 40075000.04;
        const lat = pos.lat + (360 * yMetros / R);
        const lng = pos.lng + (360 * xMetros / (R * Math.cos(rad(pos.lat))));
        return new MapLatLng(lat, lng);
    }

    public static pointIntoPolygon(point: MapLatLng, polygon: number[][]): boolean {
        let inside = false;
        for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
            if (((polygon[i][1] > point.lat) !== (polygon[j][1] > point.lat))
                && (point.lng < ((polygon[j][0] - polygon[i][0]) * (point.lat - polygon[i][1]) /
                    (polygon[j][1] - polygon[i][1]) + polygon[i][0]))) {
                inside = !inside;
            }
            j = i;
        }
        return inside;
    }

}