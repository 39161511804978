export class CatastroModel {
  id: number;

  referenciaCatastral: string = '';
  titular: string = '';

  direccion: string = '';
  codigoPostal: string = '';
  poblacion: string = '';
  municipio: string = '';
  provincia: string = '';
  comunidadAutonoma: string = '';

  numero: string = '';
  planta: string = '';
  puerta: string = '';

  fechaCreacion: Date;
  fechaModificacion: Date;
  fechaBaja: Date;

  constructor() { }
}
