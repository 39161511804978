export class Config {
    public tiempoParada = 5; // Cinco minutos
    public criterio = 'X_VELOCIDAD';
    public colorFijo = '4cff33';
    public colorVel1 = '4cff33';
    public colorVel2 = 'ffbb33';
    public colorVel3 = 'ff333c';
    public colorLu = '33f6ff';
    public colorMa = '4cff33';
    public colorMi = 'ff33f9';
    public colorJu = 'ffbb33';
    public colorVi = 'bb33ff';
    public colorSa = '3342ff';
    public colorDo = 'ff333c';
    public sensor = 16; // Ignición
    public colorSensor1 = '4cff33';
    public colorSensor0 = 'ff333c';
    public colorSensorX = '000000';
}
