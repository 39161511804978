import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { Guid } from 'guid-typescript';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { MapMarker } from 'movisat-maps';
import { MapComponent, MapLatLng } from 'src/app/imports';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { PuService } from 'src/app/services/pu/pu.service';
import { SsoService } from 'src/app/services/sso/sso.service';
import { BdtService } from 'src/app/services/bdt/bdt.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { PuModel } from 'src/app/services/pu/models/pu.model';
import { BdtCatalogoEquipamientoModel } from 'src/app/services/bdt/models/bdt-catalogo-equipamiento.model';

import { PuComponent } from '../pu.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-pu-edit',
  templateUrl: './pu-edit.component.html',
  styleUrls: ['./pu-edit.component.css']
})
export class PuEditComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('cbEquip') cbEquip: jqxDropDownButtonComponent;
  @ViewChild('ebNombre') ebNombre: jqxInputComponent;
  @ViewChild('grid') grid: jqxGridComponent;

  private componentRef = null;
  public environment = environment;
  private map: MapComponent;
  public canEdit = true;
  public puEdit: PuModel;
  private catEquipList: BdtCatalogoEquipamientoModel[] = [];
  private catEquipSelec: BdtCatalogoEquipamientoModel;

  // Subscripciones
  private subscriptionMapClick: any = null;
  private subscriptionOnMarkerDragEnd; any = null;

  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);

  renderClasesColumn = (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value) {
      try {
        let textToShow = '';
        value.forEach(element => {
          textToShow += textToShow == '' ? element.Nombre : ', ' + element.Nombre;
        });

        return `<div style="margin-left: 4px; margin-top: 5px; text-align: left;" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';" onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${textToShow}</div>`;
      } catch (error) {
      }
    }
  }

  public columns = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60, hidden: true },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'elemento', width: 110 },
    {
      text: AppComponent.translate('Residuo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'residuo', width: 180,
      hidden: this.elemService.elemGenericos
    },
    {
      text: AppComponent.translate('Clase'), columntype: 'textbox', filtertype: 'textbox', datafield: 'clase', width: 136,
      cellsrenderer: this.renderClasesColumn,
      hidden: this.elemService.elemGenericos
    }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService,
    private bdtService: BdtService,
    private elemService: ElementsService,
    private puService: PuService) {
    super();
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    this.map = MainComponent.getInstance().getMap();
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Edicion_puntos_ubicacion'));
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, pu: PuModel) {
    this.componentRef = componentRef;
    this.puEdit = pu ? pu : new PuModel();
  }

  // Cierro el formulario y destruyo el componente
  public async onClose() {
    this.map.setMousePointer('');
    this.cbEquip.close();
    if (this.puEdit.Marker) {
      if (this.puEdit.Id < 1) {
        this.map.removeMarker(this.puEdit.Marker);
      }
    }
    if (this.subscriptionMapClick) {
      this.subscriptionMapClick.unsubscribe();
    }
    if (this.subscriptionOnMarkerDragEnd) {
      this.subscriptionOnMarkerDragEnd.unsubscribe();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Para traducir los textos del template
  translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Recupera el catálogo de elementos de la empresa
  async getCatalogoEquipamiento(): Promise<BdtCatalogoEquipamientoModel[]> {
    this.loader.open();
    this.catEquipList = await this.bdtService.getCatalogoEquipamiento();
    if (this.catEquipList) {
      this.source = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number', map: 'Equipamiento>Modelo>Id' },
          { name: 'elemento', type: 'string', map: 'Equipamiento>Elemento>Nombre' },
          { name: 'residuo', type: 'string', map: 'Equipamiento>ResiduosNombres' },
          { name: 'clase', type: 'object', map: 'Equipamiento>Clases' }
        ],
        localdata: this.catEquipList,
        sortcolumn: 'marca',
        sortdirection: 'asc'
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);
    }
    this.loader.close();

    Utils.renderSizeGrid(this.grid, 500);

    return this.catEquipList;
  }

  // Cuando se selecciona un equipamiento
  onRowClick(event: any) {
    this.catEquipSelec = this.catEquipList[event.args.rowindex];
    this.puEdit.Equipamiento = this.catEquipSelec.Equipamiento;
    let content = '';
    if (!this.elemService.elemGenericos) {
      let clasesText = '';
      this.catEquipSelec.Equipamiento.Clases.forEach(clas => {
        clasesText += clasesText === '' ? clas.Nombre : ', ' + clas.Nombre;
      });
      content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
        this.catEquipSelec.Equipamiento.Elemento.Nombre +
        (this.catEquipSelec.Equipamiento.Residuo.Nombre ? ' | ' + this.catEquipSelec.Equipamiento.Residuo.Nombre : '') +
        ' | ' + clasesText +
        '</div>';
    } else {
      content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
        this.catEquipSelec.Equipamiento.Elemento.Nombre + '</div>';
    }
    this.cbEquip.setContent(content);
    this.cbEquip.close();
    if (this.puEdit.Marker) {
      let icono = 'assets/images/pu-generico.png';
      if (!this.elemService.elemGenericos) {
        switch (this.puEdit.Equipamiento.Residuo.Acronimo) {
          case 'ORG':
            icono = 'assets/images/pu-organica.png';
            break;
          case 'FRE':
            icono = 'assets/images/pu-resto.png';
            break;
          case 'PYE':
            icono = 'assets/images/pu-envases.png';
            break;
          case 'PYC':
            icono = 'assets/images/pu-papel.png';
            break;
          case 'VID':
            icono = 'assets/images/pu-vidrio.png';
            break;
        }
      }
      this.puEdit.Marker.setIcon(icono);
    }
  }

  // Cuando se visualiza el formulario
  onOpen(): void {
    // Si es un elemento que ya existe lo centro en el mapa
    if (this.puEdit.Id > 0 && this.puEdit.Marker) {
      this.onPosicionar();
    }
    const t = setTimeout(async () => {
      clearTimeout(t);
      this.catEquipList = await this.getCatalogoEquipamiento();
      if (this.puEdit.Id < 1) {
        // Relleno el combo de equipamientos
        this.catEquipSelec = this.catEquipList[0];
      } else {
        this.catEquipList.forEach((cat, i) => {
          if (cat.IdEquipamiento === this.puEdit.IdEquipamiento) {
            this.catEquipSelec = cat;
          }
        });
      }
      this.puEdit.Equipamiento = this.catEquipSelec.Equipamiento;
      let content = '';
      if (this.elemService.elemGenericos) {
        content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
          this.catEquipSelec.Equipamiento.Elemento.Nombre + '</div>';
      } else {
        let clasesText = '';
        this.catEquipSelec.Equipamiento.Clases.forEach(clas => {
          clasesText += clasesText === '' ? clas.Nombre : ', ' + clas.Nombre;
        });

        content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
          this.catEquipSelec.Equipamiento.Elemento.Nombre +
          (this.catEquipSelec.Equipamiento.Residuo.Nombre ? ' | ' + this.catEquipSelec.Equipamiento.Residuo.Nombre : '') +
          ' | ' + clasesText +
          '</div>';
      }
      this.cbEquip.setContent(content);
      this.ebNombre.focus();
    }, 0);
  }

  closeWindow(){
    this.form.close();
  }

  // Guardar los cambios
  async onGuardar(event: any): Promise<void> {
    let numReg = 0;
    PuComponent.getInstance().puList.forEach(pu => {
      if (pu.Equipamiento.Id === this.catEquipSelec.Equipamiento.Id) {
        numReg++;
      }
    });
    // Si numReg=0 no hay límite de elementos
    if (this.puEdit.Id < 1 && (numReg > 0 && numReg >= this.catEquipSelec.Unidades)) {
      MainComponent.showError('ATENCION', 'Superado_max_puntos_ubicacion', 2000);
      return;
    }
    if (this.puEdit.Equipamiento === null) {
      MainComponent.showError('ATENCION', 'Seleccione_equipamiento', 2000);
      return;
    }
    if (this.puEdit.Nombre.length < 1) {
      MainComponent.showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    if (this.puEdit.Lat === 0 && this.puEdit.Lng === 0) {
      MainComponent.showInfo('ATENCION', 'Seleccione_punto_cartografia', 2000);
      return;
    }
    // Si es un elemento nuevo creo el GUID
    if (this.puEdit.Guid.length < 1) {
      this.puEdit.Guid = Guid.create().toString();
    }
    this.puEdit.Empresa = this.ssoService.getTicket().Empresa.IdGestion;
    this.puEdit.IdEquipamiento = this.puEdit.Equipamiento.Id;
    if (await this.puService.savePU(this.puEdit) !== null) {
      MainComponent.showInfo('ATENCION', 'Registro_almacenado', 2000);
      this.form.close();
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
  }

  // Posicionar el componente sobre la cartografía
  public onPosicionar(): void {
    if (this.puEdit.Id < 1) {
      this.form.collapse();
      // Cambio el puntero del ratón sobre el mapa
      // this.map.setMousePointer('assets/images/center.png');
      if (!this.subscriptionMapClick) {
        this.subscriptionMapClick = this.map.subscribeOnMapClick(this, this.onMapClick);
      }
      if (!this.subscriptionOnMarkerDragEnd) {
        this.subscriptionOnMarkerDragEnd = this.map.subscribeOnMarkerDragEnd(this, (_this: any, marker: MapMarker) => {
          this.puEdit.Lat = marker.position.lat;
          this.puEdit.Lng = marker.position.lng;
        });
      }
    } else {
      this.map.setCenter(this.puEdit.Marker.position);
      this.puEdit.Marker.setZIndex(999);
      if (this.map.zoom < 18) {
        this.map.setZoom(18);
      }
      if(this.puEdit && this.puEdit.Marker) {
        this.puEdit.Marker.animate(2000);
      }
    }
  }

  // Cada vez que se pincha sobre la cartogafía
  onMapClick(_this: any, position: MapLatLng) {
    if (_this.puEdit.Id < 1) {
      let icono = 'assets/images/pu-generico.png';
      if (!_this.elemService.elemGenericos) {
        switch (_this.puEdit.Equipamiento.Residuo.Acronimo) {
          case 'ORG':
            icono = 'assets/images/pu-organica.png';
            break;
          case 'FRE':
            icono = 'assets/images/pu-resto.png';
            break;
          case 'PYE':
            icono = 'assets/images/pu-envases.png';
            break;
          case 'PYC':
            icono = 'assets/images/pu-papel.png';
            break;
          case 'VID':
            icono = 'assets/images/pu-vidrio.png';
            break;
        }
      }
      if (_this.puEdit.Marker) {
        _this.map.removeMarker(_this.puEdit.Marker);
      }
      // Calculo la posición que corresponde con el centro del icono que he puesto como puntero del ratón
      const point = _this.map.latLngToScreenPoint(position);
      // point.x += 16; // El icono es de 32x32
      // point.y += 16;
      const newPosition = _this.map.screenPointToLatLng(point);
      _this.puEdit.Lat = newPosition.lat;
      _this.puEdit.Lng = newPosition.lng;
      _this.puEdit.Marker = _this.map.addMarker({
        dataModel: _this.puEdit,
        label: undefined,
        title: _this.getMarkerTitle(_this.puEdit),
        content: _this.getMarkerContent(_this.puEdit),
        position: new MapLatLng(_this.puEdit.Lat, _this.puEdit.Lng),
        icon: icono,
        zIndex: 999,
        drag: true,
        visible: true
      });
    }
    _this.map.setMousePointer('');
    _this.subscriptionMapClick.unsubscribe();
    _this.subscriptionMapClick = null;
    _this.form.expand();
  }

  // Devuelve el contenido del infowindows del marcador
  getMarkerTitle(pu: PuModel): string {
    return pu.Nombre;
  }

  // Devuelve el contenido del infowindows del marcador
  getMarkerContent(pu: PuModel): string {
    let res = '<b>' + pu.Nombre + '</b><hr>';
    if (!this.elemService.elemGenericos) {
      if (pu.Equipamiento) {
        res += pu.Equipamiento.Elemento.Nombre + '<br>' + pu.Equipamiento.Residuo.Nombre + '<br>';
      }
      if (pu.Elemento) {
        res += '<br><b>' + this.translate('Elemento').toUpperCase() + '</b><br>'
        res += pu.Elemento.Nombre + '<br>' + pu.Elemento.Equipamiento.Marca.Nombre + '<br>' +
          pu.Elemento.Equipamiento.Modelo.Nombre;
      }
    } else {
      if (pu.Equipamiento) {
        res += pu.Equipamiento.Elemento.Nombre + '<br>';
      }
      if (pu.Elemento) {
        res += '<br><b>' + this.translate('Elemento').toUpperCase() + '</b><br>'
        res += pu.Elemento.Nombre;
      }
    }
    return res;
  }

}
