import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { DateUtils } from 'src/app/utils/date-utils';

@Component({
  selector: 'app-elements-edit-historico-coordenadas',
  templateUrl: './elements-edit-historico-coordenadas.component.html',
  styleUrls: ['./elements-edit-historico-coordenadas.component.css', '../elements-edit.component.css']
})
export class ElementsEditHistoricoCoordenadasComponent implements OnInit {
  @ViewChild('gridHistCoordenadas') gridHistCoordenadas: jqxGridComponent;

  @Input() elemEdit: ElementoModel;

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() { }

  ngOnInit(): void {
    this.initGridHistCoordenadas();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;

            if (this.elemEdit && this.sourceHistCoordenadas) {
              this.elemEdit.Coordenadas.sort((a,b) => a.Fecha < b.Fecha ? 1 : -1);
              this.sourceHistCoordenadas.localdata = this.elemEdit.Coordenadas;
              this.gridHistCoordenadas.updatebounddata('data');
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  // Columnas para el grid historico de coordenadas
  columHistCoordenadas;
  sourceHistCoordenadas;
  dataAdapterHistCoordenadas: any;

  initGridHistCoordenadas() {
    this.columHistCoordenadas = [
      { text: this.translate('Fecha'), columntype: 'textbox', filtertype: 'textbox', datafield: 'fecha', autowidth: 'true', cellsrenderer: this.renderDate },
      { text: this.translate('Calle'), columntype: 'textbox', filtertype: 'textbox', datafield: 'calle', autowidth: 'true' },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'poblacion', autowidth: 'true' },
    ];

    this.sourceHistCoordenadas =
    {
      datatype: 'json',
      datafields: [
        { name: 'fecha', type: 'date', map: 'Fecha' },
        { name: 'calle', type: 'number', map: 'Calle' },
        { name: 'poblacion', type: 'number', map: 'Poblacion' }
      ],
      localdata: this.elemEdit?.Coordenadas ? this.elemEdit.Coordenadas : [],
    };

    this.dataAdapterHistCoordenadas = new jqx.dataAdapter(this.sourceHistCoordenadas);
  }

  //Renderizamos las fechas
  renderDate(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      /*
        Cuando se hace new Date le esta añadiendo a la fecha GMT +1
        por tanto se debe de corregir seteando los valores a UTC
      */
      let dateUTC = new Date(value);
      let date = new Date(value);
      dateUTC.setFullYear(date.getUTCFullYear());
      dateUTC.setMonth(date.getUTCMonth());
      dateUTC.setDate(date.getUTCDate());
      dateUTC.setHours(date.getUTCHours());
      return '<div style="margin-left: 3px; margin-top: 6.5px">' + DateUtils.formatDateTimeShort(dateUTC, true) + '</div>';
    }
  }
}
