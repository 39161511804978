import { Injectable } from '@angular/core';
import { Area } from './models/area.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AreaDetail } from './models/area-detail.model';

@Injectable({
  providedIn: 'root'
})
export class CartoService {

  constructor(private http: HttpClient) { }
  //buscar areas por nombre
  async searchAdminAreas(searchString: string): Promise<Area[]> {
    let result: Area[] = null;
    try {
      result = await this.http.get<Area[]>(environment.carto.baseUrl + '/maps/adminareas/?admin_level=4&limit=5&ordering=-surface&search=' +
      searchString).toPromise();
      result = result['data'];
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  //detalles de un area
  async getAreaDetail(id) {
    let result: AreaDetail;
    try {
      result = await this.http.get<AreaDetail>(environment.carto.baseUrl + '/maps/adminareas/' + id).toPromise();


    } catch (e) {
      console.log(e);
    }
    return result;
  }
}
