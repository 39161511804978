import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import {
  MapCircle,
  MapComponent,
  MapLatLng,
  MapPolygon,
  MapPolygonPoint,
} from 'movisat-maps';

import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ConfigService } from 'src/app/services/config/config.service';

import { CasaModel } from 'src/app/services/geographics/casa.model';
import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { TicketModel } from 'src/app/services/sso/models/ticket.model';

export enum TipoGeo {
  CIRCULO = 0,
  POLIGONO = 1,
}
@Component({
  selector: 'app-map-toolbar',
  templateUrl: './map-toolbar.component.html',
  styleUrls: ['./map-toolbar.component.css'],
})
export class MapToolbarComponent extends CustomForms implements OnInit {
  @ViewChild('formContainer', { read: ViewContainerRef }) formContainer;
  @ViewChild('form') form: jqxWindowComponent;

  private _this: MapToolbarComponent;

  //subscriptions
  private subscriptionOnMapClick = null;
  private subscriptionOnPolygonClick = null;
  private subscriptionOnCircleClick = null;
  private subscriptionOnChangeRadius = null;
  private subscriptionOnPolygonDragEnd = null;
  //maps
  private circle: MapCircle;
  private arrPoints = [];
  private polygon: MapPolygon;
  private nPuntos = 0;
  private clickPolygon = false;
  private clickCircle = false;
  public zona: ZonaModel = new ZonaModel();
  private zonaSelec: ZonaModel;
  public superficie = '0';
  public geometria = 'Circulo';

  //buttons
  private clickButtonEdit = false;
  private clickButtonCircle = false;
  private clickButtonPolygon = false;
  public hiddenButton = true;
  public clickTools = false;
  public badgeButton = '';
  private map: MapComponent;

  //styles and design
  public positionForm = 'center';
  public valueGroup = '';
  public environment = environment;
  //sso
  public ticketSSO: TicketModel;
  mapWidth: any;
  mapHeight: any;

  constructor(
    private configService: ConfigService,
    private modal: NzModalService,
    private zonesService: ZonesService
  ) {
    super();
    this._this = this;
  }

  ngOnInit() {
    this.ticketSSO = JSON.parse(localStorage.getItem('ticketSSO'));
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.form);
    this.form.setTitle(this.translate('Edicion_poligonos'));
    this.setMargin();
    this.getMap();
  }

  //se activa cuando se presiona algún botón del grupo de botones
  toggleChange(event) {
    let toggle = event.source;
    let optionSelected;
    if (toggle) {
      let group = toggle.buttonToggleGroup;
      if (event.value.some((item) => item == toggle.value)) {
        group.value = [toggle.value];
        //cambia el icono del badge en el button tools
        this.badgeButton = group.value;
        optionSelected = group.value;

        if (optionSelected == 'home') {
          this.badgeButton = '';
          toggle.checked = false;
        }
      } else {
        this.badgeButton = '';
        optionSelected = [];
      }
      this.onSelectOption(optionSelected);
    }
  }

  //cuando se hace click sobre button tools
  onClickTools() {
    this.hiddenButton = this.hiddenButton ? false : true;
  }

  onPolygonDragEnd(_this: MapToolbarComponent) {
    _this.calcArea();
    _this.superficie = NumberUtils.format(_this.zona.Area, 0);
  }

  getPositionInMap(positionFigure) {
    if (
      positionFigure.x > this.mapWidth ||
      positionFigure.x < 0 ||
      positionFigure.y > this.mapHeight ||
      positionFigure.y < 0
    ) {
      return 'center';
    } else {
      return positionFigure;
    }
  }

  //filtra el botón seleccionado del button group
  onSelectOption(optionSelected) {
    this.resetParams();
    this.onClickTools();
    this.form.isOpen ? this.form.close() : null;
    switch (optionSelected[0]) {
      case 'home':
        this.onSelectHome();
        break;
      case 'circle':
        this.clickButtonCircle = true;
        this.onSelectEdit();
        break;
      case 'polyline':
        this.clickButtonPolygon = true;
        this.onSelectEdit();
        break;
      //cuando se deselecciona algún botón, es undefined
      case undefined:
        this.resetParams();
        this.unsubscribeAll();
        break;
    }
  }

  //gestiona el dibujo de la casa
  async onSelectHome() {
    this.unsubscribeAll();
    const res = await this.configService.getEmpApp('casa', null);
    if (res) {
      const casa: CasaModel = JSON.parse(res);
      MainComponent.getActiveMap().setZoom(casa.zoom);
      MainComponent.getActiveMap().setCenter(casa.posicion);
    }
  }

  //cuando se hace click sobre boton círculo o botón polígono
  onSelectEdit() {
    //Si existen se eliminan las suscripciones
    this.unsubscribeAll();

    this.subscriptionOnCircleClick = this.map.subscribeOnCircleClick(
      this,
      this.onCircleClick
    );
    this.subscriptionOnPolygonClick = this.map.subscribeOnPolygonClick(
      this,
      this.onPolygonClick
    );
    this.subscriptionOnMapClick = this.map.subscribeOnMapClick(
      this,
      this.onMapClick
    );
    this.subscriptionOnChangeRadius = this.map.subscribeOnCircleRadiusChange(
      this,
      this.onChangeRadiusOnMap
    );
    this.subscriptionOnPolygonDragEnd = this.map.subscribeOnPolygonDragEnd(
      this,
      this.onPolygonDragEnd
    );
  }

  //activo cuando se hace click sobre el mapa
  onMapClick(_this: MapToolbarComponent, position: MapLatLng) {
    if (_this.clickPolygon || _this.clickCircle || _this.clickButtonEdit) {
      return;
    }

    _this.clickPolygon = false;
    _this.clickCircle = false;
    _this.arrPoints.push(position);
    if (_this.clickButtonCircle) {
      if (_this.circle) {
        _this.map.removeCircle(_this.circle);
        _this.circle = null;
      }
      _this.zona = new ZonaModel();
      _this.zona.Nombre = null;
      _this.zona.Observaciones = null;
      _this.zona.TipoGeo = 0;
      _this.circle = _this.map.addCircle({
        dataModel: _this.zona,
        content: 'círculo',
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        position: _this.arrPoints[0],
        draggable: true,
        editable: true,
        radius: _this.zona.Radio,
      });
    } else {
      if (_this.arrPoints.length == 1) {
        _this.zona = new ZonaModel();
        _this.zona.Nombre = null;
        _this.zona.Observaciones = null;
        _this.zona.TipoGeo = 1;
        _this.polygon = _this.map.addPolygon({
          content: 'polígono',
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          editable: true,
        });
        _this.map.addPolygonPoint(_this.polygon, {
          dataModel: _this.zona,
          position: _this.arrPoints[0],
          content: 'point',
        });
      }
      _this.map.addPolygonPoint(_this.polygon, {
        dataModel: _this.zona,
        position: _this.arrPoints[_this.nPuntos],
        content: 'point',
      });
    }
    _this.nPuntos++;
  }

  //se activa cunado se hace click sobre un polígono
  onPolygonClick(_this: MapToolbarComponent, polygonPoint: MapPolygonPoint) {
    _this.clickPolygon = true;
    if ((_this.polygon && _this.form.isOpen()) || _this.clickButtonCircle) {
      return;
    }
    _this.polygon = polygonPoint.polygon;
    if (polygonPoint.dataModel != undefined) {
      _this.zona = polygonPoint.dataModel;
      //comprobar que es un polígono guardado
      if (polygonPoint.dataModel.Id != 0) {
        _this.superficie = NumberUtils.format(_this.zona.Area, 0);
        //en las zonas administrativas no permitimos editar puntos
        if (polygonPoint.dataModel.Tipo !== 1) {
          _this.zonesService.hideZone(polygonPoint.dataModel.Id);
          _this.polygon = _this.map.addPolygonsFromGeoJson(
            polygonPoint.dataModel.Geometria,
            true,
            {
              dataModel: _this.zona,
              content: _this.zona.Nombre,
              strokeColor: '#ff0000',
              strokeOpacity: 0.3,
              strokeWeight: 1,
              fillColor: '#0000ff',
              fillOpacity: 0.1,
              editable: true,
            }
          )[0];
        }
      }
    }
    _this.calcArea();
    _this.superficie = NumberUtils.format(_this.zona.Area, 0);
    delete polygonPoint.dataModel.polygon;
    delete polygonPoint.dataModel.polygons;
    delete polygonPoint.dataModel.map;
    _this.zonaSelec = Utils.clone(polygonPoint.dataModel);
    _this.geometria = 'Poligono';

    _this.form.open();
    const t = setTimeout(() => {
      clearTimeout(t);
      _this.form.expand();
    }, 250);
  }

  //se activa cunado se hace click sobre un círculo
  onCircleClick(_this: MapToolbarComponent, circle: MapCircle) {
    if (_this.clickButtonPolygon) {
      return;
    }
    if (_this.circle && _this.form.isOpen()) {
      return;
    }

    _this.circle = circle;
    _this.zona = circle.dataModel;
    if (circle.dataModel.Id != 0) {
      _this.zonesService.hideZone(circle.dataModel.Id);
      _this.circle = _this.map.addCircle({
        dataModel: circle.dataModel,
        content: circle.dataModel.Nombre,
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        position: circle.center,
        radius: circle.radius,
        draggable: true,
        editable: true,
      });
    }
    delete circle.dataModel.circle;
    delete circle.dataModel.map;
    _this.zonaSelec = Utils.clone(circle.dataModel);
    _this.zona.TipoGeo = 0;
    _this.zona.TipoGeoNombre = 'Circulo';
    _this.clickCircle = true;
    _this.geometria = 'Circulo';

    _this.form.open();
    const t = setTimeout(() => {
      clearTimeout(t);
      _this.form.expand();
    }, 250);
  }

  //traigo mapa del componente principal
  getMap() {
    setTimeout(() => {
      this.map = MainComponent.getActiveMap();
      Promise.resolve(1);
    }, 1000);
  }

  //resetea parámetros cada vez que se selecciona un botón
  resetParams() {
    this.nPuntos = 0;
    this.arrPoints = [];
    this.clickButtonEdit = false;
    this.clickButtonCircle = false;
    this.clickButtonPolygon = false;
    if (this.circle) {
      this.map.removeCircle(this.circle);
      this.circle = null;
    }
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.polygon = null;
    }
  }

  unsubscribeAll() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
    if (this.subscriptionOnPolygonClick) {
      this.subscriptionOnPolygonClick.unsubscribe();
      this.subscriptionOnPolygonClick = null;
    }
    if (this.subscriptionOnCircleClick) {
      this.subscriptionOnCircleClick.unsubscribe();
      this.subscriptionOnCircleClick = null;
    }
    if (this.subscriptionOnChangeRadius) {
      this.subscriptionOnChangeRadius.unsubscribe();
      this.subscriptionOnChangeRadius = null;
    }

    if (this.subscriptionOnPolygonDragEnd) {
      this.subscriptionOnPolygonDragEnd.unsubscribe();
      this.subscriptionOnPolygonDragEnd = null;
    }
  }

  //acción boton de borrar
  public onBorrar(event) {
    this.clickCircle = false;
    this.clickPolygon = false;
    this.nPuntos = 0;
    this.arrPoints = [];
    if (this.zonaSelec.Id != 0) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent:
          AppComponent.translate('Quiere_borrar_zona') +
          ': ' +
          this.zonaSelec.Nombre +
          ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          const result = await this.zonesService.deleteZona(this.zonaSelec.Id);
          if (this.polygon) {
            this.map.removePolygon(this.polygon);
            this.polygon = null;
          }

          if (this.circle) {
            this.map.removeCircle(this.circle);
            this.circle = null;
          }
          this.zonaSelec = null;
          delete this.zona;
          this.zona = new ZonaModel();
          this.zona.Nombre = null;
          this.zona.Observaciones = null;
          this.superficie = '0';
          this.form.close();
        },
      });
    } else {
      this.form.close();
    }
  }

  //acción boton de guardar
  public onGuardar(event) {
    if (this.polygon) {
      this.zona.polygons = [];
      this.zona.polygons.push(this.polygon);
      this.zona.Geometria = this.polygon2Geojson([this.polygon]);
    }
    this.zona.Tipo = 2; //manual
    if (this.circle) {
      this.zona.Lat = this.circle.center.lat;
      this.zona.Lng = this.circle.center.lng;
    }
    this.saveZona();
  }

  //Crear multipolígono geojson a partir de polígonos
  polygon2Geojson(polygons: MapPolygon[]) {
    let geometry = { type: 'MultiPolygon', coordinates: [] };
    let geojson = { geometry: geometry };
    polygons.forEach((polygon) => {
      let arrPoints = [];
      polygon.points.forEach((polygonPoint) => {
        arrPoints.push([polygonPoint.point.lng, polygonPoint.point.lat]);
      });
      //la primera y la última coordenada han de ser la misma, eso significa que el poligono está cerrado
      arrPoints.push(arrPoints[0]);
      geometry.coordinates.push([arrPoints]);
    });
    geojson.geometry = geometry;
    geometry.type = 'MultiPolygon';
    return geojson;
  }

  //cálcular area
  calcArea() {
    this.zona.Area = 0;
    for (let i = 0; i < this.polygon.points.length - 1; i++) {
      const p1 = this.polygon.points[i].point;
      const p2 = this.polygon.points[i + 1].point;
      this.zona.Area +=
        this.degToRad(p2.lng - p1.lng) *
        (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    }
    // Invento un último punto igual al primero
    const p1 = this.polygon.points[this.polygon.points.length - 1].point;
    const p2 = this.polygon.points[0].point;
    this.zona.Area +=
      this.degToRad(p2.lng - p1.lng) *
      (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    this.zona.Area = Math.abs((this.zona.Area * 6378137 * 6378137) / 2.0);
    this.zona.Area = Number.parseInt(this.zona.Area.toFixed(2));
  }

  // Pasa grados a radianes
  degToRad(deg: number): number {
    return (deg * Math.PI) / 180.0;
  }

  // Cuando se modifica el radio del círculo sobre la cartografía
  onChangeRadiusOnMap(_this: MapToolbarComponent, circle: MapCircle) {
    _this.zona.Radio = Number.parseInt('' + circle.radius);
  }

  //se activa cuando se cambia el radio del círculo desde el mapa
  onChangeRadius() {
    if (this.circle) {
      this.circle.setRadius(Number.parseInt('' + this.zona.Radio));
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  //boton cancelar
  onCancelar() {
    this.form.close();
  }

  //cuando se cierra el formulario
  onClose() {
    this.clickCircle = false;
    this.clickPolygon = false;
    this.nPuntos = 0;
    this.arrPoints = [];

    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.polygon = null;
    }

    if (this.circle) {
      this.map.removeCircle(this.circle);

      this.circle = null;
    }

    if (this.zonaSelec) {
      this.zonesService.showZone(this.zonaSelec);
      this.zonaSelec = null;
    }
    this.superficie = '0';
  }

  // Guardo la zona
  async saveZona() {
    if (this.zona.Nombre === null || this.zona.Nombre.length < 1) {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Introduzca_nombre',
        2000
      );
      return;
    }
    //para evitar dependencia circular
    delete this.zona.polygons;
    delete this.zona.Puntos;
    delete this.zona.map;
    delete this.zona.circle;
    let response = await this.zonesService.saveZona(this.zona);
    if (response) {
      this.zona = response;
      this.zonaSelec = this.zona;
      this.form.close();
      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_almacenado',
        2000
      );
    } else {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Fallo_almacenar_info',
        2000
      );
    }
    this.resetParams();
  }

  //cambia margen derecho según cartografía
  public setMargin() {
    let toolbar = document.getElementById('toolbar');
    if (this.ticketSSO.Empresa.Cartografia == 'Google') {
      toolbar.style.right = '10px';
    }
  }
}
