import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { SsoService } from 'src/app/services/sso/sso.service';
import { TarjetasService } from 'src/app/services/ciudadanos/tarjetas/tarjetas.service';

import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { MainComponent } from '../../main/main.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-ciudadanos-tarjetas',
  templateUrl: './ciudadanos-tarjetas.component.html',
  styleUrls: ['./ciudadanos-tarjetas.component.css']
})
export class CiudadanosTarjetasComponent implements OnInit, AfterViewInit, OnChanges {
  // Componentes del HTML
  @ViewChild('gridTarjetasAsignadas') gridTarjetasAsignadas: jqxGridComponent;
  @ViewChild('gridTarjetasLibres') gridTarjetasLibres: jqxGridComponent;

  // Entradas
  @Input() ciudadano: CiudadanoModel;
  @Input() inputDireccion: number;
  @Input() guardar = false;

  // Funcionalidad
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  // Grid asignadas
  public columnsTarjetasAsignadas = [
    { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
    { text: this.translate('Modo'), columntype: 'textbox', datafield: 'tipo', width: 50, cellsrenderer: this.renderTipo },
    { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: 'nsMovisat', editable: false, width: 85 },
    { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion', editable: false, },
    {
      text: this.translate('Tipo'), columntype: 'textbox', datafield: 'master', width: 60, cellsrenderer:
        (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any) => {
          if (value) {
            return '<div class="jqx-grid-cell-left-align" style="margin-top: 1.5px; cursor: pointer">' +
              MainComponent.translate('Master') + '</div>';
          }
          else {
            return '<div class="jqx-grid-cell-left-align" style="margin-top: 1.5px; cursor: pointer">' +
              MainComponent.translate('Blanca') + '</div>';
          }
        }
    },
    { text: this.translate('Direccion'), columntype: 'textbox', datafield: 'direccion', editable: false, cellsrenderer: this.renderDireccionTarjeta },
  ];

  public sourceTarjetasAsignadas: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number', map: 'id' },
      { name: 'tipo', type: 'string', map: 'virtual' },
      { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
      { name: 'descripcion', type: 'string', map: 'descripcion' },
      { name: 'master', type: 'string', map: 'master' },
      { name: 'direccion', type: 'object', map: 'direccion' }
    ],
    localdata: [],
  };

  renderTipo(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 1.5px; cursor: pointer">V</div>';
    } else {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 1.5px; cursor: pointer">F</div>';
    }
  }

  public adapterTarjetasAsignadas: any;
  public tarjetaAsignadaSelect: TarjetaModel;

  renderDireccionTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any): string {
    let showText = '';

    if (value) {
      showText =
        (`<div class="jqx-grid-cell-left-align" style="margin-top: 1.5px;">` +
          (value.direccion ? value.direccion : '') +
          (value.numero ? ', ' + value.numero : '') +
          (value.poblacion ? ', ' + value.poblacion : '') +
          (value.provincia ? ', ' + value.provincia : '') +
          `</div>`);
    }

    return showText;
  }

  // Tarjeta seleccionada para mover entre un grid y otro
  public tarjetasLibres: TarjetaModel[] = [];
  public tarjetasLibresVirgen: TarjetaModel[] = [];
  public columnsTarjetasLibres = [
    { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
    { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: 'nsMovisat', width: 85 },
    { text: this.translate('Ns_fabricante'), columntype: 'textbox', datafield: 'uuidHex', width: 100 },
    { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion' },
  ];
  public sourceTarjetasLibres: any;
  public adapterTarjetasLibres: any;
  public tarjetaLibreSelect: TarjetaModel;

  // Listados de tarjetas que se van a asociar/desasociar al guardar el ciudadano
  public tarjetasToAssociate: any[] = [];
  public tarjetasToDisassociate: any[] = [];

  constructor(private tarjetaServicio: TarjetasService,
    private modal: NzModalService,
    private ssoService: SsoService) {
  }

  // Traducción de los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  ngOnInit() {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit() {
    this.cargarGrids();
  }

  cargarGrids() {
    try {
      this.initGridDirecciones();
    } catch (error) {
    }
    try {
      this.initGridTarjetasLibres();
    } catch (error) {
    }
    try {
      this.initGridTarjetasAsignadas();
    } catch (error) {
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'ciudadano':
            this.ciudadano = changes[propName].currentValue;

            this.ciudadano.tarjetas.forEach(tarj => {
              this.ciudadano.direcciones.forEach(dir => {
                if (tarj.direccion.id === dir.id) {
                  dir.numeroTarjetas = tarj.direccion.numeroTarjetas;
                }
              })
            });
            break;
          case 'inputDireccion':
            this.direccionSelec = changes[propName].currentValue;

            this.setCBDireccionesContent();
            break;
          case 'guardar':
            this.guardar = changes[propName].currentValue;
            if (this.guardar === true && this.ciudadano.id > 0) {
              this.onGuardar();
            }
            break;
          default:
            break;
        }
      }
    }
  }

  // Al llamar el metodo se hace la peticion al servidor
  async onGuardar() {
    await this.tarjetaServicio.associateTarjetasToCiudadano(this.tarjetasToAssociate, this.ciudadano.id);
    await this.tarjetaServicio.disassociateTarjetasFromCiudadano(this.tarjetasToDisassociate, this.ciudadano.id);
  }

  // Se llama cuando se cambia una tarjeta libre al grid de ciudadano
  associateTarjetasToCiudadano() {
    // Si la tarjeta no está grabada la mando grabar
    if (this.tarjetaLibreSelect.idInterno < 1) {
      this.onNewCard();
      return;
    }
    // Si no está en la lista de asociadas la añado
    if (!this.tarjetasToAssociate.find(x => x.id === this.tarjetaLibreSelect.id)) {
      try {
        this.tarjetasToAssociate.push({ "id": this.tarjetaLibreSelect.id, "master": false, "idDireccion": this.direccionSelec.id });
      } catch (e) {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Selecciona_direccion_tarjeta',
          2000
        );
      }
    }
    // Si está en la lista de desasociadas la quito
    for (let index = 0; index < this.tarjetasToDisassociate.length; index++) {
      if (this.tarjetasToDisassociate[index].id === this.tarjetaLibreSelect.id) {
        this.tarjetasToDisassociate.splice(index, 1);
        break;
      }
    }
    // Si no está añado la tarjeta a la lista de tarjetas del ciudadano
    if (this.ciudadano.tarjetas && !this.ciudadano.tarjetas.find(x => x.id === this.tarjetaLibreSelect.id)) {
      this.tarjetaLibreSelect.direccion = this.direccionSelec;
      this.ciudadano.tarjetas.push(this.tarjetaLibreSelect);
    }
    // Si está en la lista de tarjetas libres la quito
    for (let index = 0; index < this.tarjetasLibres.length; index++) {
      if (this.tarjetasLibres[index].id === this.tarjetaLibreSelect.id) {
        this.tarjetasLibres.splice(index, 1);
        break;
      }
    }
    this.gridTarjetasLibres.clearselection();
    this.tarjetaLibreSelect = null;
    this.updateGrids();

    this.updateGridTarjetasAsignadas();
  }

  // Se llama cuando se cambia una tarjeta del grid de ciudadano a libre
  disassociateTarjetasFromCiudadano() {
    // Si no está en la lista de desasociadas la añado
    if (!this.tarjetasToDisassociate.find(x => x.id === this.tarjetaAsignadaSelect.id)) {
      this.tarjetasToDisassociate.push({ "id": this.tarjetaAsignadaSelect.id, "master": false, "idDireccion": this.tarjetaAsignadaSelect.direccion.id });
    }
    // Si está en la lista de asociadas la quito
    for (let index = 0; index < this.tarjetasToAssociate.length; index++) {
      if (this.tarjetasToAssociate[index].id === this.tarjetaAsignadaSelect.id) {
        this.tarjetasToAssociate.splice(index, 1);
        break;
      }
    }
    // Si está en la lista de tarjetas del ciudadano la quito
    for (let index = 0; this.ciudadano.tarjetas && index < this.ciudadano.tarjetas.length; index++) {
      if (this.ciudadano.tarjetas[index].id === this.tarjetaAsignadaSelect.id) {
        this.ciudadano.tarjetas.splice(index, 1);
        break;
      }
    }
    // Si no está en la lista de tarjetas libres la añado
    if (this.tarjetasLibres && !this.tarjetasLibres.find(x => x.id === this.tarjetaAsignadaSelect.id)) {
      this.tarjetasLibres.push(this.tarjetaAsignadaSelect);
    }
    this.gridTarjetasAsignadas.clearselection();
    this.tarjetaAsignadaSelect = null;
    this.updateGrids();

    this.updateGridTarjetasAsignadas();
  }

  // Actualiza los datos de los grids
  updateGrids() {
    this.gridTarjetasLibres.updatebounddata('data');
    this.gridTarjetasAsignadas.updatebounddata('data');
  }

  // Inicializa el grid de tarjetas asociadas al ciudadano
  async initGridTarjetasAsignadas() {
    if (this.ciudadano.id != 0) {
      this.ciudadano.tarjetas.sort((a, b) => (a.nsMovisat > b.nsMovisat) ? 1 : ((b.nsMovisat > a.nsMovisat) ? -1 : 0));
    }

    let tarjToShow: TarjetaModel[] = []
    if (this.direccionSelec && this.direccionSelec.id >= 0) {
      this.ciudadano.tarjetas.forEach(tarj => {
        if (tarj.direccion && tarj.direccion.id === this.direccionSelec.id) {
          tarjToShow.push(tarj);
        }
      });
    } else {
      tarjToShow = this.ciudadano.tarjetas;
    }

    this.sourceTarjetasAsignadas = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'tipo', type: 'string', map: 'virtual' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'descripcion', type: 'string', map: 'descripcion' },
        { name: 'master', type: 'string', map: 'master' },
        { name: 'direccion', type: 'object', map: 'direccion' }
      ],
      localdata: tarjToShow,
    }
    this.adapterTarjetasAsignadas = new jqx.dataAdapter(this.sourceTarjetasAsignadas);
    if (this.ciudadano.tarjetas && this.ciudadano.tarjetas.length > 0) {
      this.ciudadano.tarjetas.forEach(tarjeta => {
        this.tarjetasToAssociate.push({ "id": tarjeta.id, "master": tarjeta.master, "idDireccion": this.direccionSelec.id });
      });
    }
  }

  // Inicializa el grid de tarjetas libres (sólo las grabadas)
  async initGridTarjetasLibres() {
    this.tarjetasLibres = [];
    const res: TarjetaModel[] = await this.tarjetaServicio.getTarjetasLibres();
    if (res && res.length > 0) {
      res.forEach(tarjeta => {
        if (tarjeta.grabada) {
          this.tarjetasLibres.push(tarjeta);
        } else {
          this.tarjetasLibresVirgen.push(tarjeta);
        }
      });
    }
    this.tarjetasLibres.sort((a, b) => (a.nsMovisat > b.nsMovisat) ? 1 : ((b.nsMovisat > a.nsMovisat) ? -1 : 0))

    this.sourceTarjetasLibres = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'descripcion', type: 'string', map: 'descripcion' },
        { name: 'uuidHex', type: 'string', map: 'uuidHex' },
      ],
      localdata: this.tarjetasLibres,
    }
    this.adapterTarjetasLibres = new jqx.dataAdapter(this.sourceTarjetasLibres);
  }

  // Cambia el tipo de tarjeta entre blanca y master
  onCellClick(event: any) {
    if (event.args.datafield === 'master') {
      this.tarjetasToAssociate.find(t => t.id == event.args.row.bounddata.id).master = !event.args.value;
      this.ciudadano.tarjetas.find(t => t.id == event.args.row.bounddata.id).master = !event.args.value;
      this.gridTarjetasAsignadas.updatebounddata();
    }
  }

  // Cambia el tipo de tarjeta entre blanca y master
  onCellEdit(event: any) {
    this.tarjetasToAssociate.find(t => t.id == event.args.row.id).master = event.args.value;
    this.ciudadano.tarjetas.find(t => t.id == event.args.row.id).master = event.args.value;
  }

  onRowSelectAsignada(event: any) {
    let index = this.ciudadano.tarjetas.findIndex(tarj => tarj.id === event.args.row.bounddata.id);
    this.tarjetaAsignadaSelect = this.ciudadano.tarjetas[index];
  }

  onRowSelectLibre(event: any) {
    this.tarjetaLibreSelect = this.tarjetasLibres[event.args.rowindex];
  }

  // Lee o graba una nueva tarjeta desde el grabador
  async onNewCard() {
    try {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_grabar_tarjeta'),
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('ACEPTAR'),
        nzZIndex: 999,
        nzOnOk: async () => {
          // Obtengo los datos de la tarjeta que hay en el grabador
          const tarjeta = await this.tarjetaServicio.consultarTarjetaAGrabar();
          if (tarjeta) {
            if (tarjeta.uuid && tarjeta.uuid > 0) {
              if (tarjeta.Virtual) {
                tarjeta.idZona = 255; // Para separar las tarjetas virtuales de las físicas
                let ultimaTarjeta: TarjetaModel;
                if (tarjeta.idTarjeta < 1) {
                  ultimaTarjeta = await this.tarjetaServicio.getUltimaTarjeta(tarjeta.idZona);
                } else {
                  if (this.tarjetasLibres && !this.tarjetasLibres.find(x => x.nsMovisat === tarjeta.nsMovisat)) {
                    MainComponent.showError('ATENCION', "Tarjeta_asignada_otro_ciudadano", 3000);
                    return;
                  }
                }
                tarjeta.empresa = this.ssoService.getTicket().Empresa.IdGestion;
                if (tarjeta.idTarjeta < 1) {
                  tarjeta.idInterno = ultimaTarjeta ? ultimaTarjeta.idInterno + 1 : 1;
                  tarjeta.idTarjeta = (tarjeta.idInterno & 0xFFFFFF) + ((tarjeta.idZona & 0xFF) << 24);
                } else {
                  tarjeta.idInterno = (tarjeta.idTarjeta & 0xFFFFFF);
                }
                const res = await this.tarjetaServicio.grabaTarjeta(tarjeta);
                if (res) {
                  if ((res as any).Error) {
                    MainComponent.showError('ATENCION', (res as any).Error, 3000);
                  } else {
                    // Me quedo con los datos que recibo por si acaso no se ha grabado una nueva
                    // sino que se trata de la lectura de una tarjeta ya grabada
                    tarjeta.uuid = res.uuid;
                    tarjeta.nsMovisat = res.nsMovisat;
                    tarjeta.idZona = res.idZona;
                    tarjeta.idInterno = res.idInterno;
                    tarjeta.grabada = true;
                    tarjeta.idTarjeta = (res.idInterno & 0xFFFFFF) + ((res.idZona & 0xFF) << 24);
                    tarjeta.ciudadano = this.ciudadano;
                    //tarjeta.descripcion = "TARJETA " + (tarjeta.idZona > 0 ? tarjeta.idZona + "/" : "") + tarjeta.idInterno;
                    tarjeta.descripcion = "TARJETA " + (tarjeta.Virtual ? "V" + tarjeta.idInterno : tarjeta.idInterno);
                    this.tarjetaLibreSelect = await this.tarjetaServicio.creaTarjeta(tarjeta);
                    if (this.tarjetaLibreSelect) {
                      // Si la tarjeta creada ya la tenía el ciudadano, la quito de la lista para que
                      // se almacene de nuevo con el nuevo identificador interno asignado
                      for (let i = 0; i < this.ciudadano.tarjetas.length; i++) {
                        if (this.ciudadano.tarjetas[i].id === this.tarjetaLibreSelect.id) {
                          this.ciudadano.tarjetas.splice(i, 1);
                          break;
                        }
                      }
                      this.associateTarjetasToCiudadano();
                      MainComponent.getInstance().showSuccess('ATENCION', 'Tarjeta_grabada', 2000);
                    }
                    return;
                  }
                } else {
                  MainComponent.showError('ATENCION', 'No_hay_conexion_app_nfcwrite', 5000);
                }
                return;
              }

              // Compruebo si la tarjeta está libre
              this.tarjetaLibreSelect = this.tarjetasLibres.find(x => x.uuid === tarjeta.uuid);
              if (!this.tarjetaLibreSelect) {
                this.tarjetaLibreSelect = this.tarjetasLibresVirgen.find(x => x.uuid === tarjeta.uuid);
                if (!this.tarjetaLibreSelect) {
                  // Compruebo si la tiene asociada algún ciudadano
                  const tarjetas = await this.tarjetaServicio.getTarjetasWithCiudadanos();
                  if (tarjetas) {
                    const tc = tarjetas.find(x => x.uuid === tarjeta.uuid);
                    if (tc && !tc.ciudadano) {
                      if (tarjeta.grabada) {
                        this.tarjetasLibres.push({ ...tc });
                      } else {
                        this.tarjetasLibresVirgen.push({ ...tc });
                      }
                      this.tarjetaLibreSelect = { ...tc };
                    }
                  }
                }
              }

              if (this.tarjetaLibreSelect) {
                // Compruebo si la tarjeta ya está grabada
                const idTarjetaExterno = this.getIdExterno(this.tarjetaLibreSelect);
                if (idTarjetaExterno < 1) {
                  MainComponent.showError('ATENCION', 'Tarjeta_no_registrada', 3000);
                  return;
                }
                if (this.tarjetaLibreSelect.idTarjeta != idTarjetaExterno || tarjeta.idTarjeta != idTarjetaExterno) {
                  // Mando grabar la tarjeta
                  tarjeta.empresa = this.ssoService.getTicket().Empresa.IdGestion;
                  tarjeta.idZona = (idTarjetaExterno & 0xFF000000) >> 24;
                  tarjeta.idInterno = (idTarjetaExterno & 0xFFFFFF);
                  tarjeta.grabada = true;
                  tarjeta.idExterno = this.tarjetaLibreSelect.idExterno;
                  tarjeta.idTarjeta = (tarjeta.idInterno & 0xFFFFFF) + ((tarjeta.idZona & 0xFF) << 24);
                  tarjeta.nsMovisat = this.tarjetaLibreSelect.nsMovisat;
                  tarjeta.ciudadano = this.ciudadano;
                  //tarjeta.descripcion = "TARJETA " + (tarjeta.idZona > 0 ? tarjeta.idZona + "/" : "") + tarjeta.idInterno;
                  tarjeta.descripcion = "TARJETA " + (tarjeta.Virtual ? "V" + tarjeta.idInterno : tarjeta.idInterno);
                  const res = await this.tarjetaServicio.grabaTarjeta(tarjeta);
                  if (res) {
                    if ((res as any).Error) {
                      MainComponent.showError('ATENCION', (res as any).Error, 3000);
                    } else {
                      // Si era una tarjeta virgen la paso a la lista de las disponibles
                      const tarjetaLibre = this.tarjetasLibresVirgen.find(x => x.nsMovisat === tarjeta.nsMovisat);
                      if (tarjetaLibre) {
                        this.tarjetasLibres.push(Utils.clone(tarjetaLibre));
                        for (let i = 0; i < this.tarjetasLibresVirgen.length; i++) {
                          if (this.tarjetasLibresVirgen[i].id === tarjetaLibre.id) {
                            this.tarjetasLibresVirgen.splice(i, 1);
                            break;
                          }
                        }
                      }
                      this.tarjetaLibreSelect = await this.tarjetaServicio.creaTarjeta(tarjeta);
                      if (this.tarjetaLibreSelect) {
                        this.saveTarjeta();
                      }
                      return;
                    }
                  } else {
                    MainComponent.showError('ATENCION', 'No_hay_conexion_app_nfcwrite', 5000);
                  }
                } else {
                  this.saveTarjeta();
                }
              } else {
                if (this.tarjetasToAssociate && !this.tarjetasToAssociate.find(x => x.id === tarjeta.id)) {
                  const tarjetasCiudadano = await this.tarjetaServicio.getTarjetasWithCiudadanos();
                  let tm: TarjetaModel;
                  if ((tm = tarjetasCiudadano.find(x => x.uuid === tarjeta.uuid))) {
                    if (tm.ciudadano && tm.ciudadano.id != this.ciudadano.id) {
                      MainComponent.showError('ATENCION', "Tarjeta_asignada_otro_ciudadano", 3000);
                    }
                  } else {
                    MainComponent.showError('ATENCION', 'Tarjeta_no_registrada', 3000);
                  }
                } else {
                  MainComponent.showError('ATENCION', 'Tarjeta_no_registrada', 3000);
                }
              }
            }
          } else {
            MainComponent.showError('ATENCION', 'No_hay_conexion_app_nfcwrite', 5000);
          }
          return;
          /*
          // De momento no hay zonas, todas las tarjetas se graban sin zona
          const idZona = 0;
          const ultimaTarjeta: TarjetaModel = await this.tarjetaServicio.getUltimaTarjeta(idZona);
          const tarjeta = new TarjetaModel();
          tarjeta.empresa = this.ssoService.getTicket().Empresa.IdGestion;
          tarjeta.idZona = idZona;
          tarjeta.idInterno = ultimaTarjeta ? ultimaTarjeta.idInterno + 1 : 1;
          tarjeta.idTarjeta = (tarjeta.idInterno & 0xFFFFFF) + ((idZona & 0xFF) << 24);
          const res = await this.tarjetaServicio.grabaTarjeta(tarjeta);
          if (res) {
            if ((res as any).Error) {
              MainComponent.showError('ATENCION', (res as any).Error, 3000);
            } else {
              // Me quedo con los datos que recibo por si acaso no se ha grabado una nueva
              // sino que se trata de la lectura de una tarjeta ya grabada
              tarjeta.uuid = res.uuid;
              tarjeta.nsMovisat = res.nsMovisat;
              tarjeta.idZona = res.idZona;
              tarjeta.idInterno = res.idInterno;
              tarjeta.idTarjeta = (res.idInterno & 0xFFFFFF) + ((res.idZona & 0xFF) << 24);
              tarjeta.descripcion = "TARJETA " + (tarjeta.idZona > 0 ? tarjeta.idZona + "/" : "") + tarjeta.idInterno;
              this.tarjetaLibreSelect = await this.tarjetaServicio.creaTarjeta(tarjeta);
              if (this.tarjetaLibreSelect) {
                // Si la tarjeta creada ya la tenía el ciudadano, la quito de la lista para que
                // se almacene de nuevo con el nuevo identificador interno asignado
                for (let i = 0; i < this.ciudadano.tarjetas.length; i++) {
                  if (this.ciudadano.tarjetas[i].id === this.tarjetaLibreSelect.id) {
                    this.ciudadano.tarjetas.splice(i, 1);
                    break;
                  }
                }
                this.associateTarjetasToCiudadano();
                MainComponent.getInstance().showSuccess('ATENCION', 'Tarjeta_grabada', 2000);
              }
              return;
            }
          } else {
            MainComponent.showError('ATENCION', 'No_hay_conexion_app_nfcwrite', 5000);
          }
          */
        }
      });
    } catch (e) {
    }
  }

  saveTarjeta() {
    // Si la tarjeta creada ya la tenía el ciudadano, la quito de la lista para que
    // se almacene de nuevo con el nuevo identificador interno asignado
    for (let i = 0; i < this.ciudadano.tarjetas.length; i++) {
      if (this.ciudadano.tarjetas[i].id === this.tarjetaLibreSelect.id) {
        this.ciudadano.tarjetas.splice(i, 1);
        break;
      }
    }
    this.associateTarjetasToCiudadano();
    MainComponent.getInstance().showSuccess('ATENCION', 'Tarjeta_grabada', 2000);
  }

  // Recupera el id externo de la tarjeta limpiando lo que sobra
  // N-1000001234 -> 1234
  getIdExterno(tarjeta: TarjetaModel): number {
    return tarjeta.idInterno;
    // let res = 0;
    // try {
    //   if (tarjeta.idExterno) {
    //     let num = "0";
    //     for (let i = 0; i < tarjeta.idExterno.length; i++) {
    //       if (tarjeta.idExterno.charAt(i) >= '0' && tarjeta.idExterno.charAt(i) <= '9' && tarjeta.idExterno.length - i <= 9) {
    //         num = num + tarjeta.idExterno.charAt(i);
    //       }
    //     }
    //     res = Number.parseInt(num);
    //   }
    // } catch (e) {
    //   res = 0;
    // }
    // return res;
  }

  // Asignacion direccion a tarjeta
  @ViewChild('gridDropDownDirecciones') gridDropDownDirecciones: jqxGridComponent;
  @ViewChild('cbDirecciones') cbDirecciones: jqxDropDownButtonComponent;

  protected direccionSelec: DireccionModel;

  verGridDirecciones: boolean = false;
  public columnsDirecciones: any = [
    { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
    { text: AppComponent.translate('Num_tarjetas'), columntype: 'textbox', datafield: 'numeroTarjetas', width: 80 },
    { text: this.translate('Direccion'), columntype: 'textbox', datafield: 'direccion' },
    { text: this.translate('Numero'), columntype: 'textbox', datafield: 'numero' },
    { text: this.translate('Municipio'), columntype: 'textbox', datafield: 'poblacion' },
    { text: this.translate('Provincia'), columntype: 'textbox', datafield: 'provincia' },
  ];
  public sourceDirecciones: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number', map: 'id' },
      { name: 'numeroTarjetas', type: 'string', map: 'numeroTarjetas' },
      { name: 'direccion', type: 'string', map: 'direccion' },
      { name: 'numero', type: 'string', map: 'numero' },
      { name: 'poblacion', type: 'string', map: 'poblacion' },
      { name: 'provincia', type: 'string', map: 'provincia' },
    ],
    localdata: [],
  };
  public adapterDirecciones: any = new jqx.dataAdapter(this.sourceDirecciones);

  initGridDirecciones() {
    if (!this.direccionSelec) {
      if (this.ciudadano && this.ciudadano.direcciones) {
        this.ciudadano.direcciones.forEach(dir => {
          if (dir.direccionPrincipal) {
            this.direccionSelec = dir;
          }
        });

        this.ciudadano.direcciones.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

        this.setCBDireccionesContent();
      } else {
        this.ciudadano.direcciones = [];
      }
    }

    this.columnsDirecciones = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
      { text: AppComponent.translate('Num_tarjetas'), columntype: 'textbox', datafield: 'numeroTarjetas', width: 80 },
      { text: this.translate('Direccion'), columntype: 'textbox', datafield: 'direccion' },
      { text: this.translate('Numero'), columntype: 'textbox', datafield: 'numero' },
      { text: this.translate('Municipio'), columntype: 'textbox', datafield: 'poblacion' },
      { text: this.translate('Provincia'), columntype: 'textbox', datafield: 'provincia' },
    ];

    this.sourceDirecciones = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'numeroTarjetas', type: 'string', map: 'numeroTarjetas' },
        { name: 'direccion', type: 'string', map: 'direccion' },
        { name: 'numero', type: 'string', map: 'numero' },
        { name: 'poblacion', type: 'string', map: 'poblacion' },
        { name: 'provincia', type: 'string', map: 'provincia' },
      ],
      localdata: this.ciudadano.direcciones,
    }
    this.adapterDirecciones = new jqx.dataAdapter(this.sourceDirecciones);
  }

  onOpenSelectDirecciones(event) {
    this.customComponent();
    this.verGridDirecciones = true;
  }

  customComponent(component?: any) {
    setTimeout(() => {
      this.gridDropDownDirecciones.setOptions({
        showaggregates: true,
        sortable: true,
        altrows: true,
        filterable: true,
        rowsheight: 20,
        columnsheight: 20,
        statusbarheight: 20,
        columnsresize: true,
        showstatusbar: true,
        showcolumnlines: true,
      });
    }, 50);
  }

  onCloseSelectDirecciones(event) {
    this.verGridDirecciones = false;
  }

  /* Cuando se selecciona una direccion */
  onRowClickDirecciones(event: any) {
    this.direccionSelec = this.ciudadano.direcciones[event.args.rowindex];

    this.setCBDireccionesContent();

    this.cbDirecciones.close();
  }

  onAsignarDireccion(event: any) {
    let tarjSelecIndex = this.tarjetasToAssociate.findIndex(tarj => tarj.id === this.tarjetaAsignadaSelect.id);
    let tarjCiudIndex = this.ciudadano.tarjetas.findIndex(tarj => tarj.id === this.tarjetaAsignadaSelect.id);

    this.ciudadano.tarjetas[tarjCiudIndex].direccion = this.direccionSelec;
    this.tarjetasToAssociate[tarjSelecIndex].idDireccion = this.direccionSelec.id;


    this.updateGrids();
  }

  setCBDireccionesContent() {
    if (this.direccionSelec) {
      let content =
        '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
        (this.direccionSelec.direccion ? this.direccionSelec.direccion : '') +
        (this.direccionSelec.numero ? ', ' + this.direccionSelec.numero : '') +
        (this.direccionSelec.poblacion ? ', ' + this.direccionSelec.poblacion : '') +
        (this.direccionSelec.provincia ? ', ' + this.direccionSelec.provincia : '') +
        '</div>';

      if (this.cbDirecciones) {
        this.cbDirecciones.setContent(content);
      }

      this.updateGridTarjetasAsignadas();
    }
  }

  updateGridTarjetasAsignadas() {
    let tarjToShow: TarjetaModel[] = []
    if (this.direccionSelec.id >= 0) {
      this.ciudadano.tarjetas.forEach(tarj => {
        if (tarj.direccion && tarj.direccion.id === this.direccionSelec.id) {
          tarjToShow.push(tarj);
        }
      });
    } else {
      tarjToShow = this.ciudadano.tarjetas;
    }

    if (this.sourceTarjetasAsignadas && this.gridTarjetasAsignadas) {
      this.sourceTarjetasAsignadas.localdata = tarjToShow;
      this.gridTarjetasAsignadas.updatebounddata();
    }
  }
}
