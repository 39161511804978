<div class="loader-container" *ngIf="!columns.length">
  <div class=" loader"></div>
</div>
<jqxWindow #form [theme]="environment.tema" [width]="mapWidth" [height]="mapHeight" [zIndex]="999"
  [position]="getFormPos(form, 0)" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Informe_movilidad')}}
  </div>

  <div class="form" style="float:left; overflow: hidden; height: 100%; width: 100%;">
    <div style="float: left; height: 100%; width: 100%;">
      <jqxSplitter #mainSplitter [panels]="[{ min: '15%', size: '15%' }]" [height]="'100%'" [width]="'100%'">
        <div>
          <div style="height: 100%;">
            <div style="height: 100%;">
              <app-moviles-select #movilesSelectComp [filterText]="movilFilter" (passArray)="movilesSelected($event)"></app-moviles-select>
            </div>
          </div>
        </div>
        <div>
          <div style="height: 100%; margin-top: 5px;">
            <div style="height: 34px; display: flex; align-content: center; flex-wrap: wrap;">
              <app-header #header style="width: 100%;" [exportar]="true" [periodo]="true" [filtro]="true"
                [resetFiltro]="true" [buscador]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()"
                (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()">
              </app-header>
            </div>
            <div style="height: calc(100% - 49px); margin-top: 10px;">
              <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
                [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                [columns]="columns" [altrows]="true" [enabletooltips]="false" [filterable]="false"
                [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
                [columnsheight]="20" [localization]="langGrid" [columngroups]="columngroups" [groupable]="true"
                (onGroupschanged)="resizeColumns()">
              </jqxGrid>
            </div>
          </div>
        </div>
      </jqxSplitter>
    </div>
  </div>
</jqxWindow>
