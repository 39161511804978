export class AssociateCerraduraModel {
    cerraduras: number[] = [];
    calendarios: number[] = [];
    empresa: number;

    constructor(cerradurasIds: number[], calendariosIds: number[], empresaId: number) {
        this.cerraduras = cerradurasIds;
        this.calendarios = calendariosIds;
        this.empresa = empresaId;
    }
}


