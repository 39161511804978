import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { AdministradorService } from 'src/app/services/administrador/administrador.service';

import { RolModel } from 'src/app/services/sso/models/rol.model';
import { UsuarioModel } from 'src/app/services/sso/models/usuario.model';
import { TareaModel } from 'src/app/services/administrador/models/tarea.model';

import { EditTareaComponent } from '../edit-tarea/edit-tarea.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { Utils } from 'src/app/utils/utils';
import { VersionService } from 'src/app/services/version/version.service';
import { VersionModel } from 'src/app/services/version/models/version.model';

@Component({
  selector: 'app-listado-tareas',
  templateUrl: './listado-tareas.component.html',
  styleUrls: ['./listado-tareas.component.css']
})
export class ListadoTareasComponent extends CustomForms implements OnInit {
  @ViewChild('windowJob') windowJob: jqxWindowComponent;
  @ViewChild('gridJobs') gridJobs: jqxGridComponent;
  @ViewChild('formJob', { read: ViewContainerRef }) editTareaComponent;
  @ViewChild('header') header: HeaderComponent;

  public static _this: ListadoTareasComponent;
  private componentRef = null;
  environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public jobsList: TareaModel[] = [];
  public version: VersionModel;

  private rowIndex = -1;
  jobSelect: TareaModel;
  formJob: any;

  usuario: UsuarioModel;
  rol: RolModel;

  editable = false;

  mapHeight;
  mapWidth;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private administradorService: AdministradorService,
    private versionService: VersionService,
    private ssoService: SsoService
  ) {
    super();
    ListadoTareasComponent._this = this;
  }

  init(componentRef: any) {
    this.usuario = this.ssoService.getTicket().Usuario;
    this.rol = this.ssoService.getTicket().Rol;
    this.componentRef = componentRef;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.windowJob);
    this.windowJob.setTitle(AppComponent.translate('Jobs'));

    await this.getVersion();
    await this.getJobs();

    if (this.usuario.Interno && this.rol.Nombre === 'Administrador') {
      this.editable = true;
    }

    this.initGridJobs();
  }

  onExpand(){
    this.sourceJobs.localdata = this.jobsList;
    this.gridJobs.updatebounddata('data');

    this.resizeColumns();
  }

  resizeColumns(){
    Utils.renderSizeGrid(this.gridJobs, 300, ['acciones']);
  }

  closeWindow(){
    this.windowJob.close();
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    ListadoTareasComponent._this = null;
  }

  async getVersion() {
    try {
      this.versionService.getVersionJobs().then(
        async (result: VersionModel) => {
          this.version = result;

          this.windowJob.setTitle(AppComponent.translate('Jobs') + ' V' + this.version.version);
        }, (error: any) => {
        }
      )
    }catch(e) {

    }
  }

  async getJobs(){
    try{
      this.administradorService.getTareas().then(
        async (result: TareaModel[]) => {
          this.jobsList = result;

          this.sourceJobs.localdata = this.jobsList;
          this.adapterJobs = new jqx.dataAdapter(this.sourceJobs);
          this.gridJobs.updatebounddata('data');

          this.resizeColumns();
        }
      )
    }catch(e){
      this.jobsList = [];
    }
  }

  /* GRID JOBS */
  /*
    Comprueba si la tarea esta activada o no
    y marca el check en el listado
  */
  renderCheckColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value == 1 || value) {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">Si</div>';
    }
    else {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">No</div>';
    }
  };

  public columnsJobs = [
    { text: 'id', columntype: 'textbox', datafield: 'id', width: 1, hidden: true },
    {
      hidden: !this.editable,
      text: '',
      width: 50,
      columntype: 'text',
      sortable: false,
      editable: false,
      datafield: 'acciones',
      groupable: false,
      menu: false,
      rendered: (columnHeaderElement) => {
        return columnHeaderElement[0];
      },
      createwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ): void => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      initwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ) => {
        this.initBtnColumn(row, column, value, htmlElement);
      }
    },
    { text: this.translate('Nombre'), columntype: 'textbox', datafield: 'nombre', width: 'auto', editable: false, filterable: false,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      },
    },
    { text: 'Trigger', columntype: 'textbox', datafield: 'nombreTrigger', width: 'auto', editable: false, filterable: false },
    { text: this.translate('Grupo'), columntype: 'textbox', datafield: 'grupo', width: 'auto', editable: false, filterable: false
    },
    { text: this.translate('Segundos'), columntype: 'textbox', datafield: 'segundos', width: 'auto', cellsrenderer: this.numberrenderer, editable: this.editable, filterable: false },
    { text: this.translate('Expresion_cron'), columntype: 'textbox', datafield: 'cronExpresion', with: 'auto', editable: this.editable, filterable: false },
    { text: this.translate('Activa'), columntype: 'checkbox', datafield: 'activado', width: 'auto', editable: this.editable,
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('SI'),
        this.translate('NO')
      ],
    },
    { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion', with: 'auto', editable: this.editable, filterable: false },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
  ];

  public sourceJobs = {
    datatype: 'json',
    groupsrenderer: this.groupsrenderer,
    datafields: [
      { name: 'id', type: 'int', map: 'id' },
      { name: 'acciones', type: 'string' },
      { name: 'nombre', type: 'string', map: 'nombre' },
      { name: 'nombreTrigger', type: 'string', map: 'nombreTrigger' },
      { name: 'grupo', type: 'string', map: 'grupo' },
      { name: 'segundos', type: 'int', map: 'segundos' },
      { name: 'cronExpresion', type: 'string', map: 'cronExpresion' },
      { name: 'activado', type: 'boolean', map: 'activado' },
      { name: 'descripcion', type: 'string', map: 'descripcion' },
      { name: 'selec', map: 'selec' }
    ],
    localdata: this.jobsList,
  };
  public adapterJobs = new jqx.dataAdapter(this.sourceJobs);

  initGridJobs() {
    this.columnsJobs = [
      { text: 'id', columntype: 'textbox', datafield: 'id', width: 1, hidden: true },
      {
        hidden: !this.editable,
        text: this.translate('Acciones'),
        width: 50,
        columntype: 'text',
        sortable: false,
        editable: false,
        datafield: 'acciones',
        groupable: false,
        menu: false,
        rendered: (columnHeaderElement) => {
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      { text: this.translate('Nombre'), columntype: 'text', datafield: 'nombre', width: 'auto', editable: false, filterable: false,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              NumberUtils.format(aggregates["Total"], 0) + '</div>';
          }
          return renderstring;
        },
      },
      { text: 'Trigger', columntype: 'textbox', datafield: 'nombreTrigger', width: 'auto', editable: false, filterable: false },
      { text: this.translate('Grupo'), columntype: 'textbox', datafield: 'grupo', width: 'auto', editable: false, filterable: false
      },
      { text: this.translate('Segundos'), columntype: 'textbox', datafield: 'segundos', width: 'auto', cellsrenderer: this.numberrenderer, editable: this.editable, filterable: false },
      { text: this.translate('Expresion_cron'), columntype: 'textbox', datafield: 'cronExpresion', with: 'auto', editable: this.editable, filterable: false },
      { text: this.translate('Activa'), columntype: 'checkbox', datafield: 'activado', width: 'auto', editable: this.editable,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO')
        ],
      },
      { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion', with: 'auto', editable: this.editable, filterable: false },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];

    let isFirstIteration = true;
    this.columnsJobs.forEach(column => {
      if (!isFirstIteration) {
        column.rendered = (element) => { Utils.tooltiprenderer(element) };
      } else {
        isFirstIteration = false;
      }
    });
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Editar')+`">
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
      `;
    btnEdit.id = `buttonEdit_jqxButton`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', async (event: any) => {
      this.jobSelect = this.jobsList.find(job => job.id === this.gridJobs.getrowdata(row).id);
      this.onEditarJob();
    });

    htmlElement.appendChild(btnContainer);
  }

  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    let showText = `
      <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
        <b>`+ AppComponent.translate('Activa') + `: </b>
    `;

    //Renderiza los grupos de tipo booleano con el texto para cada idioma
    if (group === true) {
      showText += AppComponent.translate('SI');
      if (data.subGroups.length == 0) {
        showText += '(' + data?.subItems.length + ')';
      } else {
        showText += '(' + data?.subGroups.length + ')';
      }
      return showText;
    } else if (group === false) {
      showText += AppComponent.translate('NO');
      if (data.subGroups.length == 0) {
        showText += '(' + data?.subItems.length + ')';
      } else {
        showText += '(' + data?.subGroups.length + ')';
      }
      return showText + `</div>`;
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  /* Acciones del grid */
  onCellClick(event: any) {
    if (!event.args.column.editable && event.args.column.datafield !== 'acciones') {
      if (this.rowIndex != event.args.rowindex) {
        this.rowIndex = event.args.rowindex;
        this.jobSelect = this.jobsList[event.args.rowindex];
      } else if (this.rowIndex === event.args.rowindex) {
        this.rowIndex = null;
        this.jobSelect = null;
      }
    }
  }

  onRowdoubleclick(event: any) {
    if (this.editable) {
      this.rowIndex = event.args.rowindex;
      this.jobSelect = this.jobsList[event.args.rowindex];
      this.gridJobs.selectrow(this.rowIndex);

      this.onEditarJob();
    }
  }

  onEditarJob() {
    if (this.jobSelect) {
      this.jobSelect = this.jobsList.find(job => job.id === this.jobSelect.id);
      this.formJob = this.editTareaComponent.createComponent(EditTareaComponent);
      this.formJob.instance.init(this.formJob, this.jobSelect);

      this.windowJob.collapse();
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onCellvaluechanged(event: any) {
    let fila = this.gridJobs.getrowdata(event.args.rowindex);
    let index = this.jobsList.findIndex(job => job.id === fila.id);

    this.updateTarea(fila, index);
  }

  onFilter(event) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if (column === 'activado') {
        let filter1;
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if (element.value === this.translate('SI')) {
            filtervalue = true;
          } else if (element.value === this.translate('NO')) {
            filtervalue = false;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridJobs.addfilter(column, filtergroup);
            this.gridJobs.applyfilters();
          }
        });
      }
    }
  }

  async updateTarea(tarea: TareaModel, index: number) {
    let actualizado = await this.administradorService.updateTarea(tarea);

    if (actualizado !== null) {
      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_almacenado',
        2000
      );
      this.jobsList[index] = tarea;
    }else{
      MainComponent.getInstance().showError(
        'ATENCION',
        'Fallo_almacenar_info',
        2000
      );
    }
  }

  eventFilter(){
    this.header.searchInput['nativeElement'].value = '';
    this.getJobs();
  }

  eventResetFilter(){
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.jobsList.forEach(job => {
      if(
        (job?.nombre + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (job?.nombreTrigger + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (job?.grupo + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (job?.segundos + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (job?.cronExpresion + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (job?.descripcion + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ){
        job['selec'] = 'selec';
      }else{
        job['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridJobs.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridJobs.addfilter('selec', filtergroup);
    }
    this.gridJobs.applyfilters();
    this.gridJobs.updatebounddata('data');
  }

  onExportar(){
    const json = JSON.parse(JSON.stringify(this.gridJobs.getdisplayrows()));
    /*
      Elimino los campos que no se quieren mostrar en el excel
    */
    json.forEach(element => {
      delete element.id;
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;

      if (element.activado) {
        element.activado = AppComponent.translate('SI');
      } else {
        element.activado = AppComponent.translate('NO');
      }
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_'+ AppComponent.translate('Jobs') + '.xlsx');
  }
}
