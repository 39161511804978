<!-- <div class="loader-container" *ngIf="showLoader">
  <div class=" loader"></div>
</div> -->
<div class="form" style="position: absolute; z-index: 1000; display: flex; height: 100%; width: 100%;">
  <div style="overflow-x: hidden; width: 100%;">
    <!-- {{translate('Historico_envios_dispositivo_identificador')}} -->
    <div style="float: left; margin-top: 2px; height:95%; width: '100%';">
      <jqxTabs #myTabs [width]="'100%'" [height]="'100%'" [theme]="theme" [scrollable]="false">
        <ul>
          <li>I</li>
          <li>II</li>
        </ul>
        <div style=" width: 100%; height: 100%;">
          <div>
            <app-header #header [exportar]="true" [imprimir]="true" [periodo]="true" [buscador]="true"
              [checkMetros]="true" [filtro]="true" [resetFiltro]="true" [noLibre]="true" (eventExportar)="onExportar()"
              (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()" (eventFilter)="onAceptar()"
              (eventResetFilter)="onResetFilter()" (eventCheckMasMetros)="changeMasXmetros()">
            </app-header>
          </div>
          <div style="width: 99%;height: calc(100% - 40px)">
            <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'98%'" [source]="dataAdapter"
              [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
              [columns]="columns" [altrows]="true" [filterable]="true" [showstatusbar]="true" [statusbarheight]="20"
              [rowdetails]="true" [showaggregates]="true" [rowsheight]="20" [columnsheight]="20"
              [localization]="langGrid" [groupable]="true" [rowdetailstemplate]="rowdetailstemplate"
              [initrowdetails]="initRowDetails" (onRowexpand)="rowExpand($event)"
              (onRowdoubleclick)="onRowdoubleclick($event)" (onSort)="onSort($event)">
            </jqxGrid>
          </div>
        </div>
        <div style="height: 460px;">
          <movisat-map [provider]="mapProvider" (mapReady)="onMapReady($event)" [cartoType]="cartoType" [lang]="lang"
            [texts]="langService.getCurrentTexts()" [searchCountry]="searchCountry" [zoom]="zoom"
            [center]="center"></movisat-map>
        </div>
      </jqxTabs>
    </div>
  </div>