import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

import { SubflotasComponent } from './subflotas/subflotas.component';
import { MovilesCercaComponent } from './moviles-cerca/moviles-cerca.component';

@NgModule({
  declarations: [
    SubflotasComponent,
    MovilesCercaComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    jqxLoaderModule,
    jqxGridModule,
    jqxWindowModule,
    jqxListBoxModule,
    jqxButtonModule,
    jqxInputModule,
    jqxCheckBoxModule,
    jqxNumberInputModule,
    jqxDropDownListModule,
  ]
})
export class FlotaModule { }
