<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form, 0)" [zIndex]="999" [width]="mapWidth"
  [height]="mapHeight" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Catalogo_elementos_BDT") }}
  </div>
  <div class="form" style="float: left; overflow: hidden">
    <div>
      <app-header #header [buscador]="true" [filtro]="true" [resetFiltro]="true"
        (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()"
        (eventBuscador)="onBuscar()">
      </app-header>
    </div>

    <div style="height: calc(100% - 80px);">
      <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
        [columnsresize]="true" [filterable]="true" [selectionmode]="'singlerow'" [columns]="columns" [sortable]="true"
        [altrows]="true" [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25" [columnsheight]="25"
        [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20" [rowdetails]="true"
        [initrowdetails]="initrowdetails" [rowdetailstemplate]="rowdetailstemplate" [showemptyrow]="false"
        [localization]="langGrid" (onBindingcomplete)="onBindingComplete()" (onRowexpand)="onRowExpand($event)"
        (onRowdoubleclick)="onRowdoubleclick($event)">
      </jqxGrid>
    </div>

    <div style="margin-left: 10px; display: flex; align-items: center; margin-top: 10px;">
      <jqxButton [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>

<jqxWindow #fracciones [width]="550" [height]="250" [zIndex]="101" [isModal]="true" [position]="'center'"
  [showCloseButton]="true" [resizable]="true" [autoOpen]="false" [theme]="environment.tema"
  [title]="translate('Tabla_fracciones')">
  <div style="background-color: transparent;">
    <div class="row" style="overflow: hidden; background-color: transparent;">
      <jqxGrid #gridFracciones [theme]="environment.tema" [width]="'100%'" [height]="160"
        [source]="dataAdapterFracciones" [columnsresize]="true" [columns]="columnsFracciones" [sortable]="true"
        [altrows]="true" [showrowlines]="false" [rowsheight]="25" [columnsheight]="25" [localization]="langGrid">
      </jqxGrid>
      <jqxButton style="float: right; margin-right: 2px; margin-top: 5px;" [width]="55" [height]="25"
        [textPosition]="'center'" [value]="translate('Cerrar')" (onClick)="clickCerrar($event)">
      </jqxButton>
    </div>
  </div>
</jqxWindow>





<div *ngIf="showFicha && elemEdit">
  <jqxWindow #fichaCatalogo [theme]="environment.tema" [position]="getFormPos(fichaCatalogo, 0)" [zIndex]="999"
    [width]="'60%'" [height]="mapHeight" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
    [resizable]="true" (onClose)="onCloseFicha()">
    <div class="formHeader" style="overflow-x: hidden">
      {{ translate("Ficha_catalogo_equipamiento") }}
      {{elemEdit.Equipamiento.Marca.Nombre}}
    </div>
    <div class="form" style="float: left; overflow-x: hidden">
      <jqxRibbon [width]="'100%'" [height]="'95%'" [theme]="environment.tema">
        <ul>
          <li> {{ translate("Informacion") }}</li>
          <li> {{ translate("Detalle") }}</li>
          <li> {{translate("Elementos")}}</li>
        </ul>
        <div>
          <div style="overflow: hidden; background-color: transparent;">
            <div style="display: flex; height: 100%;">
              <div #fieldInformacion id="fieldInformacionInformacion" style="width: 50%; height: 100%;">
                <fieldset>
                  <legend>{{ translate("Informacion") }}</legend>

                  <div class="row" style="display: flex; gap: 5px;">
                    <span style="width: 140%;">
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Equipamiento") }}
                      </div>
                      <jqxDropDownButton #cbEquip [width]="'100%'" [theme]="environment.tema"
                        (onClose)="onCloseSelectEquipamientos($event)" (onOpen)="onOpenSelectEquipamientos($event)">
                      </jqxDropDownButton>
                      <div *ngIf="verGridEquipamiento" style="position: absolute; z-index: 999;">
                        <jqxGrid #gridDropDownEquip [theme]="environment.tema" [width]="455" [height]="220"
                          [source]="dataAdapterEquip" [columns]="columnsEquip" [localization]="langGrid">
                        </jqxGrid>
                      </div>
                    </span>

                    <span style="width: 100%;">
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Tipo_elemento") }}
                      </div>
                      <jqxInput [value]="elemEdit?.Equipamiento?.Elemento.Nombre" [width]="'100%'" [disabled]="true">
                      </jqxInput>
                    </span>
                  </div>

                  <div class="row" style="display: flex; gap: 5px; margin-bottom: 5px;">
                    <span style="width: 100%;">
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Marca") }}
                      </div>
                      <jqxInput [value]="elemEdit?.Equipamiento?.Marca.Nombre" [width]="'100%'" [disabled]="true">
                      </jqxInput>
                    </span>
                    <span style="width: 100%;">
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Modelo") }}
                      </div>
                      <jqxInput [value]="elemEdit?.Equipamiento?.Modelo.Nombre" [width]="'100%'" [disabled]="true">
                      </jqxInput>
                    </span>
                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Residuo") }}
                      </div>
                      <jqxInput [value]="elemEdit?.Equipamiento?.ResiduosNombres" [[width]="'100%'" [disabled]="true">
                      </jqxInput>
                    </span>
                  </div>

                  <div id="fieldDetalleInformacionGrafica">

                    <legend>{{ translate('Informacion_grafica') }}</legend>

                    <div class="row" style="display: flex;align-items: center; justify-content: space-between;">
                      <span>
                        <div style="margin-top: 5px; clear: both">
                          {{ translate("Icono_elemento") }}
                          <img style="width: 50px; height: 50px "
                            *ngIf="elemEdit?.Equipamiento?.Icono && elemEdit?.Equipamiento?.Icono?.length > 100"
                            src="data:image/png;base64,{{elemEdit.Equipamiento?.Icono}}">
                        </div>
                      </span>
                      <span>
                        <div style="margin-top: 5px; clear: both">
                          {{ translate("Icono_punto_ubicacion") }}
                          <img style="width: 50px; height: 50px "
                            *ngIf="elemEdit?.Pu?.Equipamiento?.Icono && elemEdit?.Pu?.Equipamiento?.Icono?.length > 100 "
                            src="data:image/png;base64,{{elemEdit.Pu?.Equipamiento?.Icono}}">
                        </div>
                      </span>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div id="fieldInformacionImagenes" style="height: 100%;">
                <fieldset>
                  <legend>{{ translate("Imagenes") }}</legend>

                  <div class="row" style="height: 99%;
                  display: flex;
                  flex-direction: row;
                  gap: 5px;">
                    <div class="container imagenPrincipal">
                      <div class="row" style="height: calc(100% - 35px)">
                        <div *ngIf="selectedImg != null" style="display: flex;
                        flex-direction: column;
                        height: 100%;">
                          <img #imgPrincipal src="data:image/png;base64,{{selectedImg?.Imagen}}" style="height: 90%;">
                          <h4 style="float: right;">{{ formatDate(selectedImg?.FechaCreacion, true) }}</h4>
                        </div>
                      </div>

                      <div class="row" style="display: flex; gap: 4px;">
                        <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                          [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"
                          (onClick)="changeImage('previous')" [disabled]="selectedImg == null">
                        </jqxButton>
                        <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                          [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'" (onClick)="changeImage('next')"
                          [disabled]="selectedImg == null">
                        </jqxButton>
                        <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                          [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'"
                          (onClick)="rotate('left')" [disabled]="selectedImg == null">
                        </jqxButton>
                        <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                          [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'"
                          (onClick)="rotate('right')" [disabled]="selectedImg == null">
                        </jqxButton>
                        <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
                          [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'" (onClick)="rotate('180')"
                          [disabled]="selectedImg == null">
                        </jqxButton>
                      </div>
                    </div>

                    <div class="container sliderImages">
                      <div *ngFor="let img of imagenesEquipamiento; let i = index">
                        <div class="min-image" style="width: 100%; height: 100%;">
                          <img class="min-image" style="width: 40px; height: 40px;"
                            src="data:image/png;base64,{{img.Imagen}}" (click)="setImgPrincipal(img)">
                          <p class="min-image" style="font-size: 12px;">{{ formatDate(img?.FechaCreacion, true) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>


          <!-- detalle -->
          <div style="overflow: auto; background-color: transparent; height: 100%;">

            <div style="display: flex;">
              <div id="fieldDetalleGeneral" style="width: 50%;">
                <fieldset>
                  <legend>{{ translate('Generales') }}</legend>
                  <div class="row" style="display: flex; gap: 5px; flex-wrap: wrap;">
                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Tiempo_recogida") }}
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.TiempoRecogida"
                        (onChange)="elemEdit?.Equipamiento != null ? elemEdit?.Equipamiento.TiempoRecogida = $event : ''"
                        [render]="renderer()" [width]="110" [renderizar]="false" [disabled]="true">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Tipo_material") }}
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.TipoMaterial"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.TipoMaterial = $event : ''"
                        [render]="renderer()" [width]="150" [decimal]="2" [renderizar]="false" [disabled]="true">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Tiene_ruedas_pregunta") }}
                      </div>
                      <jqxDropDownList #sTieneRuedas [width]="120" [dropDownHeight]="60"> </jqxDropDownList>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Numero_fracciones") }}
                      </div>
                      <div style="display: flex; align-items: center; gap: 2px">
                        <app-input-number [value]="elemEdit?.Equipamiento?.NumeroFracciones"
                          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.NumeroFracciones = $event : ''"
                          [render]="renderer()" [width]="80" [disabled]="true">
                        </app-input-number>

                        <jqxButton #myButton [width]="80" [height]="25" [textPosition]="'center'"
                          [value]='translate("Fracciones")' [theme]="environment.tema" (onClick)="openFracciones()">
                        </jqxButton>
                      </div>
                    </span>
                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Tiene_pedal_pregunta") }}
                      </div>
                      <jqxDropDownList #sTienePedal [width]="120" [dropDownHeight]="60"> </jqxDropDownList>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Area_influencia") }} (m)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.AreaInfluencia"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AreaInfluencia = $event : ''"
                        [render]="renderer()" [width]="110">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Emplazamiento") }}
                      </div>
                      <jqxInput [value]="elemEdit?.Equipamiento?.Emplazamiento" [width]="150" [disabled]="true">
                      </jqxInput>
                    </span>
                  </div>
                </fieldset>
              </div>

              <div id="fieldDetallePesosMedidas" style="width: 70%;">
                <fieldset>
                  <legend>{{ translate('Pesos_medidas') }}</legend>
                  <div style="display: flex; gap: 5px; flex-wrap: wrap; ">
                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Ancho") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.Altura / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Altura = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Alto") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.Ancho / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Ancho = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>


                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Largo") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.Largo / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Largo = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Diametro") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.Diametro / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Diametro = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Ancho_interior") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.AnchoInterior / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AnchoInterior = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Altura_interior") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.AltoInterior / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AltoInterior = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>


                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Altura_sensor") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.AlturaSensor / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AlturaSensor = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>


                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Grados_sensor") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.GradosSensor / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.GradosSensor = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>


                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Altura_muerte") }} (cm)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.AlturaMuerta / 10"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AlturaMuerta = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>


                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Capacidad") }} (L)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.CapacidadLitros"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CapacidadLitros = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>


                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Coeficiente_conversion") }}
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.CoeficienteConversion"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CoeficienteConversion = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Capacidad") }} (Kg)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.CapacidadKilos"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CapacidadKilos = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>


                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Carga_maxima") }} (Kg)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.CargaMaximaAutorizada"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CargaMaximaAutorizada = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Tara") }} (Kg)
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.Tara"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Tara = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2" [disabled]="true">
                      </app-input-number>
                    </span>
                  </div>
                </fieldset>
              </div>
            </div>


            <div style="display: flex;">
              <div id="fieldDetalleClasesElemento" style="width: 50%;">
                <fieldset>
                  <legend>{{ translate('Clases_asociadas_elemento') }}</legend>
                  <jqxGrid #gridFracciones [theme]="environment.tema" [width]="'100%'" [height]="120"
                    [source]="dataAdapterClasesElemento" [columnsresize]="true" [columns]="columnsClasesElemento"
                    [sortable]="true" [altrows]="true" [showrowlines]="false" [autoheight]='true' [columnsheight]="25"
                    [rowsheight]='25' [localization]="langGrid">
                  </jqxGrid>
                </fieldset>
              </div>

              <div id="fieldDetalleDatosEconomicos" style="width: 50%;">
                <fieldset>
                  <legend>{{ translate('Datos_economicos') }}</legend>
                  <div style="display: flex; gap: 5px; flex-wrap: wrap; ">

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Precio_compra") }}
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.PrecioCompra"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.PrecioCompra = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2">
                      </app-input-number>
                    </span>

                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Anio_amortizacion") }}
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.AnyoAmortizacion"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AnyoAmortizacion = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2">
                      </app-input-number>
                    </span>


                    <span>
                      <div style="margin-top: 5px; clear: both">
                        {{ translate("Mantenimiento_anual") }}
                      </div>
                      <app-input-number [value]="elemEdit?.Equipamiento?.MantenimmientoAnual"
                        (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.MantenimmientoAnual = $event : ''"
                        [render]="renderer()" [width]="110" [decimal]="2">
                      </app-input-number>
                    </span>

                  </div>
                </fieldset>
              </div>
            </div>
          </div>


          <!-- elementos -->
          <div style="background-color: transparent; height: 100%;">
            <div style="display: flex; height: 100%;">
              <div id="fieldElementos" style="width: 100%; height: 100%;">
                <fieldset>
                  <legend>{{ translate('Elementos') }}</legend>
                  <div style="display: flex; height: 100%;">
                    <div style="width: 100%; height: 100%;">
                      <jqxGrid #gridElementos [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
                        [source]="dataAdapterElementos" [columnsresize]="true" [columns]="columnsElementos"
                        [sortable]="true" [altrows]="true" [showrowlines]="false" [showstatusbar]="true"
                        [filterable]="true" [columnsheight]="25" [rowsheight]='25' [localization]="langGrid"
                        [statusbarheight]="20" [showaggregates]="true">
                      </jqxGrid>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </jqxRibbon>

      <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 5px;">
        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
          [value]="translate('Guardar')">
        </jqxButton>
        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="fichaCatalogo.close()"
          [value]="translate('CANCELAR')">
        </jqxButton>
      </div>

    </div>


  </jqxWindow>
</div>
