import { IncidenciaModel } from "./incidencia.model";

export class CambioEstadoModel {
    Id: number = 0;
    EmpresaId: number = 0;
    IncidenciaId: number = 0;
    EstadoId: number = 0;
    Fecha: Date = new Date();
    UsuarioId: number = 0;
    Observaciones: string = '';
    Incidencia: IncidenciaModel;

    //agregados
    UsuarioEmail: string = '';
    EstadoNombre: string = '';
    selec : string = '';
}