import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { BdtService } from 'src/app/services/bdt/bdt.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { BdtCatalogoEquipamientoModel } from 'src/app/services/bdt/models/bdt-catalogo-equipamiento.model';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-modelo-select',
  templateUrl: './modelo-select.component.html',
  styleUrls: ['./modelo-select.component.css']
})
export class ModeloSelectComponent implements OnInit {
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('cbEquip') cbEquip: jqxDropDownButtonComponent;
  public static _this: ModeloSelectComponent;
  private catEquipList: BdtCatalogoEquipamientoModel[] = [];
  private catEquipSelec: BdtCatalogoEquipamientoModel;
  modelo: BdtCatalogoEquipamientoModel;

  public langGrid = JqWidgets.getLocalization('es');
  public theme = environment.tema;
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);
  @Output() passArray = new EventEmitter;
  checkSelect: BdtCatalogoEquipamientoModel[] = [];

  dataSource: any;
  row: number;
  gridDrop: boolean;

  constructor(private elemService: ElementsService, private bdtService: BdtService,) {
    ModeloSelectComponent._this = this;
  }

  // Configuración del grid de modelos
  public columns = [
    {
      text: 'Id',
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'id',
      width: 60,
      hidden: true,
    },
    {
      text: '',
      columntype: 'image',
      datafield: 'imagen',
      width: 16,
      cellsrenderer: this.imagerenderer,
    },
    {
      text: AppComponent.translate('Elemento'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'elemento',
      width: 'auto',
    },
    {
      text: AppComponent.translate('Marca'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'marca',
      width: 110,
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Modelo'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'modelo',
      width: 200,
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Residuo'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'residuo',
      width: 260,
      hidden: this.elemService.elemGenericos,
    },
  ];

  ngOnInit(): void {
    this.getCatalogoEquipamiento();
    this.modify();
  }

  // Recupera el catálogo de elementos de la empresa
  async getCatalogoEquipamiento(): Promise<BdtCatalogoEquipamientoModel[]> {
    this.catEquipList = await this.bdtService.getCatalogoEquipamiento();
    if (this.catEquipList) {
      this.source = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number', map: 'Equipamiento>Modelo>Id' },
          { name: 'imagen', type: 'image', map: 'Equipamiento>Icono' },
          { name: 'marca', type: 'string', map: 'Equipamiento>Marca>Nombre' },
          { name: 'modelo', type: 'string', map: 'Equipamiento>Modelo>Nombre' },
          { name: 'residuo', type: 'string', map: 'Equipamiento>ResiduosNombres' },
          {
            name: 'elemento',
            type: 'string',
            map: 'Equipamiento>Elemento>Nombre',
          },
        ],
        localdata: this.catEquipList,
        sortcolumn: 'marca',
        sortdirection: 'asc',
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);

      Utils.renderSizeGrid(this.myGrid, 500);
    }
    return this.catEquipList;
  }

  // si noo hay elementos en el grid modelos modifico el contenido del dropdownbutton
  modify() {
    setTimeout(async () => {
      let content: string
      if (!this.catEquipList.length) {
        content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">No existen modelos</div>';
      } else {
        this.catEquipSelec = this.catEquipList[0];
        content = this.setContent();
        this.cbEquip.setContent(content);
      }
    }, 100);
  }

  // eventos para cerrar el dropdownbutton de modelos
  onCloseDrop(event) {
    if (this.myGrid) {
      this.gridDrop = true;
    }
    this.cbEquip.close();
  }

  onOpenDrop(event) {
    if (this.gridDrop) {
      this.gridDrop = false;
    }
  }

  onCollapse() {
    if (this.gridDrop) {
      this.gridDrop = true;
    }
    this.cbEquip.close();
  }

  onExpand() {
    if (this.gridDrop) {
      this.gridDrop = false;
    }
  }

  imagerenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (ModeloSelectComponent._this.elemService.elemGenericos) {
      if (value && value.length > 50) {
        return (
          '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' +
          value +
          '"/>'
        );
      }
      return '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/elemento.png"/>';
    } else {
      return (
        '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' +
        value +
        '"/>'
      );
    }
  }


  onSelect(event: any) {
    // si se seleccionan todos los elementos del grid
    switch (event.args.rowindex.length) {
      case 0:
        this.checkSelect = [];
        break;
      default:
        let rows: any[] = this.myGrid.getrows();
        let idRow: number[] = this.myGrid.getselectedrowindexes();
        rows.forEach((element, index) => {
          this.modelo = this.catEquipList.find((elem) => elem.Equipamiento.Modelo.Id == element.id && idRow[index] == element.boundindex);
          this.checkSelect.push(this.modelo);
        });
        this.checkSelect = this.onChangeArray(this.checkSelect);
        break;
    }
    this.passArray.emit(this.checkSelect);
  }

  // si cmabia el array del checkSelect
  onChangeArray(list: BdtCatalogoEquipamientoModel[]): BdtCatalogoEquipamientoModel[] {
    // Filtrar elementos repetidos y undefined
    list = list.filter((elem, index, self) =>
      index == self.findIndex((t) => t?.Id == elem?.Id) && elem !== undefined
    );

    this.checkSelect = list;
    return this.checkSelect;
  }


  // deseleccion de elementos en el grid modelos
  onUnSelect(event) {
    let row: any = this.myGrid.getrowdatabyid(event.args.rowindex);
    // elimino el elemento deseleccionado del array checkSelect
    this.checkSelect.splice(this.checkSelect.indexOf(row), 1);
    this.passArray.emit(this.checkSelect);
  }

  // si se selecciona un elemento del grid modifica el contenido del dropdownbutton
  onRowclick(event: any): void {
    this.catEquipSelec = this.catEquipList[event.args.rowindex];
    let content = '';
    if (this.elemService.elemGenericos) {
      content =
        '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
        this.catEquipSelec.Equipamiento.Elemento.Nombre +
        '</div>';
    } else {
      content = this.setContent();
    }
    this.cbEquip.setContent(content);
    this.cbEquip.close();
  }

  setContent(): string {
    let content =
      '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
      this.catEquipSelec.Equipamiento.Elemento.Nombre +
      (this.catEquipSelec.Equipamiento.Marca.Nombre
        ? ' | ' + this.catEquipSelec.Equipamiento.Marca.Nombre
        : '') +
      (this.catEquipSelec.Equipamiento.Modelo.Nombre
        ? ' | ' + this.catEquipSelec.Equipamiento.Modelo.Nombre
        : '') +
      (this.catEquipSelec.Equipamiento.ResiduosNombres
        ? ' | ' + this.catEquipSelec.Equipamiento.ResiduosNombres
        : '') +
      '</div>';
    return content
  }

  ngOnDestroy() {
    this.cbEquip.destroy();
    this.myGrid.destroy();
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}

