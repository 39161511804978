import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { GeoUtils } from 'src/app/utils/geo-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { MapComponent, MapLatLng, MapPolyline, MapPolylinePoint } from 'src/app/imports';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.css']
})
export class MeasureComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  private componentRef = null;
  public environment = environment;
  public distanciaTotal = 0;
  public distanciaParcial = 0;
  public superficie = 0;
  private polyline: MapPolyline = null;
  private polyline2: MapPolyline = null;
  private map: MapComponent;
  private subscriptionOnMapClick = null;
  private subscriptionOnPolylineClick = null;
  private subscriptionOnMapMouseMove = null;

  public static _this: MeasureComponent;

  constructor() {
    super();
    MeasureComponent._this =  this;
  }

  ngOnInit(): void {
    this.map = MainComponent.getInstance().getActiveMap();
    this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onMapClick);
    this.subscriptionOnPolylineClick = this.map.subscribeOnPolylineClick(this, this.onPolylineClick);
    this.subscriptionOnMapMouseMove = this.map.subscribeOnMapMouseMove(this, this.onMapMouseMove);
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Medir'));
    // Cambio el puntero del ratón sobre el mapa
    // this.map.setMousePointer('assets/images/regla.png');
    document.body.style.cursor = "pointer";
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.form.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Para formatear números
  format(num: number, decimals: number): string {
    return NumberUtils.format(num, decimals);
  }

  // Capturo las pulsaciones de teclado
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (this.polyline) {
        this.map.removePolyline(this.polyline);
      }
      if (this.polyline2) {
        this.map.removePolyline(this.polyline2);
      }
      this.polyline = null;
      this.polyline2 = null;
      this.distanciaParcial = 0;
      this.distanciaTotal = 0;
      this.superficie = 0;
    }
  }

  // Cierro el formulario y destruyo el componente
  onClose(): void {
    this.map.setMousePointer('');
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
    }
    if (this.subscriptionOnPolylineClick) {
      this.subscriptionOnPolylineClick.unsubscribe();
    }
    if (this.subscriptionOnMapMouseMove) {
      this.subscriptionOnMapMouseMove.unsubscribe();
    }
    if (this.polyline && this.polyline.points.length > 1) {
      this.map.removePolyline(this.polyline);
    }
    if (this.polyline2) {
      this.map.removePolyline(this.polyline2);
    }
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    MeasureComponent._this = null;
  }

  // Cuando se pincha sobre el mapa
  onMapClick(_this: MeasureComponent, point: MapLatLng) {
    if (!_this.polyline) {
      _this.polyline = _this.map.addPolyline({
        color: '#8000ff',
        weight: 6,
        opacity: 0.5
      });
    }
    _this.map.addPolylinePoint(_this.polyline, {
      position: new MapLatLng(point.lat, point.lng)
    });
    if (_this.polyline.points.length > 1) {
      _this.distanciaTotal += GeoUtils.getDistance(point.lat, point.lng,
        _this.polyline.points[_this.polyline.points.length - 2].point.lat,
        _this.polyline.points[_this.polyline.points.length - 2].point.lng);
      _this.distanciaTotal = Number.parseFloat(_this.distanciaTotal.toFixed(2));
    }
    if (_this.polyline.points.length > 2) {
      _this.calcArea();
    }
  }

  // Cuando se pincha sobre una polilina
  onPolylineClick(_this: MeasureComponent, polylinePoint: MapPolylinePoint) {
    _this.onMapClick(_this, polylinePoint.point);
  }

  // Cuando se mueve el cursor por encima del mapa
  onMapMouseMove(_this: MeasureComponent, point: MapLatLng) {
    if (_this.polyline) {
      if (_this.polyline2) {
        _this.map.removePolyline(_this.polyline2);
      }
      _this.polyline2 = _this.map.addPolyline({
        color: '#8080ff',
        weight: 4,
        opacity: 0.8
      });
      _this.map.addPolylinePoint(_this.polyline2, {
        position: _this.polyline.points[_this.polyline.points.length - 1].point
      });
      _this.map.addPolylinePoint(_this.polyline2, {
        position: point
      });
      const dist = GeoUtils.getDistance(point.lat, point.lng,
        _this.polyline.points[_this.polyline.points.length - 1].point.lat,
        _this.polyline.points[_this.polyline.points.length - 1].point.lng);
      _this.distanciaParcial = Number.parseFloat(dist.toFixed(2));
    }
  }

  // Calcula el área del polígono
  calcArea() {
    this.superficie = 0;
    for (let i = 0; i < this.polyline.points.length - 1; i++) {
      const p1 = this.polyline.points[i].point;
      const p2 = this.polyline.points[i + 1].point;
      this.superficie += this.degToRad(p2.lng - p1.lng) *
        (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    }
    // Invento un último punto igual al primero
    const p1 = this.polyline.points[this.polyline.points.length - 1].point;
    const p2 = this.polyline.points[0].point;
    this.superficie += this.degToRad(p2.lng - p1.lng) *
      (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    this.superficie = Math.abs(this.superficie * 6378137 * 6378137 / 2.0);
    this.superficie = Number.parseFloat(this.superficie.toFixed(2));
  }

  // Pasa grados a radianes
  degToRad(deg: number): number {
    return deg * Math.PI / 180.0;
  }

}
