<jqxWindow #windowStates [position]="getFormPos(windowStates)" [theme]="environment.tema" [width]="1000" [height]="463"
  [zIndex]="90" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  (onClose)="onClose()">
  <div class="formHeader">
  </div>
  <div class="form">
    <div>
      <app-header #header [exportar]="true" [periodo]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
         [noLibre]="false" (eventExportar)="onExportar()"
        (eventFilter)="onFilter()" (eventBuscador)="initSearchControl()" (eventResetFilter)="onResetFilter()">
      </app-header>
    </div>
    <div class="grid-incidences-container" style="width: 100%; height: calc(100% - 85px);">
      <jqxGrid #statesGrid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [columnsresize]="true"
        [source]="dataAdapter" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns"
        [altrows]="true" [sortmode]="'many'" [enabletooltips]="true" [filterable]="false" [showstatusbar]="true"
        [statusbarheight]="20" (onBindingcomplete)="onBindingComplete()" [showaggregates]="true" [rowsheight]="30"
        [columnsheight]="20" [localization]="langGrid" [groupable]="true" [autoloadstate]="true"
        (onRowselect)="onRowSelect($event)">
      </jqxGrid>
      <jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
      </jqxLoader>
    </div>
  </div>
</jqxWindow>