import {
  OnInit,
  Component,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfiguracionCerraduraService } from 'src/app/services/cerraduras/configuracion-cerradura.service';

import { ConfiguracionCerraduraModel } from 'src/app/services/cerraduras/models/configuracionCerradura.model';

import { InputNumberComponent } from '../../input-number/input-number.component';
import { ConfiguracionCerradurasComponent } from '../configuracion-cerraduras.component';
import { ListadoCerraduraComponent } from '../../reports/cerraduras/listado-cerradura/listado-cerradura.component';

@Component({
  selector: 'app-configuracion-cerraduras-edit',
  templateUrl: './configuracion-cerraduras-edit.component.html',
  styleUrls: ['./configuracion-cerraduras-edit.component.css']
})
export class ConfiguracionCerradurasEditComponent extends CustomForms implements OnInit {
  @ViewChild('windowForm') windowForm: jqxWindowComponent;
  @ViewChild('configRibbon') configRibbon;
  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;

  ejecutado: boolean = false;
  adminUser: Boolean = false;

  mapHeight;
  mapWidth;

  private viewInit: boolean = false;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');
  public configuracionCerradura: ConfiguracionCerraduraModel = new ConfiguracionCerraduraModel();

  residuosEquipamiento: string = '';

  desdeListadoCerraduras = false;

  /* Muestra la hora de envio inicial en el componente */
  horaEnvio = new Date('1-1-1 00:00');
  ejecutadoDos: boolean = false;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  setOrientation(event: any, instalacion) {
    if (event.checked) {
      this.configuracionCerradura.instalacionInvertida = instalacion;
    }
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  rendererDividir(number: number) {
    if (!this.ejecutadoDos) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          if (number == inputNumber.value) {
            inputNumber.rendererDividir('', '', value);
          }
        });
      }, 100);
      this.ejecutadoDos = true;
    }
  }

  resizeColumns(grid: jqxGridComponent) {
    if (grid) {
      Utils.renderSizeGrid(grid, 500);
    }
  }

  onResizing(event: any) {
    let width = event.args.width;

    if (width <= 1430) {
      document.getElementById('fieldGeneral').classList.remove('fieldGeneralLg');
      document.getElementById('fieldEquipamiento').classList.remove('fieldEquipamientoLg');
      document.getElementById('fieldConfModem').classList.remove('fieldConfModemLg');
      document.getElementById('fieldConfComunic').classList.remove('fieldConfComunicLg');
      document.getElementById('fieldConfAlarma').classList.remove('fieldConfAlarmaLg');
      document.getElementById('fieldInterUsuarios').classList.remove('fieldInterUsuariosLg');
      document.getElementById('fieldInterIdentif').classList.remove('fieldInterIdentifLg');

      document.getElementById('fieldGeneral').classList.add('fieldsetSm');
      document.getElementById('fieldEquipamiento').classList.add('fieldsetSm');
      document.getElementById('fieldConfModem').classList.add('fieldsetSm');
      document.getElementById('fieldConfComunic').classList.add('fieldsetSm');
      document.getElementById('fieldConfAlarma').classList.add('fieldsetSm');
      document.getElementById('fieldInterUsuarios').classList.add('fieldsetSm');
      document.getElementById('fieldInterIdentif').classList.add('fieldsetSm');

      if (document.getElementById('fieldTipoCerradura')) {
        document.getElementById('fieldTipoCerradura').classList.remove('fieldTipoCerraduraLg');
        document.getElementById('fieldTipoCerradura').classList.add('fieldsetSm');
      }
    } else {
      document.getElementById('fieldGeneral').classList.remove('fieldsetSm');
      document.getElementById('fieldEquipamiento').classList.remove('fieldsetSm');
      document.getElementById('fieldConfModem').classList.remove('fieldsetSm');
      document.getElementById('fieldConfComunic').classList.remove('fieldsetSm');
      document.getElementById('fieldConfAlarma').classList.remove('fieldsetSm');
      document.getElementById('fieldInterUsuarios').classList.remove('fieldsetSm');
      document.getElementById('fieldInterIdentif').classList.remove('fieldsetSm');

      document.getElementById('fieldGeneral').classList.add('fieldGeneralLg');
      document.getElementById('fieldEquipamiento').classList.add('fieldEquipamientoLg');
      document.getElementById('fieldConfModem').classList.add('fieldConfModemLg');
      document.getElementById('fieldConfComunic').classList.add('fieldConfComunicLg');
      document.getElementById('fieldConfAlarma').classList.add('fieldConfAlarmaLg');
      document.getElementById('fieldInterUsuarios').classList.add('fieldInterUsuariosLg');
      document.getElementById('fieldInterIdentif').classList.add('fieldInterIdentifLg');

      if (document.getElementById('fieldTipoCerradura')) {
        document.getElementById('fieldTipoCerradura').classList.remove('fieldsetSm');
        document.getElementById('fieldTipoCerradura').classList.add('fieldTipoCerraduraLg');
      }
    }
  }

  constructor(private configuracionCerraduraService: ConfiguracionCerraduraService, private ssoService: SsoService) {
    super();
  }

  ngOnInit(): void {
    this.adminUser = JSON.parse(localStorage.getItem('ticketSSO')).Rol.Nombre === 'Administrador' ? true : false;
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;

    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.initGridContenedor();
    this.initGridCerradura();
    this.initGridAlarma();
    this.initGridComunicacion();
    this.initGridVaciado();

    /* Seteo la hora de envio, que lleva la configuracion, a un formato leible por el componente */
    this.horaEnvio.setHours(Math.floor(this.configuracionCerradura.horaEnvio / 60));
    this.horaEnvio.setMinutes(this.configuracionCerradura.horaEnvio % 60);
  }

  public init(componentRef: any, configuracionCerradura: ConfiguracionCerraduraModel, desdeListadoCerraduras: boolean = false) {
    this.componentRef = componentRef;
    this.desdeListadoCerraduras = desdeListadoCerraduras;

    if (desdeListadoCerraduras) {
      this.configuracionCerradura = configuracionCerradura ? configuracionCerradura : new ConfiguracionCerraduraModel();
    } else {
      this.configuracionCerradura = configuracionCerradura ? configuracionCerradura : new ConfiguracionCerraduraModel();
      if (!this.configuracionCerradura.cerraduras) {
        this.configuracionCerradura.cerraduras = [];
      }

      if (this.configuracionCerradura && this.configuracionCerradura.equipamiento && this.configuracionCerradura.equipamiento.residuos) {
        this.configuracionCerradura.equipamiento.residuos.forEach(residuo => {
          this.residuosEquipamiento += this.residuosEquipamiento != '' ? ';' + residuo.Nombre : residuo.Nombre;
        });
      }
    }
  }

  async ngAfterContentInit() {
    this.configuracionCerradura.bateriaBaja = this.configuracionCerradura.bateriaBaja / 100;
    this.configuracionCerradura.bateriaCritica = this.configuracionCerradura.bateriaCritica / 100;
    this.configuracionCerradura.temperatura = this.configuracionCerradura.temperatura / 10;
  }

  checkAlarm(value): Boolean {
    if((this.configuracionCerradura.alarmas & value) > 0){
      return true;
    } else {
      return false;
    }
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.windowForm);

    this.windowForm.setTitle(this.translate('Configuracion_cerraduras'));

    if (this.configuracionCerradura.tipoContenedor != null) {
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridContenedor[this.configuracionCerradura.tipoContenedor + 1].tipo + '</div>';
      this.dropDownContenedor.setContent(content);
    }

    if (this.configuracionCerradura.tipoCerradura != null) {
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridCerradura[this.configuracionCerradura.tipoCerradura].tipo + '</div>';
      this.dropDownCerradura.setContent(content);
    }

    if (this.configuracionCerradura.tecnologia != null) {
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridComunicacion[this.configuracionCerradura.tecnologia].tipo + '</div>';
      this.dropDownComunicacion.setContent(content);
    }

    if (this.configuracionCerradura.deteccionVaciado != null && this.configuracionCerradura.deteccionVaciado != 0) {
      let content = '';
      if (this.configuracionCerradura.deteccionVaciado === 1) {
        content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridVaciado[0].tipo + '</div>';
      } else if (this.configuracionCerradura.deteccionVaciado === 5) {
        content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridVaciado[1].tipo + '</div>';
      } else if (this.configuracionCerradura.deteccionVaciado === 10) {
        content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridVaciado[2].tipo + '</div>';
      }

      this.dropDownVaciado.setContent(content);
    }
    /* Fin establecer texto de los desplegables */

    this.viewInit = true;

    setTimeout(() => {
      this.windowForm.bringToFront();
    }, 1)
  }

  closeWindow() {
    this.windowForm.close();
  }

  onClose() {
    if (this.componentRef) {
      this.configuracionCerradura.bateriaBaja = this.configuracionCerradura.bateriaBaja * 100;
      this.configuracionCerradura.bateriaCritica = this.configuracionCerradura.bateriaCritica * 100;
      this.configuracionCerradura.temperatura = this.configuracionCerradura.temperatura * 10;

      this.componentRef.destroy();
      if (ConfiguracionCerradurasComponent._this) {
        ConfiguracionCerradurasComponent._this.getConfiguraciones();
        ConfiguracionCerradurasComponent._this.form.expand();
      } else {
        ListadoCerraduraComponent.instance.form.expand();
      }

    }
  }

  async onGuardar(): Promise<void> {
    if (!this.configuracionCerradura.apn || this.configuracionCerradura.apn === '') {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Rellena_campos_obligatorios',
        2000
      );
    } else {
      let configuracionCerradura = await this.configuracionCerraduraService.createConfiguracionCerradura(this.configuracionCerradura);

      if (configuracionCerradura != null) {
        this.closeWindow();

        this.configuracionCerradura.id = configuracionCerradura.id;

        ConfiguracionCerradurasComponent._this.getConfiguraciones();

        MainComponent.getInstance().showInfo(
          'ATENCION',
          'Registro_almacenado',
          2000
        );
      } else {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Fallo_almacenar_info',
          2000
        );
      }
    }
  }

  changeSiempreAbierto(event: any) {
    this.configuracionCerradura.siempreAbierto = event.args.checked;
  }

  /* Convierte y guarda la hora de envio */
  setHoraEnvio(event: any) {
    let hora = event.split(':');
    this.configuracionCerradura.horaEnvio = hora[1] * 1 + hora[0] * 60;
  }

  /* Grid tipo contenedor */
  @ViewChild('dropDownContenedor') dropDownContenedor: jqxDropDownButtonComponent;
  @ViewChild('gridContenedor') gridContenedor: jqxGridComponent;

  public arrayGridContenedor;
  public adapterGridContenedor;
  public sourceGridContenedor;
  public columnsGridContenedor;

  gridDropContenedor: boolean;

  initGridContenedor() {
    this.arrayGridContenedor = [
      { 'tipo': '', 'value': null },
      { 'tipo': this.translate("Contenedor_tipo_apertura_vaciado"), 'value': 0 },
      { 'tipo': this.translate("Contenedor_tipo_comparte_trampilla"), 'value': 1 },
      { 'tipo': this.translate("Contenedor_tipo_soterrado"), 'value': 2 },
      { 'tipo': this.translate("Papelera"), 'value': 3 }
    ];

    this.columnsGridContenedor = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridContenedor = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridContenedor
    };

    this.adapterGridContenedor = new jqx.dataAdapter(this.sourceGridContenedor);

    this.resizeColumns(this.gridContenedor);
  }

  onOpenDropContenedor() {
    if (this.gridDropContenedor) {
      this.gridDropContenedor = false;
    }
  }

  onCloseDropContenedor() {
    if (this.gridContenedor) {
      this.gridDropContenedor = true;
    }
    this.dropDownContenedor.close();
  }

  onRowContenedorClick(event: any) {
    this.configuracionCerradura.tipoContenedor = this.arrayGridContenedor[event.args.rowindex].value;
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridContenedor[event.args.rowindex].tipo + '</div>';
    this.dropDownContenedor.setContent(content);
    this.dropDownContenedor.close();
  }

  /* Grid tipo cerradura */
  @ViewChild('dropDownCerradura') dropDownCerradura: jqxDropDownButtonComponent;
  @ViewChild('gridCerradura') gridCerradura: jqxGridComponent;

  public arrayGridCerradura;
  public adapterGridCerradura;
  public sourceGridCerradura;
  public columnsGridCerradura;

  gridDropCerradura: boolean;

  initGridCerradura() {
    this.arrayGridCerradura = [
      { 'tipo': this.translate("No_equipada"), 'value': 0 },
      { 'tipo': this.translate("Cerradura_welock"), 'value': 1 }
    ];

    this.columnsGridCerradura = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridCerradura = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridCerradura
    };

    this.adapterGridCerradura = new jqx.dataAdapter(this.sourceGridCerradura);

    this.resizeColumns(this.gridCerradura);
  }

  onOpenDropCerradura() {
    if (this.gridDropCerradura) {
      this.gridDropCerradura = false;
    }
  }

  onCloseDropCerradura() {
    if (this.gridCerradura) {
      this.gridDropCerradura = true;
    }
    this.dropDownCerradura.close();
  }

  onRowCerraduraClick(event: any) {
    this.configuracionCerradura.tipoCerradura = this.arrayGridCerradura[event.args.rowindex].value;
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridCerradura[event.args.rowindex].tipo + '</div>';
    this.dropDownCerradura.setContent(content);
    this.dropDownCerradura.close();
  }

  /* Grid tipo comunicaciones */
  @ViewChild('dropDownComunicacion') dropDownComunicacion: jqxDropDownButtonComponent;
  @ViewChild('gridComunicacion') gridComunicacion: jqxGridComponent;

  protected arrayGridComunicacion;
  public adapterGridComunicacion;
  public sourceGridComunicacion;
  public columnsGridComunicacion;

  gridDropComunicacion: boolean;

  initGridComunicacion() {
    this.arrayGridComunicacion = [
      { 'tipo': "NB IoT", 'value': 0 },
      { 'tipo': "LTE Cat M", 'value': 1 },
      { 'tipo': "GPRS 2G", 'value': 2 }
    ];

    this.columnsGridComunicacion = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridComunicacion = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridComunicacion
    };

    this.adapterGridComunicacion = new jqx.dataAdapter(this.sourceGridComunicacion);

    this.resizeColumns(this.gridComunicacion);
  }

  onOpenDropComunicacion() {
    if (this.gridDropComunicacion) {
      this.gridDropComunicacion = false;
    }
  }

  onCloseDropComunicacion() {
    if (this.gridComunicacion) {
      this.gridDropComunicacion = true;
    }
    this.dropDownComunicacion.close();
  }

  onRowComunicacionClick(event: any) {
    this.configuracionCerradura.tecnologia = this.arrayGridComunicacion[event.args.rowindex].value;
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridComunicacion[event.args.rowindex].tipo + '</div>';
    this.dropDownComunicacion.setContent(content);
    this.dropDownComunicacion.close();
  }

  /* Grid deteccion vaciado */
  @ViewChild('dropDownVaciado') dropDownVaciado: jqxDropDownButtonComponent;
  @ViewChild('gridVaciado') gridVaciado: jqxGridComponent;

  public arrayGridVaciado = [];
  public adapterGridVaciado;
  public sourceGridVaciado;
  public columnsGridVaciado;

  gridDropVaciado: boolean;

  initGridVaciado() {
    this.arrayGridVaciado = [
      { 'tipo': AppComponent.translate('Alta'), 'value': 1 },
      { 'tipo': AppComponent.translate('Media'), 'value': 5 },
      { 'tipo': AppComponent.translate('Baja'), 'value': 10 }
    ];

    this.columnsGridVaciado = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridVaciado = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridVaciado
    };

    this.adapterGridVaciado = new jqx.dataAdapter(this.sourceGridVaciado);

    this.resizeColumns(this.gridVaciado);
  }

  onOpenDropVaciado() {
    if (this.gridDropVaciado) {
      this.gridDropVaciado = false;
    }
  }

  onCloseDropVaciado() {
    if (this.gridVaciado) {
      this.gridDropVaciado = true;
    }
    this.dropDownVaciado.close();
  }

  onRowVaciadoClick(event: any) {
    this.configuracionCerradura.deteccionVaciado = this.arrayGridVaciado[event.args.rowindex].value;
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridVaciado[event.args.rowindex].tipo + '</div>';
    this.dropDownVaciado.setContent(content);
    this.dropDownVaciado.close();
  }


  /* Grid tipo alarmas */
  @ViewChild('dropDownAlarma') dropDownAlarma: jqxDropDownButtonComponent;
  @ViewChild('gridAlarma') gridAlarma: jqxGridComponent;
  textAlarmas: string = '';

  protected arrayGridAlarmas;
  public adapterGridAlarma;
  public sourceGridAlarma;
  public columnsGridAlarma;

  gridDropAlarma: boolean;

  initGridAlarma() {
    this.arrayGridAlarmas = [
      { 'tipo': "Alarma_bateria_baja", 'value': 2 },
      { 'tipo': "Alarma_bateria_critica", 'value': 4 },
      { 'tipo': "Cambio_bateria", 'value': 8 },
      { 'tipo': "Temperatura_elevada", 'value': 16 },
      { 'tipo': "Vaciado", 'value': 32 },
      { 'tipo': "Diagnostico", 'value': 64 },
      { 'tipo': "Puerta_abierta", 'value': 128 },
      { 'tipo': "Puerta_violentada", 'value': 256 },
      { 'tipo': "Vandalismo", 'value': 512 }
    ];

    this.columnsGridAlarma = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridAlarma = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridAlarmas
    };

    this.adapterGridAlarma = new jqx.dataAdapter(this.sourceGridAlarma);

    this.resizeColumns(this.gridAlarma);
  }

  onOpenDropAlarma() {
    if (this.gridDropAlarma) {
      this.gridDropAlarma = false;
    }
  }

  onCloseDropAlarma() {
    if (this.gridAlarma) {
      this.gridDropAlarma = true;
    }
    this.dropDownAlarma.close();
  }

  onChangeCheck(event: any, valor: number) {
    if (this.viewInit) {
      if(event.args.checked) {
        this.configuracionCerradura.alarmas = this.configuracionCerradura.alarmas + valor;
      }else{
        this.configuracionCerradura.alarmas = this.configuracionCerradura.alarmas - valor;
      }
      console.log(this.configuracionCerradura.alarmas);
    }
  }
}
