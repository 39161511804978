import { AccionModel } from "./accion.model";

export class AuditoriaModel {
    Id: number;
    Usuario: string;
    AccionId: number;
    Fecha: Date;
    Accion: AccionModel;
    Info: string;

    //Agregados
    AccionNombre: string;
    UsuarioNombre: string;

    constructor(usuario: string, accionId: number) {
        this.Usuario = usuario;
        this.AccionId = accionId;
    }
}
