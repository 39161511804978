export class ElemImageModel {
  id: number;
  idElemento: number;
  guidElement: string;
  fecha: Date = new Date();
  imagen: string;

  constructor() {

  }
}
