export class AccionModel {
    public Id: number;
    public Nombre: string;
    public Observaciones: string;
    public FechaAlta: string;
    public FechaBaja: string;
    public FechaModificacion: string;
}

//Acciones audioria
export enum Accion {
    CREAR_FIGURA_GEOMETRICA = 1,
    ACCESO_USUARIO = 2,
    EDITAR_FIGURA_GEOMETRICA = 3,
    ELIMINAR_FIGURA_GEOMETRICA = 4,
    CREAR_ZONA = 5,
    EDITAR_ZONA = 6,
    ELIMINAR_ZONA = 7,
    CREAR_ELEMENTO = 8,
    EDITAR_ELEMENTO = 9,
    ELIMINAR_ELEMENTO = 10,
    CREAR_PUNTO_DE_UBICACION = 11,
    EDITAR_PUNTO_UBICACION = 12,
    ELIMINAR_PUNTO_DE_UBICACION = 13,
    CREAR_INCIDENCIA = 14,
    EDITAR_INCIDENCIA = 15,
    CAMBIO_DE_ESTADO_DE_INCIDENCIA = 16,
    CREAR_MARCO_GEOGRAFICO = 17,
    CONFIGURACION_AMBITO_DE_ACTIVIDAD = 18,
    CONFIGURACION_CASA = 19,
    CREAR_CAPA_KML = 20,
    EDITAR_CAPA_KML = 21,
    ELIMINAR_CAPA_KML = 22,
    CONFIGURACION_PROVEEDOR_DE_CARTOGRAFIA = 23,
    CREAR_SUBFLOTA = 24,
    EDITAR_SUBFLOTA = 25,
    ELIMINAR_SUBFLOTA = 26,
    CREAR_CALENDARIO = 27,
    EDITAR_CALENDARIO = 28,
    BORRAR_CALENDARIO = 29,
    EXPORTAR_INFORME_MOVILIDAD = 30,
    EXPORTAR_INFORME_VOLUMETRICOS = 31,
    EXPORTAR_INFORME_SENSORES = 32,
    EXPORTAR_INFORME_ALARMAS = 33,
    CREAR_ALARMA = 34,
    EDITAR_ALARMA = 35,
    ELIMINAR_ALARMA = 36,
    ASIGNAR_MOVILES_ALARMA = 37,
    ASIGNAR_SUBFLOTA_MOVIL = 41,
    DESASIGNAR_SUBLOTA_MOVIL = 42,
    ELIMINAR_INCDENCIA = 43,
    CREAR_ENTRADA = 44,
    EDITAR_ENTRADA = 45,
    ELIMINAR_ENTRADA = 46,
    CREAR_CUADRICULA = 47,
    EDITAR_CUADRICULA = 48,
    ELIMINAR_CUADRICULA = 49,
    ASOCIAR_CERRADURA_CALENDARIO = 50,
    DESASOCIAR_CERRADURA_CALENDARIO = 51,
    CREAR_CIUDADANO = 54,
    EDITAR_CIUDADANO = 55,
    ELIMINAR_CIUDADANO = 56,
    CREAR_CERRADURA = 57,
    EDITAR_CERRADURA = 58,
    ELIMINAR_CERRADURA = 59,
    CREAR_CONFIGURACION_CERRADURA = 60,
    EDITAR_CONFIGURACION_CERRADURA = 61,
    ELIMINAR_CONFIGURACION_CERRADURA = 62,
    ASOCIAR_CERRADURA_CONFIGURACION = 63,
    DESASOCIAR_CERRADURA_CONFIGURACION = 64,
    EDITAR_TAREA = 65,
}
