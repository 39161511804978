import { InfogeoModel } from "../../maps/models/infogeo.model";

export class PosicionModel {
    MovilId: number;
    Fecha: Date;
    Ignicion: boolean;
    Lat: number;
    Lng: number;
    Velocidad: number;
    Altura: number;
    Rumbo: number;
    Conexion: boolean;
    // Este dato se rellena cuando se recibe la última posición de cada móvil
    Localizacion: InfogeoModel;

    public static compare(posicion1: PosicionModel, posicion2: PosicionModel): boolean {
        return posicion1.Fecha.getTime() === posicion2.Fecha.getTime() &&
            posicion1.Lat === posicion2.Lat &&
            posicion1.Lng === posicion2.Lng &&
            posicion1.Velocidad === posicion2.Velocidad &&
            posicion1.Altura === posicion2.Altura &&
            posicion1.Rumbo === posicion2.Rumbo &&
            posicion1.Ignicion === posicion2.Ignicion;
    }
}