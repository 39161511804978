import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';

import { KmlComponent } from './kml.component';

@NgModule({
  declarations: [
    KmlComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    jqxGridModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxLoaderModule
  ]
})
export class KmlModule { }
