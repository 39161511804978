
export class CustomForms {
    private static forms: boolean[] = [false, false, false, false, false, false, false, false];
    public maximizable: boolean = false;
    private static zIndexMin = 0;

    constructor() { }

    public addCustomForm(form: any, maximizable: boolean = true) {
        // Esto lo hago para contrarestar el incremento automático del zIndex que hace
        // internamente el componente JQXWindow
        const zIndex = form.host[0].style['zIndex'];
        if (zIndex > CustomForms.zIndexMin) {
            if (CustomForms.zIndexMin === 0) {
                CustomForms.zIndexMin = form.host[0].style['zIndex'];
            } else {
                form.host[0].style['zIndex']--;
            }
        }
        this.maximizable = maximizable;
        form.collapseAnimationDuration(0);
        // Si no es auto-open los eventos se capturan al abrir por primera vez
        if (!form.autoOpen()) {
            const onOpenSubscription = form.onOpen.subscribe((event) => {
                this.captureEvents(form);
                onOpenSubscription.unsubscribe();
            });
        } else {
            // Capturo los eventos de la ventana
            this.captureEvents(form);
        }
    }

    public captureEvents(form: any) {
        form.collapseAnimationDuration(0);
        form.attrMaxWidth = 10000;
        form.attrMaxHeight = 10000;
        form.iniWidth = form.attrWidth;
        form.oriWidth = form.attrWidth;
        form.oriHeight = form.attrHeight;
        form.maximized = false;
        const onResizeSubscription = form.onResizing.subscribe((event) => {
            form.attrWidth = event.args.width;
            form.attrHeight = event.args.height;
            if (this.maximizable) {
                let div = form.host[0].querySelector('#btnMaximize');
                if (div) {
                    if (form.attrWidth > form.iniWidth) {
                        form.maximized = false;
                        form.iniWidth = form.attrWidth;
                        div.className = 'jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-right jqx-icon-arrow-right-material';
                    }
                }
            }
        });
        const onMovingSubscription = form.onMoving.subscribe((event) => {
            form.x = event.args.x;
            form.y = event.args.y;
            if (form.isCollapsed === true) {
                form.xCollapsed = event.args.x;
                form.yCollapsed = event.args.y;
            }
        });
        const onCollapseSubscription = form.onCollapse.subscribe(() => {
            const menuContainer = document.getElementById('map-container').getClientRects();
            form.oriX = form.x;
            form.oriY = form.y;
            if (form.xCollapsed === undefined) {
                form.column = 0;
                for (; form.column < CustomForms.forms.length && CustomForms.forms[form.column]; form.column++);
                CustomForms.forms[form.column] = true;
                let row = 0;
                let column = form.column;
                if (form.column >= 8) {
                    row = 1;
                    column = form.column - 8;
                }
                form.x = menuContainer[0].left + 2 + (column * 150) + (column * 6);
                form.y = menuContainer[0].bottom - 30 - (30 * row) - (6 * row);
            } else {
                form.x = form.xCollapsed;
                form.y = form.yCollapsed;
            }
            form.oriWidth = form.attrWidth;
            form.oriHeight = form.attrHeight;
            form.oriTitle = form.title();
            form.setTitle(form.title().substring(0, 12) + '...');
            form.attrWidth = 150;
            form.isCollapsed = true;
            // Pongo el icono flecha arriba
            let div = form.host[0].querySelector('.jqx-window-collapse-button');
            if (div) {
                div.className = 'jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-up jqx-icon-arrow-up-material';
            }
        });
        const onExpandSubscription = form.onExpand.subscribe(() => {
            form.x = form.oriX;
            form.y = form.oriY;
            form.attrWidth = form.oriWidth;
            form.isCollapsed = false;
            form.setTitle(form.oriTitle);
            CustomForms.forms[form.column] = false;
            // Pongo el icono flecha abajo
            let div = form.host[0].querySelector('.jqx-window-collapse-button');
            if (div) {
                div.className = 'jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-down jqx-icon-arrow-down-material';
            }
        });
        const onCloseSubscription = form.onClose.subscribe(() => {
            if (form.column !== undefined) {
                CustomForms.forms[form.column] = false;
            }
            if (form.autoOpen()) {
                onMovingSubscription.unsubscribe();
                onCollapseSubscription.unsubscribe();
                onExpandSubscription.unsubscribe();
                onCloseSubscription.unsubscribe();
            }
        });
        // Controlo el doble click sobre la barra de título del formulario
        // form.host[0].childNodes[0].addEventListener("dblclick", () => {
        //     if (form.isCollapsed) {
        //         form.expand();
        //     } else {
        //         form.collapse();
        //     }
        // }, false);

        // Cambio el icono de minimizar
        let div = form.host[0].querySelector('.jqx-window-collapse-button');
        if (div) {
            div.className = 'jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-down jqx-icon-arrow-down-material';
        }
        if (this.maximizable) {
            // Añado botón de maximizar
            div = form.host[0].querySelector('.jqx-window-header');
            if (div) {
                const btnMaximize: any = document.createElement('div');
                btnMaximize.innerHTML = `
                    <div class="jqx-window-collapse-button-background jqx-window-collapse-button-background-material"
                        style="visibility: visible; width: 16px; height: 16px; margin-right: 4px; margin-left: 0px;
                        position: absolute; right: 32px;">
                            <div class="jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-right
                                jqx-icon-arrow-right-material" style="width: 100%; height: 100%; top: 0px;" id="btnMaximize">
                            </div>
                    </div>
                `;
                div.appendChild(btnMaximize);
                btnMaximize.addEventListener('click', (event: any) => {
                    // Si está minimizado se restaura
                    if (form.isCollapsed) {
                        form.expand();
                    } else {
                        if (form.maximized === true) {
                            form.maximized = false;
                            btnMaximize.innerHTML = `
                                <div class="jqx-window-collapse-button-background jqx-window-collapse-button-background-material"
                                style="visibility: visible; width: 16px; height: 16px; margin-right: 4px; margin-left: 0px;
                                position: absolute; right: 32px;">
                                    <div class="jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-right
                                        jqx-icon-arrow-right-material" style="width: 100%; height: 100%; top: 0px;" id="btnMaximize">
                                    </div>
                                </div>
                            `;
                            form.attrWidth = form.iniWidth;
                        } else {
                            if (form.attrWidth < window.innerWidth - form.x - 70) {
                                form.maximized = true;
                                form.iniWidth = form.attrWidth;
                                form.attrWidth = window.innerWidth - form.x - 70 + 'px';
                                btnMaximize.innerHTML = `
                                    <div class="jqx-window-collapse-button-background jqx-window-collapse-button-background-material"
                                    style="visibility: visible; width: 16px; height: 16px; margin-right: 4px; margin-left: 0px;
                                    position: absolute; right: 32px;">
                                        <div class="jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-left
                                            jqx-icon-arrow-left-material" style="width: 100%; height: 100%; top: 0px;" id="btnMaximize">
                                        </div>
                                    </div>
                                `;
                            }
                        }
                    }
                });
            }
        }
    }

    // Devuelve la posición del formulario al template
    public getFormPos(form: any, top = 60): any {
        const mapContainer = document.getElementById('map-container').getClientRects();
        if (form.x === undefined || form.y === undefined) {
            const centerContainer = document.getElementById('center-container').getClientRects();
            form.x = centerContainer[0].left + 2;
            form.y = centerContainer[0].top + top;
        }
        if (form.y > mapContainer[0].bottom - 30) {
            form.y = mapContainer[0].bottom - 30;
        }
        if (form.x < mapContainer[0].left + 2) {
            form.x = mapContainer[0].left + 2;
        }
        return {
            x: form.x,
            y: form.y
        };
    }

}
