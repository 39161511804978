import { BdtCarrozadoModel } from "./bdt-carrozado.model";
import { BdtChasisModel } from "./bdt-chasis.model";
import { BdtClaseModel } from "./bdt-clase.model";
import { BdtRecursoModel } from "./bdt-recurso.model";

export class BdtConjuntoVehiculoModel {
    Id: number;
    Codigo: string;
    Nombre: string;
    CargaMaximaNeta: number;
    Ancho: number;
    Largo: number;
    Kmco2: number;
    Coste: number;
    AnyoVida: number;
    PrecioCompra: number;
    AnyoAmortizacion: number;
    MantenimientoAnual: number;
    AnyoMatriculacion: number;
    Icono: string;
    RecursoId: number;
    ChasisId: number;
    Recurso: BdtRecursoModel;
    Chasis: BdtChasisModel = new BdtChasisModel();
    Clases: BdtClaseModel[];
    Carrozados: BdtCarrozadoModel[] = [];
    Volumen: number;

    // Nuevos campos
    Alto: number;
}
