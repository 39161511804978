import { ModeloModel } from "./modelo.model";

export class MotivoModel{
    Id: number = 0;
    EmpresaId: number = 0;
    ModeloId : number = 0;
    Nombre: string = '';
    Observaciones : string = '';

    //agregados
    Modelo: ModeloModel = new ModeloModel();   
}