import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { KmlModel } from './models/kml.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { Accion } from '../auditoria/models/accion.model';
import { MainComponent } from 'src/app/components/main/main.component';

@Injectable({
  providedIn: 'root'
})
export class KmlService {

  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  // Recupera todos los KML's de la empresa
  async getKmls(): Promise<KmlModel[]> {
    let result: KmlModel[] = null;
    try {
      result = await this.http.get<KmlModel[]>(this.ssoService.getTicket().UrlApi + '/api/kml/' +
        this.ssoService.getTicket().Empresa.IdGestion).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // Almacena un KML
  async saveKml(kml: KmlModel): Promise<KmlModel> {
    let response = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      kml.EmpresaId = this.ssoService.getTicket().Empresa.IdGestion;
      response = await this.http.post<KmlModel>(this.ssoService.getTicket().UrlApi + '/api/kml/crear',
        JSON.stringify(kml), httpOptions).toPromise();
      if (kml.Id && response) {
        this.auditoria.AccionId = Accion.EDITAR_CAPA_KML;
      } else if (response && !kml.Id) {
        this.auditoria.AccionId = Accion.CREAR_CAPA_KML;
      }
      this.msgChangeResponse(response);
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  // Borra un KML
  async deleteKml(kmlId: number): Promise<void> {
    try {
      await this.http.delete<void>(this.ssoService.getTicket().UrlApi + '/api/kml/borrar/' + kmlId).toPromise();
      this.auditoria.AccionId = Accion.ELIMINAR_CAPA_KML;
      this.auditoria.Info = 'GUID: ' + kmlId;
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (e) {
      console.log(e);
    }
  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.Id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response.Nombre;
  }

}
