<div class="loader-container" *ngIf="!columns.length">
    <div class=" loader"></div>
</div>
<jqxWindow #form [theme]="theme" [position]="getFormPos(form)" [zIndex]="90" [width]="550" [height]="400"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Cuadriculas')}}
    </div>

    <div class="form" style="float:left; overflow-x: hidden; ">
        <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
            (eventExportar)="onExportar()" (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()"
            (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
        </app-header>
        <div style="float:left; margin-top: 4px; width: 100%; height: calc(105% - 85px);">
            <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'105%'" [source]="dataAdapter"
                [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columns"
                [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
                [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="25"
                [columnsheight]="20" [editable]="false" (onRowclick)="onRowclick($event)"
                (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>
        </div>
    </div>
</jqxWindow>

<div hidden="viewForm">
    <jqxWindow #formCuadricula [theme]="theme" [position]="getFormPos(formCuadricula)" [zIndex]="90" [width]="320"
        [height]="180" [autoOpen]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
        (onClose)="onCloseForm()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Acciones')}}
        </div>
        <div class="form" style="float:left; overflow-x: hidden; ">
            <div style="width: 100%; display: flex; flex-direction: column;">
                <div style="float:left; clear:both;">
                    {{translate('Nombre')}}
                </div>
                <jqxInput [width]="235" [height]="25" [(ngModel)]="cuadricula.nombre">
                </jqxInput>
                <div style="margin-top: 2px;"> <span>{{translate('Distancia_m')}}</span></div>
                <div style="display: flex; justify-content: flex-start">
                    <div style="width: 75px;">
                        <div style="margin-top: 5px; float:left; clear:both;">
                            {{translate('Alto')}}
                        </div>
                        <jqxInput [width]="72" [height]="25" [(ngModel)]="cuadricula.alto" (keyup)="changeHigh($event)">
                        </jqxInput>
                    </div>
                    <div style="width: 75px;">
                        <div style="margin-top: 5px; float:left; clear:both;">
                            {{translate('Ancho')}}
                        </div>
                        <jqxInput [width]="72" [height]="25" [(ngModel)]="cuadricula.ancho"
                            (keyup)="changeBroad($event)">
                        </jqxInput>
                    </div>
                </div>
            </div>

            <div style="display: flex; gap: 5px; margin-top: 5px;">
                <jqxButton [width]="100" [height]="26" [imgPosition]="'left'" [textImageRelation]="'imageBeforeText'"
                    [textPosition]="'left'" [imgSrc]="'/assets/images/mas.png'" [value]="translate('Guardar')"
                    (onClick)="onSaveCuadricula($event)"></jqxButton>

                <jqxButton [width]="100" [height]="26" [textImageRelation]="'imageBeforeText'" [textPosition]="'center'"
                    (onClick)="onCancelBtn()" [value]="translate('CANCELAR')">
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>