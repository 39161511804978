import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { SubflotaModel } from './models/subflota.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { Accion } from '../auditoria/models/accion.model';
import { MainComponent } from 'src/app/components/main/main.component';

@Injectable({
  providedIn: 'root'
})
export class FlotaService {

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  // Recupera todas las subflotas
  async getSubflotas(): Promise<SubflotaModel[]> {
    let result: SubflotaModel[] = null;
    try {
      result = await this.http.get<SubflotaModel[]>(this.ssoService.getTicket().UrlApi + '/api/subflotas/' +
        this.ssoService.getTicket().Empresa.IdGestion).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // Recupera todas los móviles de una subflota
  async getSubflotaMoviles(subflotaId: number): Promise<number[]> {
    let result: number[] = null;
    try {
      result = await this.http.get<number[]>(this.ssoService.getTicket().UrlApi + '/api/subflotas/moviles/' +
        subflotaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // Crea o modifica una subflota
  async saveSubflota(subflota: SubflotaModel): Promise<SubflotaModel> {
    let response: SubflotaModel = null;
    try {
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'responseType': 'json'
        })
      }
      response = await this.http.post<SubflotaModel>(this.ssoService.getTicket().UrlApi + '/api/subflotas/crear',
        JSON.stringify(subflota), httpOptions).toPromise();

    } catch (e) {
      console.log(e);
    }
    if (subflota.Id && response) {
      this.auditoria.AccionId = Accion.EDITAR_SUBFLOTA;
    } else if (response && !subflota.Id) {
      this.auditoria.AccionId = Accion.CREAR_SUBFLOTA;
    }
    this.msgChangeResponse(response);
    this.auditoriaService.addAuditoria(this.auditoria);
    return response
  }

  // Borra una subflota
  async deleteSubflota(id: number): Promise<boolean> {
    try {
      await this.http.delete(this.ssoService.getTicket().UrlApi + '/api/subflotas/borrar/' + id).toPromise();
      this.auditoria.AccionId = Accion.ELIMINAR_SUBFLOTA;
      this.auditoria.Info = 'ID:' + id;
      this.auditoriaService.addAuditoria(this.auditoria);
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  // Asigna o desasigna móviles a una subflota
  // Si el campo SubflotaId es 0 se desasignan
  async setSubflotaMoviles(subflota: SubflotaModel): Promise<boolean> {
    let response = null;
    try {
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'responseType': 'json'
        })
      }
      const body = {
        SubflotaId: subflota.Id,
        MovilesId: subflota.MovilesId
      }
      response = await this.http.post<boolean>(this.ssoService.getTicket().UrlApi + '/api/subflotas/asignar',
        JSON.stringify(body), httpOptions).toPromise();
      if (body.SubflotaId !== 0) {
        this.auditoria.AccionId = Accion.ASIGNAR_SUBFLOTA_MOVIL;
        this.msgChangeResponse(subflota);
      } else {
        this.auditoria.AccionId = Accion.DESASIGNAR_SUBLOTA_MOVIL;
        this.msgChangeResponse(subflota);
      }
      this.auditoriaService.addAuditoria(this.auditoria);
      return true
    } catch {
    }
    return null;
  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.Id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response.Nombre;
  }

}
