import { BdtBaseModel } from "./bdt-base.model";

export class BdtRecursoModel extends BdtBaseModel {
    Id: number;
    Codigo: string;
    Acronimo: string;
    Nombre: string;
    TipoId: number;

    constructor(id: number, codigo: string, acronimo: string, nombre: string, tipoId: number) {
        super();
        this.Id = id;
        this.Codigo = codigo;
        this.Acronimo = acronimo;
        this.Nombre = nombre;
        this.TipoId = tipoId;
    }
}