import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from 'src/app/components/main/main.component';
import { CatastroModel } from 'src/app/services/ciudadanos/models/catastro.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';
import { CatastroService } from 'src/app/services/direccion/catastro.service';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-selector-catastro',
  templateUrl: './selector-catastro.component.html',
  styleUrls: ['./selector-catastro.component.css']
})
export class SelectorCatastroComponent implements OnInit {
  // Componentes del HTML
  @ViewChild('mapSearch') mapSearch: any;
  @ViewChild('referenciaCatastral') referenciaCatastral: jqxInputComponent;

  //Entradas
  @Input() ciudadano: CiudadanoModel;
  @Input() direccion: DireccionModel;

  // Funcionalidad
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  // Informacion catastral
  catastro: CatastroModel = new CatastroModel;
  catastroText: string = '';

  // Listado de catastros
  catastros: CatastroModel[] = [];

  // Comprobaciones
  primeraCarga = true;


  // Traducion de los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private catastroService: CatastroService,
  ) {

  }

  ngOnInit(): void {
    this.init();
  }

  public async init() {
    // Obtiene los catastros
    this.catastros = await this.catastroService.getCatastros();

    this.initGridCatastro();
  }

  ngAfterViewInit() {
    if(this.ciudadano && this.ciudadano.numeroCatastro){
      this.catastro = this.ciudadano.catastro;
    } else if(this.direccion && this.direccion.referenciaCatastral){
      this.catastro = this.direccion.catastro;
    }

    setTimeout(()=> {
      let value = '';

      if(this.catastro){
        value += this.catastro?.direccion ? this.catastro.direccion : '';
        value += this.catastro?.numero ? value != '' ? ', ' + this.catastro.numero : this.catastro.numero : '';
        value += this.catastro?.municipio ? value != '' ? ', ' + this.catastro.municipio : this.catastro.municipio : '';
        value += this.catastro?.provincia ? value != '' ? ', ' + this.catastro.provincia : this.catastro.provincia : '';
        value += this.catastro?.comunidadAutonoma ? value != '' ? ', ' + this.catastro.comunidadAutonoma : this.catastro.comunidadAutonoma : '';
      }

      this.catastroText = value;
      this.primeraCarga = false;
    }, 500)
  }

  // ComboBox de catastro
  public sourceCatastro: any;
  public dataAdapterCatastro: any;
  gridDropCatastro: boolean = true;
  public columnsCatastro;

  initGridCatastro() {
    this.columnsCatastro = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
      { text: AppComponent.translate('Numero_de_catastro'), columntype: 'textbox', filtertype: 'textbox', datafield: 'numReferencia' },
    ];

    this.sourceCatastro = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'numReferencia', type: 'strint', map: 'referenciaCatastral' },
      ],
      localdata: this.catastros,
    };

    this.dataAdapterCatastro = new jqx.dataAdapter(this.sourceCatastro);
  }

  onMapSearch(event: any) {
    if(!this.catastro){
      this.catastro = new CatastroModel();
    }

    this.catastro.direccion = event.place.Street;
    this.catastro.codigoPostal = event.place.PostalCode;
    this.catastro.poblacion = event.place.District;
    this.catastro.municipio = event.place.Locality;
    this.catastro.provincia = event.place.AdministrativeArea2;
    this.catastro.comunidadAutonoma = event.place.AdministrativeArea1;
    this.catastro.numero = event.place.StreetNumber;

    let value = '';

    value += this.catastro.direccion ? this.catastro.direccion : '';
    value += this.catastro.numero ? value != '' ? ', ' + this.catastro.numero : this.catastro.numero : '';
    value += this.catastro.municipio ? value != '' ? ', ' + this.catastro.municipio : this.catastro.municipio : '';
    value += this.catastro.provincia ? value != '' ? ', ' + this.catastro.provincia : this.catastro.provincia : '';
    value += this.catastro.comunidadAutonoma ? value != '' ? ', ' + this.catastro.comunidadAutonoma : this.catastro.comunidadAutonoma : '';

    this.catastroText = value;

    if(this.ciudadano){
      this.ciudadano.catastro = this.catastro;
    }else if(this.direccion){
      this.direccion.catastro = this.catastro;
    }
  }

  desvincularCatastro(){
    this.catastro = new CatastroModel();
    if(this.ciudadano){
      this.ciudadano.catastro = this.catastro;
      this.ciudadano.numeroCatastro = null;
    }else if(this.direccion){
      this.direccion.catastro = this.catastro;
      this.direccion.referenciaCatastral = null;
    }
    this.catastroText = '';
  }

  changeTitular(event: any, tipo: string){
    this.catastro.titular = event.target.value;

    if(tipo === "ciudadano"){
      this.ciudadano.catastro = this.catastro;
    }else if(tipo === 'direccion'){
      this.direccion.catastro = this.catastro;
    }
  }

  checkCatastro(event: any) {
    if(!this.primeraCarga && event.target.value !== ''){
      let catastro: CatastroModel = null;
      catastro = this.catastros.find(cat => cat.referenciaCatastral === event.target.value);

      if(catastro && this.catastro !== catastro) {
        this.catastro = catastro;

        let value = '';

        value += this.catastro.direccion ? this.catastro.direccion : '';
        value += this.catastro.numero ? value != '' ? ', ' + this.catastro.numero : this.catastro.numero : '';
        value += this.catastro.municipio ? value != '' ? ', ' + this.catastro.municipio : this.catastro.municipio : '';
        value += this.catastro.provincia ? value != '' ? ', ' + this.catastro.provincia : this.catastro.provincia : '';
        value += this.catastro.comunidadAutonoma ? value != '' ? ', ' + this.catastro.comunidadAutonoma : this.catastro.comunidadAutonoma : '';

        this.catastroText = value;

        if(this.ciudadano){
          this.ciudadano.catastro = this.catastro;
        }else if(this.direccion){
          this.direccion.catastro = this.catastro;
        }

        MainComponent.getInstance().showInfo('ATENCION', 'Catastro_duplicado', 3000)
      }else {
        this.catastro.id = 0;
        this.catastro.referenciaCatastral = event.target.value;

        if(this.ciudadano){
          this.ciudadano.catastro = this.catastro;
        }else if(this.direccion){
          this.direccion.catastro = this.catastro;
        }
      }
    }
  }
}
