import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDropDownButtonModule } from 'jqwidgets-ng/jqxdropdownbutton';

import { PuComponent } from './pu.component';
import { PuEditComponent } from './pu-edit/pu-edit.component';

@NgModule({
  declarations: [
    PuComponent,
    PuEditComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    jqxGridModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxNumberInputModule,
    jqxDropDownListModule,
    jqxDropDownButtonModule,
  ],
  exports: [
    PuComponent
  ]
})
export class PuModule { }
