import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { MapComponent, MapLatLng } from 'movisat-maps';
import { MapItinerary } from 'movisat-maps/lib/models/map.itinerary';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';

@Component({
  selector: 'app-itinerari',
  templateUrl: './itinerari.component.html',
  styleUrls: ['./itinerari.component.css']
})
export class ItinerariComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;

  public static _this: ItinerariComponent;

  private map: MapComponent;
  private componentRef = null;
  public environment = environment;
  private subscriptionOnMapClick = null;
  private subscriptionOnMarkerClick = null;
  private fromTab = 0;
  private origenFocused = false;
  private itinerary: MapItinerary;
  private position: MapLatLng;

  constructor(private ssoService: SsoService) {
    super();
    ItinerariComponent._this = this;
  }

  ngOnInit(): void {
    this.map = MainComponent.getActiveMap();
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Itinerarios'));
    this.subscribeMapEvents();


    //this.subscribeMapEventMark();
    // Cambio el puntero del ratón sobre el mapa
    // this.map.setMousePointer('assets/images/center.png');
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.form.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
  }
  // Este método es llamado por el creador del componente
  public init(componentRef: any, tab: number) {
    this.componentRef = componentRef;
    this.fromTab = tab;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    document.getElementById('itiIni').remove();
    document.getElementById('itiFin').remove();
    if (this.itinerary) {
      this.map.removeItineary(this.itinerary);
    }
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }

    if (this.subscriptionOnMarkerClick) {
      this.subscriptionOnMarkerClick.unsubscribe();
    }
    this.subscriptionOnMarkerClick = null;
    // this.map.setMousePointer('');
    MainComponent.getInstance().setFormItineraryVisible(false);
    setTimeout(() => {
      MainComponent.getInstance().tabGestion.selectAt(this.fromTab);
    }, 500);
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    ItinerariComponent._this = null;
  }

  // Cuando se abre el formulario
  onOpen() {
    MainComponent.getInstance().setFormItineraryVisible(true);
    const t = setTimeout(() => {
      clearTimeout(t);
      document.getElementById('itiIni').focus();
      this.origenFocused = true;
      this.itinerary = this.map.itinerary({
        fromInput: 'itiIni',
        toInput: 'itiFin',
        resultDiv: 'itineraryInfo'
      });
    }, 2000);
  }

  // Me subscrivo a eventos del mapa
  subscribeMapEvents() {
    this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onConstructorItinerari);
    this.subscriptionOnMarkerClick = this.map.subscribeOnMarkerClick(this, this.onConstructorItinerari);
  }

  onConstructorItinerari(_this: ItinerariComponent, marker: any) {
    if (marker.position !== undefined) {
      _this.position = marker.position;
    } else if (marker) {
      _this.position = marker;
    }
    const point = _this.map.latLngToScreenPoint(_this.position);
    const newPosition = _this.map.screenPointToLatLng(point);

    let elem;
    if (_this.origenFocused) {
      elem = document.getElementById('itiIni');
      elem.value = _this.translate('Punto') + ' A';
      _this.itinerary.setOrigen(newPosition);
      _this.origenFocused = false;
    } else {
      elem = document.getElementById('itiFin');
      elem.value = _this.translate('Punto') + ' B';
      _this.itinerary.setDestino(newPosition);
    }
    if (_this.itinerary.getOrigen() && _this.itinerary.getDestino()) {
      _this.itinerary.calcItinerary();
    }
  }

  onOrigenFocus(event: any) {
    const elem: any = document.getElementById('itiIni');
    elem.value = '';
    this.origenFocused = true;
  }

  onDestinoFocus(event: any) {
    const elem: any = document.getElementById('itiFin');
    elem.value = '';
    this.origenFocused = false;
  }

  onAlternar(event: any) {
    const elemOri: any = document.getElementById('itiIni');
    const elemDest: any = document.getElementById('itiFin');
    let oriText = elemOri.value;
    let destText = elemDest.value;
    if (oriText !== this.translate('Punto') + ' A' || destText !== this.translate('Punto') + ' B') {
      if (oriText === this.translate('Punto') + ' A') {
        oriText = this.translate('Punto') + ' B';
      } else {
        if (oriText === this.translate('Punto') + ' B') {
          oriText = this.translate('Punto') + ' A';
        }
      }
      if (destText === this.translate('Punto') + ' A') {
        destText = this.translate('Punto') + ' B';
      } else {
        if (destText === this.translate('Punto') + ' B') {
          destText = this.translate('Punto') + ' A';
        }
      }
      elemOri.value = destText;
      elemDest.value = oriText;
    }
    const ori = this.itinerary.getOrigen();
    const dest = this.itinerary.getDestino();
    if (ori && dest) {
      this.itinerary.setOrigen(dest);
      this.itinerary.setDestino(ori);
      this.itinerary.calcItinerary();
    }
  }

}
