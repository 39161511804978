import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';
import { MainComponent } from 'src/app/components/main/main.component';

import { MapBounds, MapComponent, MapLatLng, MapMarker } from 'movisat-maps';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { CasaModel } from 'src/app/services/geographics/casa.model';
import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';

@Component({
  selector: 'app-casa',
  templateUrl: './casa.component.html',
  styleUrls: ['./casa.component.css']
})
export class CasaComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;

  public static _this: CasaComponent;

  private componentRef = null;
  public environment = environment;
  public visible = false;
  public canEdit = false;
  private map: MapComponent;
  private marker: MapMarker;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  constructor(private configService: ConfigService, private auditoriaService: AuditoriaService, private ssoService: SsoService) {
    super();
    CasaComponent._this = this;
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Casa'));
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.form.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
    this.map = MainComponent.getActiveMap();
    this.visible = await this.configService.getEmpApp('ver-casa', 'false') === 'true';
    let pos: MapLatLng;
    if (MainComponent.getInstance().casaMarker) {
      pos = MainComponent.getInstance().casaMarker.position;
      this.map.setCenter(MainComponent.getInstance().casaMarker.position);
      this.map.setZoom(MainComponent.getInstance().casaZoom);
    } else {
      pos = new MapLatLng(this.map.center.lat, this.map.center.lng);
    }
    this.marker = this.map.addMarker({
      dataModel: this,
      title: this.translate('Casa'),
      content: this.translate('Casa'),
      position: pos,
      icon: '/assets/images/center.png',
      zIndex: 999,
      drag: false,
      visible: true
    });
    // this.marker.animate(1000);
    MainComponent.getInstance().hideCasa(false);
    this.map.subscribeOnBoundsChange(this, (_this: any, bounds: MapBounds) => {
      this.marker.setPosition(this.map.center);
    });
    MainComponent.showInfo('ATENCION', 'Mueva_mapa', 5000);
  }

  // Cuando se cierra
  async onClose() {
    if (this.marker) {
      this.map.removeMarker(this.marker);
    }
    if (await this.configService.getEmpApp('ver-casa', 'false') === 'true') {
      MainComponent.getInstance().showCasa();
    } else {
      MainComponent.getInstance().hideCasa();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    CasaComponent._this = null;
  }

  // Inicialización del componente
  async init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onChangeVisible(event: any) {
    this.visible = event.currentTarget.checked;
  }

  async onGuardar(event: any) {
    if (this.marker) {
      const casa = new CasaModel(this.marker.position, this.map.zoom);
      await this.configService.setEmpApp('casa', JSON.stringify(casa));
      this.auditoria.AccionId = Accion.CONFIGURACION_CASA;
      this.auditoriaService.addAuditoria(this.auditoria);
    }
    // Al cerrar se almacena si está visible o no la casa
    await this.configService.setEmpApp('ver-casa', this.visible ? 'true' : 'false');
    this.form.close();
  }

}
