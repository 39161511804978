import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MapBounds, MapComponent, MapLatLng, MapMarker } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { CerraduraModel } from 'src/app/services/cerraduras/models/cerradura.model';
import { PosicionCerraduraModel } from 'src/app/services/cerraduras/models/posicionCerradura.model';
import { ElementoCerraduraModel } from 'src/app/services/cerraduras/models/elementoCerradura.model';

import { MainComponent } from 'src/app/components/main/main.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { ConfiguracionCerradurasEditComponent } from 'src/app/components/configuracion-cerraduras/configuracion-cerraduras-edit/configuracion-cerraduras-edit.component';

@Component({
  selector: 'app-listado-cerradura',
  templateUrl: './listado-cerradura.component.html',
  styleUrls: ['./listado-cerradura.component.css']
})
export class ListadoCerraduraComponent extends CustomForms implements OnInit {
  private componentRef = null;
  public static instance: ListadoCerraduraComponent = null; // Para controlar que sólo se cree una instancia
  public static numInstances = 0; // Para controlar el número de reproductores abiertos
  public theme = environment.tema;
  private subscriptionOnMarkerDragEnd; any = null;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('formDetalle') formDetalle: jqxWindowComponent;
  @ViewChild('formLockConfiguracion', { read: ViewContainerRef }) editConfiguracionComponent;
  @ViewChild('myGrid') grid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;
  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  public dataSource: any = null;
  public dataAdapter: any;
  public cerraduras: CerraduraModel[] = [];
  public cerradura: CerraduraModel = new CerraduraModel();
  elemento: ElementoCerraduraModel = new ElementoCerraduraModel();
  private map: MapComponent;
  elementos: ElementoCerraduraModel[] = [];
  dateFin = new Date();
  dateInicio = new Date();
  checkSelect: number[] = [];
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  viewForm: boolean = false;
  clickTimer: any;
  mapWidth: number;
  mapHeight: number;
  formLockConfiguracion: any;
  showLoader: boolean = true;

  constructor(private cerraduraService: CerraduraService, private ssoService: SsoService) {
    super();
    ListadoCerraduraComponent.instance = this;
  }

  rendexTextGeneric = (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
  }



  public columns: any = [
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
    {
      width: 82,
      text: '',
      columntype: 'text',
      editable: false,
      datafield: 'Botones',
      groupable: false,
      menu: false,
      sortable: false,
      clickable: false,
      // quito el filtro de la columna
      filterable: false,
      renderer: (row: number, column: any, value: string): string => {
        return '<div style="text-align: center; margin-top: 5px;"><i style="font-size: 20px;" class="material-icons md-light">more_vert</i></div>';
      },
      createwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ): void => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      initwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ) => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Id'), datafield: 'id', hidden: true, filtertype: 'textbox', editable: false, cellsrenderer: this.rendexTextGeneric },
    { text: 'idElemento', datafield: 'elementoId', hidden: true, filtertype: 'textbox', width: 150, editable: false, cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Tipo_producto'), datafield: 'tipoProducto', filtertype: 'checkedlist', width: 80, cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Ns_movisat'), width: 170, filtertype: 'textbox', datafield: 'nsMovisat', cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Ns_fabricante'), width: 170, filtertype: 'textbox', datafield: 'nsFabricante', cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Version'), filtertype: 'textbox', width: 100, datafield: 'version', cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Imei'), filtertype: 'textbox', cellsalign: 'right', width: 170, datafield: 'imei', cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Sim'), filtertype: 'textbox', cellsalign: 'right', width: 110, datafield: 'sim', cellsrenderer: this.rendexTextGeneric },
    { text: 'IMSI', filtertype: 'textbox', width: 210, datafield: 'imsi', cellsrenderer: this.concatImsiRender, },
    { text: 'MCC', filtertype: 'textbox', datafield: 'mcc', hidden: true, cellsrenderer: this.rendexTextGeneric },
    { text: 'mnc', filtertype: 'textbox', datafield: 'mnc', hidden: true, cellsrenderer: this.rendexTextGeneric },
    { text: 'ICC', filtertype: 'textbox', datafield: 'icc', hidden: true, cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Nombre_elemento'), datafield: 'elemento', filtertype: 'textbox', width: 150, cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Marca'), filtertype: 'checkedlist', datafield: 'marca', width: 110, cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Modelo'), filtertype: 'checkedlist', datafield: 'modelo', width: 110, cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Matricula'), filtertype: 'textbox', datafield: 'matricula', width: 110, cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('F_ult_conex'), datafield: 'fechaModificacion', columntype: 'datetimeinput', width: 100, cellsrenderer: this.rendexTextGeneric },
    { text: AppComponent.translate('Observaciones'), datafield: 'observaciones', menu: false, sortable: false, width: 110, cellsrenderer: this.rendexTextGeneric },
  ];

  /**Inicializa el componente
  */
  init(componentRef: any) {
    // solo se puede crear una instancia
    if (ListadoCerraduraComponent.numInstances == 0) {
      ListadoCerraduraComponent.numInstances++;
      this.componentRef = componentRef;
    } else {
      componentRef.destroy();
    }
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.map = MainComponent.getInstance().getMap();
    this.getCerraduras();
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.addCustomForm(this.formDetalle);
    this.form.setTitle(AppComponent.translate('Cerradura_lot'));
    this.formDetalle.setTitle(AppComponent.translate('Ficha_cerradura_iot'));
    this.onSubribeMap();
  }

  // Incializa la columna de iconos
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Creo un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.grid.getrowdata(row);
    }

    const btnInfo = document.createElement('div');
    btnInfo.innerHTML =
      '<button id=info style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;">' +
      '<i style="font-size: 18px;" class="fas fa-circle-info"></i></button>';
    btnInfo.id = `buttonInfoMap_jqxButton`;
    btnContainer.appendChild(btnInfo);
    htmlElement.appendChild(btnContainer);

    // lo mismo para el resto de botones
    const newIconInfo = document.getElementById("buttonInfoMap_jqxButton");
    if (newIconInfo) {
      btnInfo.title = this.translate("Ficha_cerradura_iot");
    }

    // Capturo el click de los botones
    btnInfo.addEventListener('click', async (event: any) => {
      this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === rowdata.id);
      this.cerradura.imsi = this.concatImsi(this.cerradura.mcc, this.cerradura.mnc, this.cerradura.icc);
      this.form.collapse();
      this.formDetalle.open(); //Abre el formulario
      this.formDetalle.bringToFront();
    });

    if (rowdata && rowdata.elementoId !== null) {
      const btnViewMap = document.createElement('div');
      btnViewMap.innerHTML =
        '<button id="viewMap" style=" height: 23px; width: 25px;  padding: 0; margin: 0; cursor: pointer !important;">' +
        '<i style="font-size: 18px;" class="fa-sharp fa-solid fa-location-dot"></i></button>';
      btnViewMap.id = `buttonViewMap_jqxButton`;
      btnContainer.appendChild(btnViewMap);

      const newIconViewMap = document.getElementById("buttonViewMap_jqxButton");
      if (newIconViewMap) {
        btnViewMap.title = this.translate("Posicionar_en_mapa");
      }

      btnViewMap.addEventListener('click', async (event: any) => {
        this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === rowdata.id);
        this.onCentrar(rowdata);
        this.form.collapsed(true);
      });
    }

    // si rowdata tiene configuracion, añado un botón para ver la configuración
    if (rowdata && rowdata.configuracion !== null) {
      const btnConfig = document.createElement('div');
      btnConfig.innerHTML =
        '<button id="config" style=" height: 23px; width: 25px;  padding: 0; margin: 0; cursor: pointer !important;">' +
        '<i style="font-size: 18px;" class="fa-solid fa-cog"></i></button>';
      btnConfig.id = `buttonConfig_jqxButton`;
      btnContainer.appendChild(btnConfig);

      const newIconConfig = document.getElementById("buttonConfig_jqxButton");
      if (newIconConfig) {
        btnConfig.title = this.translate("Configuracion");
      }

      btnConfig.addEventListener('click', async (event: any) => {
        this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === rowdata.id);
        let desdeListadoCerraraduras = true;
        this.formLockConfiguracion = this.editConfiguracionComponent.createComponent(ConfiguracionCerradurasEditComponent);
        this.formLockConfiguracion.instance.init(this.formLockConfiguracion, this.cerradura.configuracion, desdeListadoCerraraduras);
        this.form.collapsed(true);
      });
    }
  }

  onResetFilter(event: any) {
    this.grid.clearfilters();
  }

  onCloseForm(): void {
    this.cerradura = new CerraduraModel();
    this.form.expand();
    this.viewForm = false;
  }



  onRowdoubleclick(event: any): void {
    clearTimeout(this.clickTimer);  // Limpiamos el temporizador si se detecta un doble clic
    this.viewForm = true;
    let row: any = this.grid.getrowdatabyid(event.args.rowindex);
    this.cerradura = this.cerraduras.find(cerradura => cerradura.id === row.id);
    this.cerradura.imsi = this.concatImsi(this.cerradura.mcc, this.cerradura.mnc, this.cerradura.icc);
    this.form.collapse();
    this.formDetalle.open();
    setTimeout(() => {
      this.formDetalle.bringToFront();
    }, 100);
  }

  onCellClick(event: any) {
    clearTimeout(this.clickTimer);  // Siempre limpiamos el temporizador con cada clic para reiniciar el proceso
    // Configuramos el temporizador
    this.clickTimer = setTimeout(() => {
      if (event.args.datafield !== 'Botones') {
        let row: any = this.grid.getrowdatabyid(event.args.rowindex);
        this.onCentrar(row);
        this.form.collapsed(true);
        this.viewForm = false;
        this.form.expand();
      }
    }, 500);  // 500 milisegundos de espera para determinar si es un clic individual
  }

  concatImsi(mcc: string, mnc: string, icc: string): string {
    // concateno el icc, mcc, msi
    let imsi: string = '';
    // modifico para que salga con guiones y no null
    if (mcc === null) {
      imsi += '-'
      this.cerradura.mcc = '-';
    } else {
      imsi += String(mcc);
    }

    if (mnc === null) {
      imsi += '-';
      this.cerradura.mnc = '-';
    } else {
      imsi += String(mnc)
    }

    if (icc === null) {
      imsi += '-';
      this.cerradura.icc = '-';
    } else {
      imsi += String(icc);
    }

    return imsi
  }

  // obtiene las cerraduras
  async getCerraduras() {
    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    await this.cerraduraService.getCerraduras().then(
      async (result: CerraduraModel[]) => {
        this.cerraduras = await result;
        this.showLoader = false;
      }, (error: any) => {
        this.cerraduras = [];
      }
    );

    this.dataSource = {
      dataType: 'json',
      dataFields: [
        { name: 'Botones', type: 'string' },
        { name: 'id', type: 'number', map: 'id' },
        { name: 'elementoId', type: 'number', map: 'idElemento' },
        { name: 'tipoProducto', type: 'string', map: 'tipoOperacionStr' },
        { name: 'marca', type: 'string', map: 'elemento>marca' },
        { name: 'modelo', type: 'string', map: 'elemento>modelo' },
        { name: 'matricula', type: 'string', map: 'elemento>tag>matricula' },
        { name: 'elemento', type: 'string', map: 'elemento>nombre' },
        { name: 'configuracion', type: 'string', map: 'configuracion' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'nsFabricante', type: 'string', map: 'nsFabricante' },
        { name: 'version', type: 'string', map: 'versoft' },
        { name: 'imei', type: 'string', map: 'imei' },
        { name: 'sim', type: 'string', map: 'sim' },
        { name: 'imsi', type: 'string', map: 'imsi' },
        { name: 'mcc', type: 'string', map: 'mcc' },
        { name: 'mnc', type: 'string', map: 'mnc' },
        { name: 'icc', type: 'string', map: 'icc' },
        { name: 'elemento', type: 'string', map: 'elemento>nombre' },
        { name: 'marca', type: 'string', map: 'elemento>marca' },
        { name: 'modelo', type: 'string', map: 'elemento>modelo' },
        { name: 'matricula', type: 'string', map: 'elemento>tag>matricula' },
        { name: 'fechaModificacion', type: 'string', map: 'fechaUltimaConexionStr' },
        { name: 'observaciones', type: 'string', map: 'elemento>observaciones' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.cerraduras
    }
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);

    this.onBuscar();
  }

  concatImsiRender(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    // concateno el icc, mcc, msi
    let imsi: string = '';
    // modifico para que salga con guiones y no null
    if (rowdata.mcc !== null) {
      imsi += String(rowdata.mcc);
    }

    if (rowdata.mnc !== null) {
      imsi += String(rowdata.mnc)
    }

    if (rowdata.icc !== null) {
      imsi += String(rowdata.icc);
    }

    rowdata.imsi = imsi

    return `<div style="margin-left: 4px; margin-top: 5px;  text-align: right;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${imsi}</div>`;

  }

  onCentrar(data: CerraduraModel) {
    if (data) {
      this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === data.id);
      if (this.cerradura.elemento !== null) {
        this.map.setCenter(new MapLatLng(this.cerradura.elemento.lat, this.cerradura.elemento.lng));
        this.map.setZoom(20);
      } else {
        return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
      }
    }
  }

  async onSelect(event: any) {
    // Si se seleccionan todos los elementos del grid
    switch (event.args.rowindex.length) {
      case 0:
        this.checkSelect = [];
        break;
      default:
        let rows: any[] = this.grid.getrows();
        let idRow: number[] = this.grid.getselectedrowindexes();
        this.checkSelect = [];
        rows.forEach((element, index) => {
          if (idRow.includes(index)) {
            this.cerradura = this.cerraduras.find((cerradura) => cerradura.id === element.id);
            this.checkSelect.push(idRow[index]);
            this.elementos.push(this.cerradura.elemento);
          }
        });
        //this.checkSelect = Utils.onChangeArray(this.checkSelect);
        this.elementos = Utils.onChangeArray(this.elementos);
        break;
    }
  }

  onUnSelect(event) {
    let row: any = this.grid.getrowdatabyid(event.args.rowindex);
    // elimino el elemento deseleccionado del array checkSelect
    this.checkSelect.splice(this.checkSelect.indexOf(row), 1);
    this.checkSelect = Utils.onChangeArray(this.checkSelect);
    this.elementos.splice(this.elementos.indexOf(row.elemento), 1);
    this.elementos = Utils.onChangeArray(this.elementos);
  }

  onSubribeMap() {
    if (!this.subscriptionOnMarkerDragEnd) {
      this.subscriptionOnMarkerDragEnd = this.map.subscribeOnMarkerDragEnd(
        this,
        (_this: any, marker: MapMarker) => {
          if (
            MainComponent.getInstance().controlAmbitoActividad(
              marker.position
            )
          ) {
            this.elemento.lat = marker.position.lat;
            this.elemento.lng = marker.position.lng;
          } else {
            if (this.elemento.lat && this.elemento.lng) {
              MainComponent.getInstance().showError(
                'ATENCION',
                'Fuera_ambito',
                2000
              );
              marker.setPosition(
                new MapLatLng(this.elemento.lat, this.elemento.lng)
              );
            }
          }
        }
      );
    }
  }

  onPosicionar() {
    if (this.subscriptionOnMarkerDragEnd && this.elementos.length !== 0) {

      // Asgino los límites máximos y mínimos de latitud y longitud
      let minLat = this.elementos[0].lat;
      let maxLat = this.elementos[0].lat;
      let minLng = this.elementos[0].lng;
      let maxLng = this.elementos[0].lng;

      this.elementos.forEach((elemento) => {
        if (elemento.lat < minLat) minLat = elemento.lat;
        if (elemento.lat > maxLat) maxLat = elemento.lat;
        if (elemento.lng < minLng) minLng = elemento.lng;
        if (elemento.lng > maxLng) maxLng = elemento.lng;
      });

      // Creo objetos MapLatLng para las esquinas SW y NE
      const swCorner = new MapLatLng(minLat, minLng);
      const neCorner = new MapLatLng(maxLat, maxLng);

      // ajusto el mapa
      this.map.fitTo(new MapBounds(swCorner, neCorner));

      this.form.collapse();
    } else if (this.checkSelect.length === 0) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'No_existen_coordenadas', 2000);
    }
  }

  // botones de acciones
  async onLocalizar() {
    let pedirPosicion: PosicionCerraduraModel = new PosicionCerraduraModel(this.checkSelect, this.empresaId);
    if (pedirPosicion && pedirPosicion.idsCerraduras.length !== 0) {
      let reult: any = await this.cerraduraService.pedirPosicion(pedirPosicion);
      if (reult.state == 'ok') {
        MainComponent.getInstance().showSuccess('ATENCION', 'Peticion_localizacion_realizada', 2000);
        this.grid.clearselection();
        this.checkSelect = [];
      }
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Error_enviar_localizacion', 2000);
    }
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getCerraduras();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.cerraduras.forEach(cerr => {
      if (
        (cerr?.idElemento + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.tipoOperacionStr + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.nsMovisat + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.nsFabricante + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.versoft + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.imei + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.sim + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (this.concatImsi(cerr.mcc, cerr.mnc, cerr.icc) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.nombre + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.marca + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.modelo + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.tag?.matricula + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.fechaUltimaConexionStr + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (cerr?.elemento?.observaciones + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        cerr['selec'] = 'selec';
      } else {
        cerr['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    this.grid.applyfilters();
    this.grid.updatebounddata('data');

  }

  onExportar() {
    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.grid.exportdata('json');
      let datos = JSON.parse(json);
      // Utiliza el array modificado en lugar del JSON original
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Cerradura_lot') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.grid.hidecolumn('Botones');
      let gridContent = this.grid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.grid.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
      ListadoCerraduraComponent.numInstances--;
    }

    if (this.subscriptionOnMarkerDragEnd) {
      this.subscriptionOnMarkerDragEnd.unsubscribe();
      this.subscriptionOnMarkerDragEnd = null;
    }

    if (this.formDetalle) {
      this.formDetalle.destroy();
      this.viewForm = false;
    }

    ListadoCerraduraComponent.instance = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
