import { NgModule } from '@angular/core';

import { HeaderComponent } from './header.component';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { CommonModule } from '@angular/common';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    jqxButtonModule,
    jqxCheckBoxModule,
    PeriodoSelectModule,
    jqxNumberInputModule,
    jqxDropDownListModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
