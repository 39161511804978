import { NgModule } from '@angular/core';
import { LabelOverLenghtComponent } from './label-over-lenght.component';



@NgModule({
  declarations: [
    LabelOverLenghtComponent
  ],
  imports: [
  ],
  exports: [
    LabelOverLenghtComponent
  ]

})
export class LabelOverLenghtModule { }
