<div class="footer" style="float: left; width: 100%;">
    <div class="footer" style="float: left; width: 33%;">
    </div>
    <div class="footer" style="float: left; width: 33%;">
        <div class="footer">
            <div *ngIf="getEntorno().length > 0">
                Copyright <a href="http://www.movisat.com" target="_blank">MOVISAT</a> © {{anno}} | {{getEntorno()}}
            </div>
            <div *ngIf="getEntorno().length < 1">
                Copyright <a href="http://www.movisat.com" target="_blank">MOVISAT</a> © {{anno}}
            </div>
        </div>
    </div>
    <div class="footer" style="float: left; width: 33%;">
        <div style="float: right; margin-right: 0px;">
            <sup>Ver. {{mainComponent.webVersion}} / API {{mainComponent.apiVersion.apiVer}}</sup>
        </div>
    </div>
</div>