
export class BdtChasisModel {
    Id: number;
    Codigo: string;
    AnyoFabricacion: number;
    Descripcion: string;
    CargaMaxima: number;
    Ancho: number;
    Tara: number;
    Procolo: string;
    VehiculoIndustrial: boolean;
    AnyoVida: number;
    PrecioCompra: number;
    AnyoAmortizacion: number;
    MantenimientoAnual: number;
    Suspension: string;
    Obdii: boolean;
    LocalizacionObdii: string;
    CanbusFms: boolean;
    LocalizacionCanbusFms: string;
    Canbus: boolean;
    LocalizacionCanbus: string;
    ContadorHoras: number;
    ModeloId: number;
    NumPersonas: number;
    NumOperarios: number;
    Combustible: number;

    // Nuevos campos
    Marca: string;
    Modelo: string;
    TipoCanbus: string;
}
