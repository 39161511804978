<div style="display: flex; gap: 2px; height: calc(100% - 55px);">
  <fieldset>
    <legend>{{ translate("Historico_coordenadas") }}</legend>
    <jqxGrid #gridHistCoordenadas [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 10px)'"
      [autoheight]="false" [source]="dataAdapterHistCoordenadas" [columnsresize]="true" [selectionmode]="'singlerow'"
      [columns]="columHistCoordenadas" [sortable]="true" [altrows]="true" [showrowlines]="true"
      [rowsheight]="25" [columnsheight]="20" [enabletooltips]="false" [localization]="langGrid">
    </jqxGrid>
  </fieldset>
</div>
