import { CalendarModel } from "src/app/services/calendar/models/calendar.model";

export class Appointment {
    Id: number;
    Description: string;
    Location: string;
    Subject: string;
    Calendar: string;
    Start: Date;
    End: Date;
    Status: string;
    Calendario: CalendarModel[];
    Allday: boolean;
}