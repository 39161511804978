import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainComponent } from 'src/app/components/main/main.component';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../config/config.service';
import { FlotaService } from '../flota/flota.service';
import { SubflotaModel } from '../flota/models/subflota.model';
import { PosicionModel } from '../positions/models/posicion.model';
import { SsoService } from '../sso/sso.service';
import { MovilesInterface } from './interfaces/moviles.interface';
import { MovilModel } from './models/movil.model';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  private ready = false;
  private loadTimer = null;
  public moviles = new Map<number, MovilModel>();
  private subflotas = new Map<number, SubflotaModel>();

  // Disparadores para notificar eventos
  @Output() newMovilEmiter: EventEmitter<MovilModel> = new EventEmitter();
  @Output() changeMovilEmiter: EventEmitter<MovilModel> = new EventEmitter();
  @Output() changeFilterEmiter: EventEmitter<void> = new EventEmitter();



  constructor(
    private http: HttpClient,
    private ssoService: SsoService,
    private flotasService: FlotaService,
    private configService: ConfigService) {
  }

  // Recupera todos los móviles de la empresa actual
  private async loadMoviles(): Promise<void> {
    try {
      if (this.loadTimer !== null) {
        clearTimeout(this.loadTimer);
      }
      this.loadTimer = null;
      // Recupero todas las subflotas de la empresa y creo una tabla hash con la subflota de cada móvil
      const subflotas = await this.flotasService.getSubflotas();
      this.subflotas.clear();
      if (subflotas) {
        subflotas.forEach(subflota => {
          if (subflota.MovilesId) {
            subflota.MovilesId.forEach(movilId => {
              this.subflotas.set(movilId, subflota);
            });
          }
        });
      }
      // Creo la subflota resto para los móviles que no están asignados a ninguna subflota
      const subflotaResto = new SubflotaModel();
      subflotaResto.Id = 0;
      subflotaResto.Nombre = 'Resto flota';
      subflotaResto.MovilesId = [];
      // Recupero todos los móviles de la empresa
      const result = await this.http.get<MovilModel[]>(this.ssoService.getTicket().UrlApi + '/api/moviles?empresaId=' +
        this.ssoService.getTicket().Empresa.IdGestion).toPromise();
      if (result !== null) {
        // Meto todos los móviles en una tabla hash
        result.forEach(movil => {
          // Me quedo con la primera clase para mostrar
          movil.Clase = (movil.ConjuntoVehiculo && movil.ConjuntoVehiculo.Clases) ?
            movil.ConjuntoVehiculo.Clases[0] : null;
          const oldMovil = { ...this.moviles.get(movil.Codigo) };
          // Antes de guardar los datos del móvil recupero los datos que
          // se han rellenado cuando se obtiene la última posición
          if (oldMovil !== undefined) {
            movil.marker = oldMovil.marker;
            movil.ultimaPos = oldMovil.ultimaPos;
            movil.selec = oldMovil.selec;
            movil.Conectado = oldMovil.Conectado ? oldMovil.Conectado : 0;
            movil.Ignicion = oldMovil.Ignicion ? oldMovil.Ignicion : 0;
          }
          movil.Subflota = this.subflotas.get(movil.Codigo);
          if (!movil.Subflota) {
            movil.Subflota = subflotaResto;
          }
          this.moviles.set(movil.Codigo, movil);
          // Compruebo si es nuevo o si se ha modificado la información
          if (oldMovil === undefined) {
            this.newMovilEmiter.emit(movil);
          } else {
            if (!MovilModel.compare(movil, oldMovil)) {
              this.changeMovilEmiter.emit(movil);
            }
          }
        });
        this.ready = true;
      }
    } catch {
    } finally {
      // Recupero los móviles cada x tiempo
      this.loadTimer = setTimeout(() => {
        this.loadMoviles();
      }, environment.refresMovilInterval);
    }
  }

  // Devuelve los móviles almacenados en la tabla, si todavía no se han 
  // recuperado todos lo hago en este momento
  public async getMoviles(all: boolean = false): Promise<MovilModel[]> {
    const result: MovilModel[] = [];
    // Recupero los filtros guardados
    let filterIA: any = null;
    if (MainComponent.getInstance().isEcoEvolution) {
      filterIA = await this.configService.getEmp('moviles-IA', null);
      if (filterIA) {
        filterIA = JSON.parse(filterIA);
      }
    }
    let filterVisible: any = await this.configService.getUsuEmpApp('moviles-visibles', null);
    if (filterVisible) {
      filterVisible = JSON.parse(filterVisible);
    } else {
      filterVisible = null;
    }
    if (!this.ready) {
      await this.loadMoviles();
    }
    // Genero el array a partir de la tabla hash
    for (const [key, value] of this.moviles) {
      // El filtro de móviles IA prevalece sobre el de visualización
      if (all || !filterIA || filterIA.find(s => s.id === value.Codigo) !== undefined) {
        if (all || !filterVisible || filterVisible.find(s => s.id === value.Codigo) !== undefined) {
          result.push(value);
        }
      }
    }
    return result;
  }

  // Devuelve la información de un móvil
  public getMovil(id: number): MovilModel {
    const movil = this.moviles.get(id);
    return movil !== undefined ? movil : null;
  }

  // Actualiza la última posición de un móvil
  public setMovilPos(id: number, pos: PosicionModel) {
    const movil = this.moviles.get(id);
    movil.ultimaPos = pos;
    this.moviles.set(id, movil);
  }

  // Actualiza la conexión y la ignición de un móvil
  public setMovilConexion(id: number, conex: boolean, igni: boolean) {
    const oldMovil = { ...this.moviles.get(id) };
    const movil = this.moviles.get(id);
    movil.Conectado = conex ? 1 : 0;
    movil.Ignicion = igni ? 1 : 0;
    this.moviles.set(id, movil);
    // Compruebo si se ha modificado la información
    if (oldMovil && !MovilModel.compare(movil, oldMovil)) {
      this.changeMovilEmiter.emit(movil);
    }
  }

  // Permite subscribirse para recibir los móviles que se incorporan a las lista inicial
  public subscribeNewMoviles(subscriber: MovilesInterface): Subscription {
    return this.newMovilEmiter.subscribe(movil => {
      subscriber.onNewMovil(movil);
    });
  }

  // Permite subscribirse para recibir los móviles que cambian de contenido
  public subscribeChangeMoviles(subscriber: MovilesInterface): Subscription {
    return this.changeMovilEmiter.subscribe(movil => {
      subscriber.onChangeMovil(movil);
    });
  }

  // Aplico el filtro de móviles visibles en el mapa
  public setFilterVisible() {
    this.changeFilterEmiter.emit();
  }

}
