<jqxWindow #form [width]="165" [height]="80" [zIndex]="100" [autoOpen]="true" [isModal]="false" [showCloseButton]="true"
    [showCollapseButton]="false" [resizable]="false" (onClose)="onClose()" (onOpen)="onOpen()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Moviles_cercanos')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float:left;">
            <div style="float: left; clear: both;">{{translate('Radio')}} </div>
            <div style="float: left; clear: both;">
                <jqxNumberInput #ebRadio style="float: left;" [(ngModel)]="radio" [width]="100" [height]="22"
                    [spinButtons]="true" [min]="0" [max]=1000000 [inputMode]="'simple'" [decimalDigits]="1"
                    (onTextchanged)="onChangeRadius($event)">
                </jqxNumberInput>
                <jqxDropDownList #cbMetros style="float:left; margin-left: 2px;" [width]="50" [height]="23"
                    [selectedIndex]="0" [autoDropDownHeight]="true" (onSelect)="onSelectUnidades($event)">
                </jqxDropDownList>
            </div>
        </div>
    </div>
</jqxWindow>