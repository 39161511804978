import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

import { CasaComponent } from './casa/casa.component';
import { MarcoComponent } from './marco/marco.component';
import { AmbitoComponent } from './ambito/ambito.component';
import { MeasureComponent } from './measure/measure.component';
import { ItinerariComponent } from './itinerari/itinerari.component';
import { CartoSelectComponent } from './carto-select/carto-select.component';

@NgModule({
  declarations: [
    CasaComponent,
    MarcoComponent,
    AmbitoComponent,
    MeasureComponent,
    ItinerariComponent,
    CartoSelectComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    jqxGridModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxDropDownListModule
  ]
})
export class MapsToolsModule { }
