import {
  Input,
  Output,
  OnInit,
  ViewChild,
  Component,
  HostListener,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';
import { jqxNumberInputComponent } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { PeriodoSelectComponent } from '../periodo-select/periodo-select.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.code === "Enter"){
      this.onFilter();
    }
  }

  // Elementos html
  @ViewChild('searchInput') searchInput: HTMLInputElement;
  @ViewChild('checkAlarms') checkAlarms: jqxCheckBoxComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;
  @ViewChild('currencyInput') currencyInput: jqxNumberInputComponent;
  @ViewChild('checkMasXmetros') checkMasXmetros: jqxCheckBoxComponent;
  @ViewChild('cbVolumetricos') cbVolumetricos: jqxDropDownListComponent;
  @ViewChild('selectSubflotas') selectSubflotas: jqxDropDownListComponent;

  // Periodo select
  @Input() height: number = 25;
  @Input() noDia: boolean = false;
  @Input() noMes: boolean = false;
  @Input() noAnno: boolean = false;
  @Input() noLibre: boolean = false;
  @Input() noSemana: boolean = false;
  @Input() noSemestre: boolean = false;
  @Input() noTrimestre: boolean = false;

  // Mostrar/ocultar componentes
  @Input() filtro: boolean = false;
  @Input() periodo: boolean = false;
  @Input() buscador: boolean = false;
  @Input() exportar: boolean = false;
  @Input() imprimir: boolean = false;
  @Input() subFlota: boolean = false;
  @Input() checkMetros: boolean = false;
  @Input() resetFiltro: boolean = false;
  @Input() volumetricos: boolean = false;
  @Input() seeCheckAlarms:boolean = false;
  @Input() listadoEstados: boolean = false;
  @Input() seeCheckTarjVirtual:boolean = false;

  // Lanza la accion cuando se interactua con un componente
  @Output() eventFilter: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventExportar: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventImprimir: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventBuscador: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventCheckEstados: EventEmitter<any> = new EventEmitter;
  @Output() eventSelectSubFlota: EventEmitter<any> = new EventEmitter;
  @Output() eventResetFilter: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventCheckAlarms: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventChangedNumber: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventCheckMasMetros: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventChangeTipoTarjeta: EventEmitter<string> = new EventEmitter;

  public environment = environment;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() { }

  ngOnInit(): void {
  }

  // Detecta cambios en variables de entrada
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      this[propName] = changes[propName].currentValue;
    }
  }

  /* METODOS PARA TODAS LAS VENTANAS */
  onExportar() {
    this.eventExportar.emit(true);
  }

  onPrint() {
    this.eventImprimir.emit(true);
  }

  onBuscar() {
    this.eventBuscador.emit(true);
  }

  onFilter() {
    this.eventFilter.emit(true);
  }

  onResetFilter() {
    this.eventResetFilter.emit(true);
  }
  /* FIN METODOS PARA TODAS LAS VENTANAS */

  /* METODOS PARA VENTANAS CONCRETAS */
  changeMasXmetros(event: any) {
    if (event.args.checked) {
      this.currencyInput.disabled(true);
      this.currencyInput.disabled(false);
    } else {
      this.currencyInput.disabled(true);
    }

    this.eventCheckMasMetros.emit(true);
  }

  onChangeCheckAlarms(event: any) {
    if(event.args.checked){
      this.eventCheckAlarms.emit(true);
    }else {
      this.eventCheckAlarms.emit(false);
    }
  }

  onCheckEstados(event: any) {
    this.eventCheckEstados.emit(event);
  }

  onChangeSubflotas(event: any){
    this.eventSelectSubFlota.emit(event);
  }

  /* LISTADO TIPO TARJETAS */
  @ViewChild('dropTipoTarjeta') dropTipoTarjeta: jqxDropDownListComponent;
  public sourceTipoTarjetas: string[] =
  [
    AppComponent.translate('Todas'),
    AppComponent.translate('Virtuales'),
    AppComponent.translate('Fisicas')
  ];

  changeTipoTarjeta(event: any) {
    this.eventChangeTipoTarjeta.emit(event.args.item.value);
  }

  onLoadTipoTarjeta() {
    setTimeout(() => {
      this.selectDefaultTipoTarjeta();
    }, 50);
  }

  selectDefaultTipoTarjeta() {
    if (this.dropTipoTarjeta) {
      let items = this.dropTipoTarjeta.getItems();
      if (items) {
        items.forEach((item, i) => {
          if (item['originalItem'] == AppComponent.translate('Todas')) {
            this.dropTipoTarjeta.selectItem(item['originalItem']);
          }
        })
      }
    }
  }

  /* LISTADO ESTADOS */
  @ViewChild('listEstados') listEstados: jqxDropDownListComponent;
  @Input() adapterEstados: any;

  //una vez cargado del dropdown se hace check en los estados por defecto
  onLoadList() {
    setTimeout(() => {
      this.selectItems();
    }, 50);
  }

  //selecciona estados por defecto
  selectItems() {
    if (this.listEstados) {
      let items = this.listEstados.getItems();
      if (items) {
        items.forEach((item, i) => {
          if (item['originalItem'].Nombre == 'En proceso' || item['originalItem'].Nombre == 'Abierta') {
            this.listEstados.checkIndex(i)
          }
        })
      }
    }
  }
  /* FIN METODOS PARA VENTANAS CONCRETAS */
}
