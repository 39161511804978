<div #movilesContainer id="gridMoviles" style="overflow: hidden; height: 100%; overflow-x: hidden;">
    <jqxGrid #gridMovil [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [source]="dataAdapter"
        [columnsresize]="true" [sortable]="true" [selectionmode]="'checkbox'" [showrowlines]="false"
        [showcolumnlines]="true" [showtoolbar]="true" [rendertoolbar]="createToolBar" [columns]="columns"
        [altrows]="true" [sortmode]="'many'" [enabletooltips]="true" [toolbarheight]="30" [filterable]="true"
        [filtermode]="'excel'" [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20"
        [autoshowfiltericon]="true" [filterrowheight]="20" [rowsheight]="25" [columnsheight]="20"
        [localization]="langGrid" (onRowclick)="onRowClick($event)" (onBindingcomplete)="onBindingComplete()"
        (onFilter)="onChangeFilter($event)" (onSort)="onSort($event)">
    </jqxGrid>
</div>
