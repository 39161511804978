<div style="width: 100%; height: 100%;">
  <div style="display: flex; align-content: center; flex-wrap: wrap;">
    <app-header #header style="width: 100%;" [subFlota]="true" (eventSelectSubFlota)="onChangeSubflotas($event)">
    </app-header>
  </div>
  <div style="height: calc(100% - 40px)">
    <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
      [columnsresize]="true" [filterable]="true" [showfilterrow]="false" [filtermode]="'excel'"
      [selectionmode]="'checkbox'" [columns]="columns" [sortable]="true" [altrows]="true" [showrowlines]="false"
      [filterrowheight]="30" [rowsheight]="25" [columnsheight]="20" [enabletooltips]="true" [showaggregates]="true"
      [showstatusbar]="true" [statusbarheight]="20" [toolbarheight]="32" [localization]="langGrid"
      (onRowselect)="onSelect()" (onRowunselect)="onSelect()">
    </jqxGrid>
  </div>
</div>
