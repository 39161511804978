<div class="loader-container" *ngIf="!columns.length">
  <div class="loader"></div>
</div>
<jqxWindow #windowIncidences [theme]="environment.tema" [autoOpen]="true" [showCloseButton]="true"
  [showCollapseButton]="true" [resizable]="true" [position]="getFormPos(windowIncidences, 0)" [zIndex]="999"
  [width]="mapWidth" [height]="mapHeight" (onClose)="onClose()">
  <div class="formHeader">
    {{translate('Incidencias')}}
  </div>
  <div #gridIncidencesContainer class="form">
    <app-header #header [exportar]="true" [filtro]="true" [resetFiltro]="true" [listadoEstados]="true" [periodo]="true"
      [buscador]="true"  [noLibre]="false"
      [adapterEstados]="adapterEstados" (eventExportar)="onExportar()" (eventBuscador)="initSearchControl()"
      (eventCheckEstados)="onCheckChange($event)" (eventFilter)="eventFilter()" (eventResetFilter)="onResetFilter()"
      (eventBuscador)="initSearchControl()">
    </app-header>

    <div class="grid-incidences-container" style="width: 100%; height: calc(100% - 85px);">
      <jqxGrid #incidencesGrid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [columnsresize]="true"
        [source]="dataAdapter" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns"
        [altrows]="true" [sortmode]="'many'" [enabletooltips]="true" [filterable]="false" [showstatusbar]="true"
        [statusbarheight]="20" (onBindingcomplete)="onBindingComplete()" [showaggregates]="true" [rowsheight]="30"
        [columnsheight]="20" [localization]="langGrid" [groupable]="true" [autoloadstate]="true"
        (onRowselect)="onRowSelect($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
      </jqxGrid>
      <jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
      </jqxLoader>
    </div>
    <div class="buttons-container">
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' (onClick)="onClickVer()"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]='"/assets/images/ver.png"'
        [value]="translate('Ver')">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
        [textImageRelation]="'imageBeforeText'" (onClick)="onClickCrear()" [textPosition]="'left'"
        [imgSrc]='"/assets/images/mas.png"' [value]="translate('Crear')">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' (onClick)="onClickEditar()"
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
        [value]="translate('Editar')">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='150' [height]='26'
        [textImageRelation]="'imageBeforeText'" (onClick)="onClickCambiar()" [textPosition]="'left'"
        [imgSrc]='"/assets/images/change.png"' [value]="translate('Cambiar_estado')">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' (onClick)="onClickBorrar()"
        [hidden]="true" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        [imgSrc]='"/assets/images/borrar.png"' [value]="translate('Borrar')">
      </jqxButton>
      <div class="url-container">
        <a class="url-text" (click)="onClickHref()">{{translate('Historico_estados')}}</a>
      </div>
    </div>
  </div>
</jqxWindow>
<app-modal></app-modal>