import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';

import { IncidencesService } from 'src/app/services/incidences/incidences.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @ViewChild('modal', { read: ElementRef })
  modal: ElementRef<HTMLElement>;
  @ViewChild('imageCanvas', { read: ElementRef })
  canvas: ElementRef<HTMLCanvasElement>;
  subscriptionImage: any;
  @Input() set open(value: boolean) {
    this.openModal(value);
  }
  @Output()
  cerrar = new EventEmitter<boolean>();
  angle = 0;
  private componentRef = null;
  img: HTMLImageElement;
  constructor(private incidencesService : IncidencesService) { }
  modalImg: HTMLImageElement;
  context;
  src = '';
  ngOnInit(): void {
    this.subscriptionImage = this.incidencesService.selectedImg.subscribe(img => {
      this.src = img['src'];
      this.img.src = 'data:image/jpeg;base64,' + this.src;
      this.img.classList.add('modal-img');
      this.canvas.nativeElement.width = this.img.width;
      this.canvas.nativeElement.height = this.img.height;
      this.context = this.canvas.nativeElement.getContext('2d');
      this.context.drawImage(this.img, 0, 0, this.img.width, this.img.height);
      this.openModal(true)
    });

  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, src) {
    this.componentRef = componentRef;
    this.src = src;
  }

  ngAfterViewInit() {
    this.img = new Image();

  }


  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  closeModal() {
    this.angle =0;
    this.openModal(false);
    this.saveImage();
    if (this.componentRef) {
      this.componentRef.destroy();
    }

  }

  ngOnDestroy(){
    if(this.subscriptionImage) this.subscriptionImage.unsubscribe();
  }

  openModal(value: boolean) {
    if (value) this.modal.nativeElement.setAttribute('style', 'display:flex');
    else this.modal.nativeElement.setAttribute('style', 'display:none');

  }

  //si el id es 0, rota a la derecha
  onRotate(id) {
    if (id == 0) {
      this.angle = (this.angle + 90) % 360;
    } else {

      if (this.angle == 0) {
        this.angle = 270;

      } else {
        this.angle = (this.angle - 90) % 360;
      }

    }
    this.drawRotated(this.angle)

  }

  saveImage() {
    let a = document.createElement('a');
    a.href = this.canvas.nativeElement.toDataURL("image/png");
    this.incidencesService.onOutputImage(a.href)
  }

  drawRotated(degrees) {

    this.context = this.canvas.nativeElement.getContext("2d");
    //this.canvas.nativeElement.style.width = "20%";

    if (degrees == 90 || degrees == 270) {
      this.canvas.nativeElement.width = this.img.height;
      this.canvas.nativeElement.height = this.img.width;
    } else {
      this.canvas.nativeElement.width = this.img.width;
      this.canvas.nativeElement.height = this.img.height;
    }

    this.context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    if (degrees == 90 || degrees == 270) {
      this.context.translate(this.img.height / 2, this.img.width / 2);
    } else {
      this.context.translate(this.img.width / 2, this.img.height / 2);
    }
    this.context.rotate(degrees * Math.PI / 180);
    this.context.drawImage(this.img, -this.img.width / 2, -this.img.height / 2);
  }


}
