import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxSliderModule } from 'jqwidgets-ng/jqxslider';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { SensorModule } from '../sensor/sensor.module';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TracksComponent } from './tracks.component';
import { LeyendaMovilesComponent } from './leyenda-moviles/leyenda-moviles.component';


@NgModule({
  declarations: [
    TracksComponent,
    LeyendaMovilesComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    NzIconModule,
    SensorModule,
    NzAlertModule,
    jqxWindowModule,
    jqxSliderModule,
    jqxLoaderModule,
    PeriodoSelectModule,
    BrowserAnimationsModule,
  ]
})
export class TracksModule { }
