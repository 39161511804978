import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { SsoService } from 'src/app/services/sso/sso.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { ProvCartoModel } from 'src/app/services/sso/models/prov-carto.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { ProvCartoEmpresaModel } from 'src/app/services/sso/models/prov-carto-empresa.model';

@Component({
  selector: 'app-carto-select',
  templateUrl: './carto-select.component.html',
  styleUrls: ['./carto-select.component.css']
})
export class CartoSelectComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('cbCarto') cbCarto: jqxDropDownListComponent;

  public static _this: CartoSelectComponent;

  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  private componentRef: any = null;
  public environment = environment;
  private proveedores: ProvCartoModel[] = [];
  private cartoId = 0;
  private cartoName: string;
  public canEdit = true;
  public raster = true;

  constructor(private ssoService: SsoService, private auditoriaService: AuditoriaService) {
    super();
    CartoSelectComponent._this = this;
  }

  ngOnInit(): void {
  }

  // Inicialización del componente
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.form);
    // cambio el título de la ventana
    this.form.setTitle(AppComponent.translate('Proveedor_carto'));
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.form.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
    this.raster = MainComponent.getSsoTicket().Empresa.Raster;
    this.loader.open();
    this.proveedores = await this.ssoService.getProvCarto();
    this.cbCarto.clear();
    if (this.proveedores) {
      this.proveedores.forEach((prov, index) => {
        this.cbCarto.addItem({ label: prov.Nombre, value: prov.Id });
        if (MainComponent.getSsoTicket().Empresa.Cartografia === prov.Nombre) {
          this.cbCarto.selectIndex(index);
        }
      });
    }
    this.loader.close();
  }

  //cuando se cierra la ventana
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    CartoSelectComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  async onChangeCarto(event: any) {
    this.cartoId = event.args.item.value;
    this.cartoName = event.args.item.label;
  }

  onChangeRaster(event: any) {
    this.raster = event.target.checked;
  }

  async onGuardar() {
    const ticket = this.ssoService.getTicket();
    if ((ticket.Empresa.Cartografia !== this.cartoName || ticket.Empresa.Raster !== this.raster) && this.cartoId > 0) {
      if (await this.ssoService.saveProvCarto(new ProvCartoEmpresaModel(ticket.Empresa.Id, this.cartoId, this.raster))) {
        ticket.Empresa.Cartografia = this.cartoName;
        ticket.Empresa.Raster = this.raster;
        sessionStorage.setItem('ticketSSO', JSON.stringify(ticket));
        localStorage.setItem('ticketSSO', JSON.stringify(ticket));
        window.location.reload();
        this.auditoria.AccionId = Accion.CONFIGURACION_PROVEEDOR_DE_CARTOGRAFIA;
        this.auditoriaService.addAuditoria(this.auditoria);

      }
    }
    this.form.close();
  }

}
