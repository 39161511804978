<jqxWindow #form [width]="700" [height]="400" [zIndex]="999" [theme]="theme"
  [isModal]="false" [position]="getFormPos(form)" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden">
      {{ translate("Ficha_ciudadano") }}
    </div>

    <div class="form" style="float:left; width: 100%; height: 100%;">
      <jqxGrid #gridTarjetas [theme]="theme" [width]="'100%'" [height]="'calc(100% - 40px)'" [source]="dataAdapterTarjetas"
        [columnsresize]="true" [filterable]="true" [showfilterrow]="true" [columns]="columnsTarjetas" [sortable]="true"
        [altrows]="true" [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25" [columnsheight]="25"
        [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20" [localization]="langGrid">
      </jqxGrid>

      <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('Salir')">
      </jqxButton>
    </div>
</jqxWindow>
