import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { CalendarModel } from 'src/app/services/calendar/models/calendar.model';
import { ListCalendarLock } from 'src/app/services/calendar/listCalendarLock.model';
import { CerraduraModel } from 'src/app/services/cerraduras/models/cerradura.model';
import { AssociateCerraduraModel } from 'src/app/services/cerraduras/models/associateCerradura.model';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-associate-lock',
  templateUrl: './associate-lock.component.html',
  styleUrls: ['./associate-lock.component.css']
})
export class AssociateLockComponent extends CustomForms implements OnInit {
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('myGridLock') myGridLock: jqxGridComponent;
  @ViewChild('myGridLockFree') myGridLockFree: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;

  public static _this: AssociateLockComponent;

  private componentRef = null;
  public theme = environment.tema;
  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  // variables grid
  dataAdapter: any;
  dataAdapterLock: any;
  dataSource: any;
  dataSourceLock: any;
  dataSourceLockFree: any;
  dataAdapterLockFree: any;
  dataDrag: any;
  indexRow: number[] = [];
  checkSelect: CerraduraModel[] = [];
  elementos: ElementoModel[] = [];
  public calendarios: CalendarModel[] = [];
  public calendario: CalendarModel = new CalendarModel();
  public cerraduras: CerraduraModel[] = [];
  public cerradura: CerraduraModel = new CerraduraModel();
  public cerradurasFree: CerraduraModel[] = [];
  listAssociate: ListCalendarLock = new ListCalendarLock();
  empresaId: number;
  isAssociating: boolean = false;
  rows: any[] = [];
  row: number;
  mapWidth: number;
  mapHeight: number;
  showLoader = true;



  // columnas de grids
  public columnCalendar: any = [
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 'auto',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Desde'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'desde', cellsrenderer: this.renderInit, },
    { text: AppComponent.translate('Hasta'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'hasta', cellsrenderer: this.renderEnd },
    { text: AppComponent.translate('Todos_dias'), columntype: 'checkbox', filtertype: 'checkedlist', filteritems: ['Si', 'No'], datafield: 'todosLosDias' },
    { text: AppComponent.translate('Dias_semana'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'diasSemana', cellsrenderer: this.renderDiasSemana },
    { text: 'null', datafield: 'horainicio', columntype: 'datetimeinput', width: 20, hidden: true },
    { text: 'null', datafield: 'horafin', columntype: 'datetimeinput', width: 20, hidden: true },
  ];

  public columnLock: any = [{
    text: AppComponent.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nsMovisat', width: 140,
    aggregates: [{
      'Total': function (aggregatedValue, currentValue: number) {
        return aggregatedValue + 1;
      }
    }], //agrego el total al grid
    aggregatesrenderer: function (aggregates) {
      let renderstring = '';
      if (aggregates["Total"] !== undefined) {
        renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total') + ': ' +
          aggregates["Total"] + '</div>';
      }
      return renderstring;
    }
  },
  { text: AppComponent.translate('Ns_fabricante'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nsFabricante', width: 160, },
  { text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'elemento', width: 150 },
  { text: AppComponent.translate('Imei'), columntype: 'textbox', filtertype: 'textbox', datafield: 'imei', width: 130 },
  ];

  constructor(private calendarService: CalendarService,
    private cerraduraService: CerraduraService,
    private ssoService: SsoService,
  ) {
    super();
    AssociateLockComponent._this = this;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    setTimeout(() => {
      this.getCalendar();
      this.getCerraraduras();
      this.getCerraradurasFree();
    }, 1000);
  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.resizeColumns(this.myGrid);
      this.resizeColumns(this.myGridLock);
      this.resizeColumns(this.myGridLockFree);
    }, 100);
    this.form.setTitle(AppComponent.translate('Asociacion_ecolock_calendario'));
    this.addCustomForm(this.form);
    this.empresaId = this.ssoService.getTicket().Empresa.IdGestion;

  }

  /**Inicializa el componente
  */
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async onRowClick(event: any) {
    // busco el calendario en el grid
    this.row = event.args.rowindex;
    let calendar: any = this.myGrid.getrowdatabyid(event.args.rowindex);
    this.calendarService.getCalendars().then(
      async (result: CalendarModel[]) => {
        this.calendarios = result;
      }
    );
    this.calendarios = await this.calendarService.getCalendars();
    this.calendario = this.calendarios.find(c => c.id == calendar?.id);
    this.updateGrids(this.calendario);

  }

  // renderers
  renderInit(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      let date = new Date(value);
      return '<div style="margin-left: 3px;">' + DateUtils.formatDate(date, true) + ' ' + DateUtils.formatTime(rowdata.horainicio) + '</div>';
    }
  }

  renderEnd(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      let date = new Date(value);
      return '<div style="margin-left: 3px;">' + DateUtils.formatDate(date, true) + ' ' + DateUtils.formatTime(rowdata.horafin) + '</div>';
    }
  }

  renderDiasSemana(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    // devolver los dias de la semana del calendario
    if (value) {
      return '<div style="margin-left: 3px;">' + value + '</div>';
    }
  }

  renderElemento(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      this.elementos = MainComponent.getInstance().elementsList;
      this.elementos = this.elementos.filter(elemento => elemento.cerradura.id !== 0);
      let elemento: ElementoModel = this.elementos.find(e => e.Id == value);
      if (elemento) {
        return '<div style="margin-left: 3px;">' + elemento.Nombre + '</div>';
      } else {
        return '<div style="margin-left: 3px;"></div>';
      }
    }
  }

  async getCalendar() {
    this.columnCalendar.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    this.columnLock.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    this.calendarios = await this.calendarService.getCalendars();
    if (this.calendarios) {
      this.dataSource = {
        dataType: 'json',
        dataFields: [
          { name: 'id', type: 'number', map: 'id' },
          { name: 'nombre', type: 'string', map: 'nombre' },
          { name: 'desde', type: 'date', map: 'fechaInicio' },
          { name: 'hasta', type: 'date', map: 'fechaFin' },
          { name: 'todosLosDias', type: 'checkbox', map: 'todosLosDias' },
          { name: 'diasSemana', type: 'string', map: 'diasSemana' },
          { name: 'horainicio', type: 'date', map: 'horaInicio1' },
          { name: 'horafin', type: 'date', map: 'horaFin1' },
        ],
        localdata: this.calendarios,
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);

      this.resizeColumns(this.myGrid);
    }
  }

  async getCerraraduras() {
    this.cerraduras = await this.cerraduraService.getCerraduras();
    this.dataSourceLock = {
      dataType: 'json',
      dataFields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'elemento', type: 'string', map: 'elemento>nombre' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'nsFabricante', type: 'string', map: 'nsFabricante' },
        { name: 'imei', type: 'string', map: 'imei' }
      ],
      localdata: '',
    }
    this.dataAdapterLock = new jqx.dataAdapter(this.dataSourceLock);

    this.resizeColumns(this.myGridLock);
  }

  async getCerraradurasFree() {
    this.cerradurasFree = await this.cerraduraService.getCerradurasSinCalendario();
    if (this.cerradurasFree) {
      this.dataSourceLockFree = {
        dataType: 'json',
        dataFields: [
          { name: 'id', type: 'number', map: 'id' },
          { name: 'elemento', type: 'number', map: 'elemento>nombre' },
          { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
          { name: 'nsFabricante', type: 'string', map: 'nsFabricante' },
          { name: 'imei', type: 'string', map: 'imei' }
        ],
        localdata: this.cerradurasFree,
      }
      this.dataAdapterLockFree = new jqx.dataAdapter(this.dataSourceLockFree);

      this.resizeColumns(this.myGridLockFree);
    }
    this.showLoader = await Utils.renderRowGridText();
  }

  // busca la cerradura en el grid
  searchCerradura(row: number, grid: any, idGrid: number): CerraduraModel {
    let cerradura: any = grid.getrowdatabyid(row);
    switch (idGrid) {
      case 1:
        return this.cerradurasFree.find(c => c.id == cerradura.id);
      case 2: return this.cerraduras.find(c => c.id == cerradura.id);
    }
  }

  // añade o elimina las cerraduras del grid
  async addGridData(data: any, gridInstance: any): Promise<void> {
    let cerraduraIds: number[] = [];
    this.calendarios = this.calendarios.filter(c => c.id == this.calendario.id);
    if (this.isAssociating) {
      return;
    }
    this.isAssociating = true;
    // si el grid es el de cerradurasFree
    if (gridInstance == this.myGridLockFree) {
      // añado los elementos de data al array de cerraduras
      this.cerraduras = [...this.cerraduras, ...data];
      cerraduraIds = data.map((cerradura: CerraduraModel) => cerradura.id);
      let associate: AssociateCerraduraModel = new AssociateCerraduraModel(cerraduraIds, [this.calendario.id], this.empresaId);
      await this.calendarService.associateLockList(associate);
      // elimino la cerradura del grid
      this.cerradurasFree = this.cerradurasFree.filter((c) => !cerraduraIds.includes(c.id));

    } else {
      // si el grid es el de cerraduras
      gridInstance = this.myGridLock;
      this.cerradurasFree = [...this.cerradurasFree, ...data];
      cerraduraIds = data.map((cerradura: CerraduraModel) => cerradura.id);
      let associate: AssociateCerraduraModel = new AssociateCerraduraModel(cerraduraIds, [this.calendario.id], this.empresaId);
      await this.calendarService.detachLockList(associate);
      // elimino la cerradura del grid
      this.cerraduras = this.cerraduras.filter((c) => !cerraduraIds.includes(c.id));
    }
    this.boundataGrids();
    // Si ya se ha asociado se sale de la función
    this.isAssociating = false;
  }

  // seleccionar cerraduras en el grid
  async onSelectV2(event: any, gridName: string) {
    let { grid, cerraduras } = this.asignedGrid(gridName);
    switch (event.args.rowindex.length) {
      case 0:
        this.checkSelect = [];
        break;
      default:
        let cerradurasSeleccionadas = cerraduras.filter(cerradura => cerradura.id == event.args.row.id)
        this.checkSelect = [...this.checkSelect, ...cerradurasSeleccionadas];
        this.checkSelect = this.onChangeArray(this.checkSelect);
        break;
    }
  }

  onUnSelect(event: any, gridName: string) {

    let { grid, cerraduras } = this.asignedGrid(gridName);
    switch (event.args.rowindex.length) {
      case 0:
        this.checkSelect = [];
        break;
      default:
        let cerradurasNoExcluir = this.checkSelect.filter(cerradura => cerradura.id !== event.args.row.id);
        this.checkSelect = cerradurasNoExcluir;
        break;
    }
  }

  // devuelve el grid y el array de cerraduras de ese grid
  asignedGrid(gridname: string) {
    let grid: any;
    let cerraduras: CerraduraModel[] = [];
    switch (gridname) {
      case 'grid1':
        grid = this.myGridLock;
        cerraduras = this.cerraduras;
        break;
      case 'grid2':
        grid = this.myGridLockFree;
        cerraduras = this.cerradurasFree;
        break;
    }
    return {
      grid: grid,
      cerraduras: cerraduras
    };
  }

  onMasClick(event: any) {
    if (this.checkSelect.length && this.row >= 0) {
      this.addGridData(this.checkSelect, this.myGridLockFree);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
  }

  onMenosClick(event: any) {
    if (this.checkSelect.length && this.row >= 0) {
      this.addGridData(this.checkSelect, this.myGridLock);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
  }

  // quita los elementos duplicados del array y lo devuelve sin undefined
  onChangeArray(list: CerraduraModel[]): CerraduraModel[] {
    list = list.filter((elem, index, self) =>
      index == self.findIndex((t) => t?.id == elem?.id) && elem !== undefined
    );
    this.checkSelect = list;
    return this.checkSelect;
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    AssociateLockComponent._this = null;
  }

  // Actualizar los grids de cerraduras
  async updateGrids(calendario: CalendarModel) {
    this.showLoader = true;
    this.calendario = calendario;
    let idCalendario: number = this.calendario.id;
    if (this.calendario) {
      // busco las cerraduras que estan asociadas al calendario
      let cerraduraCalendario: any[] = [];
      this.cerraduras = await this.cerraduraService.getCerraduras();
      if (this.cerraduras && Array.isArray(this.cerraduras)) {
        this.cerraduras.forEach(element => {
          if (element.calendarios && Array.isArray(element.calendarios)) {
            element.calendarios.forEach((element2, index) => {
              if (element2 == idCalendario) {
                cerraduraCalendario.push(element);
              }
            });
          }
        });
        this.showLoader = false;
      }
      // quito las cerraduras que son iguales a las que están asociadas al calendario
      this.cerradurasFree = this.cerraduras.filter(cerradura => {
        return cerradura.calendarios == null || !cerradura.calendarios.includes(idCalendario);
      });

      // quito las cerraduras que he desasociado
      this.cerraduras = this.cerraduras.filter(c => cerraduraCalendario.includes(c));
      this.cerraduras = cerraduraCalendario;
      this.boundataGrids();
    }
  }

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    if (dataField === "todosLosDias") {
      var filters = filterGroup.getfilters();
      for (var i = 0; i < filters.length; i++) {
        var filter = filters[i];
        if (filter.value === "Si") {
          return rowData.todosLosDias;
        }
        else if (filter.value === "No") {
          return !rowData.todosLosDias;
        }
      }
    }
  }

  // Actualizar los grids de cerraduras
  boundataGrids() {
    this.dataSourceLock.localdata = this.cerraduras;
    this.myGridLock.updatebounddata();
    this.myGridLock.clearselection();
    this.dataSourceLockFree.localdata = this.cerradurasFree;
    this.myGridLockFree.updatebounddata();
    this.myGridLockFree.clearselection();
    this.checkSelect = [];
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
