import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { TareaModel } from './models/tarea.model';
import { Accion } from '../auditoria/models/accion.model';

@Injectable({
  providedIn: 'root'
})
export class AdministradorService {
  private urlApi = this.ssoService.getTicket().UrlApi;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  constructor(
    private ssoService: SsoService,
    private httpClient: HttpClient,
    private auditoriaService: AuditoriaService
  ){}

  async getTareas(): Promise<TareaModel[]> {
    let response: TareaModel[] = [];

    try {
      await this.httpClient.get<TareaModel[]>(
        this.urlApi + '/api/jobs'
      ).toPromise().then(
        data => {
          response = data;
        }, (error) => {
          response = [];
        }
      );
    } catch (e) {
      response = [];
    }

    return response;
  }

  async updateTarea(tareaOriginal: TareaModel): Promise<TareaModel>{
    let response: TareaModel = null;

    const tarea = { ...tareaOriginal }

    delete tarea.grupo;
    delete tarea.nombre;
    delete tarea.nombreTrigger;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    try {
      await this.httpClient.post<TareaModel>(
        this.urlApi + '/api/jobs/update',
        JSON.stringify(tarea),
        httpOptions
      ).toPromise().then(
        data => {
          response = data;

          this.auditoria.AccionId = Accion.EDITAR_TAREA;
          this.msgChangeResponse(response);
          this.auditoriaService.addAuditoria(this.auditoria);
        }, error => {
          response = null;
        }
      );
    } catch (e){
      response = null;
    }

    return response;
  }


  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.id;
  }
}
