import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxEditorModule } from 'jqwidgets-ng/jqxeditor';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';

import { NotasComponent } from './notas.component';

@NgModule({
  declarations: [
    NotasComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxEditorModule
  ]
})
export class NotasModule { }
