import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { HttpClient } from '@angular/common/http';
import { ConjuntoVehiculo } from './models/conjunto-vehiculo.model';

@Injectable({
  providedIn: 'root'
})
export class ConjuntoVehiculoService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private ssoService: SsoService, private http: HttpClient) { }

  async getConjuntosVehiculo(): Promise<ConjuntoVehiculo[]>{

    let response: ConjuntoVehiculo[] = [];

    try {
      await this.http.get<ConjuntoVehiculo[]>(this.urlApi + `/api/conjuntos/vehiculos?enterprise=`
      + this.ssoService.getTicket().Empresa.IdGestion + `&include=all`)
      .toPromise().then(
        res => {
          response = res;
        }, error => {
          response = [];
        }
      );
    } catch (e) {
      response = [];
    }

    return response;
  }
}
