<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [theme]="theme" [position]="getFormPos(form)" [width]="elemService.elemGenericos ? 290 : 1000"
  [height]="340" [zIndex]="999" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Filtro_elementos") }}
  </div>
  <div class="form" style="float: left; overflow-x: hidden">
    <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'calc(100% - 50px)'" [source]="dataAdapter" [columnsresize]="true"
      [filterable]="true" [showfilterrow]="false" [filtermode]="'excel'" [selectionmode]="'checkbox'"
      [columns]="columns" [sortable]="true"  [showrowlines]="false" [filterrowheight]="30"
      [rowsheight]="25" [columnsheight]="25" [showaggregates]="true" [showstatusbar]="true" [showemptyrow]="false"
      [statusbarheight]="20" [localization]="langGrid" (onBindingcomplete)="onBindingComplete()"
      (onRowexpand)="onRowExpand($event)">
    </jqxGrid>
    <div style="margin-top: 10px;">
      <jqxButton style="float: left" [width]="100" [height]="26" [textImageRelation]="'imageBeforeText'"
        [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'" [value]="translate('Guardar')"
        (onClick)="onGuardar()">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
