import { Component, OnInit, ViewChild } from '@angular/core';

//Si se quita da error con el NodeJS
import { element } from 'protractor';

import { AppComponent } from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { GeoUtils } from 'src/app/utils/geo-utils';
import { MapSearch } from 'movisat-maps/lib/models/map.search';
import { MapBounds, MapCircle, MapComponent, MapLatLng } from 'movisat-maps';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxNumberInputComponent } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { SsoService } from 'src/app/services/sso/sso.service';

import { ElementsComponent } from '../elements.component';

@Component({
  selector: 'app-elements-cerca',
  templateUrl: './elements-cerca.component.html',
  styleUrls: ['./elements-cerca.component.css']
})
export class ElementsCercaComponent implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('ebRadio') ebRadio: jqxNumberInputComponent;
  @ViewChild('cbMetros') cbMetros: jqxDropDownListComponent;

  private map: MapComponent;
  private componentRef = null;
  public environment = environment;
  private subscriptionOnMapClick = null;
  private subscriptionOnCircleDragend = null;
  private subscriptionOnCircleRadiusChange = null;
  private subscriptionOnMapSearch = null;
  private circle: MapCircle = null;
  public radio = 1000;
  private radioMetros = 1000;
  private metros = true;
  private timerGrid;
  private initPosForm = false;

  constructor(private ssoService: SsoService) { }

  ngOnInit(): void {
    this.map = MainComponent.getInstance().getActiveMap();
  }

  async ngAfterViewInit(): Promise<void> {
    this.form.setTitle(AppComponent.translate('Elementos_cercanos'));
    this.subscribeMapEvents();
    this.cbMetros.addItem({ label: 'm.', value: 0 });
    this.cbMetros.addItem({ label: 'km', value: 1 });
    this.cbMetros.selectedIndex(0);
    // Muestro la columna "distancia" del grid de elementos
    ElementsComponent.getInstance().showColumnGrid('distancia', true);
    // Cambio el puntero del ratón sobre el mapa
    // this.map.setMousePointer('assets/images/center.png');
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.form.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    this.map.setMousePointer('');
    // Pongo a cero el campo "distancia" de todos los elementos
    ElementsComponent.getInstance().elementList.forEach(elem => {
      elem.distancia = undefined;
    });
    // Oculto la columna "distancia" del grid de elementos
    ElementsComponent.getInstance().showColumnGrid('distancia', false);
    // Vuelvo a dejar la ordenación por "conectado"
    ElementsComponent.getInstance().orderByGrid('marca', 'asc');
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
    if (this.subscriptionOnCircleDragend) {
      this.subscriptionOnCircleDragend.unsubscribe();
      this.subscriptionOnCircleDragend = null;
    }
    if (this.subscriptionOnCircleRadiusChange) {
      this.subscriptionOnCircleRadiusChange.unsubscribe();
      this.subscriptionOnCircleRadiusChange = null;
    }
    if (this.subscriptionOnMapSearch) {
      this.subscriptionOnMapSearch.unsubscribe();
      this.subscriptionOnMapSearch = null;
    }
    if (this.circle) {
      this.map.removeCircle(this.circle);
      this.circle = null;
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onOpen() {
    const t = setTimeout(() => {
      clearTimeout(t);
      this.ebRadio.focus();
    }, 500);
  }

  // Cada vez que cambia el radio de acción
  onChangeRadius(event: any) {
    if (this.circle) {
      this.circle.setRadius(Number.parseInt('' + (this.metros ? this.radio : this.radio * 1000)));
      this.onBuscar(this.circle.center);
    }
  }

  // Me subscrivo a eventos del mapa
  subscribeMapEvents() {
    this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, (_this: ElementsCercaComponent, position: MapLatLng) => {
      // Calculo la posición que corresponde con el centro del icono que he puesto como puntero del ratón
      const point = this.map.latLngToScreenPoint(position);
      // point.x += 16; // El icono es de 32x32
      // point.y += 16;
      const newPosition = this.map.screenPointToLatLng(point);
      if (!this.circle) {
        this.createCircle(newPosition);
        this.onBuscar(newPosition);
      } else {
        this.circle.setCenter(newPosition); // Esto genera el evento onCircleDragEnd
      }
    });
    // Cada vez que se cambia el radio del círculo
    this.subscriptionOnCircleRadiusChange = this.map.subscribeOnCircleRadiusChange(this, (_this: ElementsCercaComponent, circle: MapCircle) => {
      if (circle.id === this.circle.id) {
        this.radio = this.metros ? circle.radius : circle.radius / 1000;
        this.onBuscar(circle.center);
      }
    });
    // Cada vez que se arrastra el centro del círculo
    this.subscriptionOnCircleDragend = this.map.subscribeOnCircleDragEnd(this, (_this: ElementsCercaComponent, circle: MapCircle) => {
      if (circle.id === this.circle.id) {
        this.onBuscar(circle.center);
      }
    });
    // Cada vez que se realiza una búsqueda sobre el mapa
    this.subscriptionOnMapSearch = this.map.subscribeOnMapSearch(this, (_this: ElementsCercaComponent, search: MapSearch) => {
      if (this.circle) {
        this.circle.setCenter(search.position);
      } else {
        // Si todavía no se ha creado el círculo lo creo ahora
        this.createCircle(search.position);
      }
      this.onBuscar(search.position);
    });
  }

  // Crea el círculo
  createCircle(position: MapLatLng) {
    this.circle = this.map.addCircle({
      dataModel: position,
      content: '',
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 1,
      fillColor: '#0000ff',
      fillOpacity: 0.1,
      position: position,
      radius: Number.parseInt('' + this.radio),
      draggable: true,
      editable: true
    });
  }

  // Busca elementos dentro del rádio
  onBuscar(point: MapLatLng) {
    if (this.timerGrid) {
      clearTimeout(this.timerGrid);
    }
    this.timerGrid = setTimeout(() => {
      this.radioMetros = this.metros ? this.radio : this.radio * 1000;
      // Calculo los metros por grado
      const m = (1 / ((2 * Math.PI / 360) * 6378.137)) / 1000;
      // Calculo el cuadro que contiene el círculo
      const lat1 = this.circle.center.lat - (this.radioMetros * m);
      const lng1 = this.circle.center.lng - (this.radioMetros * m) / Math.cos(this.circle.center.lat * (Math.PI / 180));
      const lat2 = this.circle.center.lat + (this.radioMetros * m);
      const lng2 = this.circle.center.lng + (this.radioMetros * m) / Math.cos(this.circle.center.lat * (Math.PI / 180));
      const boundsCircle = new MapBounds(new MapLatLng(lat1, lng1), new MapLatLng(lat2, lng2));
      // Recorro los elementos para ver cuales están dentro del radio
      ElementsComponent.getInstance().elementList.forEach(elem => {
        if (boundsCircle.contains(new MapLatLng(elem.Lat, elem.Lng))) {
          const dist = GeoUtils.getDistance(elem.Lat, elem.Lng, point.lat, point.lng);
          if (dist <= this.radioMetros) {
            if (dist < 1000) {
              elem.distancia = NumberUtils.format(dist, 0) + ' m.';
            } else {
              elem.distancia = NumberUtils.format(dist / 1000, 1) + ' km.';
            }
            // if (dist < 1000) {
            //   elem.distancia = Math.round(dist) + ' m';
            // } else {
            //   elem.distancia = (dist / 1000.0).toFixed(1) + ' km';
            // }
            // for (let i = elem.distancia.length; i < 12; i++) {
            //   elem.distancia = ' ' + elem.distancia;
            // }
            // elem.distancia = elem.distancia.replace('.', JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo).decimalseparator);
          } else {
            elem.distancia = undefined;
          }
        } else {
          elem.distancia = undefined;
        }
      });
      // Encuadro el mapa según el rádio del círculo
      if (this.radioMetros > 0) {
        this.map.fitTo(boundsCircle);
        // Actualizo el grid de elementos para que se vean primero los que están más cerca
        ElementsComponent.getInstance().orderByGrid('distancia', 'asc');
      }
    }, 500);
  }

  // Cuando se cambia en el combo entre metros y kilómetros
  onSelectUnidades(event: any) {
    this.metros = event.args.item.value === 0;
    this.radio = this.metros ? this.radio * 1000 : this.radio / 1000;
  }

}
