import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxTabsModule } from 'jqwidgets-ng/jqxtabs';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxChartModule } from 'jqwidgets-ng/jqxchart';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxRibbonModule } from 'jqwidgets-ng/jqxribbon';
import { jqxButtonModule }   from 'jqwidgets-ng/jqxbuttons';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxTextAreaModule } from 'jqwidgets-ng/jqxtextarea';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxRadioButtonModule } from 'jqwidgets-ng/jqxradiobutton';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxDropDownButtonModule } from 'jqwidgets-ng/jqxdropdownbutton';

import { InputNumberModule } from '../input-number/input-number.module';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { LabelOverLenghtModule } from '../label-over-lenght/label-over-lenght.module';

import { MovilesComponent } from './moviles/moviles.component';
import { MovilEditComponent } from './moviles/movil-edit/movil-edit.component';
import { MovilesCatalogComponent } from './moviles-catalog/moviles-catalog.component';
import { HeaderModule } from '../header/header.module';

@NgModule({
  declarations: [
    MovilesComponent,
    MovilEditComponent,
    MovilesCatalogComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    HeaderModule,
    BrowserModule,
    jqxGridModule,
    jqxTabsModule,
    jqxChartModule,
    jqxInputModule,
    jqxRibbonModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxWindowModule,
    jqxTextAreaModule,
    jqxCheckBoxModule,
    InputNumberModule,
    PeriodoSelectModule,
    jqxRadioButtonModule,
    jqxNumberInputModule,
    LabelOverLenghtModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule,
    jqxDropDownButtonModule,
  ],
  exports: [
    MovilesComponent
  ]
})
export class ResourcesModule { }
