import { MapMarker } from "movisat-maps";
import { BdtEquipamientoModel } from "../../bdt/models/bdt-equipamiento.model";
import { ElementoModel } from "../../elements/models/elem.model";

export class PuModel {
  Guid: string = '';
  Id: number = 0;
  Empresa: number = 0;
  IdElemento = 0;
  IdEquipamiento: number = 0;
  Nombre: string = '';
  Lat: number = 0;
  Lng: number = 0;
  FechaCreacion: Date;
  FechaModificacion: Date;
  FechaBaja: Date;
  Calle: string;
  CodigoPostal: string;
  Provincia: string;
  ComunidadAutonoma: string;
  Municipio: string;
  Pais: string;
  NumeroCalle: string;

  // Agregados
  Marker: MapMarker;
  Equipamiento: BdtEquipamientoModel;
  Elemento: ElementoModel;
  selec: string; // Campo usado para filtrar búsquedas

  constructor() { }
}
