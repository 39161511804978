import { CatastroModel } from "./catastro.model";

export class DireccionModel {
  id: number = 0;
  idCiudadano: number;
  direccionPrincipal: boolean;

  barrio: string;
  direccion: string;
  codigoPostal: string;
  poblacion: string;
  municipio: string;
  provincia: string;
  comunidadAutonoma: string;
  distrito: string;

  numero: string;
  planta: string;
  puerta: string;

  referenciaCatastral: string;
  catastro: CatastroModel;

  numeroTarjetas: number = 0;

  fechaCreacion: Date;
  fechaModificacion: Date;
  fechaBaja: Date;
}
