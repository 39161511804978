import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { ConfigModel } from './models/config.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  @Output() newMovilEmiter: EventEmitter<void> = new EventEmitter();

  constructor(private http: HttpClient, private ssoService: SsoService) { }

  onSendConfig() {
    this.newMovilEmiter.emit();
  }

  getConfig() {
    return this.newMovilEmiter;
  }


  // Recupera una configuración en BD
  private async get(key: string, defValue: string): Promise<string> {
    let result = null;
    try {
      result = await this.http.get<ConfigModel[]>(this.ssoService.getTicket().UrlApi + '/api/config/get/' + key).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result && result.Valor ? result.Valor : defValue;
  }





  // Guarda una configuración de BD
  private async set(key: string, value: string): Promise<boolean> {
    let result = false;
    try {
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'responseType': 'json'
        })
      }
      const body = {
        Clave: key,
        Valor: value
      }
      result = await this.http.post<boolean>(this.ssoService.getTicket().UrlApi + '/api/config/set',
        JSON.stringify(body), httpOptions).toPromise();
    } catch {
    }
    return result;
  }

  // Devuelve una configuración por usuario+empresa+app
  async getUsuEmpApp(key: string, defValue: string): Promise<string> {
    const ticket = this.ssoService.getTicket();
    return await this.get(ticket.Usuario.Id + '-' + ticket.Empresa.Id + '-' + ticket.Aplicacion.Id + '-' + key, defValue);
  }

  // Almacena una configuración por usuario+empresa+app
  async setUsuEmpApp(key: string, value: string): Promise<boolean> {
    const ticket = this.ssoService.getTicket();
    return await this.set(ticket.Usuario.Id + '-' + ticket.Empresa.Id + '-' + ticket.Aplicacion.Id + '-' + key, value);
  }

  // Devuelve una configuración por empresa+app
  async getEmpApp(key: string, defValue: string): Promise<string> {
    const ticket = this.ssoService.getTicket();
    return await this.get(ticket.Empresa.Id + '-' + ticket.Aplicacion.Id + '-' + key, defValue);
  }

  // Almacena una configuración por empresa+app
  async setEmpApp(key: string, value: string): Promise<boolean> {
    const ticket = this.ssoService.getTicket();
    return await this.set(ticket.Empresa.Id + '-' + ticket.Aplicacion.Id + '-' + key, value);
  }

  // Devuelve una configuración por empresa
  async getEmp(key: string, defValue: string): Promise<string> {
    const ticket = this.ssoService.getTicket();
    return await this.get(ticket.Empresa.Id + '-' + key, defValue);
  }

  // Almacena una configuración por empresa
  async setEmp(key: string, value: string): Promise<boolean> {
    const ticket = this.ssoService.getTicket();
    return await this.set(ticket.Empresa.Id + '-' + key, value);
  }

}
