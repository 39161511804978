import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { SsoService } from '../sso/sso.service';
import { CalendarModel } from './models/calendar.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { Accion } from '../auditoria/models/accion.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { MainComponent } from 'src/app/components/main/main.component';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) {
  }

  // crear calendario
  async createCalendar(calendar: CalendarModel): Promise<CalendarModel> {
    let response = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      calendar.empresa = this.ssoService.getTicket().Empresa.IdGestion;
      response = await this.http.post<CalendarModel>(this.ssoService.getTicket().UrlApi + '/api/calendarios/crear/',
        JSON.stringify(calendar), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    if (calendar.id && response) {
      this.auditoria.AccionId = Accion.EDITAR_CALENDARIO;
    } else if (response && !calendar.id) {
      this.auditoria.AccionId = Accion.CREAR_CALENDARIO;
    }
    this.msgChangeResponse(response);
    this.auditoriaService.addAuditoria(this.auditoria);
    return response;
  }

  // obtener calendarios
  async getCalendars() {
    let result: CalendarModel[] = null;
    try {
      result = await this.http.get<CalendarModel[]>(this.ssoService.getTicket().UrlApi + '/api/calendarios/' +
        this.ssoService.getTicket().Empresa.IdGestion).toPromise();
    } catch (e) {
      console.log(e);
    }
    if (result) {
      // concateno los dias de la semana en un solo campo para mostrar en el grid
      result.forEach(element => {

        if (element.diasEspecialesConServicio) {
          element.diasEspecialesConServicio.forEach(elem => {
            elem.fecha = new Date(elem.fecha);
          });
        }

        if (element.diasEspecialesSinServicio) {
          element.diasEspecialesSinServicio.forEach(elem => {
            elem.fecha = new Date(elem.fecha);
          });
        }

        let dias = '';
        element.diasSemana = '';
        if (element.todosLosDias && element.excepto) {
          let textFormat = "";
          for (let i = 0; i < element.excepto.length; i++) {
            if (i === 0) {
              textFormat = element.excepto[i];
            } else {
              textFormat = textFormat + ' ' + element.excepto[i];
            }
          }

          return element.diasSemana = AppComponent.translate('Todos_los_dias') + ' ' + AppComponent.translate('Excepto').toLowerCase() + ' ' + '(' + textFormat + ')';
        } else if (element.todosLosDias && !element.excepto) {
          return element.diasSemana = AppComponent.translate('Todos_los_dias');
        } else if (!element.todosLosDias) {
          // si el calendario es todos los dias se concatena el nombre de los dias
          if (element.horaInicio1.toString().substring(11, 21) && element.horaFin1.toString().substring(11, 21) !== '00:00:00') {
            dias = dias + element.diasSemana + 'L';
          }
          if (element.horaInicio2.toString().substring(11, 21) && element.horaFin2.toString().substring(11, 21) !== '00:00:00') {
            dias = dias + element.diasSemana + ' M';
          }
          if (element.horaInicio3.toString().substring(11, 21) && element.horaFin3.toString().substring(11, 21) !== '00:00:00') {
            dias = dias + element.diasSemana + ' X';
          }
          if (element.horaInicio4.toString().substring(11, 21) && element.horaFin4.toString().substring(11, 21) !== '00:00:00') {
            dias = dias + element.diasSemana + ' J';
          }
          if (element.horaInicio5.toString().substring(11, 21) && element.horaFin5.toString().substring(11, 21) !== '00:00:00') {
            dias = dias + element.diasSemana + ' V';
          }
          if (element.horaInicio6.toString().substring(11, 21) && element.horaFin6.toString().substring(11, 21) !== '00:00:00') {
            dias = dias + element.diasSemana + ' S';
          }
          if (element.horaInicio7.toString().substring(11, 21) && element.horaFin7.toString().substring(11, 21) !== '00:00:00') {
            dias = dias + element.diasSemana + ' D';
          }
          element.diasSemana = dias;
        }
      });
    }

    return result;
  }
  // obtener calendario por id
  async getCalendarById(id: number): Promise<CalendarModel> {
    let result: CalendarModel = null;
    try {
      result = await this.http.get<CalendarModel>(this.ssoService.getTicket().UrlApi + '/api/calendarios/' + id).toPromise();

      if (result) {
        if (result.diasEspecialesConServicio) {
          result.diasEspecialesConServicio.forEach(elem => {
            elem.fecha = new Date(elem.fecha);
          });
        }

        if (result.diasEspecialesSinServicio) {
          result.diasEspecialesSinServicio.forEach(elem => {
            elem.fecha = new Date(elem.fecha);
          });
        }
      }

    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // borrar calendario
  async deleteCalendar(id: number): Promise<void> {
    try {
      await this.http.delete<void>(this.ssoService.getTicket().UrlApi + '/api/calendarios/borrar/' + id).toPromise();
      this.auditoria.AccionId = Accion.BORRAR_CALENDARIO;
      this.auditoria.Info = 'ID:' + id;
      this.auditoriaService.addAuditoria(this.auditoria)
    } catch (e) {
      console.log(e);
    }
  }

  async associateLockList(lista: any) {
    let response = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post<void>(this.ssoService.getTicket().UrlApi + '/api/calendario/asociar/cerradura/lista/',
        JSON.stringify(lista), httpOptions).toPromise();
      this.auditoria.AccionId = Accion.ASOCIAR_CERRADURA_CALENDARIO;
      this.auditoria.Info = 'Empresa Id:' + lista.empresaId;
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  async detachLockList(lista) {
    let response = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post<void>(this.ssoService.getTicket().UrlApi + '/api/calendario/desasociar/cerradura/lista',
        JSON.stringify(lista), httpOptions).toPromise();
      this.auditoria.AccionId = Accion.DESASOCIAR_CERRADURA_CALENDARIO;
      this.auditoria.Info = 'Empresa Id:' + lista.empresaId;
      this.auditoriaService.addAuditoria(this.auditoria);
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.Id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response.Nombre;
  }
}



