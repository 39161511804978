<jqxWindow #formEdit [theme]="theme" [position]="getFormPos(formEdit)" [zIndex]="2" [width]="960" [height]="325"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Edicion_calendarios')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="display: flex; align-items: center;gap: 5px ">
            <div style="width: 50%;">
                <div class="input" style="display: flex; align-items: center;">
                    <div class="row" style="width: calc(100% - 5px);">
                        <div style="margin-top: 5px; float:left; clear:both;width: 100%">
                            {{translate('Nombre')}}
                            <jqxInput [width]="'100%'" [height]="25" [(ngModel)]="calendario.nombre">
                            </jqxInput>
                        </div>
                        <div style="margin-top: 5px; float:left; clear:both;width: 100%">
                            {{translate('Observaciones')}}
                            <jqxInput [width]="'100%'" [height]="25" [(ngModel)]="calendario.observaciones">
                            </jqxInput>
                        </div>
                    </div>
                    <div class="row" style="display: flex; flex-direction: column; margin-left: 2px;">
                        <div style="float: left;  margin-right: 5px; width: 105px;">
                            {{translate('Desde')}}
                            <jqxDateTimeInput #fecha1
                                style="float:left; margin-left: 2px; overflow-x: hidden; margin-bottom: 5px"
                                [width]="108" [height]="22" [culture]="localization" [formatString]="'dd-MM-yyyy'"
                                [showTimeButton]="false" (onValueChanged)="onChangeFechaDesde($event)"
                                [dropDownVerticalAlignment]="'bottom'">
                            </jqxDateTimeInput>
                        </div>
                        <div style="float: right; margin-right: 5px; width: 105px;">
                            {{translate('Hasta')}}
                            <jqxDateTimeInput #fecha2 style="float:left; margin-left: 2px; overflow-x: hidden;"
                                [width]="108" [height]="22" [culture]="localization" [formatString]="'dd-MM-yyyy'"
                                [showTimeButton]=" false" (onValueChanged)="onChangeFechaHasta($event)"
                                [dropDownVerticalAlignment]="'bottom'">
                            </jqxDateTimeInput>
                        </div>
                    </div>
                    <div class="row" style="display: flex; flex-direction: column; margin-left: 2px;">
                        <div style="float: right; margin-right: 5px;">
                            {{translate('Hora_inicio')}}
                            <jqxDateTimeInput #horaInicio [width]="95" [height]="22"
                                [dropDownVerticalAlignment]="'bottom'" [formatString]="'HH:mm:ss'"
                                [showTimeButton]="true" [showCalendarButton]="false"
                                (onValueChanged)="onChangeHoraInicio($event)">
                            </jqxDateTimeInput>
                        </div>
                        <div style="float: right; margin-right: 15px;" style="margin-top: 5px">
                            {{translate('Hora_fin')}}
                            <jqxDateTimeInput #horaFin [width]="95" [height]="22" [dropDownVerticalAlignment]="'bottom'"
                                [formatString]="'HH:mm:ss'" [showTimeButton]="true" [showCalendarButton]="false"
                                [value]="value" (onValueChanged)="onChangeHoraFin($event)">
                            </jqxDateTimeInput>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="inputSelect">
                        <div class="col">
                            <div style="margin-right: 10px;">
                                {{translate('Todos_dias')}}
                                <input style="margin-left: 2px;" #todosDias type="checkbox"
                                    (change)="onChangeTodosDias($event)" />
                            </div>
                        </div>
                        <div class="col" style="display: flex;">
                            <label class="label" style="margin-right: 2px;">{{translate('Excepto')}}:</label>
                            <jqxDropDownList #jqxDropDownList [theme]="theme" [popupZIndex]="9999" [width]="187"
                                [height]="20" [source]="diasSemana" [valueMember]="'Id'" [filterable]="true"
                                [displayMember]="'Nombre'" [filterPlaceHolder]="'Buscar...'" [autoDropDownHeight]="true"
                                [checkboxes]="true" (onSelect)="selectDiaSemana($event)">
                            </jqxDropDownList>
                        </div>
                    </div>


                    <div class="row" id="myTabs" style="margin-top: 5px;">
                        <jqxTabs id="myTabs" #myTabs [width]="'100%'" [height]="115" [theme]="theme"
                            [scrollable]="true">
                            <ul>
                                <li>Lu<input class="listWeek" type="checkbox" id="checkbox0"></li>
                                <li>Ma<input class="listWeek" type="checkbox" id="checkbox1"></li>
                                <li>Mi<input class="listWeek" type="checkbox" id="checkbox2"></li>
                                <li>Ju<input class="listWeek" type="checkbox" id="checkbox3"></li>
                                <li>Vi<input class="listWeek" type="checkbox" id="checkbox4"></li>
                                <li>Sa<input class="listWeek" type="checkbox" id="checkbox5"></li>
                                <li>Do<input class="listWeek" type="checkbox" id="checkbox6"></li>
                            </ul>
                            <div class="content">
                                <div class="content-uno">
                                    <div class="textDate">{{translate('Hora_inicio')}}</div>
                                    <div id="dateTime0"></div>
                                </div>
                                <div class="content-dos">
                                    <div>{{translate('Hora_fin')}}</div>
                                    <div id="dateTimeDos0"></div>
                                </div>
                            </div>
                            <div class="content">
                                <div class="content-uno">
                                    <div class="textDate">{{translate('Hora_inicio')}}</div>
                                    <div id="dateTime1"></div>
                                </div>
                                <div class="content-dos">
                                    <div>{{translate('Hora_fin')}}</div>
                                    <div id="dateTimeDos1"></div>
                                </div>
                            </div>
                            <div class="content">
                                <div class="content-uno">
                                    <div class="textDate">{{translate('Hora_inicio')}}</div>
                                    <div id="dateTime2"></div>
                                </div>
                                <div class="content-dos">
                                    <div>{{translate('Hora_fin')}}</div>
                                    <div id="dateTimeDos2"></div>
                                </div>
                            </div>
                            <div class="content">
                                <div class="content-uno">
                                    <div class="textDate">{{translate('Hora_inicio')}}</div>
                                    <div id="dateTime3"></div>
                                </div>
                                <div class="content-dos">
                                    <div>{{translate('Hora_fin')}}</div>
                                    <div id="dateTimeDos3"></div>
                                </div>
                            </div>
                            <div class="content">
                                <div class="content-uno">
                                    <div class="textDate">{{translate('Hora_inicio')}}</div>
                                    <div id="dateTime4"></div>
                                </div>
                                <div class="content-dos">
                                    <div>{{translate('Hora_fin')}}</div>
                                    <div id="dateTimeDos4"></div>
                                </div>
                            </div>
                            <div class="content">
                                <div class="content-uno">
                                    <div class="textDate">{{translate('Hora_inicio')}}</div>
                                    <div id="dateTime5"></div>
                                </div>
                                <div class="content-dos">
                                    <div>{{translate('Hora_fin')}}</div>
                                    <div id="dateTimeDos5"></div>
                                </div>
                            </div>
                            <div class="content">
                                <div class="content-uno">
                                    <div class="textDate">{{translate('Hora_inicio')}}</div>
                                    <div id="dateTime6"></div>
                                </div>
                                <div class="content-dos">
                                    <div>{{translate('Hora_fin')}}</div>
                                    <div id="dateTimeDos6"></div>
                                </div>
                            </div>
                        </jqxTabs>
                        <div style="float: left; margin-top: 10px; width: 100%;">
                            <jqxButton #create style="float:left; margin-left: 2px;" [width]='100' [height]='26'
                                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                                [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
                                (onClick)="onGuardar($event)">
                            </jqxButton>
                        </div>
                    </div>
                </div>
            </div>
            <div style="float: left; width: 50%; height: 286px;">
                <jqxRibbon style="overflow: hidden; width: calc(100% - 5px);" class="jqx-widget-content-material"
                    [theme]="theme" [width]="'100%'" [height]="'100%'" [position]="'top'"
                    (onChange)="onClickDiasNo($event)">
                    <ul style="width: 100%; text-align: left">
                        <li style="height: 26px; vertical-align: middle">
                            {{ translate("Dias_especiales_servicio") }}
                        </li>
                        <li style="height: 26px; vertical-align: middle">
                            {{ translate("Dias_especiales_sin_servicio") }}
                        </li>
                    </ul>
                    <div>
                        <div style="overflow: hidden">
                            <jqxGrid #wridDiasSi [theme]="theme" [width]="'100%'" [height]="'214'"
                                [source]="dataAdapterDiasSi" [columnsresize]="true" [localization]="langGrid"
                                [filterable]="false" [showfilterrow]="false" [columns]="columns" [showrowlines]="false"
                                [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
                                [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
                                [columnsheight]="20" [enabletooltips]="true" [editable]="true"
                                (onCellendedit)="onCellEditSi($event)" (onRowclick)="onRowClickSi($event)">
                            </jqxGrid>
                            <jqxButton style="float:left; margin-left: 4px; margin-top: 10px;" [width]='80'
                                [height]='26' [imgPosition]="'left'" [textImageRelation]='"imageBeforeText"'
                                [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"' [value]="translate('Mas')"
                                (onClick)="onMasSi($event)">
                            </jqxButton>
                            <jqxButton style="float:left; margin-left: 4px; margin-top: 10px;" [width]='80'
                                [height]='26' [imgPosition]="'left'" [textImageRelation]='"imageBeforeText"'
                                [textPosition]='"left"' [imgSrc]='"/assets/images/menos.png"'
                                [value]="translate('Menos')" (onClick)="onMenosSi($event)">
                            </jqxButton>
                        </div>
                        <div style="overflow: hidden">
                            <jqxGrid #wridDiasNo [theme]="theme" [width]="'100%'" [height]="'214'"
                                [source]="dataAdapterDiasNo" [columnsresize]="true" [localization]="langGrid"
                                [filterable]="false" [showfilterrow]="false" [columns]="columns" [showrowlines]="false"
                                [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
                                [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
                                [columnsheight]="20" [enabletooltips]="true" [editable]="true"
                                (onCellendedit)="onCellEditNo($event)" (onRowclick)="onRowClickNo($event)">
                            </jqxGrid>
                            <jqxButton style="float:left; margin-left: 4px; margin-top: 10px;" [width]='80'
                                [height]='26' [imgPosition]="'left'" [textImageRelation]='"imageBeforeText"'
                                [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"' [value]="translate('Mas')"
                                (onClick)="onMasNo($event)">
                            </jqxButton>
                            <jqxButton style="float:left; margin-left: 4px; margin-top: 10px;" [width]='80'
                                [height]='26' [imgPosition]="'left'" [textImageRelation]='"imageBeforeText"'
                                [textPosition]='"left"' [imgSrc]='"/assets/images/menos.png"'
                                [value]="translate('Menos')" (onClick)="onMenosNo($event)">
                            </jqxButton>
                        </div>
                    </div>
                </jqxRibbon>
            </div>
        </div>

    </div>
</jqxWindow>